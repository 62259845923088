export const GET_HOT_INDUSTRIES = 'GET_HOT_INDUSTRIES';
export const GET_HOT_INDUSTRIES_SUCCESS = 'GET_HOT_INDUSTRIES_SUCCESS';
export const GET_HOT_INDUSTRIES_FAIL = 'GET_HOT_INDUSTRIES_FAIL';

export const CREATE_HOT_INDUSTRY = 'CREATE_HOT_INDUSTRY';
export const CREATE_HOT_INDUSTRY_SUCCESS = 'CREATE_HOT_INDUSTRY_SUCCESS';
export const CREATE_HOT_INDUSTRY_FAIL = 'CREATE_HOT_INDUSTRY_FAIL';

export const UPDATE_HOT_INDUSTRIES = 'UPDATE_HOT_INDUSTRIES';
export const UPDATE_HOT_INDUSTRIES_SUCCESS = 'UPDATE_HOT_INDUSTRIES_SUCCESS';
export const UPDATE_HOT_INDUSTRIES_FAIL = 'UPDATE_HOT_INDUSTRIES_FAIL';

export const SORT_HOT_INDUSTRIES = 'SORT_HOT_INDUSTRIES';
export const SORT_HOT_INDUSTRIES_SUCCESS = 'SORT_HOT_INDUSTRIES_SUCCESS';
export const SORT_HOT_INDUSTRIES_FAIL = 'SORT_HOT_INDUSTRIES_FAIL';

export const GET_HOT_INDUSTRIES_BY_ID = 'GET_HOT_INDUSTRIES_BY_ID';
export const GET_HOT_INDUSTRIES_BY_ID_SUCCESS = 'GET_HOT_INDUSTRIES_BY_ID_SUCCESS';
export const GET_HOT_INDUSTRIES_BY_ID_FAIL = 'GET_HOT_INDUSTRIES_BY_ID_FAIL';

export const GET_INDUSTRIES = 'GET_INDUSTRIES';
export const GET_INDUSTRIES_SUCCESS = 'GET_INDUSTRIES_SUCCESS';
export const GET_INDUSTRIES_FAIL = 'GET_INDUSTRIES_FAIL';

export const DELETE_INDUSTRY = 'DELETE_INDUSTRY';
export const DELETE_INDUSTRY_SUCCESS = 'DELETE_INDUSTRY_SUCCESS';
export const DELETE_INDUSTRY_FAIL = 'DELETE_INDUSTRY_FAIL';

export function getHotIndustries() {
  return {
    type: GET_HOT_INDUSTRIES,
  };
}

export function getHotIndustriesSuccess(data) {
  return {
    type: GET_HOT_INDUSTRIES_SUCCESS,
    data,
  };
}

export function getHotIndustriesFail(error) {
  return {
    type: GET_HOT_INDUSTRIES_FAIL,
    error,
  };
}

export function getHotIndustriesById(id) {
  return {
    type: GET_HOT_INDUSTRIES_BY_ID,
    id,
  };
}

export function getHotIndustriesByIdSuccess(data) {
  return {
    type: GET_HOT_INDUSTRIES_BY_ID_SUCCESS,
    data,
  };
}

export function getHotIndustriesByIdFail(error) {
  return {
    type: GET_HOT_INDUSTRIES_BY_ID_FAIL,
    error,
  };
}

export function createHotIndustry(params) {
  return {
    type: CREATE_HOT_INDUSTRY,
    params,
  };
}

export function createHotIndustrySuccess(data) {
  return {
    type: CREATE_HOT_INDUSTRY_SUCCESS,
    data,
  };
}

export function createHotIndustryFail(error) {
  return {
    type: CREATE_HOT_INDUSTRY_FAIL,
    error,
  };
}

export function updateHotIndustries(id, params) {
  return {
    type: UPDATE_HOT_INDUSTRIES,
    id,
    params,
  };
}

export function updateHotIndustriesSuccess(data) {
  return {
    type: UPDATE_HOT_INDUSTRIES_SUCCESS,
    data,
  };
}

export function updateHotIndustriesFail(error) {
  return {
    type: UPDATE_HOT_INDUSTRIES_FAIL,
    error,
  };
}

export function sortHotIndustries(items) {
  return {
    type: SORT_HOT_INDUSTRIES,
    items,
  };
}

export function HotIndustriesSuccess(data) {
  return {
    type: SORT_HOT_INDUSTRIES_SUCCESS,
    data,
  };
}

export function HotIndustriesFail(error) {
  return {
    type: SORT_HOT_INDUSTRIES_FAIL,
    error,
  };
}

export function getIndustries() {
  return {
    type: GET_INDUSTRIES,
  };
}

export function getIndustriesSuccess(data) {
  return {
    type: GET_INDUSTRIES_SUCCESS,
    data,
  };
}

export function getIndustriesFail(error) {
  return {
    type: GET_INDUSTRIES_FAIL,
    error,
  };
}

export function deleteHotIndustry(id) {
  return {
    type: DELETE_INDUSTRY,
    id,
  };
}

export function deleteHotIndustrySuccess(data) {
  return {
    type: DELETE_INDUSTRY_SUCCESS,
    data,
  };
}

export function deleteHotIndustryFail(error) {
  return {
    type: DELETE_INDUSTRY_FAIL,
    error,
  };
}
