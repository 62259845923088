/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-underscore-dangle */
export const addElementToBeginningArray = (array, element) => {
  const newAray = [...array];
  newAray.unshift(element);
  return newAray;
};

export const addElementToEndArray = (array, element) => {
  const newAray = [...array];
  newAray.push(element);
  return newAray;
};

export const addElementToBeginningArrayForNews = (array, element) => {
  const newelement = {
    ...element,
    category: {
      _id: element.categoryId,
    },
    _id: element.id,
  };
  const newAray = [...array];
  newAray.unshift(newelement);
  return newAray;
};

export const deleteElementFromArrayById = (array, id) => array.filter(item => item.id !== id);

export const deleteElementFromArrayByUnderscoreId = (array, id) => array.filter(item => item._id !== id);

// eslint-disable-next-line no-unused-vars
export const updateElementFromArrayById = (array, element, id) => array.map((item) => {
  if (item.id === element.id) {
    return element;
  }
  return item;
});

// eslint-disable-next-line no-unused-vars
export const updateHotCareersIndustries = (array, element) => {
  if (!element.isHot) {
    return array.filter(item => item.id !== element.id);
  }
  const isExist = array.find(item => item.id === element.id);
  if (!isExist) {
    const newAray = [...array];
    newAray.push(element);
    return newAray;
  }
  return array.map((item) => {
    if (item.id === element.id) {
      return element;
    }
    return item;
  });
};

// eslint-disable-next-line no-unused-vars
export const updateNewsList = (array, element, _id) => array.map((item) => {
  if (item._id === element.id) {
    item.isHot = element.isHot;
    item.status = element.status;
  }
  return item;
});

export const updateRecruiterFromArrayById = (array, element) => array.map((item) => {
  // eslint-disable-next-line no-underscore-dangle
  if (item._id === element.id) {
    item.status = element.isDeleted ? 'Inactive' : 'Active';
    item.rcEmail = element.rcEmail;
    item.rcSendEmailUrl = element.rcSendEmailUrl;
    return item;
  }
  return item;
});

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const updateJobseekerFromArrayById = (array, element) => array.map((item) => {
  // eslint-disable-next-line no-underscore-dangle
  if (item._id === element.id) {
    item.status = element.status;
    return item;
  }
  return item;
});

export const changeTextToSlug = (text) => {
  let slug = text.toLowerCase();

  slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
  slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
  slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
  slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
  slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
  slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
  slug = slug.replace(/đ/gi, 'd');


  slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');

  slug = slug.replace(/ /gi, '-');

  slug = slug.replace(/\-\-\-\-\-/gi, '-');
  slug = slug.replace(/\-\-\-\-/gi, '-');
  slug = slug.replace(/\-\-\-/gi, '-');
  slug = slug.replace(/\-\-/gi, '-');

  slug = `@${slug}@`;

  slug = slug.replace(/\@\-|\-\@|\@/gi, '');
  slug = slug.replace(' ', '');
  return slug;
};
