import { take, call, put } from 'redux-saga/effects';
import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAIL,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAIL,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  GET_TAG_BY_ID,
  GET_TAG_BY_ID_SUCCESS,
  GET_TAG_BY_ID_FAIL,
} from '../actions/tagAction';
import {
  getTags,
  createTag,
  updateTag,
  deleteTag,
  getTagById,
} from '../../shared/api/methods/tag';

export function* getTagsSaga() {
  while (true) {
    try {
      const { tagTyes } = yield take(GET_TAGS);
      const data = yield call(getTags, tagTyes);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_TAGS_FAIL, error });
      } else {
        yield put({ type: GET_TAGS_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_TAGS_FAIL, error });
    }
  }
}

export function* createTagSaga() {
  while (true) {
    try {
      const { params } = yield take(CREATE_TAG);
      const data = yield call(createTag, params);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: CREATE_TAG_FAIL, error });
      } else {
        yield put({ type: CREATE_TAG_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: CREATE_TAG_FAIL, error });
    }
  }
}

export function* updateTagSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_TAG);
      const data = yield call(updateTag, [id, params]);
      const { isDeleted } = params;
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_TAG_FAIL, error, isDeleted });
      } else {
        yield put({ type: UPDATE_TAG_SUCCESS, data, isDeleted });
      }
    } catch (error) {
      yield put({ type: UPDATE_TAG_FAIL, error });
    }
  }
}

export function* deleteTagSaga() {
  while (true) {
    try {
      const { id } = yield take(DELETE_TAG);
      const data = yield call(deleteTag, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: DELETE_TAG_FAIL, error });
      } else {
        yield put({ type: DELETE_TAG_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: DELETE_TAG_FAIL, error });
    }
  }
}

export function* getTagByIdSaga() {
  while (true) {
    try {
      const { id } = yield take(GET_TAG_BY_ID);
      const data = yield call(getTagById, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_TAG_BY_ID_FAIL, error });
      } else {
        yield put({ type: GET_TAG_BY_ID_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_TAG_BY_ID_FAIL, error });
    }
  }
}
