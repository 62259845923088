import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import EditForm from './EditForm';
import JobList from './JobList';

class JobseekerDetailt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onUpdateJobseeker = (jobseeker) => {
    const { data, updateJobseeker } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    updateJobseeker(data._id, jobseeker);
  }

  onHandleCancel = () => {
    const { onClickCancel } = this.props;
    onClickCancel();
  }

  render() {
    const { data, jobsList } = this.props;
    return (
      <Card>
        <EditForm
          data={data}
          updateJobseeker={jobseeker => this.onUpdateJobseeker(jobseeker)}
          cancelEdit={this.onHandleCancel}
        />
        <hr />
        <JobList data={jobsList} />
      </Card>
    );
  }
}
JobseekerDetailt.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  updateJobseeker: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  jobsList: PropTypes.instanceOf(Object).isRequired,
};

export default JobseekerDetailt;
