/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  ModalBody, Form, FormGroup, Label, Input, Row, Col, Button, Table, ButtonGroup,
} from 'reactstrap';
import DeleteIcon from 'mdi-react/TrashIcon';
import EditIcon from 'mdi-react/EditIcon';
import AddIcon from 'mdi-react/AddIcon';
import TableItem from './TableItem';

class DetailsAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    // eslint-disable-next-line react/no-unused-state
    };
  }

  render() {
    const {
      state,
      addTableItem,
      getDataItem,
      handleChangeEvent,
      deleteTableItem,
      updateTableItem,
      modal,
      msgError,
    } = this.props;
    const { dataField } = state;
    let html;
    const fieldType = dataField.type;
    if (fieldType === 'Set') {
      const {
        labelItemEnglish,
        labelItemVn,
        tooltipItemEnglish,
        tooltipItemVn,
      } = state;

      html = (
        <div>
          <Row row>
            <Col for="exampleEmail" sm={2}><strong>Recruiter:</strong></Col>
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="recruiterSelect" value="multiple" checked={!!dataField.detail && !!dataField.detail.recruiterSelect && dataField.detail.recruiterSelect === 'multiple'} />
                  Select multi
                </Label>
              </FormGroup>
            </Col>
            <Col for="exampleEmail" sm={2}><strong>Applicant:</strong></Col>
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="applicantSelect" value="multiple" checked={!!dataField.detail && !!dataField.detail.applicantSelect && dataField.detail.applicantSelect === 'multiple'} />
                  Select multi
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row row>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="recruiterSelect" value="one" checked={!!dataField.detail && !!dataField.detail.recruiterSelect && dataField.detail.recruiterSelect === 'one'} />
                  Select one
                </Label>
              </FormGroup>
            </Col>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="applicantSelect" value="one" checked={!!dataField.detail && !!dataField.detail.applicantSelect && dataField.detail.applicantSelect === 'one'} />
                  Select one
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col for="exampleEmail" sm={2}><strong>DisplayType:</strong></Col>
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="displayType" value="Dropdown" checked={!!dataField.detail && !!dataField.detail.displayType && dataField.detail.displayType === 'Dropdown'} />
                  Display Dropdown
                </Label>
              </FormGroup>
            </Col>
            <Col for="exampleEmail" sm={3}><strong>Matching condition:</strong></Col>
            <Col sm={3}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="matchingCondition" value="And" checked={!!dataField.detail && !!dataField.detail.matchingCondition && dataField.detail.matchingCondition === 'And'} />
                  And
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="displayType" value="Pickup" checked={!!dataField.detail && !!dataField.detail.displayType && dataField.detail.displayType === 'Pickup'} />
                  Display Pickup
                </Label>
              </FormGroup>
            </Col>
            <Col sm={3} />
            <Col sm={3}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="matchingCondition" value="Or" checked={!!dataField.detail && !!dataField.detail.matchingCondition && dataField.detail.matchingCondition === 'Or'} />
                  Or
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <FormGroup row>
            <Col for="exampleEmail" sm={2}><strong>Label:</strong></Col>
            <Col sm={5}>
              <Input type="text" name="labelItemEnglish" id="exampleEmail" placeholder="English" value={labelItemEnglish} />
            </Col>
            <Col sm={5}>
              <Input type="text" name="labelItemVn" id="exampleEmail" placeholder="Vietnamese" value={labelItemVn} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col for="exampleEmail" sm={2}><strong>Tooltip:</strong></Col>
            <Col sm={5}>
              <Input type="textarea" name="tooltipItemEnglish" id="exampleEmail" placeholder="English" value={tooltipItemEnglish} />
            </Col>
            <Col sm={5}>
              <Input type="textarea" name="tooltipItemVn" id="exampleEmail" placeholder="Vietnamese" value={tooltipItemVn} />
            </Col>
          </FormGroup>
          <FormGroup>
            <ButtonGroup className="float-right">
              <Button outline color="danger" size="sm" onClick={deleteTableItem}><p><DeleteIcon /></p></Button>
              <Button outline color="primary" size="sm" onClick={updateTableItem}><p><EditIcon /></p></Button>
              <Button outline color="primary" size="sm" onClick={addTableItem}><p><AddIcon /></p></Button>
            </ButtonGroup>
          </FormGroup>
          <Table striped>
            <thead>
              <tr>
                <th>Value(En)</th>
                <th>Value(Vi)</th>
                <th>Tooltip(En)</th>
                <th>Tooltip(Vi)</th>
              </tr>
            </thead>
            <tbody>
              <TableItem state={state} getDataItem={getDataItem} />
            </tbody>
          </Table>
        </div>
      );
    } else if (fieldType === 'Location') {
      html = (
        <div>
          <Row row>
            <Col for="exampleEmail" sm={2}><strong>Recruiter select district:</strong></Col>
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="recruiterSelect" value="multiple" checked={!!dataField.detail && !!dataField.detail.recruiterSelect && dataField.detail.recruiterSelect === 'multiple'} />
                  Select multi
                </Label>
              </FormGroup>
            </Col>
            <Col for="exampleEmail" sm={2}><strong>Applicant select district:</strong></Col>
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="applicantSelect" value="multiple" checked={!!dataField.detail && !!dataField.detail.applicantSelect && dataField.detail.applicantSelect === 'multiple'} />
                  Select multi
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup row>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="recruiterSelect" value="one" checked={!!dataField.detail && !!dataField.detail.recruiterSelect && dataField.detail.recruiterSelect === 'one'} />
                  Select one
                </Label>
              </FormGroup>
            </Col>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="applicantSelect" value="one" checked={!!dataField.detail && !!dataField.detail.applicantSelect && dataField.detail.applicantSelect === 'one'} />
                  Select one
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="recruiterShowProvinceCity" checked={!!dataField.detail && !!dataField.detail.recruiterShowProvinceCity} />
                  Show Province/City
                </Label>
              </FormGroup>
            </Col>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="applicantShowProvinceCity" checked={!!dataField.detail && !!dataField.detail.applicantShowProvinceCity} />
                  Show Province/City
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="recruiterShowDistrict" checked={!!dataField.detail && !!dataField.detail.recruiterShowDistrict} />
                  Show District
                </Label>
              </FormGroup>
            </Col>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="applicantShowDistrict" checked={!!dataField.detail && !!dataField.detail.applicantShowDistrict} />
                  Show District
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
        </div>
      );
    } else if (fieldType === 'Range') {
      html = (
        <div>
          <Row row>
            <Col for="exampleEmail" sm={2}><strong>Recruiter:</strong></Col>
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="allowRecruiterEnterRange" value="true" checked={dataField.detail.allowRecruiterEnterRange === true} />
                  Enter Range
                </Label>
              </FormGroup>
            </Col>
            <Col for="exampleEmail" sm={2}><strong>Applicant:</strong></Col>
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="allowApplicantEnterRange" value="true" checked={dataField.detail.allowApplicantEnterRange === true} />
                  Enter Range
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row row>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="allowRecruiterEnterRange" value="false" checked={dataField.detail.allowRecruiterEnterRange === false} />
                  Enter 1 Value
                </Label>
              </FormGroup>
            </Col>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="allowApplicantEnterRange" value="false" checked={dataField.detail.allowApplicantEnterRange === false} />
                  Enter 1 Value
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row row>
            <Col for="exampleEmail" sm={2}><strong>From:</strong></Col>
            <Col sm={4}>
              <FormGroup>
                <Input type="number" min={0} name="rangeFrom" id="exampleEmail" value={dataField.detail.gteValue ? dataField.detail.gteValue : null} />
              </FormGroup>
            </Col>
            <Col sm={1} />
            <Col for="exampleEmail" sm={1}><strong>To:</strong></Col>
            <Col sm={4}>
              <FormGroup>
                <Input type="number" min={0} name="rangeTo" id="exampleEmail" value={dataField.detail.lteValue ? dataField.detail.lteValue : null} />
              </FormGroup>
            </Col>
          </Row>
          <Row row>
            <Col for="exampleEmail" sm={2}><strong>Unit label:</strong></Col>
            <Col sm={4}>
              <FormGroup>
                <Input type="text" name="unitLabelEnglish" id="exampleEmail" placeholder="English" value={dataField.detail.unitLabel ? dataField.detail.unitLabel.en : ''} />
              </FormGroup>
            </Col>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup>
                <Input type="text" name="unitLabelVn" id="exampleEmail" placeholder="Vietnamese" value={dataField.detail.unitLabel ? dataField.detail.unitLabel.vi : ''} />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <FormGroup row>
            <Col sm={2} />
            <Col sm={10}>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="additionalField" checked={!!dataField.detail && !!dataField.detail.additionalField} />
                  Additional Field(To enter year, for example)
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
        </div>
      );
    } else if (fieldType === 'Number') {
      html = (
        <div>
          <FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="enterValue" value="applicant" checked={!!dataField.detail && !!dataField.detail.enterValue && dataField.detail.enterValue === 'applicant'} />
                For applicant enter value only
              </Label>
            </FormGroup>
          </FormGroup>
          <FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="enterValue" value="both" checked={!!dataField.detail && !!dataField.detail.enterValue && dataField.detail.enterValue === 'both'} />
                Both applicant and recuiter enter value
              </Label>
            </FormGroup>
          </FormGroup>
          <FormGroup row>
            <Col for="exampleEmail" sm={2}><strong>Suffix label:</strong></Col>
            <Col sm={5}>
              <FormGroup>
                <Input type="text" name="suffixLabelEnglish" id="exampleEmail" value={dataField.detail.suffixLabel ? dataField.detail.suffixLabel.en : ''} placeholder="English" />
              </FormGroup>
            </Col>
            <Col sm={5}>
              <FormGroup>
                <Input type="text" name="suffixLabelVn" id="exampleEmail" value={dataField.detail.suffixLabel ? dataField.detail.suffixLabel.vi : ''} placeholder="Vietnamese" />
              </FormGroup>
            </Col>
          </FormGroup>
        </div>
      );
    } else if (fieldType === 'Slider') {
      html = (
        <div>
          <Row row>
            <Col for="exampleEmail" sm={2}><strong>Recruiter:</strong></Col>
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="recruiterSelect" value="range" checked={!!dataField.detail && !!dataField.detail.recruiterSelect && dataField.detail.recruiterSelect === 'range'} />
                  Select range
                </Label>
              </FormGroup>
            </Col>
            <Col for="exampleEmail" sm={2}><strong>Applicant:</strong></Col>
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="applicantSelect" value="range" checked={!!dataField.detail && !!dataField.detail.applicantSelect && dataField.detail.applicantSelect === 'range'} />
                  Select range
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row row>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="recruiterSelect" value="one" checked={!!dataField.detail && !!dataField.detail.recruiterSelect && dataField.detail.recruiterSelect === 'one'} />
                  Select one
                </Label>
              </FormGroup>
            </Col>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="applicantSelect" value="one" checked={!!dataField.detail && !!dataField.detail.applicantSelect && dataField.detail.applicantSelect === 'one'} />
                  Select one
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <FormGroup row>
            <Col for="exampleEmail" sm={1}><strong>Min:</strong></Col>
            <Col sm={3}>
              <Input type="number" min={0} name="min" id="exampleEmail" value={dataField.detail.gteValue ? dataField.detail.gteValue : null} placeholder="" />
            </Col>
            <Col for="exampleEmail" sm={1}><strong>Max:</strong></Col>
            <Col sm={3}>
              <Input type="number" min={0} name="max" id="exampleEmail" value={dataField.detail.lteValue ? dataField.detail.lteValue : ''} placeholder="" />
            </Col>
            <Col for="exampleEmail" sm={1}><strong>Step:</strong></Col>
            <Col sm={3}>
              <Input type="number" min={0} name="step" id="exampleEmail" value={dataField.detail.step ? dataField.detail.step : ''} placeholder="" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col for="exampleEmail" sm={2}><strong>Min label:</strong></Col>
            <Col sm={5}>
              <Input type="text" name="labelMinEnglish" id="exampleEmail" value={dataField.detail.labelMin ? dataField.detail.labelMin.en : ''} placeholder="English" />
            </Col>
            <Col sm={5}>
              <Input type="text" name="labelMinVn" id="exampleEmail" value={dataField.detail.labelMin ? dataField.detail.labelMin.vi : ''} placeholder="Vietnamese" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col for="exampleEmail" sm={2}><strong>Max label:</strong></Col>
            <Col sm={5}>
              <Input type="text" name="labelMaxEnglish" id="exampleEmail" value={dataField.detail.labelMax ? dataField.detail.labelMax.en : ''} placeholder="English" />
            </Col>
            <Col sm={5}>
              <Input type="text" name="labelMaxVn" id="exampleEmail" value={dataField.detail.labelMax ? dataField.detail.labelMax.vi : ''} placeholder="Vietnamese" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2} />
            <Col sm={10}>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="additionalField" checked={!!dataField.detail && !!dataField.detail.additionalField} />
                  Additional Field(To enter year, for example)
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
        </div>
      );
    } else if (fieldType === 'InputText') {
      html = (
        <div>
          <Form>
            <Row row>
              <Col for="exampleEmail" sm={2}><strong>Placeholder:</strong></Col>
              <Col sm={5}>
                <Input type="text" name="placeholderEnglish" id="exampleEmail" value={dataField.detail.placeholder ? dataField.detail.placeholder.en : ''} placeholder="English" />
              </Col>
              <Col sm={5}>
                <Input type="text" name="placeholderVn" id="exampleEmail" value={dataField.detail.placeholder ? dataField.detail.placeholder.vi : ''} placeholder="Vietnamese" />
              </Col>
            </Row>
            <br />
            <Row row>
              <Col sm={2} />
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="allowMultiline" checked={!!dataField.detail && !!dataField.detail.allowMultiline} />
                    AllowMultiline
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br />
            { dataField.detail.allowMultiline
            && (
              <div>
                <Row row>
                  <Col for="exampleEmail" sm={2}><strong>Cols:</strong></Col>
                  <Col sm={5}>
                    <Input type="number" min={0} name="cols" id="exampleEmail" value={dataField.detail.cols ? dataField.detail.cols : ''} />
                  </Col>
                </Row>
                <br />
                <Row row>
                  <Col for="exampleEmail" sm={2}><strong>Rows:</strong></Col>
                  <Col sm={5}>
                    <Input type="number" min={0} name="rows" id="exampleEmail" value={dataField.detail.rows ? dataField.detail.rows : ''} />
                  </Col>
                </Row>
              </div>
            )}
          </Form>
        </div>
      );
    } else if (fieldType === 'Year') {
      html = (
        <div>
          <Row row>
            <Col for="exampleEmail" sm={2}><strong>Recruiter:</strong></Col>
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="recruiterSelect" value="multiple" checked={!!dataField.detail && !!dataField.detail.recruiterSelect && dataField.detail.recruiterSelect === 'multiple'} />
                  Select multi
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup row>
            <Col sm={2} />
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="recruiterSelect" value="one" checked={!!dataField.detail && !!dataField.detail.recruiterSelect && dataField.detail.recruiterSelect === 'one'} />
                  Select one
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
          <Row row>
            <Col for="exampleEmail" sm={1}><strong>Min:</strong></Col>
            <Col sm={4}>
              <FormGroup>
                <Input type="number" name="min" value={dataField.detail.gteValue ? dataField.detail.gteValue : null} id="exampleEmail" />
              </FormGroup>
            </Col>
            <Col sm={1} />
            <Col for="exampleEmail" sm={1}><strong>Max:</strong></Col>
            <Col sm={4}>
              <FormGroup>
                <Input type="number" name="max" value={dataField.detail.lteValue ? dataField.detail.lteValue : null} id="exampleEmail" />
              </FormGroup>
            </Col>
          </Row>
        </div>
      );
    } else if (fieldType === 'Language') {
      const {
        languageEnglish,
        languageVn,
        listeningLte,
        listeningGte,
        speakingLte,
        speakingGte,
        readingLte,
        readingGte,
        writingLte,
        writingGte,
      } = state;
      html = (
        <div>
          <FormGroup row>
            <Col for="exampleEmail" sm={2}><strong>Language:</strong></Col>
            <Col sm={5}>
              <Input type="text" name="languageEnglish" id="exampleEmail" placeholder="English" value={languageEnglish} />
            </Col>
            <Col sm={5}>
              <Input type="text" name="languageVn" id="exampleEmail" placeholder="Vietnamese" value={languageVn} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col for="exampleEmail" sm={2}><strong>Listening:</strong></Col>
            <Col sm={5}>
              <Input type="number" min={0} name="listeningGte" id="exampleEmail" placeholder="Min Mark" value={listeningGte} />
            </Col>
            <Col sm={5}>
              <Input type="number" min={0} name="listeningLte" id="exampleEmail" placeholder="Max Mark" value={listeningLte} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col for="exampleEmail" sm={2}><strong>Speaking:</strong></Col>
            <Col sm={5}>
              <Input type="number" min={0} name="speakingGte" id="exampleEmail" placeholder="Min Mark" value={speakingGte} />
            </Col>
            <Col sm={5}>
              <Input type="number" min={0} name="speakingLte" id="exampleEmail" placeholder="Max Mark" value={speakingLte} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col for="exampleEmail" sm={2}><strong>Reading:</strong></Col>
            <Col sm={5}>
              <Input type="number" min={0} name="readingGte" id="exampleEmail" placeholder="Min Mark" value={readingGte} />
            </Col>
            <Col sm={5}>
              <Input type="number" min={0} name="readingLte" id="exampleEmail" placeholder="Max Mark" value={readingLte} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col for="exampleEmail" sm={2}><strong>Writing:</strong></Col>
            <Col sm={5}>
              <Input type="number" min={0} name="writingGte" id="exampleEmail" placeholder="Min Mark" value={writingGte} />
            </Col>
            <Col sm={5}>
              <Input type="number" min={0} name="writingLte" id="exampleEmail" placeholder="Max Mark" value={writingLte} />
            </Col>
          </FormGroup>
          <FormGroup>
            <ButtonGroup className="float-right">
              <Button outline color="danger" size="sm" onClick={deleteTableItem}><p><DeleteIcon /></p></Button>
              <Button outline color="primary" size="sm" onClick={updateTableItem}><p><EditIcon /></p></Button>
              <Button outline color="primary" size="sm" onClick={addTableItem}><p><AddIcon /></p></Button>
            </ButtonGroup>
          </FormGroup>
          <Table striped>
            <thead>
              <tr>
                <th>Language(En)</th>
                <th>Language(Vi)</th>
                <th>Listening</th>
                <th>Speaking</th>
                <th>Reading</th>
                <th>Writing</th>
              </tr>
            </thead>
            <tbody>
              <TableItem state={state} getDataItem={getDataItem} />
            </tbody>
          </Table>
        </div>
      );
    } else if (fieldType === 'Currency') {
      const { unit } = state;
      html = (
        <div>
          <FormGroup row>
            <Col for="exampleEmail" sm={2}><strong>Unit:</strong></Col>
            <Col sm={5}>
              <Input type="text" name="unit" id="exampleEmail" placeholder="" value={unit} />
            </Col>
          </FormGroup>
          <FormGroup>
            <ButtonGroup className="float-right">
              <Button outline color="danger" size="sm" onClick={deleteTableItem}><p><DeleteIcon /></p></Button>
              <Button outline color="primary" size="sm" onClick={updateTableItem}><p><EditIcon /></p></Button>
              <Button outline color="primary" size="sm" onClick={addTableItem}><p><AddIcon /></p></Button>
            </ButtonGroup>
          </FormGroup>
          <Table striped>
            <thead>
              <tr>
                <th>Unit</th>
              </tr>
            </thead>
            <tbody>
              <TableItem state={state} getDataItem={getDataItem} />
            </tbody>
          </Table>
        </div>
      );
    } else if (fieldType === 'Point') {
      html = (
        <div>
          <FormGroup row>
            <Col for="exampleEmail" sm={1}><strong>Min:</strong></Col>
            <Col sm={3}>
              <Input type="number" min={0} name="min" id="exampleEmail" value={dataField.detail.lteValue ? dataField.detail.lteValue : null} placeholder="" />
            </Col>
            <Col for="exampleEmail" sm={1}><strong>Max:</strong></Col>
            <Col sm={3}>
              <Input type="number" min={0} name="max" id="exampleEmail" value={dataField.detail.gteValue ? dataField.detail.gteValue : null} placeholder="" />
            </Col>
            <Col for="exampleEmail" sm={1}><strong>Step:</strong></Col>
            <Col sm={3}>
              <Input type="number" min={0} name="step" id="exampleEmail" value={dataField.detail.step ? dataField.detail.step : null} placeholder="" />
            </Col>
          </FormGroup>
        </div>
      );
    } else if (fieldType === 'Skill') {
      html = (
        <div>
          <FormGroup row>
            <Col sm={2} />
            <Col sm={5}>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="isShowYearsExperience" checked={!!dataField.detail.isShowYearsExperience} />
                  {'Show year\'s experience'}
                </Label>
              </FormGroup>
            </Col>
            <Col sm={5} />
          </FormGroup>
          <FormGroup row>
            <Col sm={2} />
            <Col sm={5}>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="isShowRate" checked={!!dataField.detail && !!dataField.detail.isShowRate} />Show rate
                </Label>
              </FormGroup>
            </Col>
            <Col sm={5} />
          </FormGroup>
          { dataField.detail.isShowRate && (
            <div>
              <Row row>
                <Col sm={2}>
                  <b>Title Description</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="textarea" name="titleDescriptiontipEnglish" id="exampleEmail" value={dataField.detail.titleDescription && dataField.detail.titleDescription.en ? dataField.detail.titleDescription.en : ''} placeholder="English" />
                    </Col>
                    <Col sm={5}>
                      <Input type="textarea" name="titleDescriptionVn" id="exampleEmail" value={dataField.detail.titleDescription && dataField.detail.titleDescription.vi ? dataField.detail.titleDescription.vi : ''} placeholder="Vietnamese" />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup row>
                <Col sm={2} />
                <Col sm={10} row>
                  <b>Column 1</b>
                </Col>
              </FormGroup>
              <Row row>
                <Col sm={2}>
                  <b>Header</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="header1English" id="exampleEmail" value={dataField.detail.columns[0].header.en ? dataField.detail.columns[0].header.en : ''} placeholder="English" />
                      <p className="text-danger">{!dataField.detail.columns[0].header.en || (dataField.detail.columns[0].header.en && dataField.detail.columns[0].header.en === '') ? msgError : ''}</p>
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="header1Vn" id="exampleEmail" value={dataField.detail.columns[0].header.vi ? dataField.detail.columns[0].header.vi : ''} placeholder="Vietnamese" />
                      <p className="text-danger">{!dataField.detail.columns[0].header.vi || (dataField.detail.columns[0].header.vi && dataField.detail.columns[0].header.vi === '') ? msgError : ''}</p>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row row>
                <Col sm={2}>
                  <b>Header Tooltip</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="header1TooltipEnglish" id="exampleEmail" value={dataField.detail.columns[0].headerTooltip.en ? dataField.detail.columns[0].headerTooltip.en : ''} placeholder="English" />
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="header1TooltipVn" id="exampleEmail" value={dataField.detail.columns[0].headerTooltip.vi ? dataField.detail.columns[0].headerTooltip.vi : ''} placeholder="Vietnamese" />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row row>
                <Col sm={2}>
                  <b>Row 1</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="row1Col1English" id="exampleEmail" value={dataField.detail.columns[0].rows[0].en ? dataField.detail.columns[0].rows[0].en : ''} placeholder="English" />
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="row1Col1Vn" id="exampleEmail" value={dataField.detail.columns[0].rows[0].vi ? dataField.detail.columns[0].rows[0].vi : ''} placeholder="Vietnamese" />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row row>
                <Col sm={2}>
                  <b>Row 2</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="row2Col1English" id="exampleEmail" value={dataField.detail.columns[0].rows[1].en ? dataField.detail.columns[0].rows[1].en : ''} placeholder="English" />
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="row2Col1Vn" id="exampleEmail" value={dataField.detail.columns[0].rows[1].vi ? dataField.detail.columns[0].rows[1].vi : ''} placeholder="Vietnamese" />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row row>
                <Col sm={2}>
                  <b>Row 3</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="row3Col1English" id="exampleEmail" value={dataField.detail.columns[0].rows[2].en ? dataField.detail.columns[0].rows[2].en : ''} placeholder="English" />
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="row3Col1Vn" id="exampleEmail" value={dataField.detail.columns[0].rows[2].vi ? dataField.detail.columns[0].rows[2].vi : ''} placeholder="Vietnamese" />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row row>
                <Col sm={2}>
                  <b>Row 4</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="row4Col1English" id="exampleEmail" value={dataField.detail.columns[0].rows[3].en ? dataField.detail.columns[0].rows[3].en : ''} placeholder="English" />
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="row4Col1Vn" id="exampleEmail" value={dataField.detail.columns[0].rows[3].vi ? dataField.detail.columns[0].rows[3].vi : ''} placeholder="Vietnamese" />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row row>
                <Col sm={2}>
                  <b>Row 5</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="row5Col1English" id="exampleEmail" value={dataField.detail.columns[0].rows[4].en ? dataField.detail.columns[0].rows[4].en : ''} placeholder="English" />
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="row5Col1Vn" id="exampleEmail" value={dataField.detail.columns[0].rows[4].vi ? dataField.detail.columns[0].rows[4].vi : ''} placeholder="Vietnamese" />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup row>
                <Col sm={2} />
                <Col sm={10} row>
                  <b>Column 2</b>
                </Col>
              </FormGroup>
              <Row row>
                <Col sm={2}>
                  <b>Header</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="header2English" id="exampleEmail" value={dataField.detail.columns[1].header.en ? dataField.detail.columns[1].header.en : ''} placeholder="English" />
                      <p className="text-danger">{!dataField.detail.columns[1].header.en || (dataField.detail.columns[1].header.en && dataField.detail.columns[1].header.en === '') ? msgError : ''}</p>
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="header2Vn" id="exampleEmail" value={dataField.detail.columns[1].header.vi ? dataField.detail.columns[1].header.vi : ''} placeholder="Vietnamese" />
                      <p className="text-danger">{!dataField.detail.columns[1].header.vi || (dataField.detail.columns[1].header.vi && dataField.detail.columns[1].header.vi === '') ? msgError : ''}</p>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row row>
                <Col sm={2}>
                  <b>Header Tooltip</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="header2TooltipEnglish" id="exampleEmail" value={dataField.detail.columns[1].headerTooltip.en ? dataField.detail.columns[1].headerTooltip.en : ''} placeholder="English" />
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="header2TooltipVn" id="exampleEmail" value={dataField.detail.columns[1].headerTooltip.vi ? dataField.detail.columns[1].headerTooltip.vi : ''} placeholder="Vietnamese" />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row row>
                <Col sm={2}>
                  <b>Row 1</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="row1Col2English" id="exampleEmail" value={dataField.detail.columns[1].rows[0].en ? dataField.detail.columns[1].rows[0].en : ''} placeholder="English" />
                      <p className="text-danger">{!dataField.detail.columns[1].rows[0].en || (dataField.detail.columns[1].rows[0].en && dataField.detail.columns[1].rows[0].en === '') ? msgError : ''}</p>
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="row1Col2Vn" id="exampleEmail" value={dataField.detail.columns[1].rows[0].vi ? dataField.detail.columns[1].rows[0].vi : ''} placeholder="Vietnamese" />
                      <p className="text-danger">{!dataField.detail.columns[1].rows[0].vi || (dataField.detail.columns[1].rows[0].vi && dataField.detail.columns[1].rows[0].vi === '') ? msgError : ''}</p>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row row>
                <Col sm={2}>
                  <b>Row 2</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="row2Col2English" id="exampleEmail" value={dataField.detail.columns[1].rows[1].en ? dataField.detail.columns[1].rows[1].en : ''} placeholder="English" />
                      <p className="text-danger">{!dataField.detail.columns[1].rows[1].en || (dataField.detail.columns[1].rows[1].en && dataField.detail.columns[1].rows[1].en === '') ? msgError : ''}</p>
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="row2Col2Vn" id="exampleEmail" value={dataField.detail.columns[1].rows[1].vi ? dataField.detail.columns[1].rows[1].vi : ''} placeholder="Vietnamese" />
                      <p className="text-danger">{!dataField.detail.columns[1].rows[1].vi || (dataField.detail.columns[1].rows[1].vi && dataField.detail.columns[1].rows[1].vi === '') ? msgError : ''}</p>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row row>
                <Col sm={2}>
                  <b>Row 3</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="row3Col2English" id="exampleEmail" value={dataField.detail.columns[1].rows[2].en ? dataField.detail.columns[1].rows[2].en : ''} placeholder="English" />
                      <p className="text-danger">{!dataField.detail.columns[1].rows[2].en || (dataField.detail.columns[1].rows[2].en && dataField.detail.columns[1].rows[2].en === '') ? msgError : ''}</p>
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="row3Col2Vn" id="exampleEmail" value={dataField.detail.columns[1].rows[2].vi ? dataField.detail.columns[1].rows[2].vi : ''} placeholder="Vietnamese" />
                      <p className="text-danger">{!dataField.detail.columns[1].rows[2].vi || (dataField.detail.columns[1].rows[2].vi && dataField.detail.columns[1].rows[2].vi === '') ? msgError : ''}</p>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row row>
                <Col sm={2}>
                  <b>Row 4</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="row4Col2English" id="exampleEmail" value={dataField.detail.columns[1].rows[3].en ? dataField.detail.columns[1].rows[3].en : ''} placeholder="English" />
                      <p className="text-danger">{!dataField.detail.columns[1].rows[3].en || (dataField.detail.columns[1].rows[3].en && dataField.detail.columns[1].rows[3].en === '') ? msgError : ''}</p>
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="row4Col2Vn" id="exampleEmail" value={dataField.detail.columns[1].rows[3].vi ? dataField.detail.columns[1].rows[3].vi : ''} placeholder="Vietnamese" />
                      <p className="text-danger">{!dataField.detail.columns[1].rows[3].vi || (dataField.detail.columns[1].rows[3].vi && dataField.detail.columns[1].rows[3].vi === '') ? msgError : ''}</p>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row row>
                <Col sm={2}>
                  <b>Row 5</b>
                </Col>
                <Col sm={10}>
                  <FormGroup row>
                    <Col sm={5}>
                      <Input type="text" name="row5Col2English" id="exampleEmail" value={dataField.detail.columns[1].rows[4].en ? dataField.detail.columns[1].rows[4].en : ''} placeholder="English" />
                      <p className="text-danger">{!dataField.detail.columns[1].rows[4].en || (dataField.detail.columns[1].rows[4].en && dataField.detail.columns[1].rows[4].en === '') ? msgError : ''}</p>
                    </Col>
                    <Col sm={5}>
                      <Input type="text" name="row5Col2Vn" id="exampleEmail" value={dataField.detail.columns[1].rows[4].vi ? dataField.detail.columns[1].rows[4].vi : ''} placeholder="Vietnamese" />
                      <p className="text-danger">{!dataField.detail.columns[1].rows[4].vi || (dataField.detail.columns[1].rows[4].vi && dataField.detail.columns[1].rows[4].vi === '') ? msgError : ''}</p>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          )}
        </div>
      );
    }

    const htmlModal = (
      <ModalBody>
        <h2>{ modal.isEdit ? 'Edit Field' : `Add New ${dataField.type}`}</h2>
        <hr />
        <Form onChange={handleChangeEvent}>
          {html}
        </Form>
      </ModalBody>
    );

    return htmlModal;
  }
}

DetailsAttribute.propTypes = {
  state: PropTypes.objectOf(PropTypes.object).isRequired,
  addTableItem: PropTypes.func.isRequired,
  getDataItem: PropTypes.func.isRequired,
  handleChangeEvent: PropTypes.func.isRequired,
  deleteTableItem: PropTypes.func.isRequired,
  updateTableItem: PropTypes.func.isRequired,
  modal: PropTypes.objectOf(PropTypes.object).isRequired,
  msgError: PropTypes.string.isRequired,
};

export default connect(
  null,
  null,
)(withTranslation('common')(DetailsAttribute));
