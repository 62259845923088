export const GET_RECRUITERS = 'GET_RECRUITERS';
export const GET_RECRUITERS_SUCCESS = 'GET_RECRUITERS_SUCCESS';
export const GET_RECRUITERS_FAIL = 'GET_RECRUITERS_FAIL';

export const CREATE_RECRUITER = 'CREATE_RECRUITER';
export const CREATE_RECRUITER_SUCCESS = 'CREATE_RECRUITER_SUCCESS';
export const CREATE_RECRUITER_FAIL = 'CREATE_RECRUITER_FAIL';

export const UPDATE_RECRUITER = 'UPDATE_RECRUITER';
export const UPDATE_RECRUITER_SUCCESS = 'UPDATE_RECRUITER_SUCCESS';
export const UPDATE_RECRUITER_FAIL = 'UPDATE_RECRUITER_FAIL';

export const DELETE_RECRUITER = 'DELETE_RECRUITER';
export const DELETE_RECRUITER_SUCCESS = 'DELETE_RECRUITER_SUCCESS';
export const DELETE_RECRUITER_FAIL = 'DELETE_RECRUITER_FAIL';

export const GET_JOBS_BY_RECRUITER_ID = 'GET_JOBS_BY_RECRUITER_ID';
export const GET_JOBS_BY_RECRUITER_ID_SUCCESS = 'GET_JOBS_BY_RECRUITER_ID_SUCCESS';
export const GET_JOBS_BY_RECRUITER_ID_FAIL = 'GET_JOBS_BY_RECRUITER_ID_FAIL';

export function getRecruiters(query, order, asc, limit, skip) {
  return {
    type: GET_RECRUITERS,
    query,
    order,
    asc,
    limit,
    skip,
  };
}

export function getRecruitersSuccess(data) {
  return {
    type: GET_RECRUITERS_SUCCESS,
    data,
  };
}

export function getRecruitersFail() {
  return {
    type: GET_RECRUITERS_FAIL,
  };
}

export function createRecruiter(params) {
  return {
    type: CREATE_RECRUITER,
    params,
  };
}

export function createRecruiterSuccess(data) {
  return {
    type: CREATE_RECRUITER_SUCCESS,
    data,
  };
}

export function createRecruiterFail() {
  return {
    type: CREATE_RECRUITER_FAIL,
  };
}

export function updateRecruiter(id, params) {
  return {
    type: UPDATE_RECRUITER,
    id,
    params,
  };
}

export function updateRecruiterSuccess(data) {
  return {
    type: UPDATE_RECRUITER_SUCCESS,
    data,
  };
}

export function updateRecruiterFail() {
  return {
    type: UPDATE_RECRUITER_FAIL,
  };
}

export function deleteRecruiter(id) {
  return {
    type: DELETE_RECRUITER,
    id,
  };
}

export function deleteRecruiterSuccess(data) {
  return {
    type: DELETE_RECRUITER_SUCCESS,
    data,
  };
}

export function deleteRecruiterFail(error) {
  return {
    type: DELETE_RECRUITER_FAIL,
    error,
  };
}


export function getJobsByRecruiterId(recruiterId, order, asc, limit, skip) {
  return {
    type: GET_JOBS_BY_RECRUITER_ID,
    recruiterId,
    order,
    asc,
    limit,
    skip,
  };
}

export function getJobsByRecruiterIdSuccess(data) {
  return {
    type: GET_JOBS_BY_RECRUITER_ID_SUCCESS,
    data,
  };
}

export function getJobsByRecruiterIdFail() {
  return {
    type: GET_JOBS_BY_RECRUITER_ID_FAIL,
  };
}
