/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import WrapperContentModal from './WrapperContentModal';

class Modals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleCancelModal = this.handleCancelModal.bind(this);
    this.handleSaveModal = this.handleSaveModal.bind(this);
  }

  componentDidMount() {
    if (this.props.escExit) {
      document.addEventListener('keyup', this.handleESC);
    }
  }

  componentWillUnmount() {
    if (this.props.escExit) {
      document.addEventListener('keyup', this.handleESC);
    }
  }

  handleESC = (e) => {
    if (e.keyCode === 27) this.handleCancelModal();
  };

  handleCancelModal() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleSaveModal() {
    if (this.props.onSave) {
      this.props.onSave();
    }
  }

  renderModal() {
    const { isOpen, title, size } = this.props;

    if (size === 'lg') {
      WrapperContentModal.content.left = '5%';
      WrapperContentModal.content.right = '5%';
    } else if (size === 'md') {
      WrapperContentModal.content.left = '15%';
      WrapperContentModal.content.right = '15%';
    } else if (size === 'sm') {
      WrapperContentModal.content.left = '25%';
      WrapperContentModal.content.right = '25%';
    } else if (size === 'xs') {
      WrapperContentModal.content.left = '35%';
      WrapperContentModal.content.right = '35%';
    }

    return (
      <Modal isOpen={isOpen} ariaHideApp={false} style={WrapperContentModal}>
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          {this.props.showButtonClose && (
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.handleCancelModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
        <div className="modal-body">
          {this.props.children ? this.props.children() : ''}
        </div>
        <div className="modal-footer">
          {this.props.footerModal ? (
            this.props.footerModal()
          ) : (
            <div className="Modal__footer">
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={this.handleSaveModal}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm ml-1"
                onClick={this.handleCancelModal}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  }

  render() {
    return <div>{this.renderModal()}</div>;
  }
}

// If the parent component doesn't specify a specific prop,
// then the default value will be used.
Modals.defaultProps = {
  title: 'Modal Add/Delete',
  size: 'sm',
  showButtonClose: true,
  escExit: true,
};
Modals.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  footerModal: PropTypes.func,
  children: PropTypes.func,
  size: PropTypes.string,
  showButtonClose: PropTypes.bool,
  escExit: PropTypes.bool,
};

export default Modals;
