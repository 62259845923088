/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Form, FormGroup, Label } from 'reactstrap';
import validateForm from '../../../../../shared/utils/validation';
import schemaCategoryForm from './validation';
import './styleEditForm.scss';

class EditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemEditing: null,
      enName: '',
      viName: '',
      sortNum: '',
      error: '',
    };
  }

  componentWillMount = () => {
    const { data } = this.props;
    if (data != null) {
      this.setState({
        itemEditing: data,
        enName: data.content.en,
        viName: data.content.vi,
        sortNum: data.sortNum,
      });
    }
  }

  handleClickSave = () => {
    const { save, catType } = this.props;
    const { enName, viName, sortNum, itemEditing } = this.state;
    const formData = { enName, viName, sortNum };
    const { isError, error: err } = validateForm(schemaCategoryForm, formData);
    if (isError) {
      this.setState({ error: err });
      return;
    }
    let id;
    const item = {
      content:
      {
        en: enName,
        vi: viName,
      },
      userRole: catType,
      sortNum: parseInt(sortNum, 10),
    };
    if (itemEditing != null) {
      // eslint-disable-next-line prefer-destructuring
      id = itemEditing.id;
      item.isDeleted = false;
    }
    save(id, item);
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  renderButton() {
    return (
      <div className="card-form-wrapper">
        <button
          type="button"
          color="primary"
          className="card-form__btn card-form__btn--submit"
          onClick={() => this.handleClickSave()}
        >
          Save & Close
        </button>
        <button
          type="button"
          color="danger"
          className="card-form__btn card-form__btn--cancel"
          onClick={() => this.handleCancel()}
        >
          Close
        </button>
      </div>
    );
  }

  render() {
    const { enName, viName, sortNum, error } = this.state;
    return (
      <Form className="form-cat">
        <FormGroup row>
          <Col sm={6}>
            <Label for="enName">Name (EN)</Label>
            <Input type="text" id="enName" name="enName" onChange={e => this.handleChange(e)} value={enName} />
            <div className={`${error.enName ? 'show-err' : ''} error-message`}>{error.enName}</div>
          </Col>
          <Col sm={6}>
            <Label for="viName">Name (VI)</Label>
            <Input type="text" id="viName" name="viName" onChange={e => this.handleChange(e)} value={viName} />
            <div className={`${error.viName ? 'show-err' : ''} error-message`}>{error.viName}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="sortNum">Order number</Label>
            <Input type="number" id="sortNum" name="sortNum" onChange={e => this.handleChange(e)} value={sortNum} />
            <div className={`${error.sortNum ? 'show-err' : ''} error-message`}>{error.sortNum}</div>
          </Col>
        </FormGroup>
        {this.renderButton()}
      </Form>
    );
  }
}

EditForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  save: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  catType: PropTypes.string.isRequired,
};

export default EditForm;
