import {
  take,
  call,
  put,
} from 'redux-saga/effects';
import {
  LOAD_TEMPLATE,
  LOADING_TEMPLATE,
  LOAD_TEMPLATE_SUCCESS,
  LOAD_TEMPLATE_FAILED,
  SAVE_TEMPLATE,
  SAVING_TEMPLATE,
  SAVE_TEMPLATE_SUCCESS,
  SAVE_TEMPLATE_FAILED,
} from '../actions/templateAction';

import {
  getTemplate, saveTemplate,
} from '../../shared/api/methods/template';

export function* getTemplateSaga() {
  while (true) {
    try {
      yield take(LOAD_TEMPLATE);
      yield put({ type: LOADING_TEMPLATE });
      const data = yield call(getTemplate);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: LOAD_TEMPLATE_FAILED, error });
      } else {
        yield put({ type: LOAD_TEMPLATE_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: LOAD_TEMPLATE_FAILED });
    }
  }
}

export function* saveTemplateSaga() {
  while (true) {
    try {
      const { dataSave } = yield take(SAVE_TEMPLATE);
      yield put({ type: SAVING_TEMPLATE });
      const data = yield call(saveTemplate, dataSave);
      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: SAVE_TEMPLATE_FAILED, error });
      } else {
        yield put({ type: SAVE_TEMPLATE_SUCCESS });
      }
    } catch (error) {
      yield put({ type: SAVE_TEMPLATE_FAILED, error });
    }
  }
}
