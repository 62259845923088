/* eslint-disable object-curly-newline */
import { take, call, put } from 'redux-saga/effects';
import {
  GET_HOT_INDUSTRIES,
  GET_HOT_INDUSTRIES_SUCCESS,
  GET_HOT_INDUSTRIES_FAIL,
  GET_HOT_INDUSTRIES_BY_ID,
  GET_HOT_INDUSTRIES_BY_ID_SUCCESS,
  GET_HOT_INDUSTRIES_BY_ID_FAIL,
  UPDATE_HOT_INDUSTRIES,
  UPDATE_HOT_INDUSTRIES_SUCCESS,
  UPDATE_HOT_INDUSTRIES_FAIL,
  SORT_HOT_INDUSTRIES,
  SORT_HOT_INDUSTRIES_SUCCESS,
  SORT_HOT_INDUSTRIES_FAIL,
  GET_INDUSTRIES,
  GET_INDUSTRIES_SUCCESS,
  GET_INDUSTRIES_FAIL,
  DELETE_INDUSTRY,
  DELETE_INDUSTRY_SUCCESS,
  DELETE_INDUSTRY_FAIL,
  CREATE_HOT_INDUSTRY,
  CREATE_HOT_INDUSTRY_SUCCESS,
  CREATE_HOT_INDUSTRY_FAIL,
} from '../actions/hotIndustriesAction';
import {
  getHotIndustries,
  updateHotIndustries,
  getHotIndustriesById,
  getIndustriesFromMasterData,
  deleteHotIndustry,
  createHotIndustry,
} from '../../shared/api/methods/hotIndustries';

export function* getHotIndustriesSaga() {
  while (true) {
    try {
      yield take(GET_HOT_INDUSTRIES);
      const data = yield call(getHotIndustries);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_HOT_INDUSTRIES_FAIL, error });
      } else {
        yield put({ type: GET_HOT_INDUSTRIES_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_HOT_INDUSTRIES_FAIL, error });
    }
  }
}

export function* updateHotIndustriesSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_HOT_INDUSTRIES);
      const data = yield call(updateHotIndustries, [id, params]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_HOT_INDUSTRIES_FAIL, error });
      } else {
        yield put({ type: UPDATE_HOT_INDUSTRIES_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: UPDATE_HOT_INDUSTRIES_FAIL, error });
    }
  }
}

export function* createHotIndustrySaga() {
  while (true) {
    try {
      const { params } = yield take(CREATE_HOT_INDUSTRY);
      const data = yield call(createHotIndustry, params);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: CREATE_HOT_INDUSTRY_FAIL, error });
      } else {
        yield put({ type: CREATE_HOT_INDUSTRY_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: CREATE_HOT_INDUSTRY_FAIL, error });
    }
  }
}

export function* getHotIndustriesByIdSaga() {
  while (true) {
    try {
      const { id } = yield take(GET_HOT_INDUSTRIES_BY_ID);
      const data = yield call(getHotIndustriesById, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_HOT_INDUSTRIES_BY_ID_FAIL, error });
      } else {
        yield put({ type: GET_HOT_INDUSTRIES_BY_ID_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_HOT_INDUSTRIES_BY_ID_FAIL, error });
    }
  }
}

export function* sortHotIndustriesSaga() {
  while (true) {
    try {
      const { items } = yield take(SORT_HOT_INDUSTRIES);
      const data = yield Promise.all(items.map(async (item, i) => {
        const result = updateHotIndustries([item.id, { sortNum: i, name: { en: item.name.en, vi: item.name.vi } }]);
        return result;
      }));
      if (data === null || Object.prototype.hasOwnProperty.call(data[0], 'error')) {
        const { error } = data[0];
        yield put({ type: SORT_HOT_INDUSTRIES_FAIL, error });
      } else {
        yield put({ type: SORT_HOT_INDUSTRIES_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: SORT_HOT_INDUSTRIES_FAIL, error });
    }
  }
}

export function* getIndustriesSaga() {
  while (true) {
    try {
      yield take(GET_INDUSTRIES);
      const data = yield call(getIndustriesFromMasterData);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_INDUSTRIES_FAIL, error });
      } else {
        yield put({ type: GET_INDUSTRIES_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_INDUSTRIES_FAIL, error });
    }
  }
}

export function* deleteHotIndustrySaga() {
  while (true) {
    try {
      const { id } = yield take(DELETE_INDUSTRY);
      const data = yield call(deleteHotIndustry, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: DELETE_INDUSTRY_FAIL, error });
      } else {
        yield put({ type: DELETE_INDUSTRY_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: DELETE_INDUSTRY_FAIL, error });
    }
  }
}
