import React, { PureComponent } from 'react';
import {
  Card, Row, Col, Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Input, JEditor } from '../../../../components';
import validateForm from '../../../../shared/utils/validation';
import schemaEmailTemplateForm from '../validation';
import './styleForm.scss';

const initFormInputs = { name: '', enSubjectInput: '', viSubjectInput: '' };

class emailTemplateForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      formInputs: initFormInputs,
      enContent: '',
      viContent: '',
      emailTemplateId: '',
      error: {},
    };
  }

  componentWillMount = () => {
    const { data } = this.props;
    if (data) {
      this.setState({
        emailTemplateId: data.id,
        formInputs: {
          name: data.name, enSubjectInput: data.subject.en, viSubjectInput: data.subject.vi,
        },
        enContent: data.content.en,
        viContent: data.content.vi,
      });
    }
  }

  handleChangeInput = (e) => {
    const { formInputs } = this.state;
    const { name, value } = e.target;
    this.setState({ formInputs: { ...formInputs, [name]: value } });
  }

  handleClickSubmit = () => {
    const { formInputs, enContent, viContent } = this.state;
    const { isError, error: err } = validateForm(schemaEmailTemplateForm, formInputs);
    if (isError) {
      this.setState({ error: err });
      return;
    }
    const { onSubmit } = this.props;
    onSubmit(formInputs, enContent, viContent);
  }

  handleCancel = () => {
    const { handleCancelForm } = this.props;
    handleCancelForm();
  }

  onChangeEnContent = (value) => {
    this.setState({
      enContent: value,
    });
  }

  onChangeViContent = (value) => {
    this.setState({
      viContent: value,
    });
  }

  render() {
    const {
      formInputs, enContent, viContent, emailTemplateId, error,
    } = this.state;
    return (
      <Card className="form-wrapper">
        <Row>
          <Col md={12}>
            <h4>{`${emailTemplateId ? 'Update' : 'Create'}`} Email Template</h4>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Label for="enSubjectInput">Name</Label>
            <Input
              type="text"
              className=""
              disabled={emailTemplateId ? 'disabled' : ''}
              name="name"
              value={formInputs.name}
              onChange={this.handleChangeInput}
              errorMesssage={error.name || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={6}>
            <Label for="enSubjectInput">English Subject</Label>
            <Input
              type="text"
              className=""
              name="enSubjectInput"
              value={formInputs.enSubjectInput}
              onChange={this.handleChangeInput}
              errorMesssage={error.enSubjectInput || ''}
            />
          </Col>
          <Col md={6} lg={6}>
            <Label for="enSubjectInput">Vietnamese Subject</Label>
            <Input
              type="text"
              className=""
              name="viSubjectInput"
              value={formInputs.viSubjectInput}
              onChange={this.handleChangeInput}
              errorMesssage={error.enSubjectInput || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Label for="enContent">English Content</Label>
            <JEditor
              name="enContent"
              content={enContent}
              onChangeEditor={value => this.onChangeEnContent(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Label for="viContent">VietNamese Content</Label>
            <JEditor
              name="viContent"
              content={viContent}
              onChangeEditor={value => this.onChangeViContent(value)}
            />
          </Col>
        </Row>
        <div className="card-form-wrapper">
          <button
            type="button"
            color="primary"
            className="card-form__btn card-form__btn--submit"
            onClick={() => this.handleClickSubmit()}
          >
            {`${emailTemplateId ? 'Save' : 'Add'}`}
          </button>
          <button
            type="button"
            color="danger"
            className="card-form__btn card-form__btn--cancel"
            onClick={() => this.handleCancel()}
          >
            Cancel
          </button>
        </div>
      </Card>
    );
  }
}
emailTemplateForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleCancelForm: PropTypes.func.isRequired,
};

export default emailTemplateForm;
