/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import './stylePagination.scss';

const defaultButton = ({ children, ...rest }) => (
  <button type="button" {...rest}>
    {children}
  </button>
);

defaultButton.propTypes = {
  children: PropTypes.any,
};

export default class Pagination extends React.Component {
  constructor(props) {
    super();

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { pages } = this.props;
    if (pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages),
      });
    }
    // this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => visiblePages.filter(page => page <= totalPages);

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    }
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    }
    if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    }
    return [1, 2, 3, 4, 5, total];
  };

  changePage = (page) => {
    const { pages, onPageChange } = this.props;
    const activePage = page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, pages),
    });

    onPageChange(page - 1);
  };

  render() {
    const {
      PageButtonComponent = defaultButton, page, previousText, nextText, pages,
    } = this.props;
    const { visiblePages } = this.state;
    const activePage = page + 1;

    return (
      <div className="Table__pagination">
        <div className="Table__prevPageWrapper">
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === 1) return;
              this.changePage(activePage - 1);
            }}
            disabled={activePage === 1}
          >
            {previousText}
          </PageButtonComponent>
        </div>
        <div className="Table__visiblePagesWrapper">
          {visiblePages.map((p, index, array) => (
            <PageButtonComponent
              key={p}
              className={activePage === p ? 'Table__pageButton Table__pageButton--active' : 'Table__pageButton'}
              onClick={() => this.changePage(p)}
            >
              {array[index - 1] + 2 < page ? `...${p}` : p}
            </PageButtonComponent>
          ))}
        </div>
        <div className="Table__nextPageWrapper">
          <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === pages) return;
              this.changePage(activePage + 1);
            }}
            disabled={activePage === pages}
          >
            {nextText}
          </PageButtonComponent>
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  PageButtonComponent: PropTypes.any,
  onPageChange: PropTypes.func,
  previousText: PropTypes.string,
  nextText: PropTypes.string,
};

Pagination.defaultProps = {
  onPageChange: () => {},
};
