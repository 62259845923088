import React from 'react';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import PropTypes from 'prop-types';
import { Card, Row, Input } from 'reactstrap';
import Table from '../../../../components/Table/index';
import './styleJobseekerList.scss';

class JobseekerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  handleChange = (event) => {
    this.setState({ query: event.target.value });
  };

  searchJobseeker = () => {
    const { query } = this.state;
    const { onSearch } = this.props;
    onSearch(query);
  };

  handleClickEditRow = (idRow) => {
    const { jobseekerListData, jobseekerEditing } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    const jobseekerDetailView = jobseekerListData.find(item => item._id === idRow);
    if (jobseekerDetailView) {
      jobseekerEditing(jobseekerDetailView);
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.searchJobseeker();
    }
  }

  render() {
    const { jobseekerListData } = this.props;
    return (
      <Card>
        <Row>
          <div className="form__form-group">
            <div className="form__form-group-field form__search">
              <div className="form__form-group-icon">
                <MagnifyIcon />
              </div>
              <Input
                type="text"
                className=""
                name="search"
                placeholder="Search"
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
              />
              <button
                type="button"
                className="card-form__btn card-form__btn--submit btn-search"
                color="primary"
                onClick={this.searchJobseeker}
              >
                Search
              </button>
            </div>
          </div>
        </Row>
        <Table
          data={jobseekerListData}
          columns={[
            {
              Header: 'Code',
              accessor: 'candidateId',
              sortable: true,
            },
            {
              Header: 'Name',
              accessor: 'fullName',
              sortable: true,
            },
            {
              Header: 'Email',
              accessor: 'email',
              sortable: true,
            },
            {
              Header: 'Phone No',
              accessor: 'phone',
            },
            {
              Header: 'No of Applied Job',
              accessor: 'jobs',
            },
            {
              Header: 'Update',
              accessor: 'updatedDate',
            },
            {
              Header: 'Status',
              accessor: 'status',
            },
            {
              Header: 'Actions',
              accessor: null,
              Cell: (row) => {
                const { _id } = row.original;
                return (
                  <div className="table-actions">
                    <span
                      role="presentation"
                      className="action-edit"
                      onClick={() => this.handleClickEditRow(_id)}
                    >
                      Edit
                    </span>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          className="custom-table"
          showPaginationBottom
          sortable={false}
        />
      </Card>
    );
  }
}


JobseekerList.propTypes = {
  onSearch: PropTypes.func.isRequired,
  jobseekerEditing: PropTypes.func.isRequired,
  jobseekerListData: PropTypes.instanceOf(Object).isRequired,
};

export default JobseekerList;
