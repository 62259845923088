/* eslint-disable max-len */
export const STATUS_CODE_401 = '401';
export const STATUS_CODE_500 = '500';
export const STATUS_CODE_200 = '200';
export const DEFAULT_QUERY_ORDER_BY = 'email';
export const DEFAULT_QUERY_LIMIT = '1000000';
export const DEFAULT_QUERY_SKIP = '0';
export const DEFAULT_QUERY_ASC = '-1';
export const MSG_UPDATE_NEWS_SUCCESS = 'Updated Posts.';
export const MSG_UPDATE_NEWS_FAIL = 'Update Posts Fail.';
export const MSG_CREATE_NEWS_SUCCESS = 'Created Posts.';
export const MSG_CREATE_NEWS_FAIL = 'Create Posts Fail.';
export const MSG_UPDATE_TAG_SUCCESS = 'Updated Tag.';
export const MSG_UPDATE_TAG_FAIL = 'Update Tag Fail.';
export const MSG_CREATE_TAG_SUCCESS = 'Created Tag.';
export const MSG_CREATE_TAG_FAIL = 'Create Tag Fail.';
export const MSG_UPDATE_CATEGORY_SUCCESS = 'Updated Category.';
export const MSG_UPDATE_CATEGORY_FAIL = 'Update Category Fail.';
export const MSG_CREATE_CATEGORY_SUCCESS = 'Created Category.';
export const MSG_CREATE_CATEGORY_FAIL = 'Create Category Fail.';
export const MSG_DELETE_CATEGORY_SUCCESS = 'Deleted Category.';
export const MSG_DELETE_CATEGORY_FAIL = 'Delete Category Fail.';
export const MSG_DELETE_NEWS_SUCCESS = 'Deleted Posts.';
export const MSG_DELETE_NEWS_FAIL = 'Delete Posts Fail.';
export const MSG_UPDATE_FAQ_SUCCESS = 'Updated Faq.';
export const MSG_UPDATE_FAQ_FAIL = 'Update Faq Fail.';
export const MSG_CREATE_FAQ_SUCCESS = 'Created Faq.';
export const MSG_CREATE_FAQ_FAIL = 'Create Faq Fail.';
export const MSG_DELETE_FAQ_SUCCESS = 'Deleted Faq.';
export const MSG_DELETE_FAQ_FAIL = 'Deleted Fail.';
export const MSG_DELETE_TAG_SUCCESS = 'Deleted Tag.';
export const MSG_DELETE_TAG_FAIL = 'Delete Tag Fail.';
export const IMAGE_TYPE = ['xbm', 'tif', 'pjp', 'pjpeg', 'jfif', 'png', 'gif', 'jpeg', 'jpg', 'tiff', 'bmp', 'webp', 'ico', 'svgz', 'svg'];
export const MSG_CONFIRM_DELETE = 'Are you sure to delete?';
export const FILE_UPLOAD_MAX_SIZE = 5;
export const MSG_FILE_UPLOAD_OVER_SIZE = 'The file is too large! Maximum is 5MB. Please upload another file.';
export const MSG_CREATE_EXPERTISE_SUCCESS = 'Created Expertise.';
export const MSG_CREATE_EXPERTISE_FAIL = 'Create Expertise Fail.';
export const MSG_UPDATE_EXPERTISE_SUCCESS = 'Updated Expertise.';
export const MSG_UPDATE_EXPERTISE_FAIL = 'Update Expertise Fail.';
