import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  Row,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import EmailTemplateList from './components/emailTemplateList';
import EmailTemplateForm from './components/emailTemplateForm';
import { BasicNotification } from '../../../components/Notification/index';
import {
  getEmailTemplates as getEmailTemplatesAction,
  createEmailTemplate as createEmailTemplateAction,
  updateEmailTemplate as updateEmailTemplateAction,
} from '../../../redux/actions/emailTemplateAction';

let notificationRU = null;

class EmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isOpenForm: false,
      emailTemplateEdit: null,
      isShowNotify: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillMount = () => {
    const { cookies, getEmailTemplates } = this.props;
    const token = cookies.get('token') || '';
    getEmailTemplates();
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  emailTemplateEditById = (item) => {
    this.setState({ isOpenForm: true, emailTemplateEdit: item });
  }

  emailTemplateAddNew = () => {
    this.setState({ isOpenForm: true });
  }

  handleExitForm = () => {
    this.setState({ isOpenForm: false, emailTemplateEdit: null });
  }

  onSubmit = (formInput, enContent, viContent) => {
    const { name: nameField, enSubjectInput: enSubject, viSubjectInput: viSubject } = formInput;
    const { emailTemplateEdit } = this.state;
    const { updateEmailTemplate, createEmailTemplate } = this.props;
    this.setState({ isOpenForm: false, emailTemplateEdit: null, isShowNotify: true });
    if (emailTemplateEdit) {
      return updateEmailTemplate(emailTemplateEdit.id, {
        subject: {
          en: enSubject,
          vi: viSubject,
        },
        content: {
          en: enContent,
          vi: viContent,
        },
      });
    }
    return createEmailTemplate({
      name: nameField,
      subject: {
        en: enSubject,
        vi: viSubject,
      },
      content: {
        en: enContent,
        vi: viContent,
      },
    });
  }

  // eslint-disable-next-line arrow-body-style
  show = (color, message) => {
    return notificationRU.notice({
      content: <BasicNotification
        title="Email Template"
        color={color}
        message={message}
      />,
      duration: 5,
      closable: true,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: 'right-up',
    });
  }

  render() {
    const { emailTemplate, cookies } = this.props;
    const {
      hasToken,
      isOpenForm,
      emailTemplateEdit,
      isShowNotify,
    } = this.state;
    if (isShowNotify && emailTemplate.message) {
      if (emailTemplate.isError) {
        this.show('warning', emailTemplate.message);
      } else {
        this.show('success', emailTemplate.message);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || emailTemplate.statusCode === '401') {
      if (emailTemplate.statusCode === '401') cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Email Template</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          {isOpenForm ? (
            <EmailTemplateForm
              data={emailTemplateEdit}
              handleCancelForm={() => this.handleExitForm()}
              onSubmit={(formInput, enContent, viContent) => this.onSubmit(formInput, enContent, viContent)}
            />
          ) : (
            <EmailTemplateList
              emailTemplates={emailTemplate.emailTemplates}
              handleAddNew={() => this.emailTemplateAddNew()}
              emailTemplateEditing={item => this.emailTemplateEditById(item)}
            />
          )}
        </Card>
      </Container>
    );
  }
}

EmailTemplate.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  getEmailTemplates: PropTypes.func.isRequired,
  createEmailTemplate: PropTypes.func.isRequired,
  updateEmailTemplate: PropTypes.func.isRequired,
  emailTemplate: PropTypes.instanceOf(Object).isRequired,

};

const mapStateToProps = (state) => {
  const { emailTemplate } = state;
  return {
    emailTemplate,
  };
};
const mapDispatchToProps = dispatch => ({
  getEmailTemplates: () => dispatch(getEmailTemplatesAction()),
  createEmailTemplate: params => dispatch(createEmailTemplateAction(params)),
  updateEmailTemplate: (...args) => dispatch(updateEmailTemplateAction(...args)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(EmailTemplate), withTranslation('common')(EmailTemplate));
