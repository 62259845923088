import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import EditForm from './EditForm';
import {
  STATUS_CODE_401,
} from '../../../../shared/utils/constants';
import {
  getContactById as getContactByIdAction,

} from '../../../../redux/actions/contactAction';

class ContactsDetailt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
    };
  }

  componentWillMount = async () => {
    const { match, cookies, getContactById } = this.props;
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    if (match) {
      getContactById(match.params.id);
    }
  }

  cancelViewDetailt = () => {
    const { history } = this.props;
    history.push('/admin/contact');
  }

  render() {
    const { contact, cookies } = this.props;
    const { hasToken } = this.state;
    if (!hasToken || contact.statusCode === STATUS_CODE_401) {
      if (contact.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Contact Detailt</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          {contact.contactEdit ? (
            <EditForm data={contact.contactEdit} cancelEdit={this.cancelViewDetailt} />
          ) : ''
          }
        </Card>
      </Container>
    );
  }
}

ContactsDetailt.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  contact: PropTypes.objectOf(PropTypes.object).isRequired,
  getContactById: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => {
  const { contact } = state;
  return {
    contact,
  };
};
const mapDispatchToProps = dispatch => ({
  getContactById: id => dispatch(getContactByIdAction(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(ContactsDetailt));
