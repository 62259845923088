import api from '../index';
import ApiConstants from '../ApiConstants';

const loadData = (from, to) => api(
  `${ApiConstants.CHART}/${from}/${to}`,
  null,
  'get',
  null,
);

export { loadData as default };
