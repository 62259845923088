import api from '../index';
import ApiConstants from '../ApiConstants';

// eslint-disable-next-line import/prefer-default-export
export const uploadFile = file => api(
  `${ApiConstants.UPLOADFILE}`,
  file,
  'POST',
  null,
);
