import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import AddFieldDescriptionModal from './modal/AddFieldDescriptionModal';
import EditFieldDescriptionModal from './modal/EditFieldDescriptionModal';
import DeleteFieldDescriptionModal from './modal/DeleteFieldDescriptionModal';
import SaveFieldModal from '../../../../shared/components/modal/SaveFieldModal';
import { toogleModalAdd, toogleModalEdit, toogleModalDelete } from '../../../../redux/actions/modalAction';
import { loadDescription, deleteField, updateOrderDescription } from '../../../../redux/actions/descriptionAction';
import TableDndApp from './Table';

class BoxedCollapseFullWidth extends Component {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadDescription());
  }

  toogleModalAdd = () => {
    const { dispatch } = this.props;
    const data = {
      isShow: true,
      typeSection: 'offerabilitySection',
      isShowGroup: true,
    };
    dispatch(toogleModalAdd(data));
  }

  toogleModalEdit = (dataEdit) => {
    const { dispatch } = this.props;
    const data = {
      isShow: true,
      isEdit: true,
      dataEdit,
    };
    dispatch(toogleModalEdit(data));
  }

  toogleModalDelete = (idRow) => {
    const { dispatch } = this.props;
    const data = {
      isShow: true,
      isDelete: true,
      idRow,
    };
    dispatch(toogleModalDelete(data));
  }

  handleDeleteRow = (idRow) => {
    const { dispatch } = this.props;
    dispatch(deleteField(idRow));
  }

  handleOrderDescription = (data) => {
    const { dispatch } = this.props;
    dispatch(updateOrderDescription(data));
  }

  renderHtmlModal = () => {
    const { modal } = this.props;
    let html;
    if (modal.isEdit) {
      html = <EditFieldDescriptionModal />;
    } else if (modal.isDelete) {
      html = <DeleteFieldDescriptionModal />;
    } else if (modal.isSave) {
      html = <SaveFieldModal />;
    } else {
      html = <AddFieldDescriptionModal />;
    }
    return html;
  }

  render() {
    const { description } = this.props;
    const { data } = description;
    const newData = data.map(item => ({
      id: item.id,
      labelEnglish: item.label.en,
      labelVn: item.label.vi,
      allowMultiline: item.allowMultiline,
      row: item.row,
      isHidden: item.isHidden,
      data: item,
    }));
    return (
      <Card body className="mb-5">
        <div className="card-form-wrapper">
          <button type="button" className="card-form__btn card-form__btn--submit" onClick={this.toogleModalAdd}>
            Add
          </button>
        </div>
        <TableDndApp
          data={newData}
          handleDeleteRow={this.toogleModalDelete}
          toogleModalEdit={this.toogleModalEdit}
          handleOrderDescription={this.handleOrderDescription}
        />
        {this.renderHtmlModal()}
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal, description } = state;
  return {
    modal,
    description,
  };
};

BoxedCollapseFullWidth.propTypes = {
  modal: PropTypes.objectOf(PropTypes.object).isRequired,
  description: PropTypes.objectOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  null,
)(withTranslation('common')(BoxedCollapseFullWidth));
