/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_CONTACT,
  GET_CONTACT_SUCCESS,
  GET_CONTACT_FAIL,
  GET_CONTACT_BY_ID,
  GET_CONTACT_BY_ID_SUCCESS,
  GET_CONTACT_BY_ID_FAIL,
} from '../actions/contactAction';

const initialState = {
  contactList: [],
  message: '',
  isError: false,
  data: null,
  statusCode: '',
  contactEdit: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONTACT: {
      return { ...state, type: action.type };
    }
    case GET_CONTACT_SUCCESS: {
      return { ...state, ...{ type: action.type, contactList: action.data } };
    }
    case GET_CONTACT_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case GET_CONTACT_BY_ID: {
      return { ...state, type: action.type };
    }
    case GET_CONTACT_BY_ID_SUCCESS: {
      return { ...state, ...{ type: action.type, contactEdit: action.data } };
    }
    case GET_CONTACT_BY_ID_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    default:
      return state;
  }
}
