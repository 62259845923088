import { take, call, put } from 'redux-saga/effects';
import {
  GET_JOBSEEKERS,
  GET_JOBSEEKERS_SUCCESS,
  GET_JOBSEEKERS_FAIL,
  UPDATE_JOBSEEKER,
  UPDATE_JOBSEEKER_SUCCESS,
  UPDATE_JOBSEEKER_FAIL,
  GET_JOBS_BY_JOBSEEKER_ID,
  GET_JOBS_BY_JOBSEEKER_ID_SUCCESS,
  GET_JOBS_BY_JOBSEEKER_ID_FAIL,
} from '../actions/jobseekerAction';
import {
  getJobseekers,
  updateJobseeker,
  getJobsByJobseekerId,
} from '../../shared/api/methods/jobseeker';

export function* getJobseekersSaga() {
  while (true) {
    try {
      const {
        query,
        order,
        asc,
        limit,
        skip,
      } = yield take(GET_JOBSEEKERS);
      const data = yield call(getJobseekers, [query, order, asc, limit, skip]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_JOBSEEKERS_FAIL, error });
      } else {
        yield put({ type: GET_JOBSEEKERS_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_JOBSEEKERS_FAIL, data: [] });
    }
  }
}

export function* getJobsByJobseekerIdSaga() {
  while (true) {
    try {
      const {
        jobseekerId,
        order,
        asc,
        limit,
        skip,
      } = yield take(GET_JOBS_BY_JOBSEEKER_ID);
      const data = yield call(getJobsByJobseekerId, [jobseekerId, order, asc, limit, skip]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_JOBS_BY_JOBSEEKER_ID_FAIL, error });
      } else {
        yield put({ type: GET_JOBS_BY_JOBSEEKER_ID_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_JOBS_BY_JOBSEEKER_ID_FAIL, data: [] });
    }
  }
}

export function* updateJobseekerSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_JOBSEEKER);
      const data = yield call(updateJobseeker, [id, params]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_JOBSEEKER_FAIL, error });
      } else {
        yield put({ type: UPDATE_JOBSEEKER_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: UPDATE_JOBSEEKER_FAIL, error });
    }
  }
}
