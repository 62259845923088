/* eslint-disable object-curly-newline */
import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Col, Card, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import HotIndustriesList from './HotIndustriesList';
import { BasicNotification } from '../../../components/Notification/index';
import HotIndustriesForm from './HotIndustriesForm';
import {
  getHotIndustries as getHotIndustriesAction,
  updateHotIndustries as updateHotIndustriesAction,
  sortHotIndustries as sortHotIndustriesAction,
  deleteHotIndustry as deleteHotIndustryAction,
} from '../../../redux/actions/hotIndustriesAction';
import {
  STATUS_CODE_401,
} from '../../../shared/utils/constants';

let notificationRU = null;
class HotIndustries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isForm: false,
      isShowNotify: false,
    };
  }

  componentWillMount = async () => {
    const { cookies, getHotIndustries } = this.props;
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    getHotIndustries();
  }


  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  onUpdateSortNumber = (items) => {
    const { sortHotIndustries } = this.props;
    sortHotIndustries(items);
  }

  onUpdateHotIndustries = (id, params) => {
    const { updateHotIndustries } = this.props;
    updateHotIndustries(id, params);
    this.setState({ isShowNotify: true });
  }

  onDelete = (id) => {
    const { deleteHotIndustry } = this.props;
    deleteHotIndustry(id);
    this.setState({ isShowNotify: true });
  }

  canceForm = () => {
    this.setState({ isForm: false });
  }

  show = (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Hot Industries"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  handleClickAddHotIndustries = () => {
    this.setState({ isForm: true });
  }

  render() {
    const { hotIndustries, cookies } = this.props;
    const { hasToken, isForm, isShowNotify } = this.state;
    if (isShowNotify && hotIndustries.message) {
      if (hotIndustries.isError) {
        this.show('warning', hotIndustries.message);
      } else {
        this.show('success', hotIndustries.message);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || hotIndustries.statusCode === STATUS_CODE_401) {
      if (hotIndustries.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <div>
        {isForm ? (
          <HotIndustriesForm
            onCancelForm={this.canceForm}
            isAddItem={isForm}
          />
        ) : (
          <Container>
            <Row>
              <Col md={12}>
                <h3 className="page-title">Hot Industries</h3>
              </Col>
            </Row>
            <Card body className="mb-5">
              <HotIndustriesList
                hotIndustriesList={hotIndustries.hotIndustriesList}
                updateSortNumber={items => this.onUpdateSortNumber(items)}
                hotIndustriesEditing={item => this.onEditHotIndustries(item)}
                onAddHotIndustries={this.handleClickAddHotIndustries}
                onDeleteHotIndustries={id => this.onDelete(id)}
              />
            </Card>
          </Container>
        )}
      </div>
    );
  }
}

HotIndustries.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  hotIndustries: PropTypes.arrayOf(Object).isRequired,
  getHotIndustries: PropTypes.func.isRequired,
  updateHotIndustries: PropTypes.func.isRequired,
  sortHotIndustries: PropTypes.func.isRequired,
  deleteHotIndustry: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { hotIndustries } = state;
  return {
    hotIndustries,
  };
};
const mapDispatchToProps = dispatch => ({
  getHotIndustries: (...args) => dispatch(getHotIndustriesAction(...args)),
  updateHotIndustries: (...args) => dispatch(updateHotIndustriesAction(...args)),
  sortHotIndustries: items => dispatch(sortHotIndustriesAction(items)),
  deleteHotIndustry: id => dispatch(deleteHotIndustryAction(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(HotIndustries));
