export const GET_NEWS = 'GET_NEWS';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAIL = 'GET_NEWS_FAIL';

export const CREATE_NEWS = 'CREATE_NEWS';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_FAIL = 'CREATE_NEWS_FAIL';

export const UPDATE_NEWS = 'UPDATE_NEWS';
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS';
export const UPDATE_NEWS_FAIL = 'UPDATE_NEWS_FAIL';

export const DELETE_NEWS = 'DELETE_NEWS';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAIL = 'DELETE_NEWS_FAIL';

export const GET_NEWS_BY_ID = 'GET_NEWS_BY_ID';
export const GET_NEWS_BY_ID_SUCCESS = 'GET_NEWS_BY_ID_SUCCESS';
export const GET_NEWS_BY_ID_FAIL = 'GET_NEWS_BY_ID_FAIL';

export const GET_NEWS_CATEGORIES = 'GET_NEWS_CATEGORIES';
export const GET_NEWS_CATEGORIES_SUCCESS = 'GET_NEWS_CATEGORIES_SUCCESS';
export const GET_NEWS_CATEGORIES_FAIL = 'GET_NEWS_CATEGORIES_FAIL';

export function getNews(query, catId, catType, order, asc, limit, skip) {
  return {
    type: GET_NEWS,
    query,
    catId,
    catType,
    order,
    asc,
    limit,
    skip,
  };
}

export function getNewsSuccess(data) {
  return {
    type: GET_NEWS_SUCCESS,
    data,
  };
}

export function getNewsFail(error) {
  return {
    type: GET_NEWS_FAIL,
    error,
  };
}

export function getNewsCategories(limit, skip) {
  return {
    type: GET_NEWS_CATEGORIES,
    limit,
    skip,
  };
}

export function getNewsCategoriesSuccess(data) {
  return {
    type: GET_NEWS_CATEGORIES_SUCCESS,
    data,
  };
}

export function getNewsCategoriesFail(error) {
  return {
    type: GET_NEWS_CATEGORIES_FAIL,
    error,
  };
}

export function getNewsById(id) {
  return {
    type: GET_NEWS_BY_ID,
    id,
  };
}

export function getNewsByIdSuccess(data) {
  return {
    type: GET_NEWS_BY_ID_SUCCESS,
    data,
  };
}

export function getNewsByIdFail(error) {
  return {
    type: GET_NEWS_BY_ID_FAIL,
    error,
  };
}

export function createNews(params) {
  return {
    type: CREATE_NEWS,
    params,
  };
}

export function createNewsSuccess(data) {
  return {
    type: CREATE_NEWS_SUCCESS,
    data,
  };
}

export function createNewsFail(error) {
  return {
    type: CREATE_NEWS_FAIL,
    error,
  };
}

export function updateNews(id, params) {
  return {
    type: UPDATE_NEWS,
    id,
    params,
  };
}

export function updateNewsSuccess(data, isDeleted) {
  return {
    type: UPDATE_NEWS_SUCCESS,
    data,
    isDeleted,
  };
}

export function updateNewsFail(error, isDeleted) {
  return {
    type: UPDATE_NEWS_FAIL,
    error,
    isDeleted,
  };
}

export function deleteNews(id) {
  return {
    type: DELETE_NEWS,
    id,
  };
}

export function deleteNewsSuccess(data) {
  return {
    type: DELETE_NEWS_SUCCESS,
    data,
  };
}

export function deleteNewsFail(error) {
  return {
    type: DELETE_NEWS_FAIL,
    error,
  };
}
