/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Input, Form, FormGroup, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Select from 'react-select';
import classnames from 'classnames';
import { JEditor } from '../../../../../components';
import ApiConstants from '../../../../../shared/api/ApiConstants';
import { changeTextToSlug, getCookie } from '../../../../../shared/utils/calculation';
import validateForm from '../../../../../shared/utils/validation';
import schemaPostForm from './validation';
import './styleEditForm.scss';
import {
  FILE_UPLOAD_MAX_SIZE,
} from '../../../../../shared/utils/constants';

class EditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newEditing: null,
      enContent: '',
      viContent: '',
      enTitle: '',
      viTitle: '',
      url: '',
      categoryId: '',
      tag: [],
      featureImage: '',
      error: '',
      activeTab: '1',
      languageForAdmin: 'ALL',
    };
  }

  componentWillMount = async () => {
    const { data, tagList } = this.props;
    if (data != null) {
      const { tags } = data;
      const pTags = tags.map((tag) => {
        let tagitem;
        let tagOpt;
        if (tagList) {
          tagitem = tagList.find(item => item.id === tag);
          if (tagitem) {
            tagOpt = {
              value: tagitem.id,
              label: tagitem.name.en,
            };
            return tagOpt;
          }
        }
        return false;
      });
      const postTag = pTags.filter(item => item);
      this.setState({
        newEditing: data,
        enContent: data.content.en || '',
        viContent: data.content.vi || '',
        enTitle: data.title.en || '',
        viTitle: data.title.vi || '',
        url: data.url,
        tag: postTag,
        categoryId: data.categoryId,
        featureImage: data.featureImage,
        languageForAdmin: data.languageForAdmin || 'ALL',
      });
    }
  }

  onChangeEnContent = (value) => {
    this.setState({
      enContent: value,
    });
  }

  onChangeViContent = (value) => {
    this.setState({
      viContent: value,
    });
  }

  onChangeFeatureImage = (e) => {
    const { history } = this.props;
    const { files } = e.target;
    const reader = new FileReader();
    if (files.length > 0) {
      reader.readAsDataURL(files && files.length > 0 && files[0]);
      if (files[0].size > FILE_UPLOAD_MAX_SIZE * 1024 * 1024) {
      // eslint-disable-next-line no-alert
        alert(`File is too big! Maximum is ${FILE_UPLOAD_MAX_SIZE}MB. Please upload another file.`);
        e.target.value = null;
        return;
      }
      reader.onload = async () => {
        const formData = new FormData();
        formData.append('file', files[0]);
        const token = getCookie('token');
        const bearer = `Bearer ${token}`;
        const options = {
          headers: {
            Authorization: bearer,
          },
          method: 'POST',
          body: formData,
        };
        const resp = await fetch(`${ApiConstants.BASE_URL}${ApiConstants.UPLOADFILE}`, options);
        const respCopy = resp.clone();
        const json = await respCopy.json();
        if (json.error) {
          history.push('/');
        }
        this.setState({
          featureImage: json.url,
        });
      };
    }
  }

  handleClickSave = () => {
    const { save } = this.props;
    const { enTitle, viTitle, enContent, viContent, url, tag, categoryId, newEditing, featureImage, languageForAdmin } = this.state;
    let id;
    const englishTitle = languageForAdmin === 'VI' ? viTitle : enTitle;
    const vietnameseTitle = languageForAdmin === 'EN' ? enTitle : viTitle;
    const englishContent = languageForAdmin === 'VI' ? viContent : enContent;
    const vietnameseContent = languageForAdmin === 'EN' ? enContent : viContent;
    const formData = { englishTitle, vietnameseTitle, url, tag, categoryId, newEditing, featureImage };
    const { isError, error: err } = validateForm(schemaPostForm, formData);
    if (isError) {
      this.setState({ error: err });
      return;
    }
    let tagSelected;
    if (tag) {
      tagSelected = tag.map(item => item.value);
    }
    const news = {
      title: {
        en: englishTitle,
        vi: vietnameseTitle,
      },
      content: {
        en: englishContent,
        vi: vietnameseContent,
      },
      url,
      categoryId,
      tags: tagSelected,
      featureImage,
      languageForAdmin,
    };
    if (newEditing != null) {
      // eslint-disable-next-line prefer-destructuring
      id = newEditing.id;
    } else {
      news.isHot = false;
      news.views = 0;
      news.status = 'publish';
    }
    save(id, news);
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  }

  changeSeletectTag = (selectedOption) => {
    this.setState({ tag: selectedOption });
  }

  changeSeletectLanguage = (selectedOption) => {
    this.setState({ languageForAdmin: selectedOption });
  }

  handleChange = (e) => {
    const { languageForAdmin } = this.state;
    if (e.target.name === 'enTitle' && (languageForAdmin === 'EN' || languageForAdmin === 'ALL')) {
      this.setState({ url: changeTextToSlug(e.target.value) });
    }
    if (e.target.name === 'viTitle' && languageForAdmin === 'VI') {
      this.setState({ url: changeTextToSlug(e.target.value) });
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  renderButton() {
    return (
      <div className="card-form-wrapper">
        <button
          type="button"
          color="primary"
          className="card-form__btn card-form__btn--submit"
          onClick={() => this.handleClickSave()}
        >
          Save & Close
        </button>
        <button
          type="button"
          color="danger"
          className="card-form__btn card-form__btn--cancel"
          onClick={() => this.handleCancel()}
        >
          Close
        </button>
      </div>
    );
  }

  render() {
    const { enContent, viContent, enTitle, viTitle, url, categoryId, tag, featureImage, error, activeTab, languageForAdmin } = this.state;
    const { categories, tagList, postType } = this.props;
    const tabContent = languageForAdmin === 'VI' ? '2' : activeTab;
    let tagOptions = [];
    if (tagList) {
      tagOptions = tagList.map(item => ({
        value: item.id,
        label: item.name.en,
      }));
    }
    const categoriesList = categories.filter(item => item.catType === postType);
    const categoriesOptions = categoriesList.map(category => <option value={category.id}>{category.name.en}</option>);
    return (
      <Form className="form-news">
        {this.renderButton()}
        <FormGroup row>
          <Col sm={6}>
            <Label for="languageForAdmin">Language</Label>
            <Input type="select" name="languageForAdmin" id="languageForAdmin" onChange={e => this.handleChange(e)} value={languageForAdmin}>
              <option value="ALL">ALL</option>
              <option value="EN">English</option>
              <option value="VI">Vietnamese</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          {languageForAdmin === 'EN' || languageForAdmin === 'ALL' ? (
            <Col sm={6}>
              <Label for="enTitle">Title (EN)</Label>
              <Input type="text" id="enTitle" name="enTitle" onChange={e => this.handleChange(e)} value={enTitle} />
              <div className={`${error.englishTitle ? 'show-err' : ''} error-message`}>{error.englishTitle}</div>
            </Col>
          ) : ''
          }
          {languageForAdmin === 'VI' || languageForAdmin === 'ALL' ? (
            <Col sm={6}>
              <Label for="viTitle">Title (VI)</Label>
              <Input type="text" id="viTitle" name="viTitle" onChange={e => this.handleChange(e)} value={viTitle} />
              <div className={`${error.englishTitle ? 'show-err' : ''} error-message`}>{error.englishTitle}</div>
            </Col>
          ) : ''
          }

        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="url">Slug</Label>
            <Input type="text" id="url" name="url" onChange={e => this.handleChange(e)} value={url} />
            <div className={`${error.url ? 'show-err' : ''} error-message`}>{error.url}</div>
          </Col>
          <Col sm={6}>
            <Label for="categoryId">Categories</Label>
            <Input type="select" name="categoryId" id="categoryId" onChange={e => this.handleChange(e)} value={categoryId}>
              <option>Choose One</option>
              {categoriesOptions}
            </Input>
            <div className={`${error.categoryId ? 'show-err' : ''} error-message`}>{error.categoryId}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="tag">Tags</Label>
            <Select
              value={tag}
              onChange={this.changeSeletectTag}
              closeMenuOnSelect={false}
              options={tagOptions}
              isOptionDisabled={this.isDisabled}
              isMulti
            />
            <div className={`${error.tag ? 'show-err' : ''} error-message`}>{error.tag}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="featured_image">Featured image (Limit {FILE_UPLOAD_MAX_SIZE}MB)</Label>
          <img className="featured_image" src={featureImage} alt="" />
          <Input type="file" id="featured_image" name="featured_image" onChange={e => this.onChangeFeatureImage(e)} />
          <div className={`${error.featureImage ? 'show-err' : ''} error-message`}>{error.featureImage}</div>
        </FormGroup>
        <FormGroup row>
          <div className="tabs tabs--bordered-top">
            <Nav tabs>
              {languageForAdmin === 'EN' || languageForAdmin === 'ALL' ? (
                <NavItem>
                  <NavLink
                    className={classnames({ active: tabContent === '1' })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    Engish Content
                  </NavLink>
                </NavItem>
              ) : ''
              }
              {languageForAdmin === 'VI' || languageForAdmin === 'ALL' ? (
                <NavItem>
                  <NavLink
                    className={classnames({ active: tabContent === '2' })}
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    Vietnamese Content
                  </NavLink>
                </NavItem>
              ) : ''
              }
            </Nav>
            <TabContent activeTab={tabContent}>
              {languageForAdmin === 'EN' || languageForAdmin === 'ALL' ? (
                <TabPane tabId="1">
                  <JEditor
                    content={enContent}
                    onChangeEditor={value => this.onChangeEnContent(value)}
                  />
                </TabPane>
              ) : ''
              }
              {languageForAdmin === 'VI' || languageForAdmin === 'ALL' ? (
                <TabPane tabId="2">
                  <JEditor
                    content={viContent}
                    onChangeEditor={value => this.onChangeViContent(value)}
                  />
                </TabPane>
              ) : ''
              }
            </TabContent>
          </div>
        </FormGroup>
        {this.renderButton()}
      </Form>
    );
  }
}

EditForm.propTypes = {
  data: PropTypes.instanceOf(Object),
  categories: PropTypes.instanceOf(Object).isRequired,
  save: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tagList: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.instanceOf(Object),
  postType: PropTypes.string.isRequired,
};

export default withRouter(EditForm);
