/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Input, Form, FormGroup, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { JEditor } from '../../../../../components';
import validateForm from '../../../../../shared/utils/validation';
import schemaQaForm from './validation';
import './styleEditForm.scss';

class EditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqEditing: null,
      enContent: '',
      viContent: '',
      enTitle: '',
      viTitle: '',
      categoryId: '',
      error: '',
      activeTab: '1',
    };
  }

  componentWillMount = async () => {
    const { data } = this.props;
    if (data != null) {
      this.setState({
        faqEditing: data,
        enContent: data.answer.en,
        viContent: data.answer.vi,
        enTitle: data.question.en,
        viTitle: data.question.vi,
        categoryId: data.subjectId,
      });
    }
  }

  onChangeEnContent = (value) => {
    this.setState({
      enContent: value,
    });
  }

  onChangeViContent = (value) => {
    this.setState({
      viContent: value,
    });
  }

  handleClickSave = () => {
    const { save } = this.props;
    const { enTitle, viTitle, enContent, viContent, categoryId, faqEditing } = this.state;
    let id;
    const formData = { enTitle, viTitle, enContent, viContent, categoryId, faqEditing };
    const { isError, error: err } = validateForm(schemaQaForm, formData);
    if (isError) {
      this.setState({ error: err });
      return;
    }
    const faq = {
      question: {
        en: enTitle,
        vi: viTitle,
      },
      answer: {
        en: enContent,
        vi: viContent,
      },
      subjectId: categoryId,
      views: 0,
    };
    if (faqEditing != null) {
      // eslint-disable-next-line prefer-destructuring
      id = faqEditing.id;
      faq.views = faqEditing.views;
      faq.isDeleted = false;
    }
    save(id, faq);
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  renderButton() {
    return (
      <div className="card-form-wrapper">
        <button
          type="button"
          color="primary"
          className="card-form__btn card-form__btn--submit"
          onClick={() => this.handleClickSave()}
        >
          Save & Close
        </button>
        <button
          type="button"
          color="danger"
          className="card-form__btn card-form__btn--cancel"
          onClick={() => this.handleCancel()}
        >
          Close
        </button>
      </div>
    );
  }

  render() {
    const { enContent, viContent, enTitle, viTitle, categoryId, error, activeTab } = this.state;
    const { categories } = this.props;
    const categoriesOptions = categories.map(category => <option value={category.id}>{category.content.en}</option>);
    return (
      <Form className="form-faq">
        {this.renderButton()}
        <FormGroup row>
          <Col sm={6}>
            <Label for="enTitle">Question (EN)</Label>
            <Input type="text" id="enTitle" name="enTitle" onChange={e => this.handleChange(e)} value={enTitle} />
            <div className={`${error.enTitle ? 'show-err' : ''} error-message`}>{error.enTitle}</div>
          </Col>
          <Col sm={6}>
            <Label for="viTitle">Question (VI)</Label>
            <Input type="text" id="viTitle" name="viTitle" onChange={e => this.handleChange(e)} value={viTitle} />
            <div className={`${error.viTitle ? 'show-err' : ''} error-message`}>{error.viTitle}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="categoryId">Categories</Label>
            <Input type="select" name="categoryId" id="categoryId" onChange={e => this.handleChange(e)} value={categoryId}>
              <option>Choose One</option>
              {categoriesOptions}
            </Input>
            <div className={`${error.categoryId ? 'show-err' : ''} error-message`}>{error.categoryId}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <div className="tabs tabs--bordered-top">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  English Answer
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  Vietnamese Answer
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <JEditor
                  content={enContent}
                  onChangeEditor={value => this.onChangeEnContent(value)}
                />
              </TabPane>
              <TabPane tabId="2">
                <JEditor
                  content={viContent}
                  onChangeEditor={value => this.onChangeViContent(value)}
                />
              </TabPane>
            </TabContent>
          </div>
        </FormGroup>
        {this.renderButton()}
      </Form>
    );
  }
}

EditForm.propTypes = {
  data: PropTypes.instanceOf(Object),
  categories: PropTypes.instanceOf(Object).isRequired,
  save: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withRouter(EditForm);
