import React, { PureComponent } from 'react';
import {
  Col,
  CardBody,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './styleEditForm.scss';

class EditForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contactEditing: null,
    };
  }

  componentWillMount = () => {
    const { data } = this.props;
    this.setState({ contactEditing: data });
  }

  handleCancel = () => {
    const { cancelEdit } = this.props;
    cancelEdit();
  }

  render() {
    const { contactEditing } = this.state;
    return (
      <Col md={12} lg={12} xl={12}>
        <CardBody>
          <Form className="form-contact">
            <FormGroup row>
              <Label for="name" sm={2}>ContactId</Label>
              <Col sm={10}>
                {contactEditing.contactId}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="name" sm={2}>Name</Label>
              <Col sm={10}>
                {contactEditing.name}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="email" sm={2}>Email</Label>
              <Col sm={10}>
                <a href={`mailto:${contactEditing.email}`}>{contactEditing.email}</a>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="phone" sm={2}>Phone</Label>
              <Col sm={10}>
                {contactEditing.phone}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="subject" sm={2}>Subject</Label>
              <Col sm={10}>
                {contactEditing.subject}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="subject" sm={2}>Comments</Label>
              <Col sm={10}>
                {contactEditing.question}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="subject" sm={2}>Submitted Date</Label>
              <Col sm={10}>
                {contactEditing.createdDate}
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
        <button
          type="button"
          color="danger"
          className="card-form__btn card-form__btn--submit"
          onClick={() => this.handleCancel()}
        >
          Back to List
        </button>
      </Col>
    );
  }
}

EditForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  cancelEdit: PropTypes.func.isRequired,
};
export default EditForm;
