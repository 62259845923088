/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAIL,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAIL,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  GET_TAG_BY_ID,
  GET_TAG_BY_ID_SUCCESS,
  GET_TAG_BY_ID_FAIL,
} from '../actions/tagAction';
import {
  updateElementFromArrayById,
  deleteElementFromArrayById,
  addElementToBeginningArray,
} from '../../shared/utils/calculation';
import {
  MSG_UPDATE_TAG_SUCCESS,
  MSG_UPDATE_TAG_FAIL,
  MSG_CREATE_TAG_SUCCESS,
  MSG_CREATE_TAG_FAIL,
  MSG_DELETE_TAG_SUCCESS,
  MSG_DELETE_TAG_FAIL,
} from '../../shared/utils/constants';

const initialState = {
  tagList: [],
  message: '',
  isUpdate: false,
  isError: false,
  data: null,
  statusCode: '',
  tagEdit: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TAGS: {
      return { ...state, type: action.type };
    }
    case GET_TAGS_SUCCESS: {
      return { ...state, ...{ type: action.type, tagList: action.data } };
    }
    case GET_TAGS_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case GET_TAG_BY_ID: {
      return { ...state, type: action.type };
    }
    case GET_TAG_BY_ID_SUCCESS: {
      return { ...state, ...{ type: action.type, tagEdit: action.data } };
    }
    case GET_TAG_BY_ID_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case CREATE_TAG: {
      return { ...state, type: action.type, message: '' };
    }
    case CREATE_TAG_SUCCESS: {
      return {
        ...state,
        type: action.type,
        tagList: addElementToBeginningArray(state.tagList, action.data),
        isError: false,
        message: MSG_CREATE_TAG_SUCCESS,
      };
    }
    case CREATE_TAG_FAIL: {
      return {
        ...state,
        ...{
          type: action.type,
          isError: true,
          message: MSG_CREATE_TAG_FAIL,
          statusCode: action.error ? action.error.statusCode.toString() : '',
        },
      };
    }
    case UPDATE_TAG: {
      return { ...state, type: action.type, message: '' };
    }
    case UPDATE_TAG_SUCCESS: {
      return {
        ...state,
        ...{
          type: action.type,
          isError: false,
          message: MSG_UPDATE_TAG_SUCCESS,
          tagEdit: action.data,
          tagList: updateElementFromArrayById(state.tagList, action.data),
        },
      };
    }
    case UPDATE_TAG_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: action.isDeleted ? MSG_DELETE_TAG_FAIL : MSG_UPDATE_TAG_FAIL,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case DELETE_TAG: {
      return { ...state, type: action.type, message: '' };
    }
    case DELETE_TAG_SUCCESS: {
      return {
        ...state,
        ...{
          type: action.type,
          isError: false,
          message: MSG_DELETE_TAG_SUCCESS,
          tagList: deleteElementFromArrayById(state.tagList, action.data.id),
        },
      };
    }
    case DELETE_TAG_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: MSG_DELETE_TAG_FAIL,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    default:
      return state;
  }
}
