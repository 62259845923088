/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Form, FormGroup, Label } from 'reactstrap';
import validateForm from '../../../../../shared/utils/validation';
import { changeTextToSlug } from '../../../../../shared/utils/calculation';
import schemaCategoryForm from './validation';
import './styleEditForm.scss';

class EditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemEditing: null,
      enName: '',
      viName: '',
      url: '',
      error: '',
    };
  }

  componentWillMount = () => {
    const { data } = this.props;
    if (data != null) {
      this.setState({
        itemEditing: data,
        enName: data.name.en,
        viName: data.name.vi,
        url: data.url,
      });
    }
  }

  handleClickSave = () => {
    const { save, catType, catList } = this.props;
    const { enName, viName, itemEditing, url } = this.state;
    const formData = { enName, viName, url };
    const { isError, error: err } = validateForm(schemaCategoryForm, formData);
    if (isError) {
      this.setState({ error: err });
      return;
    }
    let id;
    let sort = 0;
    let isUsefulResource;
    if (itemEditing != null) {
      id = itemEditing.id;
      sort = itemEditing.sortNum;
      isUsefulResource = itemEditing.isUsefulResource;
    } else {
      sort = catList.length;
      isUsefulResource = false;
    }
    const item = {
      name:
      {
        en: enName,
        vi: viName,
      },
      catType,
      sortNum: sort,
      url,
      isUsefulResource,
    };
    save(id, item);
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  }

  handleChange = (e) => {
    if (e.target.name === 'enName') {
      this.setState({ url: changeTextToSlug(e.target.value) });
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  renderButton() {
    return (
      <div className="card-form-wrapper">
        <button
          type="button"
          color="primary"
          className="card-form__btn card-form__btn--submit"
          onClick={() => this.handleClickSave()}
        >
          Save & Close
        </button>
        <button
          type="button"
          color="danger"
          className="card-form__btn card-form__btn--cancel"
          onClick={() => this.handleCancel()}
        >
          Close
        </button>
      </div>
    );
  }

  render() {
    const { enName, viName, error, url } = this.state;
    return (
      <Form className="form-cat">
        <FormGroup row>
          <Col sm={6}>
            <Label for="enName">Name (EN)</Label>
            <Input type="text" id="enName" name="enName" onChange={e => this.handleChange(e)} value={enName} />
            <div className={`${error.enName ? 'show-err' : ''} error-message`}>{error.enName}</div>
          </Col>
          <Col sm={6}>
            <Label for="viName">Name (VI)</Label>
            <Input type="text" id="viName" name="viName" onChange={e => this.handleChange(e)} value={viName} />
            <div className={`${error.viName ? 'show-err' : ''} error-message`}>{error.viName}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="url">Slug</Label>
            <Input type="text" id="url" name="url" onChange={e => this.handleChange(e)} value={url} />
            <div className={`${error.url ? 'show-err' : ''} error-message`}>{error.url}</div>
          </Col>
        </FormGroup>
        {this.renderButton()}
      </Form>
    );
  }
}

EditForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  save: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  catType: PropTypes.string.isRequired,
  catList: PropTypes.arrayOf(Object).isRequired,
};

export default EditForm;
