export const GET_JOBSEEKERS = 'GET_JOBSEEKERS';
export const GET_JOBSEEKERS_SUCCESS = 'GET_JOBSEEKERS_SUCCESS';
export const GET_JOBSEEKERS_FAIL = 'GET_JOBSEEKERS_FAIL';

export const UPDATE_JOBSEEKER = 'UPDATE_JOBSEEKER';
export const UPDATE_JOBSEEKER_SUCCESS = 'UPDATE_JOBSEEKER_SUCCESS';
export const UPDATE_JOBSEEKER_FAIL = 'UPDATE_JOBSEEKER_FAIL';

export const GET_JOBS_BY_JOBSEEKER_ID = 'GET_JOBS_BY_JOBSEEKER_ID';
export const GET_JOBS_BY_JOBSEEKER_ID_SUCCESS = 'GET_JOBS_BY_JOBSEEKER_ID_SUCCESS';
export const GET_JOBS_BY_JOBSEEKER_ID_FAIL = 'GET_JOBS_BY_JOBSEEKER_ID_FAIL';

export function getJobseekers(query, order, asc, limit, skip) {
  return {
    type: GET_JOBSEEKERS,
    query,
    order,
    asc,
    limit,
    skip,
  };
}

export function getJobseekersSuccess(data) {
  return {
    type: GET_JOBSEEKERS_SUCCESS,
    data,
  };
}

export function getJobseekersFail() {
  return {
    type: GET_JOBSEEKERS_FAIL,
  };
}

export function updateJobseeker(id, params) {
  return {
    type: UPDATE_JOBSEEKER,
    id,
    params,
  };
}

export function updateJobseekerSuccess(data) {
  return {
    type: UPDATE_JOBSEEKER_SUCCESS,
    data,
  };
}

export function updateJobseekerFail() {
  return {
    type: UPDATE_JOBSEEKER_FAIL,
  };
}

export function getJobsByJobseekerId(jobseekerId, order, asc, limit, skip) {
  return {
    type: GET_JOBS_BY_JOBSEEKER_ID,
    jobseekerId,
    order,
    asc,
    limit,
    skip,
  };
}

export function getJobsByJobseekerIdSuccess(data) {
  return {
    type: GET_JOBS_BY_JOBSEEKER_ID_SUCCESS,
    data,
  };
}

export function getJobsByJobseekerIdFail() {
  return {
    type: GET_JOBS_BY_JOBSEEKER_ID_FAIL,
  };
}
