/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React from 'react';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import StarOutline from 'mdi-react/StarOutlineIcon';
import StarIcon from 'mdi-react/StarIcon';
import PropTypes from 'prop-types';
import { Card, Row, Input, Modal, ModalBody, ButtonGroup, ModalHeader, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import Table from '../../../../../components/Table/index';
import './stylePostList.scss';
import {
  MSG_CONFIRM_DELETE,
} from '../../../../../shared/utils/constants';

class PostList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      catId: '',
      isPopupDelete: false,
      deleteItemId: null,
    };
  }

  componentDidMount() {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) this.handleCancelModal();
    });
  }

  handleCancelModal = () => {
    this.setState({
      isPopupDelete: false,
      deleteItemId: null,
    });
  };

  handleShowModalDelete = (id) => {
    this.setState({
      isPopupDelete: true,
      deleteItemId: id,
    });
  };

  handleChange = (event) => {
    this.setState({ query: event.target.value });
  };

  searchNews = () => {
    const { query, catId } = this.state;
    const { onSearch } = this.props;
    onSearch(query, catId);
  };

  handleSelectCat = (e) => {
    this.setState({ catId: e.target.value });
  }

  handleClickEditRow = (idRow) => {
    const { postList, newsEditing } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    const newsDetailView = postList.find(item => item._id === idRow);
    if (newsDetailView) {
      newsEditing(newsDetailView);
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.searchNews();
    }
  }

  onClickAddNews = () => {
    const { onAddNews } = this.props;
    onAddNews();
  }

  onChangeIsHot = (idRow) => {
    const { postList, onUpdateNews } = this.props;
    const news = postList.find(item => item._id === idRow);
    if (news && news.category) {
      onUpdateNews(idRow, { isHot: !news.isHot, categoryId: news.category._id, languageForAdmin: news.languageForAdmin });
    }
  }

  handleClickDeleteRow = (idRow) => {
    const { onDeleteNews } = this.props;
    onDeleteNews(idRow);
    this.handleCancelModal();
  };

  handleClickChangeStatus = (id, status) => {
    const { postList, onUpdateNews } = this.props;
    const news = postList.find(item => item._id === id);
    if (news.category) {
      onUpdateNews(id, {
        status, categoryId: news.category._id, languageForAdmin: news.languageForAdmin,
      });
    }
  };

  renderPopupConfirmDelete = () => {
    const { deleteItemId, isPopupDelete } = this.state;
    return (
      <Modal isOpen={isPopupDelete} toogle={this.toogleModalSave} className="order-popup">
        <ModalHeader>Delete Post</ModalHeader>
        <ModalBody>
          {MSG_CONFIRM_DELETE}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup className="float-right">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.handleClickDeleteRow(deleteItemId)}
            >
              Yes
            </button>
            <button
              type="button"
              className="card-form__btn card-form__btn--cancel"
              color="danger"
              onClick={() => this.handleCancelModal()}
            >
              Cancel
            </button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { postList, catList, postType, tagList } = this.props;
    const { catId } = this.state;
    const categoryList = catList.filter(item => item.catType === postType);
    const categoriesOptions = categoryList.map(category => <option key={category.id} value={category.id}>{category.name.en}</option>);
    let listUrl;
    if (postType === 'CANDIDATE') {
      listUrl = '/admin/news-jobseeker/posts';
    }
    if (postType === 'RECRUITER') {
      listUrl = '/admin/news-recruiter/posts';
    }
    const data = postList.filter((item) => {
      if (catId !== '') {
        if (catId === 'uncategorized') {
          return !item.category;
        }
        if (item.category) {
          return item.category._id === catId;
        }
      } else {
        if (!item.category) {
          return item;
        }
        return item.category.catType === postType;
      }
      return null;
    });
    return (
      <Card>
        <Row>
          <div className="form__form-group">
            <div className="form__form-group-field form__searchPost">
              <div className="form__form-group-icon">
                <MagnifyIcon />
              </div>
              <Input
                type="text"
                className=""
                name="search"
                placeholder="Search"
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
              />
              <Input type="select" name="categoryId" id="categoryId" onChange={e => this.handleSelectCat(e)} value={catId}>
                <option value="">Select Category</option>
                <option value="uncategorized">Uncategorized</option>
                {categoriesOptions}
              </Input>
              <button
                type="button"
                className="card-form__btn card-form__btn--submit btn-search"
                color="primary"
                onClick={this.searchNews}
              >
                Search
              </button>
            </div>
          </div>
          <div className="card-form-wrapper">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.onClickAddNews()}
            >
              Add
            </button>
          </div>
        </Row>
        <Table
          data={data}
          columns={[
            {
              Header: 'Title (EN)',
              accessor: 'title.en',
              sortable: true,
            },
            {
              Header: 'Title (VI)',
              accessor: 'title.vi',
              sortable: true,
            },
            {
              Header: 'Published',
              accessor: null,
              Cell: (row) => {
                const { _id, status } = row.original;
                if (status === 'publish') {
                  return <div><StarIcon onClick={() => this.handleClickChangeStatus(_id, 'unpublish')} className="ishot-yes" /></div>;
                }
                return <StarOutline onClick={() => this.handleClickChangeStatus(_id, 'publish')} className="ishot-no" />;
              },
            },
            {
              Header: 'Categories',
              accessor: null,
              Cell: (row) => {
                const { category } = row.original;
                const cat = category ? catList.find(item => item.id === category._id) : null;
                if (cat) {
                  const enName = cat.name.en;
                  return <span>{enName}</span>;
                }
                return <span>Uncategorized</span>;
              },
            },
            {
              Header: 'Tags',
              accessor: null,
              Cell: (row) => {
                const { tags } = row.original;
                let pTags;
                if (tags) {
                  pTags = tags.map((tag) => {
                    const tagitem = tagList.find(item => item.id === tag);
                    let tagsName;
                    if (tagitem) {
                      tagsName = `${tagitem.name.en}, `;
                    }
                    return tagsName;
                  });
                }
                return <span>{pTags}</span>;
              },
            },
            {
              Header: 'Slug',
              accessor: 'url',
            },
            {
              Header: 'Views',
              accessor: 'views',
            },
            {
              Header: 'Updated',
              accessor: 'updatedDate',
            },
            {
              Header: 'Featured',
              accessor: null,
              Cell: (row) => {
                const { isHot, _id } = row.original;
                if (isHot) {
                  return <div><StarIcon onClick={() => this.onChangeIsHot(_id)} className="ishot-yes" /></div>;
                }
                return <StarOutline onClick={() => this.onChangeIsHot(_id)} className="ishot-no" />;
              },
            },
            {
              Header: 'Actions',
              accessor: null,
              Cell: (row) => {
                const { _id } = row.original;
                return (
                  <div className="table-actions">
                    <Link to={`${listUrl}/${_id}`}>
                      Edit
                    </Link>
                    <span
                      role="presentation"
                      className="action-delete"
                      onClick={() => this.handleShowModalDelete(_id)}
                    >
                      Delete
                    </span>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          className="custom-table"
          showPaginationBottom
          sortable={false}
        />
        {this.renderPopupConfirmDelete()}
      </Card>
    );
  }
}

PostList.propTypes = {
  onAddNews: PropTypes.func.isRequired,
  newsEditing: PropTypes.func.isRequired,
  postList: PropTypes.instanceOf(Object).isRequired,
  tagList: PropTypes.instanceOf(Object).isRequired,
  onSearch: PropTypes.func.isRequired,
  onUpdateNews: PropTypes.func.isRequired,
  catList: PropTypes.instanceOf(Object).isRequired,
  postType: PropTypes.string.isRequired,
  onDeleteNews: PropTypes.string.isRequired,
};

export default PostList;
