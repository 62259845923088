/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import Table from '../../../../components/Table/index';
import { getJobApplicationStatuses } from '../../../../shared/utils/status';

class EmailTemplateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onClickAddNewEmailTemplate = () => {
    const { handleAddNew } = this.props;
    handleAddNew();
  }

  handleClickEditRow = (idRow) => {
    const { emailTemplateEditing, emailTemplates } = this.props;
    const filteredData = emailTemplates.find(item => item.id === idRow);
    emailTemplateEditing(filteredData);
  }

  handleClickDeleteRow = (idRow) => {
    const { emailTemplateDeleting, emailTemplates } = this.props;
    const filteredData = emailTemplates.find(item => item.id === idRow);
    emailTemplateDeleting(filteredData);
  }


  convertJobStatus = value => {
    const statuses = getJobApplicationStatuses();
    const statusObj = statuses.find(s => s.value === value);

    return statusObj ? statusObj.text : '';
  };

  render() {
    const { emailTemplates } = this.props;
    return (
      <Card>
        <div className="card-form-wrapper">
          <button
            type="button"
            className="card-form__btn card-form__btn--submit"
            color="primary"
            onClick={() => this.onClickAddNewEmailTemplate()}
          >
            Add
          </button>
        </div>
        <Table
          data={emailTemplates}
          columns={[
            {
              Header: 'Name',
              accessor: 'name',
              sortable: true,
            },
            {
              Header: 'English Subject',
              accessor: 'subject.en',
              sortable: true,
            },
            {
              Header: 'Vietnamese Subject',
              accessor: 'subject.vi',
              sortable: true,
            },
            {
              Header: 'Job Status',
              accessor: 'jobStatus',
              Cell: ({ value }) => {
                return this.convertJobStatus(value);
              }
            },
            {
              Header: 'Actions',
              accessor: null,
              Cell: (row) => {
                const { id } = row.original;
                return (
                  <div className="table-actions">
                    <span
                      role="presentation"
                      className="action-edit"
                      onClick={() => this.handleClickEditRow(id)}
                    >
                      Edit
                    </span>
                    <span
                      role="presentation"
                      className="action-delete"
                      onClick={() => this.handleClickDeleteRow(id)}
                    >
                      Delete
                    </span>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          className="custom-table"
          showPaginationBottom
          sortable={false}
        />
      </Card>
    );
  }
}
EmailTemplateList.propTypes = {
  handleAddNew: PropTypes.func.isRequired,
  emailTemplateEditing: PropTypes.func.isRequired,
  emailTemplates: PropTypes.instanceOf(Object).isRequired,
};

export default EmailTemplateList;
