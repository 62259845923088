/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Input } from 'reactstrap';
import { JEditor } from '../../../../components';
import {
  FILE_UPLOAD_MAX_SIZE,
} from '../../../../shared/utils/constants';
import ApiConstants from '../../../../shared/api/ApiConstants';
import { getCookie } from '../../../../shared/utils/calculation';
import './styleField.scss';

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChangeDescEn = (value) => {
    const { onChange } = this.props;
    onChange('enNewsBannerDesc', value);
  }

  handleChangeDescVi = (value) => {
    const { onChange } = this.props;
    onChange('viNewsBannerDesc', value);
  }

  onChangeNewsBannerImage = (e) => {
    const { history } = this.props;
    const { onChange } = this.props;
    const { files } = e.target;
    const reader = new FileReader();
    if (files.length > 0) {
      reader.readAsDataURL(files && files.length > 0 && files[0]);
      if (files[0].size > FILE_UPLOAD_MAX_SIZE * 1024 * 1024) {
      // eslint-disable-next-line no-alert
        alert(`File is too big! Maximum is ${FILE_UPLOAD_MAX_SIZE}MB. Please upload another file.`);
        e.target.value = null;
        return;
      }
      reader.onload = async () => {
        const formData = new FormData();
        formData.append('file', files[0]);
        const token = getCookie('token');
        const bearer = `Bearer ${token}`;
        const options = {
          headers: {
            Authorization: bearer,
          },
          method: 'POST',
          body: formData,
        };
        const resp = await fetch(`${ApiConstants.BASE_URL}${ApiConstants.UPLOADFILE}`, options);
        const respCopy = resp.clone();
        const json = await respCopy.json();
        if (json.error) {
          history.push('/');
        }
        onChange('NewsBannerImage', json.url);
      };
    }
  }

  render() {
    const { formField, error, isEdit } = this.props;
    const inputClass = isEdit ? '' : 'input-disable';
    const isDisable = isEdit ? '' : 'disabled';
    return (
      <>
        <FormGroup row>
          <Col sm={12}>
            <Label for="NewsBannerImage">Banner image (Limit {FILE_UPLOAD_MAX_SIZE}MB)</Label>
            <img className="NewsBannerImage" src={formField.NewsBannerImage} alt="" />
            <Input type="file" id="NewsBannerImage" className={inputClass} disabled={isDisable} name="featured_image" onChange={e => this.onChangeNewsBannerImage(e)} />
            <div className={`${error.NewsBannerImage ? 'show-err' : ''} error-message`}>{error.NewsBannerImage}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="enNewsBannerDesc">Banner Description (EN)</Label>
            <JEditor
              content={formField.enNewsBannerDesc}
              onChangeEditor={value => this.handleChangeDescEn(value)}
              name="enNewsBannerDesc"
              id="enNewsBannerDesc"
            />
            <div className={`${error.enNewsBannerDesc ? 'show-err' : ''} error-message`}>{error.enNewsBannerDesc}</div>
          </Col>
          <Col sm={6}>
            <Label for="viNewsBannerDesc">Banner Description (EN)</Label>
            <JEditor
              content={formField.viNewsBannerDesc}
              onChangeEditor={(e, value) => this.handleChangeDescVi(e, value)}
              name="viNewsBannerDesc"
              id="viNewsBannerDesc"
            />
            <div className={`${error.viNewsBannerDesc ? 'show-err' : ''} error-message`}>{error.viNewsBannerDesc}</div>
          </Col>
        </FormGroup>
      </>
    );
  }
}

News.propTypes = {
  formField: PropTypes.instanceOf(Object).isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default News;
