import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './Table.scss';
import PropTypes from 'prop-types';
import Table from '../../../../components/Table/index';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
/* eslint-disable */
class DragTrComponent extends React.Component {
  render() {
    const { children = null, rowInfo } = this.props;
    if (rowInfo) {
      const { original, index } = rowInfo;
      const { id } = original;
      return (
        <Draggable key={id} index={index} draggableId={id}>
          {draggableProvided => (
            <div
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
              style={{ flex: 1, display: 'flex' }}
            >
              <ReactTable.defaultProps.TrComponent>
                {children}
              </ReactTable.defaultProps.TrComponent>
            </div>
          )}
        </Draggable>
      );
    } return (
      <ReactTable.defaultProps.TrComponent>
        {children}
      </ReactTable.defaultProps.TrComponent>
    );
  }
}

class DropTbodyComponent extends React.Component {
  render() {
    const { children = null } = this.props;

    return (
      <Droppable droppableId="droppable">
        {droppableProvided => (
          <div ref={droppableProvided.innerRef}>
            <ReactTable.defaultProps.TbodyComponent>
              {children}
            </ReactTable.defaultProps.TbodyComponent>
          </div>
        )}
      </Droppable>
    );
  }
}

class TableDndApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        data: nextProps.data,
      });
    }
  }


    handleDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const data = reorder(
        this.state.data,
        result.source.index,
        result.destination.index,
      );

      this.setState({
        data,
      });

      const newData = data.map(item => item.id);
      this.props.handleOrderDescription(newData);
    };

    getTrProps = (state, rowInfo) => ({ rowInfo });

    render() {
      const { data } = this.state;
      return (
        <div>
          <DragDropContext onDragEnd={this.handleDragEnd}>
            <Table
              TrComponent={DragTrComponent}
              TbodyComponent={DropTbodyComponent}
              getTrProps={this.getTrProps}
              data={data}
              columns={[
                {
                  Header: 'Label',
                  columns: [
                    {
                      Header: 'English',
                      accessor: 'labelEnglish',
                    },
                    {
                      Header: 'Vietnamese',
                      accessor: 'labelVn',
                    },
                  ],
                },
                {
                  Header: 'Actions',
                  accessor: null,
                  Cell: (props) => {
                    const {
                      original: { id: idRow },
                      original,
                    } = props;
                    return (
                      <div className="table-actions">
                        <span role="presentation" className="action-edit" onClick={() => this.props.toogleModalEdit(original.data)}>
                                                Edit
                        </span>
                        <span
                          role="presentation"
                          className="action-delete"
                          onClick={() => this.props.handleDeleteRow(idRow)}
                        >
                                                Delete
                        </span>
                      </div>
                    );
                  },
                },
              ]}
              className="custom-table"
              showPaginationBottom
              defaultPageSize={5}
              sortable={false}
            />
          </DragDropContext>
        </div>
      );
    }
}

TableDndApp.propTypes = {
  modal: PropTypes.objectOf(PropTypes.object).isRequired,
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  handleOrderDescription: PropTypes.func.isRequired,
};


export default TableDndApp;
