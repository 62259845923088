/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AddIcon from 'mdi-react/AddIcon';
import Collapse from '../Collapse';
import { toogleModalAdd } from '../../../redux/actions/modalAction';
import Group from './GroupComponent';
import Field from './FieldComponent';


class SectionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.toogleModalAdd = this.toogleModalAdd.bind(this);
    this.renderField = this.renderField.bind(this);
  }

  toogleModalAdd = () => {
    const { dispatch, typeSection } = this.props;
    const data = {
      isShow: true,
      typeSection,
      isShowGroup: true,
    };
    dispatch(toogleModalAdd(data));
  }

  renderField = (data) => {
    const { typeSection, typeGroupSection } = this.props;
    const html = [];
    const isShowGroup = true;
    if (data.length > 0) {
      for (let i = 0; i < data.length; i += 1) {
        const item = data[i];
        if (item.type === 'Group') {
          html.push(<Group typeSection={typeSection} typeGroupSection={typeGroupSection} indexInTypeSection={i} />);
        } else {
          html.push(<Field data={item} typeSection={typeSection} indexInTypeSection={i} isShowGroup={isShowGroup} />);
        }
      }
    }
    return html;
  }

  render() {
    const {
      title,
      className,
      template,
      typeSection,
    } = this.props;

    let data = [];
    if (typeSection === 'hardSkillSection' || typeSection === 'softSkillSection') {
      data = template.data.jobRequirementSection[typeSection].fields;
    } else if(template.data[typeSection]) {
      data = template.data[typeSection].fields;
    } else {
      data = []
    }

    return (
      <Collapse title={title} className={className}>
        {this.renderField(data)}
        <br />
        <Button outline color="primary" size="sm" onClick={() => this.toogleModalAdd()}>
          <p><AddIcon />Add Field</p>
        </Button>
      </Collapse>
    );
  }
}

SectionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  // data: PropTypes.objectOf.isRequired,
  className: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  typeSection: PropTypes.string.isRequired,
  typeGroupSection: PropTypes.string.isRequired,
  template: PropTypes.objectOf.isRequired,
};

const mapStateToProps = (state) => {
  const { template } = state;
  return {
    template,
  };
};
export default connect(
  mapStateToProps,
  null,
)(withTranslation('common')(SectionComponent));
