import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import validateForm from '../../../shared/utils/validation';
import { schemaCardForm } from './validation';
import TextArea from "../../../components/TextArea";

const initFormQuestionInputs = { vi: '', en: '' };

const CardForm = ({
  onSubmit, cancelForm, formValues, type,
}) => {
  const [formInputs, setFormInputs] = useState({...formValues});
  const [error, setError] = useState({});

  useEffect(() => {
    setFormInputs({...formValues});
    setError({});
  }, [formValues]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormInputs({ ...formInputs, [name]: value });
  };

  const handleClickSubmit = () => {
    onSubmit(formInputs);
    setFormInputs(initFormQuestionInputs);
  };

  const handleClickCancel = () => {
    cancelForm();
    setError({});
  };

  return (
    <>
      <div className="card-input card-form__input">
        <TextArea
          styles={{
            minHeight: '68px',
            padding: '8px'
          }}
          classRoot="w-100"
          placeholder="Question Name(EN)"
          name="en"
          value={formInputs.en || ''}
          onChange={handleChangeInput}
          errorMesssage={error.en || ''}
        />
        <TextArea
          styles={{
            minHeight: '68px',
            padding: '8px'
          }}
          classRoot="w-100"
          placeholder="Question Name(VI)"
          name="vi"
          value={formInputs.vi || ''}
          onChange={handleChangeInput}
          errorMesssage={error.vi || ''}
        />
      </div>
      <div className="btn-button" style={{ width: '108px'}}>
        <button
          type="button"
          disabled={!(formInputs.vi && formInputs.en)}
          className="card-form__btn card-form__btn--submit w-100"
          onClick={handleClickSubmit}
        >
          {`${type === 'add' ? 'Add' : 'Save'}`}
        </button>
        <button type="button" className="card-form__btn card-form__btn--cancel w-100 mt-1" onClick={handleClickCancel}>
          Cancel
        </button>
      </div>
    </>
  );
};

CardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  cancelForm: PropTypes.func,
  setReadOnly: PropTypes.func,
  type: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  formValues: PropTypes.objectOf(PropTypes.any),
};

CardForm.defaultProps = {
  formValues: {
    vi: '',
    en: '',
  },
  isReadOnly: false,
  cancelForm: () => {},
  setReadOnly: () => {},
};
export default CardForm;
