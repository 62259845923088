import { take, call, put } from 'redux-saga/effects';
import {
  GET_RECRUITERS,
  GET_RECRUITERS_SUCCESS,
  GET_RECRUITERS_FAIL,
  UPDATE_RECRUITER,
  UPDATE_RECRUITER_SUCCESS,
  UPDATE_RECRUITER_FAIL,
  DELETE_RECRUITER,
  DELETE_RECRUITER_SUCCESS,
  DELETE_RECRUITER_FAIL,
  GET_JOBS_BY_RECRUITER_ID,
  GET_JOBS_BY_RECRUITER_ID_SUCCESS,
  GET_JOBS_BY_RECRUITER_ID_FAIL,
  CREATE_RECRUITER_FAIL,
  CREATE_RECRUITER_SUCCESS,
  CREATE_RECRUITER,
} from '../actions/recruiterAction';
import {
  getRecruiters,
  updateRecruiter,
  deleteRecruiter,
  getJobsByRecruiterId,
  createRecruiter,
} from '../../shared/api/methods/recruiter';

export function* getRecruitersSaga() {
  while (true) {
    try {
      const {
        query,
        order,
        asc,
        limit,
        skip,
      } = yield take(GET_RECRUITERS);
      const data = yield call(getRecruiters, [query, order, asc, limit, skip]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_RECRUITERS_FAIL, error });
      } else {
        yield put({ type: GET_RECRUITERS_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_RECRUITERS_FAIL, data: [] });
    }
  }
}

export function* createRecruiterSaga() {
  while (true) {
    try {
      const { params } = yield take(CREATE_RECRUITER);
      const data = yield call(createRecruiter, params);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: CREATE_RECRUITER_FAIL, error });
      } else {
        yield put({ type: CREATE_RECRUITER_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: CREATE_RECRUITER_FAIL, error });
    }
  }
}

export function* updateRecruiterSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_RECRUITER);
      const data = yield call(updateRecruiter, [id, params]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_RECRUITER_FAIL, error });
      } else {
        yield put({ type: UPDATE_RECRUITER_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: UPDATE_RECRUITER_FAIL, error });
    }
  }
}

export function* getJobsByRecruiterIdSaga() {
  while (true) {
    try {
      const {
        recruiterId,
        order,
        asc,
        limit,
        skip,
      } = yield take(GET_JOBS_BY_RECRUITER_ID);
      const data = yield call(getJobsByRecruiterId, [recruiterId, order, asc, limit, skip]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_JOBS_BY_RECRUITER_ID_FAIL, error });
      } else {
        yield put({ type: GET_JOBS_BY_RECRUITER_ID_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_JOBS_BY_RECRUITER_ID_FAIL, data: [] });
    }
  }
}

export function* deleteRecruiterSaga() {
  while (true) {
    try {
      const { id } = yield take(DELETE_RECRUITER);
      const data = yield call(deleteRecruiter, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: DELETE_RECRUITER_FAIL, error });
      } else {
        yield put({ type: DELETE_RECRUITER_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: DELETE_RECRUITER_FAIL, error });
    }
  }
}