import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import BoxedCollapseFullWidth from './components/BoxedCollapseFullWidth';
// import { redirectBackToLogin, redirectAfterLoginSuccess } from '../../../redux/actions/authenticationAction';
// import SelectedHeader from './components/SelectedHeader';

class JobTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
    };
    this.selectedHeader = this.selectedHeader.bind(this);
  }

  componentWillMount = async () => {
    const { cookies } = this.props;
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  selectedHeader(data) {
    // eslint-disable-next-line no-console
    console.log(data);
  }

  render() {
    const { template, cookies } = this.props;
    const { hasToken } = this.state;

    if (!hasToken || template.statusCode === '401') {
      if (template.statusCode === '401') cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Job Template</h3>
          </Col>
        </Row>
        {/* <Row>
          <SelectedHeader selectCallback={data => this.selectedHeader(data)} />
        </Row> */}
        <Row>
          <BoxedCollapseFullWidth />
        </Row>
      </Container>
    );
  }
}

JobTemplate.propTypes = {
  template: PropTypes.objectOf(PropTypes.object).isRequired,
  // dispatch: PropTypes.func.isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

const mapStateToProps = (state) => {
  const { template } = state;
  return {
    template,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withCookies(JobTemplate), withTranslation('common')(JobTemplate));
