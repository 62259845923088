/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Label,
  Button,
  FormGroup,
  Tooltip,
  ButtonGroup,
  UncontrolledCollapse,
} from 'reactstrap';
import DeleteIcon from 'mdi-react/TrashIcon';
import EditIcon from 'mdi-react/EditIcon';
import CopyIcon from 'mdi-react/ContentCopyIcon';
import InformationIcon from 'mdi-react/InformationIcon';
import CollapseVerticalIcon from 'mdi-react/CollapseAllIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toogleModalEdit, toogleModalDelete } from '../../../redux/actions/modalAction';

class FieldComponent extends React.Component {
  constructor(props) {
    super(props);
    const generateID = Math.random().toString(36).substr(2, 9);
    this.state = {
      tooltipOpen: false,
      tooltipTarget: `tooltip-${generateID}`,
      collapseTarget: `collapse-${generateID}`,
    };
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toogleModalEditDeleteClone = this.toogleModalEditDeleteClone.bind(this);
  }

  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  toogleModalEditDeleteClone = (dataEdit, actionEdit, actionDelete) => {
    const {
      dispatch,
      typeSection,
      index,
      indexInGroup,
      indexInTypeSection,
    } = this.props;

    const dataModal = {
      isShow: true,
      typeSection,
      indexInTypeSection,
      indexInGroup,
      index,
    };
    if (actionEdit) {
      const isShowGroup = typeof index === 'undefined';
      dataModal.isEdit = true;
      dataModal.dataEdit = dataEdit;
      dataModal.isShowGroup = isShowGroup;
      dispatch(toogleModalEdit(dataModal));
    } else {
      if (actionDelete) {
        dataModal.isDelete = true;
      } else {
        dataModal.isClone = true;
      }
      dispatch(toogleModalDelete(dataModal));
    }
  }

  render() {
    const {
      data,
    } = this.props;
    const {
      type, label, tooltip, weight, fieldName,
      showDescriptionForApplicant,
      allowUploadFile,
      allowRecruiterRemove,
      allowApplicantRemove,
      level,
    } = data;
    const { tooltipOpen, tooltipTarget, collapseTarget } = this.state;
    const labelField = label && label.en ? label.en : '';
    const tooltipField = tooltip && tooltip.en ? tooltip.en : '';

    return (
      <Container>
        <Row>
          <Col md={3} sm={12} className="border">
            <FormGroup>
              {labelField !== '' ? (
                <Label sm={12}><strong>{label.en}</strong>
                  { tooltipField !== '' && (
                    <span>
                      <span id={tooltipTarget}><InformationIcon /></span>
                      <Tooltip isOpen={tooltipOpen} target={tooltipTarget} toggle={this.toggleTooltip}>
                        {tooltipField}
                      </Tooltip>
                    </span>
                  )}
                </Label>
              ) : null }
            </FormGroup>
          </Col>
          <Col className="border">
            <FormGroup row>
              <Col md={9}>
                <Label><h4><strong>{type}</strong></h4></Label><br />
                <Label><strong>{fieldName}</strong></Label><br />
                <Label id={collapseTarget}><strong><CollapseVerticalIcon />Common attributes </strong></Label>
                <UncontrolledCollapse toggler={collapseTarget}>
                  <FormGroup>
                    <p>Show description field for applicant: <strong>{allowRecruiterRemove ? showDescriptionForApplicant.toString() : 'false'}</strong></p>
                    <p>Recruiter can remove this field: <strong>{allowRecruiterRemove ? allowRecruiterRemove.toString() : 'false'}</strong></p>
                    <p>Applicant can remove this field: <strong>{allowApplicantRemove ? allowApplicantRemove.toString() : 'false'}</strong></p>
                    <p>Weight: <strong>{weight ? weight.toString() : ''}</strong></p>
                    <p>Level Field: <strong>{level ? level.toString() : ''}</strong></p>
                    <p>Allow upload file: <strong>{allowUploadFile ? allowUploadFile.toString() : 'false'}</strong></p>
                  </FormGroup>
                </UncontrolledCollapse>
              </Col>
              <Col md={3}>
                <ButtonGroup className="float-right">
                  <Button outline color="danger" size="sm" onClick={() => this.toogleModalEditDeleteClone(data, false, true)}>
                    <p><DeleteIcon /></p>
                  </Button>
                  <Button outline color="primary" size="sm" onClick={() => this.toogleModalEditDeleteClone(data, true, false)}>
                    <p><EditIcon /></p>
                  </Button>
                  <Button outline color="primary" size="sm" onClick={() => this.toogleModalEditDeleteClone(data, false, false)}>
                    <p><CopyIcon /></p>
                  </Button>
                </ButtonGroup>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </Container>
    );
  }
}

FieldComponent.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  typeSection: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  indexInTypeSection: PropTypes.number.isRequired,
  indexInGroup: PropTypes.number.isRequired,
};

export default connect(
  null,
  null,
)(withTranslation('common')(FieldComponent));
