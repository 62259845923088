import { take, call, put } from 'redux-saga/effects';
import {
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAIL,
} from '../actions/uploadFileAction';
import {
  uploadFile,
} from '../../shared/api/methods/uploadFile';

export default function* uploadFileSaga() {
  while (true) {
    try {
      const { file } = yield take(UPLOAD_FILE);
      const data = yield call(uploadFile, file);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPLOAD_FILE_FAIL, error });
      } else {
        yield put({ type: UPLOAD_FILE_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: UPLOAD_FILE_FAIL, data: [] });
    }
  }
}
