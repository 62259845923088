import api from '../index';
import ApiConstants from '../ApiConstants';

export const getFunctions = () => api(
  `${ApiConstants.FUNCTION}`,
  null,
  'get',
  null,
);

export const createDepartment = department => api(
  `${ApiConstants.DEPARTMENT}`,
  department,
  'POST',
  null,
);

export const updateDepartment = department => api(
  `${ApiConstants.DEPARTMENT}/${department.id}`,
  department,
  'PATCH',
  null,
);

export const getFunctionsByDepartmentId = departmentId => api(
  `${ApiConstants.FUNCTION}/${departmentId}`,
  null,
  'get',
  null,
);

export const createFunction = func => api(
  `${ApiConstants.FUNCTION}`,
  func,
  'post',
  null,
);

export const updateFunction = args => api(
  `${ApiConstants.FUNCTION}/${args[0]}`,
  args[1],
  'PATCH',
  null,
);
export const deleteFunction = func => api(
  `${ApiConstants.FUNCTION}/${func.id}`,
  func,
  'PATCH',
  null,
);
