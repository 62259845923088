/**
 * Function for validate
 * @param {object} schema yup schema
 * @param {object} value
 * The return value have 2 value
 *  - isError => For check have error
 *  - error  => The object return error follow format { {{fieldName}}: {{message}} }
 */

const validateForm = (schema = {}, value = {}) => {
  let isError = false;
  const error = {};

  try {
    schema.validateSync(value, { abortEarly: false });
  } catch (err) {
    isError = true;
    err.inner.forEach((item) => {
      error[item.path] = item.message;
    });
  }

  return { isError, error };
};

export default validateForm;
