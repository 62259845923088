/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Col, CardBody, Container, Row, Card } from 'reactstrap';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import PropTypes from 'prop-types';
import { BasicNotification } from '../../../../../components/Notification/index';
import {
  getCategoryById as getCategoryByIdAction,
  updateCategory as updateCategoryAction,
  createCategory as createCategoryAction,
} from '../../../../../redux/actions/categoryAction';
import {
  STATUS_CODE_401,
} from '../../../../../shared/utils/constants';
import './styleEditForm.scss';
import EditForm from './EditForm';

let notificationRU = null;

class CatForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isShowNotify: false,
      catType: '',
    };
  }

  componentWillMount = () => {
    const { match, getCategoryById, cookies, pType } = this.props;
    if (pType) {
      this.setState({ catType: pType });
    } else if (match) {
      const { path } = match;
      if (path.includes('recruiter')) {
        this.setState({ catType: 'RECRUITER' });
      }
      if (path.includes('jobseeker')) {
        this.setState({ catType: 'CANDIDATE' });
      }
    }
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    if (match) {
      getCategoryById(match.params.id);
    }
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  showNotification = (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Posts"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  onUpdateCategory = (id, params) => {
    const { updateCategory, createCategory } = this.props;
    if (id) {
      updateCategory(id, params);
    } else {
      createCategory(params);
    }
    this.setState({ isShowNotify: true });
  }

  cancelForm = () => {
    const { match, onCancelForm, history } = this.props;
    const { catType } = this.state;
    if (match) {
      if (catType === 'RECRUITER') {
        history.push('/admin/news-recruiter/category');
      }
      if (catType === 'CANDIDATE') {
        history.push('/admin/news-jobseeker/category');
      }
    } else {
      onCancelForm();
    }
  }

  render() {
    const { category, cookies, match, isAddItem, catList } = this.props;
    const { hasToken, isShowNotify, catType } = this.state;
    let categoryForm;
    if (category.categoryEdit != null && match) {
      if (category.categoryEdit.id === match.params.id) {
        categoryForm = <EditForm catType={catType} catList={catList} data={category.categoryEdit} save={(id, params) => this.onUpdateCategory(id, params)} onCancel={this.cancelForm} />;
      }
    }
    if (isAddItem) {
      categoryForm = <EditForm catType={catType} catList={catList} save={(id, params) => this.onUpdateCategory(id, params)} onCancel={this.cancelForm} />;
    }
    if (isShowNotify && category.message) {
      if (category.isError) {
        this.showNotification('warning', category.message);
      } else {
        this.showNotification('success', category.message);
        setTimeout(() => {
          this.cancelForm();
        }, 3000);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || category.statusCode === STATUS_CODE_401) {
      if (category.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`${isAddItem ? 'Create' : 'Update'}`} Category</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          <Col md={12} lg={12} xl={12}>
            <CardBody>
              { categoryForm }
            </CardBody>
          </Col>
        </Card>
      </Container>
    );
  }
}

CatForm.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  getCategoryById: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  category: PropTypes.instanceOf(Object).isRequired,
  updateCategory: PropTypes.func.isRequired,
  createCategory: PropTypes.func.isRequired,
  onCancelForm: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  pType: PropTypes.string,
  isAddItem: PropTypes.bool,
  catList: PropTypes.arrayOf(Object).isRequired,
};
const mapStateToProps = (state) => {
  const { category } = state;
  return {
    category,
  };
};
const mapDispatchToProps = dispatch => ({
  getCategoryById: id => dispatch(getCategoryByIdAction(id)),
  updateCategory: (id, params) => dispatch(updateCategoryAction(id, params)),
  createCategory: params => dispatch(createCategoryAction(params)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(CatForm));
