import * as yup from 'yup';
import FIELD_REQUIRED from '../../../shared/utils/messageError';

const schemaGlobalConfigForm = yup.object().shape({
  adminEmail: yup.string().required(FIELD_REQUIRED),
  enCompanyName: yup.string().required(FIELD_REQUIRED),
  viCompanyName: yup.string().required(FIELD_REQUIRED),
  companyEmail: yup.string().required(FIELD_REQUIRED),
  enCompanyAddress1: yup.string().required(FIELD_REQUIRED),
  enCompanyAddress2: yup.string().required(FIELD_REQUIRED),
  viCompanyAddress1: yup.string().required(FIELD_REQUIRED),
  viCompanyAddress2: yup.string().required(FIELD_REQUIRED),
  companyPhone: yup.string().required(FIELD_REQUIRED),
  viContactDesc: yup.string().required(FIELD_REQUIRED),
  enContactDesc: yup.string().required(FIELD_REQUIRED),
  enNewsDesc: yup.string().required(FIELD_REQUIRED),
  viNewsDesc: yup.string().required(FIELD_REQUIRED),
  enUseful: yup.string().required(FIELD_REQUIRED),
  viUseful: yup.string().required(FIELD_REQUIRED),
  enFAQ: yup.string().required(FIELD_REQUIRED),
  viFAQ: yup.string().required(FIELD_REQUIRED),
  enHotCareer: yup.string().required(FIELD_REQUIRED),
  viHotCareer: yup.string().required(FIELD_REQUIRED),
  enHotIndustry: yup.string().required(FIELD_REQUIRED),
  viHotIndustry: yup.string().required(FIELD_REQUIRED),
  enFirstLookDesc: yup.string().required(FIELD_REQUIRED),
  viFirstLookDesc: yup.string().required(FIELD_REQUIRED),
  viNewsBannerDesc: yup.string().required(FIELD_REQUIRED),
  enNewsBannerDesc: yup.string().required(FIELD_REQUIRED),
  NewsBannerImage: yup.string().required(FIELD_REQUIRED),
});
export default schemaGlobalConfigForm;
