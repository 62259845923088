/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import Table from '../../../../components/Table/index';
import FormExpertise from './FormExpertise';
import './CardExpertise.scss';

const CardExpertise = ({
  idFunction, dataList, deleteExpertiseById, createExpertise, updateExpertise,
}) => {
  const [dataTable, setDataTable] = useState();
  const [formValues, setFormValues] = useState({});
  const [isReadOnly, setReadOnly] = useState(false);
  const [isAddForm, setIsAddForm] = useState(false);

  const NAME_ACTION = 'Actions';
  useEffect(() => {
    const newData = dataList.map(item => ({
      id: item.id,
      english: item.label.en || '',
      vietnamese: item.label.vi || '',
    }));
    setDataTable(newData);
  }, [dataList]);
  const handleClickDeleteRow = (idRow) => {
    deleteExpertiseById(idRow);
    setFormValues({});
  };
  const handleClickEditRow = (idRow) => {
    const findedData = dataList.find(item => item.id === idRow) || {};
    setFormValues({ ...findedData });
  };
  const handleAddExpertise = () => {
    setFormValues({});
    setReadOnly(false);
  };
  return (
    <Card body className="mb-5">
      <div className="card-title">Expertise</div>
      <Table
        data={dataTable}
        columns={[
          {
            Header: 'English',
            accessor: 'english',
          },
          {
            Header: 'Vietnamese',
            accessor: 'vietnamese',
          },
          {
            Header: 'Tooltip (EN)',
            accessor: null,
          },
          {
            Header: 'Tooltip (VI)',
            accessor: null,
          },
          {
            Header: NAME_ACTION,
            accessor: 'id',
            Cell: props => (
              <div className="table-actions">
                <span
                  role="presentation"
                  className="action-edit"
                  onClick={() => {
                    handleClickEditRow(props.value);
                    setReadOnly(false);
                  }}
                >
                  Edit
                </span>
                <span role="presentation" className="action-delete" onClick={() => handleClickDeleteRow(props.value)}>
                  Delete
                </span>
              </div>
            ),
          },
        ]}
        className="custom-table"
        showPaginationBottom
        sortable={false}
        defaultPageSize={5}
        getTdProps={(state, rowInfo, column, instance) => ({
          onClick: () => {
            if (rowInfo && column.Header !== NAME_ACTION) {
              const { id } = rowInfo.original;
              handleClickEditRow(id);
              setReadOnly(true);
            }
          },
        })}
      />
      <div className="add-expertise">
        <button
          type="button"
          onClick={() => handleAddExpertise('add')}
          className="card-form__btn card-form__btn--submit"
        >
          Add Expertise
        </button>
      </div>
      <FormExpertise
        idFunction={idFunction}
        data={formValues}
        setFormValuesProps={setFormValues}
        createExpertise={createExpertise}
        updateExpertise={updateExpertise}
        isReadOnly={isReadOnly}
        setReadOnly={setReadOnly}
        isAddForm={isAddForm}
        setIsAddForm={setIsAddForm}
      />
    </Card>
  );
};
CardExpertise.propTypes = {};
CardExpertise.defaultProps = {
  data: [],
};
export default CardExpertise;
