/* eslint-disable max-len */
import api from '../index';
import ApiConstants from '../ApiConstants';

export const getNews = args => api(
  `${ApiConstants.NEWS}?q=${args[0]}&catId=${args[1]}&catType=${args[2]}&order=${args[3]}&asc=${args[4]}&limit=${args[5]}&skip=${args[6]}`,
  null,
  'get',
  null,
);
export const getAllNews = args => api(
  `${ApiConstants.NEWS}/all?q=${args[0]}&order=${args[1]}&asc=${args[2]}&limit=${args[3]}&skip=${args[4]}`,
  null,
  'get',
  null,
);
export const createNews = news => api(
  `${ApiConstants.NEWS}`,
  news,
  'POST',
  null,
);
export const updateNews = args => api(
  `${ApiConstants.NEWS}/${args[0]}`,
  args[1],
  'PATCH',
  null,
);

export const deleteNews = id => api(
  `${ApiConstants.NEWS}/${id}`,
  null,
  'DELETE',
  null,
);

export const getNewsById = id => api(
  `${ApiConstants.NEWS}/${id}`,
  null,
  'GET',
  null,
);

export const getNewsCategories = args => api(
  `${ApiConstants.NEWS_CATEGORIES}/?limit=${args[0]}&skip=${args[1]}`,
  null,
  'get',
  null,
);
