/* eslint-disable object-curly-newline */
import { take, call, put } from 'redux-saga/effects';
import {
  GET_GLOBAL_SETTING,
  GET_GLOBAL_SETTING_SUCCESS,
  GET_GLOBAL_SETTING_FAIL,
  UPDATE_GLOBAL_SETTING,
  UPDATE_GLOBAL_SETTING_SUCCESS,
  UPDATE_GLOBAL_SETTING_FAIL,
} from '../actions/globalSettingAction';
import {
  getGlobalSetting,
  updateGlobalSetting,
} from '../../shared/api/methods/globalSetting';

export function* getGlobalSettingSaga() {
  while (true) {
    try {
      yield take(GET_GLOBAL_SETTING);
      const data = yield call(getGlobalSetting);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_GLOBAL_SETTING_FAIL, error });
      } else {
        yield put({ type: GET_GLOBAL_SETTING_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_GLOBAL_SETTING_FAIL, error });
    }
  }
}

export function* updateGlobalSettingSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_GLOBAL_SETTING);
      const data = yield call(updateGlobalSetting, [id, params]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_GLOBAL_SETTING_FAIL, error });
      } else {
        yield put({ type: UPDATE_GLOBAL_SETTING_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: UPDATE_GLOBAL_SETTING_FAIL, error });
    }
  }
}
