export const GET_FAQ_CATEGORIES = 'GET_FAQ_CATEGORIES';
export const GET_FAQ_CATEGORIES_SUCCESS = 'GET_FAQ_CATEGORIES_SUCCESS';
export const GET_FAQ_CATEGORIES_FAIL = 'GET_FAQ_CATEGORIES_FAIL';

export const CREATE_FAQ_CATEGORY = 'CREATE_FAQ_CATEGORY';
export const CREATE_FAQ_CATEGORY_SUCCESS = 'CREATE_FAQ_CATEGORY_SUCCESS';
export const CREATE_FAQ_CATEGORY_FAIL = 'CREATE_FAQ_CATEGORY_FAIL';

export const UPDATE_FAQ_CATEGORY = 'UPDATE_FAQ_CATEGORY';
export const UPDATE_FAQ_CATEGORY_SUCCESS = 'UPDATE_FAQ_CATEGORY_SUCCESS';
export const UPDATE_FAQ_CATEGORY_FAIL = 'UPDATE_FAQ_CATEGORY_FAIL';

export const DELETE_FAQ_CATEGORY = 'DELETE_FAQ_CATEGORY';
export const DELETE_FAQ_CATEGORY_SUCCESS = 'DELETE_FAQ_CATEGORY_SUCCESS';
export const DELETE_FAQ_CATEGORY_FAIL = 'DELETE_FAQ_CATEGORY_FAIL';

export const GET_FAQ_CATEGORY_BY_ID = 'GET_FAQ_CATEGORY_BY_ID';
export const GET_FAQ_CATEGORY_BY_ID_SUCCESS = 'GET_FAQ_CATEGORY_BY_ID_SUCCESS';
export const GET_FAQ_CATEGORY_BY_ID_FAIL = 'GET_FAQ_CATEGORY_BY_ID_FAIL';
export const RESET_MESSAGE = 'RESET_MESSAGE';

export function getCategories(catType, orderBy, asc, limit, skip) {
  return {
    type: GET_FAQ_CATEGORIES,
    catType,
    orderBy,
    asc,
    limit,
    skip,
  };
}

export function getCategoriesSuccess(data) {
  return {
    type: GET_FAQ_CATEGORIES_SUCCESS,
    data,
  };
}

export function getCategoriesFail(error) {
  return {
    type: GET_FAQ_CATEGORIES_FAIL,
    error,
  };
}

export function createCategory(params) {
  return {
    type: CREATE_FAQ_CATEGORY,
    params,
  };
}

export function createCategorySuccess(data) {
  return {
    type: CREATE_FAQ_CATEGORY_SUCCESS,
    data,
  };
}

export function createCategoryFail(error) {
  return {
    type: CREATE_FAQ_CATEGORY_FAIL,
    error,
  };
}

export function updateCategory(id, params) {
  return {
    type: UPDATE_FAQ_CATEGORY,
    id,
    params,
  };
}

export function updateCategorySuccess(data) {
  return {
    type: UPDATE_FAQ_CATEGORY_SUCCESS,
    data,
  };
}

export function updateCategoryFail(error) {
  return {
    type: UPDATE_FAQ_CATEGORY_FAIL,
    error,
  };
}

export function deleteCategory(id) {
  return {
    type: DELETE_FAQ_CATEGORY,
    id,
  };
}

export function deleteCategorySuccess(data) {
  return {
    type: DELETE_FAQ_CATEGORY_SUCCESS,
    data,
  };
}

export function deleteFail(error) {
  return {
    type: DELETE_FAQ_CATEGORY_FAIL,
    error,
  };
}

export function getCategoryById(id) {
  return {
    type: GET_FAQ_CATEGORY_BY_ID,
    id,
  };
}

export function getCategoryByIdSuccess(data) {
  return {
    type: GET_FAQ_CATEGORY_BY_ID_SUCCESS,
    data,
  };
}

export function getCategoryByIdFail(error) {
  return {
    type: GET_FAQ_CATEGORY_BY_ID_FAIL,
    error,
  };
}

export function resetMessage() {
  return {
    type: RESET_MESSAGE,
  };
}
