/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Col, CardBody, Container, Row, Card } from 'reactstrap';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import PropTypes from 'prop-types';
import { BasicNotification } from '../../../../components/Notification/index';
import {
  getHotIndustriesById as getHotIndustriesByIdAction,
  createHotIndustry as createHotIndustryAction,
  updateHotIndustries as updateHotIndustriesAction,
  getHotIndustries as getHotIndustriesAction,
  getIndustries as getIndustriesAction,
} from '../../../../redux/actions/hotIndustriesAction';
import {
  STATUS_CODE_401,
} from '../../../../shared/utils/constants';
import './styleEditForm.scss';
import EditForm from './EditForm';

let notificationRU = null;

class HotIndustries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isShowNotify: false,
    };
  }

  componentWillMount = () => {
    const { match, getHotIndustriesById, getIndustries, getHotIndustries, cookies } = this.props;
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    getHotIndustries();
    getIndustries();
    if (match) {
      getHotIndustriesById(match.params.id);
    }
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  showNotification = (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Hot Industries"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  onUpdateHotIndustries = (id, params) => {
    const { updateHotIndustries, createHotIndustry } = this.props;
    if (id) {
      updateHotIndustries(id, params);
    } else {
      createHotIndustry(params);
    }
    this.setState({ isShowNotify: true });
  }

  cancelForm = () => {
    const { match, onCancelForm, history } = this.props;
    if (match) {
      history.push('/admin/hot-industries');
    } else {
      onCancelForm();
    }
  }

  render() {
    const { hotIndustries, cookies, match, isAddItem } = this.props;
    const { hasToken, isShowNotify } = this.state;
    let hotIndustriesForm;
    const industriesSelect = hotIndustries.industries.filter(({ id: id1 }) => !hotIndustries.hotIndustriesList.some(({ idIndustry: id2 }) => id2 === id1));
    if (hotIndustries.hotIndustriesEdit != null && match) {
      if (hotIndustries.hotIndustriesEdit.id === match.params.id) {
        hotIndustriesForm = <EditForm industries={industriesSelect} hotIndustriesList={hotIndustries.hotIndustriesList} data={hotIndustries.hotIndustriesEdit} save={(id, params) => this.onUpdateHotIndustries(id, params)} onCancel={this.cancelForm} />;
      }
    }
    if (isAddItem) {
      hotIndustriesForm = <EditForm industries={industriesSelect} hotIndustriesList={hotIndustries.hotIndustriesList} save={(id, params) => this.onUpdateHotIndustries(id, params)} onCancel={this.cancelForm} />;
    }
    if (isShowNotify && hotIndustries.message) {
      if (hotIndustries.isError) {
        this.showNotification('warning', hotIndustries.message);
      } else {
        this.showNotification('success', hotIndustries.message);
        setTimeout(() => {
          this.cancelForm();
        }, 3000);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || hotIndustries.statusCode === STATUS_CODE_401) {
      if (hotIndustries.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`${isAddItem ? 'Add' : 'Update'}`} Hot Industries</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          <Col md={12} lg={12} xl={12}>
            <CardBody>
              { hotIndustriesForm }
            </CardBody>
          </Col>
        </Card>
      </Container>
    );
  }
}

HotIndustries.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  getHotIndustries: PropTypes.func.isRequired,
  getHotIndustriesById: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  hotIndustries: PropTypes.instanceOf(Object).isRequired,
  createHotIndustry: PropTypes.func.isRequired,
  updateHotIndustries: PropTypes.func.isRequired,
  onCancelForm: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object),
  isAddItem: PropTypes.bool,
  getIndustries: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { hotIndustries } = state;
  return {
    hotIndustries,
  };
};
const mapDispatchToProps = dispatch => ({
  getHotIndustriesById: id => dispatch(getHotIndustriesByIdAction(id)),
  updateHotIndustries: (id, params) => dispatch(updateHotIndustriesAction(id, params)),
  getHotIndustries: () => dispatch(getHotIndustriesAction()),
  getIndustries: () => dispatch(getIndustriesAction()),
  createHotIndustry: params => dispatch(createHotIndustryAction(params)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(HotIndustries));
