import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import EditForm from './EditForm';
import JobList from './JobList';

class RecruiterDetailt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onUpdateRecruiter = (recruiter) => {
    const { data, updateRecruiter } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    updateRecruiter(data._id, recruiter);
  }

  onCreateNewRecruiter = (recruiter) => {
    const { createRecruiter } = this.props;
    createRecruiter(recruiter);
  }

  onHandleCancel = () => {
    const { onClickCancel } = this.props;
    onClickCancel();
  }

  render() {
    const { data, jobsList, isCreateNew } = this.props;
    return (
      <Card>
        <EditForm
          data={data}
          updateRecruiter={recruiter => this.onUpdateRecruiter(recruiter)}
          createNewRecruiter={recruiter => this.onCreateNewRecruiter(recruiter)}
          cancelEdit={this.onHandleCancel}
          isCreateNew={isCreateNew}
        />
        <hr />
        <JobList data={jobsList} />
      </Card>
    );
  }
}
RecruiterDetailt.propTypes = {
  jobsList: PropTypes.array.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  updateRecruiter: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
};

export default RecruiterDetailt;
