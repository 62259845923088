/* eslint-disable max-len */
import api from '../index';
import ApiConstants from '../ApiConstants';

export const getFaq = args => api(
  `${ApiConstants.FAQ}?q=${args[0]}&subjectId=${args[1]}&userRole=${args[2]}&order=${args[3]}&asc=${args[4]}&limit=${args[5]}&skip=${args[6]}`,
  null,
  'get',
);
export const createFaq = data => api(
  `${ApiConstants.FAQ}`,
  data,
  'POST',
);

export const updateFaq = args => api(
  `${ApiConstants.FAQ}/${args[0]}`,
  args[1],
  'PATCH',
);

export const getFaqById = id => api(
  `${ApiConstants.FAQ}/${id}`,
  null,
  'GET',
);

export const deleteFaq = id => api(
  `${ApiConstants.FAQ}/${id}`,
  null,
  'DELETE',
);
