/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import StarIcon from 'mdi-react/StarIcon';
import StarOutline from 'mdi-react/StarOutlineIcon';
import { Card, Row, Input, Modal, ModalBody, ButtonGroup, ModalHeader, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import Table from '../../../../../components/Table/index';
import './styleTagList.scss';
import {
  MSG_CONFIRM_DELETE,
} from '../../../../../shared/utils/constants';

class TagList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      tagData: [],
      isPopupDelete: false,
      deleteItemId: null,
      isSeaching: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) this.handleCancelModal();
    });
  }

  handleCancelModal = () => {
    this.setState({
      isPopupDelete: false,
      deleteItemId: null,
    });
  };

  handleShowModalDelete = (id) => {
    this.setState({
      isPopupDelete: true,
      deleteItemId: id,
    });
  };

  handleChange = (event) => {
    this.setState({ query: event.target.value });
  };

  searchTag = () => {
    const { query } = this.state;
    const { tags } = this.props;
    const lowercasedFilter = query.toLowerCase();
    // eslint-disable-next-line max-len
    const filteredData = tags.filter(item => Object.keys(item).some(key => item[key].toString().toLowerCase().includes(lowercasedFilter)));
    this.setState({ tagData: filteredData, isSeaching: true });
  };

  handleClickEditRow = (idRow) => {
    const { tags, tagEditing } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    const tagDetailView = tags.find(item => item._id === idRow);
    if (tagDetailView) {
      tagEditing(tagDetailView);
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.searchTag();
    }
  }

  onClickAddTag = () => {
    const { onAddTag } = this.props;
    onAddTag();
  }

  onChangeIsHot = (idRow) => {
    const { tags, onUpdateTag } = this.props;
    const tag = tags.find(item => item.id === idRow);
    if (tag) {
      onUpdateTag(idRow, { isHot: !tag.isHot });
    }
  }

  handleClickChangeStatus = (id, isDeleted) => {
    const { onUpdateTag } = this.props;
    onUpdateTag(id, {
      isDeleted: !isDeleted,
    });
  };

  handleClickDeleteRow = (idRow) => {
    const { onDeleteTag } = this.props;
    onDeleteTag(idRow);
    this.handleCancelModal();
  };

  renderPopupConfirmDelete = () => {
    const { deleteItemId, isPopupDelete } = this.state;
    return (
      <Modal isOpen={isPopupDelete} toogle={this.toogleModalSave} className="order-popup">
        <ModalHeader>Delete Tag</ModalHeader>
        <ModalBody>
          {MSG_CONFIRM_DELETE}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup className="float-right">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.handleClickDeleteRow(deleteItemId)}
            >
              Yes
            </button>
            <button
              type="button"
              className="card-form__btn card-form__btn--cancel"
              color="danger"
              onClick={() => this.handleCancelModal()}
            >
              Cancel
            </button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { tagData, isSeaching } = this.state;
    const { tags, tType } = this.props;
    const data = isSeaching ? tagData : tags;
    let listUrl;
    if (tType === 'CANDIDATE') {
      listUrl = '/admin/news-jobseeker/tag';
    }
    if (tType === 'RECRUITER') {
      listUrl = '/admin/news-recruiter/tag';
    }
    return (
      <Card>
        <Row>
          <div className="form__form-group">
            <div className="form__form-group-field form__searchPost">
              <div className="form__form-group-icon">
                <MagnifyIcon />
              </div>
              <Input
                type="text"
                className=""
                name="search"
                placeholder="Search"
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
              />
              <button
                type="button"
                className="card-form__btn card-form__btn--submit btn-search"
                color="primary"
                onClick={this.searchTag}
              >
                Search
              </button>
            </div>
          </div>
          <div className="card-form-wrapper">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.onClickAddTag()}
            >
              Add
            </button>
          </div>
        </Row>
        <Table
          data={data}
          columns={[
            {
              Header: 'Name (EN)',
              accessor: 'english',
              sortable: true,
            },
            {
              Header: 'Name (VI)',
              accessor: 'vietnamese',
              sortable: true,
            },
            {
              Header: 'Published',
              accessor: null,
              Cell: (row) => {
                const { id, isDeleted } = row.original;
                if (!isDeleted) {
                  return <div><StarIcon onClick={() => this.handleClickChangeStatus(id, isDeleted)} className="ishot-yes" /></div>;
                }
                return <StarOutline onClick={() => this.handleClickChangeStatus(id, isDeleted)} className="ishot-no" />;
              },
            },
            {
              Header: 'Description',
              accessor: 'description',
              sortable: true,
            },
            {
              Header: 'Slug',
              accessor: 'slug',
            },
            {
              Header: 'Views',
              accessor: 'views',
            },
            {
              Header: 'Featured',
              accessor: null,
              Cell: (row) => {
                const { isHot, id } = row.original;
                if (isHot) {
                  return <div><StarIcon onClick={() => this.onChangeIsHot(id)} className="ishot-yes" /></div>;
                }
                return <StarOutline onClick={() => this.onChangeIsHot(id)} className="ishot-no" />;
              },
            },
            {
              Header: 'Actions',
              accessor: null,
              Cell: (row) => {
                const { id } = row.original;
                return (
                  <div className="table-actions">
                    <Link to={`${listUrl}/${id}`}>
                      Edit
                    </Link>
                    <span
                      role="presentation"
                      className="action-delete"
                      onClick={() => this.handleShowModalDelete(id)}
                    >
                      Delete
                    </span>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          className="custom-table"
          showPaginationBottom
          sortable={false}
        />
        {this.renderPopupConfirmDelete()}
      </Card>
    );
  }
}

TagList.propTypes = {
  onAddTag: PropTypes.func.isRequired,
  onUpdateTag: PropTypes.func.isRequired,
  onDeleteTag: PropTypes.func.isRequired,
  tagEditing: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  tType: PropTypes.string.isRequired,
};

export default TagList;
