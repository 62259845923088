import React from 'react';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import PropTypes from 'prop-types';
import { Card, Row, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import Table from '../../../../components/Table/index';
import './styleContactList.scss';

class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      contactData: [],
      isSeaching: false,
    };
  }

  searchContact = () => {
    const { query } = this.state;
    const { contactListData } = this.props;
    const lowercasedFilter = query.toLowerCase();
    // eslint-disable-next-line max-len
    const filteredData = contactListData.filter(item => Object.keys(item).some(key => item[key].toString().toLowerCase().includes(lowercasedFilter)));
    this.setState({ contactData: filteredData, isSeaching: true });
  };

  handleChange = (event) => {
    this.setState({ query: event.target.value });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.searchContact();
    }
  }

  render() {
    const { contactData, isSeaching } = this.state;
    const { contactListData } = this.props;
    const data = isSeaching ? contactData : contactListData;
    return (
      <Card>
        <Row>
          <div className="form__form-group">
            <div className="form__form-group-field form__search">
              <div className="form__form-group-icon">
                <MagnifyIcon />
              </div>
              <Input
                type="text"
                className=""
                name="search"
                placeholder="Search"
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
              />
              <button
                type="button"
                className="card-form__btn card-form__btn--submit btn-search"
                color="primary"
                onClick={this.searchContact}
              >
                Search
              </button>
            </div>
          </div>
        </Row>
        <Table
          data={data}
          columns={[
            {
              Header: 'contactId',
              accessor: 'contactId',
              sortable: true,
            },
            {
              Header: 'Name',
              accessor: 'name',
              sortable: true,
            },
            {
              Header: 'Email',
              accessor: 'email',
              sortable: true,
            },
            {
              Header: 'Phone No',
              accessor: 'phone',
            },
            {
              Header: 'Subject',
              accessor: 'subject',
            },
            {
              Header: 'Question/Comment',
              accessor: 'question',
            },
            {
              Header: 'Submitted Date',
              accessor: 'createdDate',
            },
            {
              Header: 'Actions',
              accessor: null,
              Cell: (row) => {
                const { id } = row.original;
                return (
                  <div className="table-actions">
                    <Link to={`/admin/contact/${id}`}>
                      View Detail
                    </Link>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          className="custom-table"
          showPaginationBottom
          sortable={false}
        />
      </Card>
    );
  }
}

ContactList.propTypes = {
  contactListData: PropTypes.instanceOf(Object).isRequired,
};

export default ContactList;
