import React from 'react';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import PropTypes from 'prop-types';
import { Card, Row, Modal, Input, ModalBody, ButtonGroup, ModalHeader, ModalFooter } from 'reactstrap';
import Table from '../../../../components/Table/index';
import './styleRecruiterList.scss';
import {
  MSG_CONFIRM_DELETE,
} from '../../../../shared/utils/constants';

class RecruiterList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      isPopupDelete: false,
      deleteItemId: null,
    };
  }

  handleChange = (event) => {
    this.setState({ query: event.target.value });
  };

  searchRecruiter = () => {
    const { query } = this.state;
    const { onSearch } = this.props;
    onSearch(query);
  };

  onClickAddNewRecruiter = () => {
    const { onAddNewRecruiter } = this.props;
    onAddNewRecruiter();
  };

  handleClickEditRow = (idRow) => {
    const { recruiterList, recruiterEditing } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    const recruiterDetailView = recruiterList.find(item => item._id === idRow);
    if (recruiterDetailView) {
      recruiterEditing(recruiterDetailView);
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.searchRecruiter();
    }
  }

  handleClickDeleteRow = (idRow) => {
    const { onDeleteRecruiter } = this.props;
    onDeleteRecruiter(idRow);
    this.handleCancelModal();
  };

  handleCancelModal = () => {
    this.setState({
      isOpenOrder: false,
      isPopupDelete: false,
      deleteItemId: null,
    });
  };

  handleShowModalDelete = (id) => {
    this.setState({
      isPopupDelete: true,
      deleteItemId: id,
    });
  };

  renderPopupConfirmDelete = () => {
    const { deleteItemId, isPopupDelete } = this.state;
    return (
      <Modal isOpen={isPopupDelete} toogle={this.toogleModalSave} className="order-popup">
        <ModalHeader>Delete Recruiter</ModalHeader>
        <ModalBody>
          {MSG_CONFIRM_DELETE}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup className="float-right">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.handleClickDeleteRow(deleteItemId)}
            >
              Yes
            </button>
            <button
              type="button"
              className="card-form__btn card-form__btn--cancel"
              color="danger"
              onClick={() => this.handleCancelModal()}
            >
              Cancel
            </button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { recruiterList } = this.props;
    const newMember = {
      color: 'red',
    };

    return (
      <Card>
        <Row>
          <div className="form__form-group">
            <div className="form__form-group-field form__search">
              <div className="form__form-group-icon">
                <MagnifyIcon />
              </div>
              <Input
                type="text"
                className=""
                name="search"
                placeholder="Search"
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
              />
              <button
                type="button"
                className="card-form__btn card-form__btn--submit btn-search"
                color="primary"
                onClick={this.searchRecruiter}
              >
                Search
              </button>
              <button
                type="button"
                className="card-form__btn card-form__btn--submit btn-add"
                color="primary"
                onClick={this.onClickAddNewRecruiter}
              >
                Add
              </button>
            </div>
          </div>
        </Row>
        <Table
          data={recruiterList}
          columns={[
            {
              Header: 'Code',
              Cell: props => (
                <div className={(props.original.status == "Inactive") ? "text-danger" : ""}>{props.original.recruiterId}</div>
              ),
              accessor: 'recruiterId',
              sortable: true,
            },
            {
              Header: 'Name',
              Cell: props => (
                <div className={(props.original.status == "Inactive") ? "text-danger" : ""}>{props.original.fullName}</div>
              ),
              accessor: 'fullName',
              sortable: true,
            },
            {
              Header: 'Registered Email',
              Cell: props => (
                <div className={(props.original.status == "Inactive") ? "text-danger" : ""}>{props.original.email}</div>
              ),
              accessor: 'email',
              sortable: true,
            },
            {
              Header: 'Is Head',
              headerStyle: {
                ...{textAlign: 'center'},
              },
              Cell: props => (
                <div className='text-center'>{props.original.companies && props.original.companies.length ? 'Yes' : 'No'}</div>
              ),
              accessor: 'companies',
              sortable: true,
            },
            {
              Header: 'Contact Person Name',
              Cell: props => (
                <div className={(props.original.status == "Inactive") ? "text-danger" : ""}>{props.original.contact}</div>
              ),
              accessor: 'contact',
            },
            {
              Header: 'Phone No',
              Cell: props => (
                <div className={(props.original.status == "Inactive") ? "text-danger" : ""}>{props.original.phone}</div>
              ),
              accessor: 'phone',
            },
            {
              Header: 'No of Job',
              Cell: props => (
                <div className={(props.original.status == "Inactive") ? "text-danger" : ""}>{props.original.jobs}</div>
              ),
              accessor: 'jobs',
            },
            {
              Header: 'Update',
              Cell: props => (
                <div className={(props.original.status == "Inactive") ? "text-danger" : ""}>{props.original.updated_date}</div>
              ),
              accessor: 'updated_date',
            },
            {
              Header: 'Status',
              Cell: props => (
                <div className={(props.original.status == "Inactive") ? "text-danger" : ""}>{props.original.status}</div>
              ),
              accessor: 'status',
            },
            {
              Header: 'Actions',
              accessor: null,
              Cell: (row) => {
                const { _id } = row.original;
                return (
                  <div className="table-actions">
                    <span
                      role="presentation"
                      className="action-edit"
                      onClick={() => this.handleClickEditRow(_id)}
                    >
                      Edit
                    </span>
                    <span
                      role="presentation"
                      className="action-delete"
                      onClick={() => this.handleShowModalDelete(_id)}
                    >
                      Delete
                    </span>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          className="custom-table"
          showPaginationBottom
          sortable={false}
        />
        {this.renderPopupConfirmDelete()}
      </Card>
    );
  }
}

RecruiterList.propTypes = {
  recruiterEditing: PropTypes.func.isRequired,
  recruiterList: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default RecruiterList;
