/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/named */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Button, Modal,
} from 'reactstrap';
import { toogleModalAdd } from '../../../../../redux/actions/modalAction';
import { saveDescription } from '../../../../../redux/actions/descriptionAction';
import CommonAttribute from '../CommonAttribute';

class AddFieldDescriptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: false,
      isError: false,
      msgError: '',
      labelEnglish: '',
      labelVn: '',
      row: 1,
      id: '',
      type: '',
    };
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    this.toogleModalAdd = this.toogleModalAdd.bind(this);
    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.addNewField = this.addNewField.bind(this);
    this.htmlCommonAttribute = this.htmlCommonAttribute.bind(this);
  }

  componentDidMount() {
    const { modal } = this.props;
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) this.toogleModalAdd();
    });

    if (modal && modal.dataEdit) {
      this.setState({
        id: modal.dataEdit.id,
        labelEnglish: modal.dataEdit.label.en,
        labelVn: modal.dataEdit.label.vi,
        row: modal.dataEdit.row,
        isHidden: modal.dataEdit.isHidden,
        type: modal.dataEdit.allowMultiline ? 'TextArea' : 'InputText',
      });
    }
  }

  toogleModalAdd = () => {
    const { dispatch, modal } = this.props;
    const data = {
      isShow: !modal.isShow,
    };
    dispatch(toogleModalAdd(data));
    this.setState({
      isError: false,
      msgError: '',
      isHidden: false,
      labelEnglish: '',
      labelVn: '',
      type: 'InputText',
      row: 1,
    });
  };

  onDismissAlert = () => {
    this.setState({
      isError: false,
    });
  }

  handleChangeEvent = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;
    const targetType = event.target.type;

    if (targetType === 'checkbox') {
      this.setState({ [targetName]: !event.target.checked });
    } else {
      this.setState({ [targetName]: targetValue });
    }
  };

  addNewField = () => {
    const { dispatch } = this.props;
    const {
      labelEnglish,
      labelVn,
      row,
      isHidden,
      id,
      type,
    } = this.state;

    const dataSave = {
      id,
      label: {
        en: labelEnglish,
        vi: labelVn,
      },
      allowMultiline: type === 'TextArea',
      row: Number(row),
      isHidden,
    };
    dispatch(saveDescription(dataSave));
    this.toogleModalAdd();
  }

  htmlCommonAttribute = (modal) => {
    const { msgError, isError } = this.state;
    return (
      <div>
        <CommonAttribute isError={isError} msgError={msgError} dataField={this.state} modal={modal} htmlButton={this.htmlButton()} onDismissAlert={this.onDismissAlert} handleChangeEvent={this.handleChangeEvent} />
      </div>
    );
  }

  htmlButton() {
    return (
      <div>
        <Button outline color="danger" size="sm" onClick={() => this.toogleModalAdd()}>Cancel</Button>
        <Button outline color="primary" size="sm" onClick={() => this.addNewField()}>Save</Button>
      </div>
    );
  }

  render() {
    const { modal } = this.props;
    return (
      <div>
        <Modal isOpen={modal.isShow} toogle={this.toogleModalAdd} size="lg">
          {this.htmlCommonAttribute(modal)}
        </Modal>
      </div>
    );
  }
}

AddFieldDescriptionModal.propTypes = {
  modal: PropTypes.objectOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { modal } = state;
  return {
    modal,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withTranslation('common')(AddFieldDescriptionModal));
