/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Col, CardBody, Container, Row, Card } from 'reactstrap';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  uploadFile as uploadFileAction,
} from '../../../../../redux/actions/uploadFileAction';
import {
  getFaq as getFaqAction,
  getFaqById as getFaqByIdAction,
  updateFaq as updateFaqAction,
  createFaq as createFaqAction,
} from '../../../../../redux/actions/faqAction';
import {
  getCategories as getCategoriesAction,
} from '../../../../../redux/actions/faqCategoryAction';
import {
  STATUS_CODE_401,
  DEFAULT_QUERY_ASC,
  DEFAULT_QUERY_LIMIT,
  DEFAULT_QUERY_SKIP,
} from '../../../../../shared/utils/constants';
import './styleEditForm.scss';
import EditForm from './EditForm';

class QaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      faqType: '',
      isAddItem: false,
    };
  }

  componentWillMount = async () => {
    const { match, getFaqById, getCategories, cookies, qaType } = this.props;
    const token = cookies.get('token') || '';
    let faqType;
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    if (qaType) {
      faqType = qaType;
      this.setState({ faqType: qaType });
    } else if (match) {
      const { path } = match;
      if (path.includes('recruiter')) {
        faqType = 'RECRUITER';
        this.setState({ faqType: 'RECRUITER' });
      }
      if (path.includes('jobseeker')) {
        faqType = 'CANDIDATE';
        this.setState({ faqType: 'CANDIDATE' });
      }
      if (path.includes('add')) {
        this.setState({ isAddItem: true });
      }
    }
    if (match.params.id) {
      getFaqById(match.params.id);
    }
    getCategories(faqType, 'sortNum', '1', DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  onUpdateFaq = async (id, params) => {
    const { updateFaq, createFaq, getFaq } = this.props;
    const { faqType } = this.state;
    if (id) {
      updateFaq(id, params);
    } else {
      await createFaq(params);
      getFaq('', '', faqType, 'id', DEFAULT_QUERY_ASC, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
    }
    this.cancelForm();
  }

  cancelForm = () => {
    const { faqType } = this.state;
    if (faqType === 'RECRUITER') {
      window.location.href = '/admin/faq-recruiter/qa';
    }

    if (faqType === 'CANDIDATE') {
      window.location.href = '/admin/faq-jobseeker/qa';
    }
  }

  render() {
    const { faq, cookies, match, faqCategory } = this.props;
    const { hasToken, isAddItem, faqType } = this.state;
    let faqForm;
    if (faq.faqEdit != null && match) {
      if (faq.faqEdit.id === match.params.id) {
        faqForm = <EditForm data={faq.faqEdit} categories={faqCategory.categoryList} save={(id, params) => this.onUpdateFaq(id, params)} onCancel={this.cancelForm} />;
      }
    }
    if (isAddItem) {
      faqForm = <EditForm categories={faqCategory.categoryList} save={(id, params) => this.onUpdateFaq(id, params)} onCancel={this.cancelForm} />;
    }

    if (!hasToken || faq.statusCode === STATUS_CODE_401) {
      if (faq.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`${isAddItem ? 'Create' : 'Update'} ${faqType === 'RECRUITER' ? 'Recruiter' : 'Jobseeker'} Question And Answer`}</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          <Col md={12} lg={12} xl={12}>
            <CardBody>
              {faqForm}
            </CardBody>
          </Col>
        </Card>
      </Container>
    );
  }
}

QaForm.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  getFaqById: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object),
  faq: PropTypes.instanceOf(Object).isRequired,
  getCategories: PropTypes.func.isRequired,
  updateFaq: PropTypes.func.isRequired,
  createFaq: PropTypes.func.isRequired,
  getFaq: PropTypes.func.isRequired,
  faqCategory: PropTypes.instanceOf(Object).isRequired,
  qaType: PropTypes.string,
};
const mapStateToProps = (state) => {
  const { faq, faqCategory } = state;
  return {
    faq,
    faqCategory,
  };
};
const mapDispatchToProps = dispatch => ({
  uploadFile: file => dispatch(uploadFileAction(file)),
  getFaqById: id => dispatch(getFaqByIdAction(id)),
  getCategories: (...args) => dispatch(getCategoriesAction(...args)),
  updateFaq: (id, params) => dispatch(updateFaqAction(id, params)),
  createFaq: params => dispatch(createFaqAction(params)),
  getFaq: (...args) => dispatch(getFaqAction(...args)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(QaForm));
