import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withCookies, Cookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NotificationSystem from 'rc-notification';
import './styles.scss';
import CardDeparment from './CardDeparment/index';
import CardFunction from './CardFunction/index';
import CardExpertise from './CardExpertise/index';
import {
  getDepartments as getDepartmentsAction,
  createDepartment as createDepartmentAction,
  updateDepartment as updateDepartmentAction,
} from '../../../redux/actions/departmentAction';
import {
  getFunctionsByDepartmentId as getFunctionsByDepartmentIdAction,
  createFunction as createFunctionAction,
  updateFunction as updateFunctionAction,
} from '../../../redux/actions/functionAction';

import {
  getExpertiseByFunctionId as getExpertiseByFunctionIdAction,
  createExpertise as createExpertiseAction,
  updateExpertise as updateExpertiseAction,
  deleteExpertiseById as deleteExpertiseByIdAction,
  resetMessage as resetMessageExpertiseAction,
} from '../../../redux/actions/expertiseAction';
import { BasicNotification } from '../../../components/Notification/index';

const Deparment = ({
  getDepartments,
  createDepartment,
  updateDepartment,
  department,

  getFunctionsByDepartmentId,
  createFunction,
  updateFunction,
  func,

  getExpertiseByFunctionId,
  createExpertise,
  updateExpertise,
  deleteExpertiseById,
  resetMessageExpertise,
  expertise,
  cookies,
}) => {
  const [idDeparment, setIdDepartment] = useState('');
  const [idFunction, setIdFunction] = useState('');

  useEffect(() => {
    getDepartments();
  }, []);
  const show = (color, message) => {
    NotificationSystem.newInstance({}, (notification) => {
      notification.notice({
        content: <BasicNotification
          title="Expertise"
          color={color}
          message={message}
        />,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: 'right-up',
      });
    });
    resetMessageExpertise();
  };

  const token = cookies.get('token') || '';
  if (token === '' || department.statusCode === '401' || func.statusCode === '401' || expertise.statusCode === '401') {
    // eslint-disable-next-line max-len
    if (department.statusCode === '401' || func.statusCode === '401' || expertise.statusCode === '401') cookies.set('token', '', { path: '/' });
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }

  if (expertise.message && expertise.message !== '') {
    if (expertise.isError) {
      show('warning', expertise.message);
    } else {
      show('success', expertise.message);
    }
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Deparment/Function/Expertise</h3>
        </Col>
      </Row>
      <CardDeparment
        data={department.departments}
        setIdDepartment={setIdDepartment}
        createDepartment={createDepartment}
        updateDepartment={updateDepartment}
        getFunctionsByDepartmentId={getFunctionsByDepartmentId}
      />
      {idDeparment && (
        <CardFunction
          data={func.functions}
          idDeparment={idDeparment}
          setIdFunction={setIdFunction}
          createFunction={createFunction}
          updateFunction={updateFunction}
          getExpertiseByFunctionId={getExpertiseByFunctionId}
        />
      )}
      {idFunction && (
        <CardExpertise
          dataList={expertise.expertises}
          deleteExpertiseById={deleteExpertiseById}
          createExpertise={createExpertise}
          updateExpertise={updateExpertise}
          idFunction={idFunction}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { department, func, expertise } = state;
  return {
    department,
    func,
    expertise,
  };
};

const mapDispatchToProps = dispatch => ({
  getDepartments: () => dispatch(getDepartmentsAction()),
  createDepartment: params => dispatch(createDepartmentAction(params)),
  updateDepartment: (...args) => dispatch(updateDepartmentAction(...args)),

  getFunctionsByDepartmentId: id => dispatch(getFunctionsByDepartmentIdAction(id)),
  createFunction: params => dispatch(createFunctionAction(params)),
  updateFunction: (...argsFunc) => dispatch(updateFunctionAction(...argsFunc)),

  getExpertiseByFunctionId: id => dispatch(getExpertiseByFunctionIdAction(id)),
  createExpertise: params => dispatch(createExpertiseAction(params)),
  updateExpertise: (...args) => dispatch(updateExpertiseAction(...args)),
  deleteExpertiseById: id => dispatch(deleteExpertiseByIdAction(id)),
  resetMessageExpertise: () => dispatch(resetMessageExpertiseAction()),
});

Deparment.propTypes = {
  getDepartments: PropTypes.func.isRequired,
  createDepartment: PropTypes.func.isRequired,
  updateDepartment: PropTypes.func.isRequired,
  getFunctionsByDepartmentId: PropTypes.func.isRequired,
  createFunction: PropTypes.func.isRequired,
  updateFunction: PropTypes.func.isRequired,
  getExpertiseByFunctionId: PropTypes.func.isRequired,
  createExpertise: PropTypes.func.isRequired,
  updateExpertise: PropTypes.func.isRequired,
  deleteExpertiseById: PropTypes.func.isRequired,
  resetMessageExpertise: PropTypes.func.isRequired,

  department: PropTypes.arrayOf(PropTypes.object).isRequired,
  func: PropTypes.arrayOf(PropTypes.object).isRequired,
  expertise: PropTypes.arrayOf(PropTypes.object).isRequired,
  statusCode: PropTypes.string.isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

Deparment.defaultProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(Deparment));
