/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import validateForm from '../../../shared/utils/validation';
import schemaGlobalConfigForm from './validation';
import FirstLook from './TabPanel/FirstLook';
import Home from './TabPanel/Home';
import ContactUs from './TabPanel/ContactUs';
import News from './TabPanel/News';
import Sologan from './TabPanel/Sologan';

import './styleEditForm.scss';

class EditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formField: {
        adminEmail: '',
        enCompanyName: '',
        viCompanyName: '',
        companyEmail: '',
        enCompanyAddress1: '',
        viCompanyAddress1: '',
        enCompanyAddress2: '',
        viCompanyAddress2: '',
        companyPhone: '',
        viContactDesc: '',
        enContactDesc: '',
        enNewsDesc: '',
        viNewsDesc: '',
        enUseful: '',
        viUseful: '',
        enFAQ: '',
        viFAQ: '',
        enHotCareer: '',
        viHotCareer: '',
        enHotIndustry: '',
        viHotIndustry: '',
        enFirstLookDesc: '',
        viFirstLookDesc: '',
        enNewsBannerDesc: '',
        viNewsBannerDesc: '',
        NewsBannerImage: '',
        sologanDesc: [{
          question: {
            en: '',
            vi: '',
          },
          title: [],
        }, {
          question: {
            en: '',
            vi: '',
          },
          title: [],
        }],
      },
      error: '',
      activeTab: '1',
      isEdit: false,
    };
  }

  componentWillMount = () => {
    const { data } = this.props;
    if (data != null) {
      const tempSologanDesc = data.sologanDesc ? JSON.parse(JSON.stringify(data.sologanDesc)) : [{
        question: {
          en: '',
          vi: '',
        },
        title: [],
      }, {
        question: {
          en: '',
          vi: '',
        },
        title: [],
      }];

      this.setState({
        formField: {
          adminEmail: data.adminEmail,
          enCompanyName: data.companyName.en,
          viCompanyName: data.companyName.vi,
          companyEmail: data.companyEmail,
          enCompanyAddress1: data.companyAddress1.en,
          viCompanyAddress1: data.companyAddress1.vi,
          enCompanyAddress2: data.companyAddress2.en,
          viCompanyAddress2: data.companyAddress2.vi,
          companyPhone: data.companyPhone,
          viContactDesc: data.contactDesc.vi,
          enContactDesc: data.contactDesc.en,
          enNewsDesc: data.newsDesc.en,
          viNewsDesc: data.newsDesc.vi,
          enUseful: data.usefulDesc.en,
          viUseful: data.usefulDesc.vi,
          enFAQ: data.faqDesc.en,
          viFAQ: data.faqDesc.vi,
          enHotCareer: data.hotCareerDesc.en,
          viHotCareer: data.hotCareerDesc.vi,
          enHotIndustry: data.hotIndustryDesc.en,
          viHotIndustry: data.hotIndustryDesc.vi,
          enFirstLookDesc: data.firstLookDesc.en,
          viFirstLookDesc: data.firstLookDesc.vi,
          enNewsBannerDesc: data.NewsBannerDesc.en,
          viNewsBannerDesc: data.NewsBannerDesc.vi,
          NewsBannerImage: data.NewsBannerImage,
          sologanDesc: tempSologanDesc,
        },
      });
    }
  }

  handleClickSave = () => {
    const { onUpdate } = this.props;
    const { formField } = this.state;
    const { adminEmail, viCompanyName, enCompanyName, companyEmail,
      viCompanyAddress1, enCompanyAddress1, viCompanyAddress2,
      enCompanyAddress2, companyPhone, viContactDesc, enContactDesc,
      enNewsDesc, viNewsDesc, enUseful, viUseful, enFAQ, viFAQ,
      enHotCareer, viHotCareer, enHotIndustry, viHotIndustry,
      enFirstLookDesc, viFirstLookDesc, enNewsBannerDesc, viNewsBannerDesc, NewsBannerImage, sologanDesc,
    } = formField;
    const formData = {
      adminEmail,
      viCompanyName,
      enCompanyName,
      companyEmail,
      viCompanyAddress1,
      enCompanyAddress1,
      viCompanyAddress2,
      enCompanyAddress2,
      companyPhone,
      viContactDesc,
      enContactDesc,
      enNewsDesc,
      viNewsDesc,
      enUseful,
      viUseful,
      enFAQ,
      viFAQ,
      enHotCareer,
      viHotCareer,
      enHotIndustry,
      viHotIndustry,
      enFirstLookDesc,
      viFirstLookDesc,
      enNewsBannerDesc,
      viNewsBannerDesc,
      NewsBannerImage,
      sologanDesc,
    };
    const { isError, error: err } = validateForm(schemaGlobalConfigForm, formData);
    if (isError) {
      this.setState({ error: err });
      return;
    }
    const item = {
      data: {
        adminEmail,
        companyName: {
          en: enCompanyName,
          vi: viCompanyName,
        },
        companyEmail,
        companyAddress1: {
          en: enCompanyAddress1,
          vi: viCompanyAddress1,
        },
        companyAddress2: {
          en: enCompanyAddress2,
          vi: viCompanyAddress2,
        },
        companyPhone,
        contactDesc: {
          en: enContactDesc,
          vi: viContactDesc,
        },
        newsDesc: {
          en: enNewsDesc,
          vi: viNewsDesc,
        },
        usefulDesc: {
          en: enUseful,
          vi: viUseful,
        },
        faqDesc: {
          en: enFAQ,
          vi: viFAQ,
        },
        hotCareerDesc: {
          en: enHotCareer,
          vi: viHotCareer,
        },
        hotIndustryDesc: {
          en: enHotIndustry,
          vi: viHotIndustry,
        },
        firstLookDesc: {
          en: enFirstLookDesc,
          vi: viFirstLookDesc,
        },
        NewsBannerDesc: {
          en: enNewsBannerDesc,
          vi: viNewsBannerDesc,
        },
        NewsBannerImage,
        sologanDesc,
      },
    };
    onUpdate(item);
    this.setState({ isEdit: false });
  }

  handleCancel = () => {
    const { data } = this.props;
    if (data != null) {
      this.setState({
        formField: {
          adminEmail: data.adminEmail,
          enCompanyName: data.companyName.en,
          viCompanyName: data.companyName.vi,
          companyEmail: data.companyEmail,
          enCompanyAddress1: data.companyAddress1.en,
          viCompanyAddress1: data.companyAddress1.vi,
          enCompanyAddress2: data.companyAddress2.en,
          viCompanyAddress2: data.companyAddress2.vi,
          companyPhone: data.companyPhone,
          viContactDesc: data.contactDesc.vi,
          enContactDesc: data.contactDesc.en,
          enNewsDesc: data.newsDesc.en,
          viNewsDesc: data.newsDesc.vi,
          enUseful: data.usefulDesc.en,
          viUseful: data.usefulDesc.vi,
          enFAQ: data.faqDesc.en,
          viFAQ: data.faqDesc.vi,
          enHotCareer: data.hotCareerDesc.en,
          viHotCareer: data.hotCareerDesc.vi,
          enHotIndustry: data.hotIndustryDesc.en,
          viHotIndustry: data.hotIndustryDesc.vi,
          enFirstLookDesc: data.firstLookDesc.en,
          viFirstLookDesc: data.firstLookDesc.vi,
          enNewsBannerDesc: data.NewsBannerDesc.en,
          viNewsBannerDesc: data.NewsBannerDesc.vi,
          NewsBannerImage: data.NewsBannerImage,
          sologanDesc: data.sologanDesc ? Array.from(data.sologanDesc) : [{
            question: {
              en: '',
              vi: '',
            },
            title: [],
          }, {
            question: {
              en: '',
              vi: '',
            },
            title: [],
          }],
        },
        isEdit: false,
      });
    }
  }

  onClickEdit = () => {
    this.setState({ isEdit: true });
  }

  handleChange = (name, value) => {
    this.setState(prevState => ({
      formField: {
        ...prevState.formField,
        [name]: value,
      },
    }));
  };

  updateSologan = (data) => {
    const { formField } = this.state;
    formField.sologanDesc = data;
    this.setState({
      formField,
    });
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  renderButton() {
    const { isEdit } = this.state;
    return (
      <div className="card-btn">
        {isEdit ? (
          <>
            <button
              type="button"
              color="danger"
              className="card-form__btn card-form__btn--cancel"
              onClick={() => this.handleCancel()}
            >
              Cancel
            </button>
            <button
              type="button"
              color="primary"
              className="card-form__btn card-form__btn--submit"
              onClick={() => this.handleClickSave()}
            >
              Save
            </button>
          </>
        ) : (
          <button
            type="button"
            color="success"
            className="card-form__btn card-form__btn--save"
            onClick={() => this.onClickEdit()}
          >
            Edit
          </button>
        )}
      </div>
    );
  }

  render() {
    const { error, isEdit, activeTab, formField } = this.state;
    return (
      <Col md={12} lg={12} xs={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              {this.renderButton()}
            </div>
            <div className="tabs tabs--justify tabs--bordered-top">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      First Look
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      Home
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                      Contact Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => {
                        this.toggle('4');
                      }}
                    >
                      News
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '5' })}
                      onClick={() => {
                        this.toggle('5');
                      }}
                    >
                      Sologan
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <FirstLook onChange={(name, value) => this.handleChange(name, value)} error={error} isEdit={isEdit} formField={formField} />
                  </TabPane>
                  <TabPane tabId="2">
                    <Home onChange={(name, value) => this.handleChange(name, value)} error={error} isEdit={isEdit} formField={formField} />
                  </TabPane>
                  <TabPane tabId="3">
                    <ContactUs onChange={(name, value) => this.handleChange(name, value)} error={error} isEdit={isEdit} formField={formField} />
                  </TabPane>
                  <TabPane tabId="4">
                    <News onChange={(name, value) => this.handleChange(name, value)} error={error} isEdit={isEdit} formField={formField} />
                  </TabPane>
                  <TabPane tabId="5">
                    <Sologan onUpdateSologan={data => this.updateSologan(data)} error={error} isEdit={isEdit} formField={formField} />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

EditForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default EditForm;
