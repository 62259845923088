export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES';
export const GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS';
export const GET_EMAIL_TEMPLATES_FAIL = 'GET_EMAIL_TEMPLATES_FAIL';

export const CREATE_EMAIL_TEMPLATE = 'CREATE_EMAIL_TEMPLATE';
export const CREATE_EMAIL_TEMPLATE_SUCCESS = 'CREATE_EMAIL_TEMPLATE_SUCCESS';
export const CREATE_EMAIL_TEMPLATE_FAIL = 'CREATE_EMAIL_TEMPLATE_FAIL';

export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_SUCCESS';
export const UPDATE_EMAIL_TEMPLATE_FAIL = 'UPDATE_EMAIL_TEMPLATE_FAIL';

export const GET_EMAIL_TEMPLATES_SYSTEM = 'GET_EMAIL_TEMPLATES_SYSTEM';
export const GET_EMAIL_TEMPLATES_SUCCESS_SYSTEM = 'GET_EMAIL_TEMPLATES_SUCCESS_SYSTEM';
export const GET_EMAIL_TEMPLATES_FAIL_SYSTEM = 'GET_EMAIL_TEMPLATES_FAIL_SYSTEM';

export const CREATE_EMAIL_TEMPLATE_SYSTEM = 'CREATE_EMAIL_TEMPLATE_SYSTEM';
export const CREATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM = 'CREATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM';
export const CREATE_EMAIL_TEMPLATE_FAIL_SYSTEM = 'CREATE_EMAIL_TEMPLATE_FAIL_SYSTEM';

export const UPDATE_EMAIL_TEMPLATE_SYSTEM = 'UPDATE_EMAIL_TEMPLATE_SYSTEM';
export const UPDATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM = 'UPDATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM';
export const UPDATE_EMAIL_TEMPLATE_FAIL_SYSTEM = 'UPDATE_EMAIL_TEMPLATE_FAIL_SYSTEM';

export const DELETE_EMAIL_TEMPLATE_SYSTEM = 'DELETE_EMAIL_TEMPLATE_SYSTEM';
export const DELETE_EMAIL_TEMPLATE_SUCCESS_SYSTEM = 'DELETE_EMAIL_TEMPLATE_SUCCESS_SYSTEM';
export const DELETE_EMAIL_TEMPLATE_FAIL_SYSTEM = 'DELETE_EMAIL_TEMPLATE_FAIL_SYSTEM';

export function getEmailTemplates() {
  return {
    type: GET_EMAIL_TEMPLATES,
  };
}

export function getEmailTemplatesSuccess(data) {
  return {
    type: GET_EMAIL_TEMPLATES_SUCCESS,
    data,
  };
}

export function getEmailTemplatesFail() {
  return {
    type: GET_EMAIL_TEMPLATES_FAIL,
  };
}

export function createEmailTemplate(params) {
  return {
    type: CREATE_EMAIL_TEMPLATE,
    params,
  };
}

export function createEmailTemplateSuccess() {
  return {
    type: CREATE_EMAIL_TEMPLATE_SUCCESS,
  };
}

export function createEmailTemplateFail() {
  return {
    type: CREATE_EMAIL_TEMPLATE_FAIL,
  };
}

export function updateEmailTemplate(id, params) {
  return {
    type: UPDATE_EMAIL_TEMPLATE,
    id,
    params,
  };
}

export function updateEmailTemplateSuccess(data) {
  return {
    type: UPDATE_EMAIL_TEMPLATE_SUCCESS,
    data,
  };
}

export function updateEmailTemplateFail() {
  return {
    type: UPDATE_EMAIL_TEMPLATE_FAIL,
  };
}
export function getEmailTemplatesSystem() {
  return {
    type: GET_EMAIL_TEMPLATES_SYSTEM,
  };
}

export function getEmailTemplatesSuccessSystem(data) {
  return {
    type: GET_EMAIL_TEMPLATES_SUCCESS_SYSTEM,
    data,
  };
}

export function getEmailTemplatesFailSystem() {
  return {
    type: GET_EMAIL_TEMPLATES_FAIL_SYSTEM,
  };
}

export function createEmailTemplateSystem(params) {
  return {
    type: CREATE_EMAIL_TEMPLATE_SYSTEM,
    params,
  };
}

export function createEmailTemplateSuccessSystem() {
  return {
    type: CREATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM,
  };
}

export function createEmailTemplateFailSystem() {
  return {
    type: CREATE_EMAIL_TEMPLATE_FAIL_SYSTEM,
  };
}



export function deleteEmailTemplateSystem(id) {
  return {
    type: DELETE_EMAIL_TEMPLATE_SYSTEM,
    id,
  };
}

export function deleteEmailTemplateSystemSuccess(data) {
  return {
    type: DELETE_EMAIL_TEMPLATE_SUCCESS_SYSTEM,
    data,
  };
}

export function deleteEmailTemplateSystemFail(error) {
  return {
    type: DELETE_EMAIL_TEMPLATE_FAIL_SYSTEM,
    error,
  };
}

export function updateEmailTemplateSystem(id, params) {
  return {
    type: UPDATE_EMAIL_TEMPLATE_SYSTEM,
    id,
    params,
  };
}

export function updateEmailTemplateSuccessSystem(data) {
  return {
    type: UPDATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM,
    data,
  };
}

export function updateEmailTemplateFailSystem() {
  return {
    type: UPDATE_EMAIL_TEMPLATE_FAIL_SYSTEM,
  };
}