import * as yup from 'yup';

import FIELD_REQUIRED from '../../../shared/utils/messageError';

export const schameFormExpertise = yup.object().shape({
  'label.en': yup.string().required(FIELD_REQUIRED),
  'label.vi': yup.string().required(FIELD_REQUIRED),
  'columns[0].header.en': yup.string().required(FIELD_REQUIRED),
  'columns[0].header.vi': yup.string().required(FIELD_REQUIRED),
  // 'columns[0].rows[0].en': yup.string().required(FIELD_REQUIRED),
  // 'columns[0].rows[0].vi': yup.string().required(FIELD_REQUIRED),
  // 'columns[0].rows[1].en': yup.string().required(FIELD_REQUIRED),
  // 'columns[0].rows[1].vi': yup.string().required(FIELD_REQUIRED),
  // 'columns[0].rows[2].en': yup.string().required(FIELD_REQUIRED),
  // 'columns[0].rows[2].vi': yup.string().required(FIELD_REQUIRED),
  // 'columns[0].rows[3].en': yup.string().required(FIELD_REQUIRED),
  // 'columns[0].rows[3].vi': yup.string().required(FIELD_REQUIRED),
  // 'columns[0].rows[4].en': yup.string().required(FIELD_REQUIRED),
  // 'columns[0].rows[4].vi': yup.string().required(FIELD_REQUIRED),
  'columns[1].header.en': yup.string().required(FIELD_REQUIRED),
  'columns[1].header.vi': yup.string().required(FIELD_REQUIRED),
  'columns[1].rows[0].en': yup.string().required(FIELD_REQUIRED),
  'columns[1].rows[0].vi': yup.string().required(FIELD_REQUIRED),
  'columns[1].rows[1].en': yup.string().required(FIELD_REQUIRED),
  'columns[1].rows[1].vi': yup.string().required(FIELD_REQUIRED),
  'columns[1].rows[2].en': yup.string().required(FIELD_REQUIRED),
  'columns[1].rows[2].vi': yup.string().required(FIELD_REQUIRED),
  'columns[1].rows[3].en': yup.string().required(FIELD_REQUIRED),
  'columns[1].rows[3].vi': yup.string().required(FIELD_REQUIRED),
  'columns[1].rows[4].en': yup.string().required(FIELD_REQUIRED),
  'columns[1].rows[4].vi': yup.string().required(FIELD_REQUIRED),
});

export const schemaCardForm = yup.object().shape({
  engInput: yup.string().required(FIELD_REQUIRED),
  vietInput: yup.string().required(FIELD_REQUIRED),
});
