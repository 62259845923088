/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React from 'react';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import PropTypes from 'prop-types';
import { Card, Row, Input } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import Table from '../../../../../components/Table/index';
import './styleFaqList.scss';

class QaList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      catId: '',
    };
  }

  handleChange = (event) => {
    this.setState({ query: event.target.value });
  };

  searchFaq = () => {
    const { query, catId } = this.state;
    const { onSearch } = this.props;
    onSearch(query, catId);
  };

  handleSelectCat = (e) => {
    this.setState({ catId: e.target.value });
  }


  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.searchFaq();
    }
  }

  onAddFaq = () => {
    const { history } = this.props;
    const listUrl = this.defineBaseUrl();
    history.push(`${listUrl}/add`);
  }

  handleClickDeleteRow = (idRow) => {
    const { onDeleteFaq } = this.props;
    onDeleteFaq(idRow);
  };

  defineBaseUrl = () => {
    const { qaType } = this.props;

    let listUrl = '';
    if (qaType === 'CANDIDATE') {
      listUrl = '/admin/faq-jobseeker/qa';
    }
    if (qaType === 'RECRUITER') {
      listUrl = '/admin/faq-recruiter/qa';
    }
    return listUrl;
  };

  render() {
    const { qaList, catList } = this.props;
    const { catId } = this.state;
    const categoriesOptions = catList.length > 0
      ? catList.map(category => <option value={category.id}>{category.content.en}</option>) : [];
    const listUrl = this.defineBaseUrl();
    return (
      <Card>
        <Row>
          <div className="form__form-group">
            <div className="form__form-group-field form__searchPost">
              <div className="form__form-group-icon">
                <MagnifyIcon />
              </div>
              <Input
                type="text"
                className=""
                name="search"
                placeholder="Search"
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
              />
              <Input type="select" name="categoryId" id="categoryId" onChange={e => this.handleSelectCat(e)} value={catId}>
                <option value="">Select Category</option>
                {categoriesOptions}
              </Input>
              <button
                type="button"
                className="card-form__btn card-form__btn--submit btn-search"
                color="primary"
                onClick={this.searchFaq}
              >
                Search
              </button>
            </div>
          </div>
          <div className="card-form-wrapper">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.onAddFaq()}
            >
              Add
            </button>
          </div>
        </Row>
        <Table
          data={qaList}
          columns={[
            {
              Header: 'Question (EN)',
              accessor: 'question.en',
              sortable: true,
            },
            {
              Header: 'Question (VI)',
              accessor: 'question.vi',
              sortable: true,
            },
            {
              Header: 'Categories',
              accessor: 'subject.content.en',
            },
            {
              Header: 'Updated',
              accessor: 'updatedDate',
            },
            {
              Header: 'Actions',
              accessor: null,
              Cell: (row) => {
                const { _id } = row.original;
                return (
                  <div className="table-actions">
                    <Link to={`${listUrl}/edit/${_id}`}>
                      Edit
                    </Link>
                    <span
                      role="presentation"
                      className="action-delete"
                      onClick={() => this.handleClickDeleteRow(_id)}
                    >
                      Delete
                    </span>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          className="custom-table"
          showPaginationBottom
          sortable={false}
        />
      </Card>
    );
  }
}

QaList.propTypes = {
  qaList: PropTypes.instanceOf(Object).isRequired,
  onSearch: PropTypes.func.isRequired,
  onDeleteFaq: PropTypes.func.isRequired,
  catList: PropTypes.instanceOf(Object).isRequired,
  qaType: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(QaList);
