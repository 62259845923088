import api from '../index';
import ApiConstants from '../ApiConstants';

export const getQuestions = () => api(
  `${ApiConstants.QUESTION}`,
  null,
  'get',
  null,
);

export const createQuestion = data => api(
  `${ApiConstants.QUESTION}/save`,
  data,
  'POST',
  null,
);
