/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
export const setDataField = (dataChange, dataField) => {
  let dataSet = dataField;
  const key = Object.keys(dataChange)[0];
  // Common attribute
  if (key === 'fieldId') {
    dataSet.fieldId = dataChange.fieldId;
  } else if (key === 'labelEnglish') {
    if (Object.prototype.hasOwnProperty.call(dataField, 'label')) {
      if (Object.prototype.hasOwnProperty.call(dataField.label, 'en')) {
        dataSet.label.en = dataChange.labelEnglish;
      } else if (Object.prototype.hasOwnProperty.call(dataField.label, 'vi')) {
        dataSet.label = {
          en: dataChange.labelEnglish,
          vi: dataField.label.vi,
        };
      }
    } else {
      dataSet.label = { en: dataChange.labelEnglish };
    }
  } else if (key === 'labelVn') {
    if (Object.prototype.hasOwnProperty.call(dataField, 'label')) {
      if (Object.prototype.hasOwnProperty.call(dataField.label, 'vi')) {
        dataSet.label.vi = dataChange.labelVn;
      } else if (Object.prototype.hasOwnProperty.call(dataField.label, 'en')) {
        dataSet.label = {
          en: dataField.label.en,
          vi: dataChange.labelVn,
        };
      }
    } else {
      dataSet.label = { vi: dataChange.labelVn };
    }
  } else if (key === 'tooltipEnglish') {
    if (Object.prototype.hasOwnProperty.call(dataField, 'tooltip')) {
      if (Object.prototype.hasOwnProperty.call(dataField.tooltip, 'en')) {
        dataSet.tooltip.en = dataChange.tooltipEnglish;
      } else if (Object.prototype.hasOwnProperty.call(dataField.tooltip, 'vi')) {
        dataSet.tooltip = {
          en: dataChange.tooltipEnglish,
          vi: dataField.tooltip.vi,
        };
      }
    } else {
      dataSet.tooltip = { en: dataChange.tooltipEnglish };
    }
  } else if (key === 'tooltipVn') {
    if (Object.prototype.hasOwnProperty.call(dataField, 'tooltip')) {
      if (Object.prototype.hasOwnProperty.call(dataField.tooltip, 'vi')) {
        dataSet.tooltip.vi = dataChange.tooltipVn;
      } else if (Object.prototype.hasOwnProperty.call(dataField.tooltip, 'en')) {
        dataSet.tooltip = {
          en: dataField.tooltip.en,
          vi: dataChange.tooltipVn,
        };
      }
    } else {
      dataSet.tooltip = { vi: dataChange.tooltipVn };
    }
  } else if (key === 'weight') {
    dataSet.weight = dataChange.weight === '' ? '' : parseInt(dataChange.weight, 10);
  } else if (dataChange.tooltipVn) {
    if (Object.prototype.hasOwnProperty.call(dataField, 'tooltip')) {
      if (Object.prototype.hasOwnProperty.call(dataField.tooltip, 'vi')) {
        dataSet.tooltip.vi = dataChange.tooltipVn;
      } else if (Object.prototype.hasOwnProperty.call(dataField.tooltip, 'en')) {
        dataSet.tooltip = {
          en: dataField.tooltip.en,
          vi: dataChange.tooltipVn,
        };
      }
    } else {
      dataSet.tooltip = { vi: dataChange.tooltipVn };
    }
  } else if (key === 'fieldType') {
    dataSet = {};
    dataSet.detail = {};
    dataSet.level = 1;
    dataSet.type = dataChange.fieldType;
    if (dataSet.type === 'Group') {
      dataSet.detail.fields = [];
    } else if (dataSet.type === 'Location') {
      dataSet.detail.cities = [];
      dataSet.detail.districts = [];
    } else if (dataSet.type === 'Skill') {
      dataSet.detail.titleDescription = {
        en: '',
        vi: '',
      };
      dataSet.detail.columns = [
        {
          header: {
            en: '',
            vi: '',
          },
          headerTooltip: {
            en: '',
            vi: '',
          },
          rows: [
            {
              en: '',
              vi: '',
            },
            {
              en: '',
              vi: '',
            },
            {
              en: '',
              vi: '',
            },
            {
              en: '',
              vi: '',
            },
            {
              en: '',
              vi: '',
            },
          ],
        },
        {
          header: {
            en: '',
            vi: '',
          },
          headerTooltip: {
            en: '',
            vi: '',
          },
          rows: [
            {
              en: '',
              vi: '',
            },
            {
              en: '',
              vi: '',
            },
            {
              en: '',
              vi: '',
            },
            {
              en: '',
              vi: '',
            },
            {
              en: '',
              vi: '',
            },
          ],
        },
      ];
    } else if (dataSet.type === 'Set') {
      dataSet.detail.data = [];
    }
  } else if (key === 'levelField') {
    dataSet.level = parseInt(dataChange.levelField, 10);
  } else if (key === 'allowUploadFile') {
    dataSet.allowUploadFile = dataChange.allowUploadFile;
  } else if (key === 'showDescriptionForApplicant') {
    dataSet.showDescriptionForApplicant = dataChange.showDescriptionForApplicant;
  } else if (key === 'allowRecruiterRemove') {
    dataSet.allowRecruiterRemove = dataChange.allowRecruiterRemove;
  } else if (key === 'allowApplicantRemove') {
    dataSet.allowApplicantRemove = dataChange.allowApplicantRemove;
  } else if (key === 'isHidden') {
    dataSet.isHidden = dataChange.isHidden;
  } else if (key === 'requiredFieldApplyJob') {
    if (dataChange.requiredFieldApplyJob) {
      if (dataField.validateInfo && dataField.validateInfo.view) {
        dataSet.validateInfo.view.push('apply_job');
      } else {
        dataSet.validateInfo = {
          type: 'required',
          view: ['apply_job'],
        };
      }
    } else {
      dataSet.validateInfo = {
        type: 'required',
        view: dataField.validateInfo.view.filter(item => item !== 'apply_job'),
      };
    }
  } else if (key === 'requiredFieldCreateJob') {
    if (dataChange.requiredFieldCreateJob) {
      if (dataField.validateInfo && dataField.validateInfo.view) {
        dataSet.validateInfo.view.push('create_job');
      } else {
        dataSet.validateInfo = {
          type: 'required',
          view: ['create_job'],
        };
      }
    } else {
      dataSet.validateInfo = {
        type: 'required',
        view: dataField.validateInfo.view.filter(item => item !== 'create_job'),
      };
    }
  } else if (key === 'requiredFieldCreateFilter') {
    if (dataChange.requiredFieldCreateFilter) {
      if (dataField.validateInfo && dataField.validateInfo.view) {
        dataSet.validateInfo.view.push('create_filter');
      } else {
        dataSet.validateInfo = {
          type: 'required',
          view: ['create_filter'],
        };
      }
    } else {
      dataSet.validateInfo = {
        type: 'required',
        view: dataField.validateInfo.view.filter(item => item !== 'create_filter'),
      };
    }
  } else if (key === 'isJobSeekerReadonly') {
    dataSet.isJobSeekerReadonly = dataChange.isJobSeekerReadonly;
  } else if (key === 'isClone') {
    dataSet.isClone = dataChange.isClone;
  } else if (key === 'isNotShowButtonAdd') {
    dataSet.isShowButtonAdd = (dataChange.isNotShowButtonAdd === false);
  } else if (key === 'applicantSelect') { // Set, Location attribute
    dataSet.detail.applicantSelect = dataChange.applicantSelect;
  } else if (key === 'recruiterSelect') {
    dataSet.detail.recruiterSelect = dataChange.recruiterSelect;
  } else if (key === 'displayType') {
    dataSet.detail.displayType = dataChange.displayType;
  } else if (key === 'matchingCondition') {
    dataSet.detail.matchingCondition = dataChange.matchingCondition;
  } else if (key === 'rangeTo') { // Range attribute
    dataSet.detail.lteValue = parseInt(dataChange.rangeTo, 10);
  } else if (key === 'additionalField') {
    dataSet.detail.additionalField = dataChange.additionalField;
  } else if (key === 'rangeFrom') {
    dataSet.detail.gteValue = parseInt(dataChange.rangeFrom, 10);
  } else if (key === 'unitLabelEnglish') {
    if (Object.prototype.hasOwnProperty.call(dataField.detail, 'unitLabel')) {
      if (Object.prototype.hasOwnProperty.call(dataField.detail.unitLabel, 'en')) {
        dataSet.detail.unitLabel.en = dataChange.unitLabelEnglish;
      } else if (Object.prototype.hasOwnProperty.call(dataField.detail.unitLabel, 'vi')) {
        dataSet.detail.unitLabel = {
          en: dataChange.unitLabelEnglish,
          vi: dataField.detail.unitLabel.vi,
        };
      }
    } else {
      dataSet.detail.unitLabel = { en: dataChange.unitLabelEnglish };
    }
  } else if (key === 'unitLabelVn') {
    if (Object.prototype.hasOwnProperty.call(dataField.detail, 'unitLabel')) {
      if (Object.prototype.hasOwnProperty.call(dataField.detail.unitLabel, 'vi')) {
        dataSet.detail.unitLabel.vi = dataChange.unitLabelVn;
      } else if (Object.prototype.hasOwnProperty.call(dataField.detail.unitLabel, 'en')) {
        dataSet.detail.unitLabel = {
          en: dataField.detail.unitLabel.en,
          vi: dataChange.unitLabelVn,
        };
      }
    } else {
      dataSet.detail.unitLabel = { vi: dataChange.unitLabelVn };
    }
  } else if (key === 'allowRecruiterEnterRange') {
    dataSet.detail.allowRecruiterEnterRange = (dataChange.allowRecruiterEnterRange === 'true');
  } else if (key === 'allowApplicantEnterRange') {
    dataSet.detail.allowApplicantEnterRange = (dataChange.allowApplicantEnterRange === 'true');
  } else if (key === 'recruiterShowProvinceCity') { // Location attribute
    dataSet.detail.recruiterShowProvinceCity = dataChange.recruiterShowProvinceCity;
  } else if (key === 'applicantShowProvinceCity') {
    dataSet.detail.applicantShowProvinceCity = dataChange.applicantShowProvinceCity;
  } else if (key === 'recruiterShowDistrict') {
    dataSet.detail.recruiterShowDistrict = dataChange.recruiterShowDistrict;
  } else if (key === 'applicantShowDistrict') {
    dataSet.detail.applicantShowDistrict = dataChange.applicantShowDistrict;
  } else if (key === 'enterValue') {
    dataSet.detail.enterValue = dataChange.enterValue;
  } else if (key === 'suffixLabelEnglish') {
    if (Object.prototype.hasOwnProperty.call(dataField.detail, 'suffixLabel')) {
      if (Object.prototype.hasOwnProperty.call(dataField.detail.suffixLabel, 'en')) {
        dataSet.detail.suffixLabel.en = dataChange.suffixLabelEnglish;
      } else if (Object.prototype.hasOwnProperty.call(dataField.detail.suffixLabel, 'vi')) {
        dataSet.detail.suffixLabel = {
          en: dataChange.suffixLabelEnglish,
          vi: dataField.detail.suffixLabel.vi,
        };
      }
    } else {
      dataSet.detail.suffixLabel = { en: dataChange.suffixLabelEnglish };
    }
  } else if (key === 'suffixLabelVn') {
    if (Object.prototype.hasOwnProperty.call(dataField.detail, 'suffixLabel')) {
      if (Object.prototype.hasOwnProperty.call(dataField.detail.suffixLabel, 'vi')) {
        dataSet.detail.suffixLabel.vi = dataChange.suffixLabelVn;
      } else if (Object.prototype.hasOwnProperty.call(dataField.detail.suffixLabel, 'en')) {
        dataSet.detail.suffixLabel = {
          en: dataField.detail.suffixLabel.en,
          vi: dataChange.suffixLabelVn,
        };
      }
    } else {
      dataSet.detail.suffixLabel = { vi: dataChange.suffixLabelVn };
    }
  } else if (key === 'max') { // Slider attribute
    dataSet.detail.lteValue = parseInt(dataChange.max, 10);
  } else if (key === 'min') {
    dataSet.detail.gteValue = parseInt(dataChange.min, 10);
  } else if (key === 'step') {
    dataSet.detail.step = parseInt(dataChange.step, 10);
  } else if (key === 'labelMinEnglish') {
    if (Object.prototype.hasOwnProperty.call(dataField.detail, 'labelMin')) {
      if (Object.prototype.hasOwnProperty.call(dataField.detail.labelMin, 'en')) {
        dataSet.detail.labelMin.en = dataChange.labelMinEnglish;
      } else if (Object.prototype.hasOwnProperty.call(dataField.detail.labelMin, 'vi')) {
        dataSet.detail.labelMin = {
          en: dataChange.labelMinEnglish,
          vi: dataField.detail.labelMin.vi,
        };
      }
    } else {
      dataSet.detail.labelMin = { en: dataChange.labelMinEnglish };
    }
  } else if (key === 'labelMinVn') {
    if (Object.prototype.hasOwnProperty.call(dataField.detail, 'labelMin')) {
      if (Object.prototype.hasOwnProperty.call(dataField.detail.labelMin, 'vi')) {
        dataSet.detail.labelMin.vi = dataChange.labelMinVn;
      } else if (Object.prototype.hasOwnProperty.call(dataField.detail.labelMin, 'en')) {
        dataSet.detail.labelMin = {
          en: dataField.detail.labelMin.en,
          vi: dataChange.labelMinVn,
        };
      }
    } else {
      dataSet.detail.labelMin = { vi: dataChange.labelMinVn };
    }
  } else if (key === 'labelMaxEnglish') {
    if (Object.prototype.hasOwnProperty.call(dataField.detail, 'labelMax')) {
      if (Object.prototype.hasOwnProperty.call(dataField.detail.labelMax, 'en')) {
        dataSet.detail.labelMax.en = dataChange.labelMaxEnglish;
      } else if (Object.prototype.hasOwnProperty.call(dataField.detail.labelMax, 'vi')) {
        dataSet.detail.labelMax = {
          en: dataChange.labelMaxEnglish,
          vi: dataField.detail.labelMax.vi,
        };
      }
    } else {
      dataSet.detail.labelMax = { en: dataChange.labelMaxEnglish };
    }
  } else if (key === 'labelMaxVn') {
    if (Object.prototype.hasOwnProperty.call(dataField.detail, 'labelMax')) {
      if (Object.prototype.hasOwnProperty.call(dataField.detail.labelMax, 'vi')) {
        dataSet.detail.labelMax.vi = dataChange.labelMaxVn;
      } else if (Object.prototype.hasOwnProperty.call(dataField.detail.labelMax, 'en')) {
        dataSet.detail.labelMax = {
          en: dataField.detail.labelMax.en,
          vi: dataChange.labelMaxVn,
        };
      }
    } else {
      dataSet.detail.labelMax = { vi: dataChange.labelMaxVn };
    }
  } else if (key === 'placeholderEnglish') { // Inputext attribute
    if (Object.prototype.hasOwnProperty.call(dataField.detail, 'placeholder')) {
      if (Object.prototype.hasOwnProperty.call(dataField.detail.placeholder, 'en')) {
        dataSet.detail.placeholder.en = dataChange.placeholderEnglish;
      } else if (Object.prototype.hasOwnProperty.call(dataField.detail.placeholder, 'vi')) {
        dataSet.detail.placeholder = {
          en: dataChange.placeholderEnglish,
          vi: dataField.detail.placeholder.vi,
        };
      }
    } else {
      dataSet.detail.placeholder = { en: dataChange.placeholderEnglish };
    }
  } else if (key === 'placeholderVn') {
    if (Object.prototype.hasOwnProperty.call(dataField.detail, 'placeholder')) {
      if (Object.prototype.hasOwnProperty.call(dataField.detail.placeholder, 'vi')) {
        dataSet.detail.placeholder.vi = dataChange.placeholderVn;
      } else if (Object.prototype.hasOwnProperty.call(dataField.detail.placeholder, 'en')) {
        dataSet.detail.placeholder = {
          en: dataField.detail.placeholder.en,
          vi: dataChange.placeholderVn,
        };
      }
    } else {
      dataSet.detail.placeholder = { vi: dataChange.placeholderVn };
    }
  } else if (key === 'cols') {
    dataSet.detail.cols = parseInt(dataChange.cols, 10);
  } else if (key === 'rows') {
    dataSet.detail.rows = parseInt(dataChange.rows, 10);
  } else if (key === 'allowMultiline') {
    dataSet.detail.allowMultiline = dataChange.allowMultiline;
  } else if (key === 'isShowYearsExperience') { // Skill attribute
    dataSet.detail.isShowYearsExperience = dataChange.isShowYearsExperience;
  } else if (key === 'isShowRate') {
    dataSet.detail.isShowRate = dataChange.isShowRate;
  } else if (key === 'titleDescriptiontipEnglish') {
    if (Object.prototype.hasOwnProperty.call(dataField.detail, 'titleDescription')) {
      if (Object.prototype.hasOwnProperty.call(dataField.detail.titleDescription, 'en')) {
        dataSet.detail.titleDescription.en = dataChange.titleDescriptiontipEnglish;
      } else if (Object.prototype.hasOwnProperty.call(dataField.detail.titleDescription, 'vi')) {
        dataSet.detail.titleDescription = {
          en: dataChange.titleDescriptiontipEnglish,
          vi: dataField.detail.titleDescription.vi,
        };
      }
    } else {
      dataSet.detail.titleDescription = { en: dataChange.titleDescriptiontipEnglish };
    }
    // dataSet.detail.titleDescription.en = dataChange.titleDescriptiontipEnglish;
  } else if (key === 'titleDescriptionVn') {
    if (Object.prototype.hasOwnProperty.call(dataField.detail, 'titleDescription')) {
      if (Object.prototype.hasOwnProperty.call(dataField.detail.titleDescription, 'vi')) {
        dataSet.detail.titleDescription.vi = dataChange.titleDescriptionVn;
      } else if (Object.prototype.hasOwnProperty.call(dataField.detail.titleDescription, 'en')) {
        dataSet.detail.titleDescription = {
          en: dataField.detail.titleDescription.en,
          vi: dataChange.titleDescriptionVn,
        };
      }
    } else {
      dataSet.detail.titleDescription = { vi: dataChange.titleDescriptionVn };
    }
    // dataSet.detail.titleDescription.vi = dataChange.titleDescriptionVn;
  } else if (key === 'header1English') {
    dataSet.detail.columns[0].header.en = dataChange.header1English;
  } else if (key === 'header1Vn') {
    dataSet.detail.columns[0].header.vi = dataChange.header1Vn;
  } else if (key === 'header1TooltipEnglish') {
    dataSet.detail.columns[0].headerTooltip.en = dataChange.header1TooltipEnglish;
  } else if (key === 'header1TooltipVn') {
    dataSet.detail.columns[0].headerTooltip.vi = dataChange.header1TooltipVn;
  } else if (key === 'row1Col1English') {
    dataSet.detail.columns[0].rows[0].en = dataChange.row1Col1English;
  } else if (key === 'row1Col1Vn') {
    dataSet.detail.columns[0].rows[0].vi = dataChange.row1Col1Vn;
  } else if (key === 'row2Col1English') {
    dataSet.detail.columns[0].rows[1].en = dataChange.row2Col1English;
  } else if (key === 'row2Col1Vn') {
    dataSet.detail.columns[0].rows[1].vi = dataChange.row2Col1Vn;
  } else if (key === 'row3Col1English') {
    dataSet.detail.columns[0].rows[2].en = dataChange.row3Col1English;
  } else if (key === 'row3Col1Vn') {
    dataSet.detail.columns[0].rows[2].vi = dataChange.row3Col1Vn;
  } else if (key === 'row4Col1English') {
    dataSet.detail.columns[0].rows[3].en = dataChange.row4Col1English;
  } else if (key === 'row4Col1Vn') {
    dataSet.detail.columns[0].rows[3].vi = dataChange.row4Col1Vn;
  } else if (key === 'row5Col1English') {
    dataSet.detail.columns[0].rows[4].en = dataChange.row5Col1English;
  } else if (key === 'row5Col1Vn') {
    dataSet.detail.columns[0].rows[4].vi = dataChange.row5Col1Vn;
  } else if (key === 'header2English') {
    dataSet.detail.columns[1].header.en = dataChange.header2English;
  } else if (key === 'header2Vn') {
    dataSet.detail.columns[1].header.vi = dataChange.header2Vn;
  } else if (key === 'header2TooltipEnglish') {
    dataSet.detail.columns[1].headerTooltip.en = dataChange.header2TooltipEnglish;
  } else if (key === 'header2TooltipVn') {
    dataSet.detail.columns[1].headerTooltip.vi = dataChange.header2TooltipVn;
  } else if (key === 'row1Col2English') {
    dataSet.detail.columns[1].rows[0].en = dataChange.row1Col2English;
  } else if (key === 'row1Col2Vn') {
    dataSet.detail.columns[1].rows[0].vi = dataChange.row1Col2Vn;
  } else if (key === 'row2Col2English') {
    dataSet.detail.columns[1].rows[1].en = dataChange.row2Col2English;
  } else if (key === 'row2Col2Vn') {
    dataSet.detail.columns[1].rows[1].vi = dataChange.row2Col2Vn;
  } else if (key === 'row3Col2English') {
    dataSet.detail.columns[1].rows[2].en = dataChange.row3Col2English;
  } else if (key === 'row3Col2Vn') {
    dataSet.detail.columns[1].rows[2].vi = dataChange.row3Col2Vn;
  } else if (key === 'row4Col2English') {
    dataSet.detail.columns[1].rows[3].en = dataChange.row4Col2English;
  } else if (key === 'row4Col2Vn') {
    dataSet.detail.columns[1].rows[3].vi = dataChange.row4Col2Vn;
  } else if (key === 'row5Col2English') {
    dataSet.detail.columns[1].rows[4].en = dataChange.row5Col2English;
  } else if (key === 'row5Col2Vn') {
    dataSet.detail.columns[1].rows[4].vi = dataChange.row5Col2Vn;
  }

  return dataSet;
};

export const validData = (dataField, listItemSet, listItemLanguage, listItemUnit) => {
  if (!Object.prototype.hasOwnProperty.call(dataField, 'fieldId')) {
    dataField.fieldId = '';
  }

  if (!Object.prototype.hasOwnProperty.call(dataField, 'level')) {
    dataField.level = 1;
  }

  if (!Object.prototype.hasOwnProperty.call(dataField, 'label')) {
    dataField.label = {
      en: '',
      vi: '',
    };
  } else if (!Object.prototype.hasOwnProperty.call(dataField.label, 'en')) {
    dataField.label = {
      en: '',
      vi: dataField.label.vi,
    };
  } else if (!Object.prototype.hasOwnProperty.call(dataField.label, 'vi')) {
    dataField.label = {
      en: dataField.label.en,
      vi: '',
    };
  }

  if (!Object.prototype.hasOwnProperty.call(dataField, 'tooltip')) {
    dataField.tooltip = {
      en: '',
      vi: '',
    };
  } else if (!Object.prototype.hasOwnProperty.call(dataField.tooltip, 'en')) {
    dataField.tooltip = {
      en: '',
      vi: dataField.tooltip.vi,
    };
  } else if (!Object.prototype.hasOwnProperty.call(dataField.tooltip, 'vi')) {
    dataField.tooltip = {
      en: dataField.tooltip.en,
      vi: '',
    };
  }

  if (!Object.prototype.hasOwnProperty.call(dataField, 'weight')) {
    dataField.weight = 1;
  } else if (dataField.weight === '') {
    dataField.weight = 1;
  }

  if (!Object.prototype.hasOwnProperty.call(dataField, 'allowUploadFile')) {
    dataField.allowUploadFile = false;
  }
  if (!Object.prototype.hasOwnProperty.call(dataField, 'showDescriptionForApplicant')) {
    dataField.showDescriptionForApplicant = false;
  }
  if (!Object.prototype.hasOwnProperty.call(dataField, 'allowRecruiterRemove')) {
    dataField.allowRecruiterRemove = false;
  }
  if (!Object.prototype.hasOwnProperty.call(dataField, 'allowApplicantRemove')) {
    dataField.allowApplicantRemove = false;
  }
  if (!Object.prototype.hasOwnProperty.call(dataField, 'isHidden')) {
    dataField.isHidden = false;
  }

  if (!Object.prototype.hasOwnProperty.call(dataField, 'isJobSeekerReadonly')) {
    dataField.isJobSeekerReadonly = false;
  }

  if (dataField.type === 'Set') {
    if (!Object.prototype.hasOwnProperty.call(dataField, 'detail')) {
      dataField.detail = {
        data: listItemSet,
      };
    } else {
      dataField.detail.data = listItemSet;
    }

    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'applicantSelect')) {
      dataField.detail.applicantSelect = 'one';
    }
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'recruiterSelect')) {
      dataField.detail.recruiterSelect = 'one';
    }

    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'displayType')) {
      dataField.detail.displayType = 'Dropdown';
    }

    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'matchingCondition')) {
      dataField.detail.matchingCondition = 'Or';
    }
  } else if (dataField.type === 'Group') {
    if (!Object.prototype.hasOwnProperty.call(dataField, 'isClone')) {
      dataField.isClone = false;
    }
    if (!Object.prototype.hasOwnProperty.call(dataField, 'isShowButtonAdd')) {
      dataField.isShowButtonAdd = true;
    }
  } else if (dataField.type === 'Range') {
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'allowRecruiterEnterRange')) {
      dataField.detail.allowRecruiterEnterRange = true;
    }

    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'allowApplicantEnterRange')) {
      dataField.detail.allowApplicantEnterRange = false;
    }

    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'additionalField')) {
      dataField.detail.additionalField = false;
    }

    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'unitLabel')) {
      dataField.detail.unitLabel = {
        en: '',
        vi: '',
      };
    } else if (!Object.prototype.hasOwnProperty.call(dataField.detail.unitLabel, 'en')) {
      dataField.detail.unitLabel = {
        en: '',
        vi: dataField.detail.unitLabel.vi,
      };
    } else if (!Object.prototype.hasOwnProperty.call(dataField.detail.unitLabel, 'vi')) {
      dataField.detail.unitLabel = {
        en: dataField.detail.unitLabel.en,
        vi: '',
      };
    }
  } else if (dataField.type === 'Location') {
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'applicantSelect')) {
      dataField.detail.applicantSelect = 'one';
    }
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'recruiterSelect')) {
      dataField.detail.recruiterSelect = 'one';
    }
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'recruiterShowProvinceCity')) {
      dataField.detail.recruiterShowProvinceCity = false;
    }
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'applicantShowProvinceCity')) {
      dataField.detail.applicantShowProvinceCity = false;
    }
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'recruiterShowDistrict')) {
      dataField.detail.recruiterShowDistrict = false;
    }
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'applicantShowDistrict')) {
      dataField.detail.applicantShowDistrict = false;
    }
  } else if (dataField.type === 'Number') {
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'enterValue')) {
      dataField.detail.enterValue = 'both';
    }
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'suffixLabel')) {
      dataField.detail.suffixLabel = {
        en: '',
        vi: '',
      };
    } else if (!Object.prototype.hasOwnProperty.call(dataField.detail.suffixLabel, 'en')) {
      dataField.detail.suffixLabel = {
        en: '',
        vi: dataField.detail.suffixLabel.vi,
      };
    } else if (!Object.prototype.hasOwnProperty.call(dataField.detail.suffixLabel, 'vi')) {
      dataField.detail.suffixLabel = {
        en: dataField.detail.suffixLabel.en,
        vi: '',
      };
    }
  } else if (dataField.type === 'Slider') {
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'recruiterSelect')) {
      dataField.detail.recruiterSelect = 'one';
    }
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'applicantSelect')) {
      dataField.detail.applicantSelect = 'one';
    }
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'additionalField')) {
      dataField.detail.additionalField = false;
    }

    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'labelMin')) {
      dataField.detail.labelMin = {
        en: '',
        vi: '',
      };
    } else if (!Object.prototype.hasOwnProperty.call(dataField.detail.labelMin, 'en')) {
      dataField.detail.labelMin = {
        en: '',
        vi: dataField.detail.labelMin.vi,
      };
    } else if (!Object.prototype.hasOwnProperty.call(dataField.detail.labelMin, 'vi')) {
      dataField.detail.labelMin = {
        en: dataField.detail.labelMin.en,
        vi: '',
      };
    }

    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'labelMax')) {
      dataField.detail.labelMax = {
        en: '',
        vi: '',
      };
    } else if (!Object.prototype.hasOwnProperty.call(dataField.detail.labelMax, 'en')) {
      dataField.detail.labelMax = {
        en: '',
        vi: dataField.detail.labelMax.vi,
      };
    } else if (!Object.prototype.hasOwnProperty.call(dataField.detail.labelMax, 'vi')) {
      dataField.detail.labelMax = {
        en: dataField.detail.labelMax.en,
        vi: '',
      };
    }

    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'step')) {
      dataField.detail.step = 10;
    }

    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'gteValue')) {
      dataField.detail.gteValue = 0;
    }

    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'lteValue')) {
      dataField.detail.lteValue = 100;
    }
  } else if (dataField.type === 'InputText') {
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'allowMultiline')) {
      dataField.detail.allowMultiline = false;
    }
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'placeholder')) {
      dataField.detail.placeholder = {
        en: '',
        vi: '',
      };
    } else if (!Object.prototype.hasOwnProperty.call(dataField.detail.placeholder, 'en')) {
      dataField.detail.placeholder = {
        en: '',
        vi: dataField.detail.placeholder.vi,
      };
    } else if (!Object.prototype.hasOwnProperty.call(dataField.detail.placeholder, 'vi')) {
      dataField.detail.placeholder = {
        en: dataField.detail.placeholder.en,
        vi: '',
      };
    }
  } else if (dataField.type === 'Language') {
    dataField.detail.data = listItemLanguage;
  } else if (dataField.type === 'Currency') {
    dataField.detail.data = listItemUnit;
  } else if (dataField.type === 'Skill') {
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'isShowYearsExperience')) {
      dataField.detail.isShowYearsExperience = false;
    }
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'isShowRate')) {
      dataField.detail.isShowRate = false;
    }
  } else if (dataField.type === 'Year') {
    if (!Object.prototype.hasOwnProperty.call(dataField.detail, 'recruiterSelect')) {
      dataField.detail.recruiterSelect = 'multiple';
    }
  }

  return dataField;
};

export const checkSetDataChange = (targetName) => {
  const listTarget = ['labelItemEnglish', 'labelItemVn', 'tooltipItemEnglish', 'tooltipItemVn', 'languageEnglish', 'languageVn', 'listeningLte', 'listeningGte', 'speakingLte', 'speakingGte', 'readingLte', 'readingGte', 'writingLte', 'writingGte', 'unit'];
  let isSetDataChange = true;
  if (listTarget.includes(targetName)) {
    isSetDataChange = false;
  }

  return isSetDataChange;
};

export const defineItem = (state) => {
  const { dataField } = state;
  const fieldType = dataField.type;
  let item = {};
  if (fieldType === 'Set') {
    const {
      labelItemEnglish,
      labelItemVn,
      tooltipItemEnglish,
      tooltipItemVn,
    } = state;

    if (labelItemEnglish !== '' || labelItemVn !== '' || tooltipItemEnglish !== '' || tooltipItemVn !== '') {
      item = {
        value: {
          en: labelItemEnglish,
          vi: labelItemVn,
        },
        tooltip: {
          en: tooltipItemEnglish,
          vi: tooltipItemVn,
        },
      };
    }
  } else if (fieldType === 'Language') {
    const {
      languageEnglish,
      languageVn,
      listeningLte,
      listeningGte,
      speakingLte,
      speakingGte,
      readingLte,
      readingGte,
      writingLte,
      writingGte,
    } = state;
    if (parseInt(listeningLte, 10) >= parseInt(listeningGte, 10) && parseInt(speakingLte, 10) >= parseInt(speakingGte, 10) && parseInt(readingLte, 10) >= parseInt(readingGte, 10) && parseInt(writingLte, 10) >= parseInt(writingGte, 10)) {
      item = {
        label: {
          en: languageEnglish,
          vi: languageVn,
        },
        speaking: {
          lte: parseInt(speakingLte, 10),
          gte: parseInt(speakingGte, 10),
        },
        listening: {
          lte: parseInt(listeningLte, 10),
          gte: parseInt(listeningGte, 10),
        },
        reading: {
          lte: parseInt(readingLte, 10),
          gte: parseInt(readingGte, 10),
        },
        writing: {
          lte: parseInt(writingLte, 10),
          gte: parseInt(writingGte, 10),
        },
      };
    }
  } else if (fieldType === 'Currency') {
    const { unit } = state;
    if (unit !== '') {
      item = { unit };
    }
  }

  return item;
};


export const checkDelete = (state) => {
  const { dataField, indexItemSelect } = state;
  const fieldType = dataField.type;
  let canDelete = false;
  if (indexItemSelect !== null) {
    if (fieldType === 'Set' || fieldType === 'Skill') {
      const {
        labelItemEnglish,
        labelItemVn,
        tooltipItemEnglish,
        tooltipItemVn,
      } = state;

      if (labelItemEnglish !== '' || labelItemVn !== '' || tooltipItemEnglish !== '' || tooltipItemVn !== '') {
        canDelete = true;
      }
    } else if (fieldType === 'Language') {
      const {
        languageEnglish,
        languageVn,
        listeningLte,
        listeningGte,
        speakingLte,
        speakingGte,
        readingLte,
        readingGte,
        writingLte,
        writingGte,
      } = state;

      if (languageEnglish !== '' || languageVn !== '' || listeningLte !== '' || listeningGte !== '' || speakingLte !== '' || speakingGte !== '' || readingLte !== '' || readingGte !== '' || writingLte !== '' || writingGte !== '') {
        canDelete = true;
      }
    } else if (fieldType === 'Currency') {
      const { unit } = state;
      if (unit !== '') {
        canDelete = true;
      }
    }
  }

  return canDelete;
};
