/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
import {
  GET_GLOBAL_SETTING,
  GET_GLOBAL_SETTING_SUCCESS,
  GET_GLOBAL_SETTING_FAIL,
  UPDATE_GLOBAL_SETTING,
  UPDATE_GLOBAL_SETTING_SUCCESS,
  UPDATE_GLOBAL_SETTING_FAIL,
} from '../actions/globalSettingAction';

const initialState = {
  message: '',
  isUpdate: false,
  isError: false,
  data: null,
  statusCode: '',
  globalSettingData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GLOBAL_SETTING: {
      return { ...state, type: action.type };
    }
    case GET_GLOBAL_SETTING_SUCCESS: {
      return { ...state, ...{ type: action.type, globalSettingData: action.data } };
    }
    case GET_GLOBAL_SETTING_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case UPDATE_GLOBAL_SETTING: {
      return { ...state, type: action.type, message: '' };
    }
    case UPDATE_GLOBAL_SETTING_SUCCESS: {
      return {
        ...state,
        message: 'Successful!',
        ...{
          type: action.type,
          isError: false,
          globalSettingData: action.data,
        },
      };
    }
    case UPDATE_GLOBAL_SETTING_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: 'Fail!',
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    default:
      return state;
  }
}
