import React, { useEffect, useState } from 'react';
import { Button, Container } from 'reactstrap';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import { Table } from '../../../components';
import CardForm from './CartForm';
import {
  getQuestions as getQuestionsAction,
  saveQuestion as saveQuestionsAction,
} from '../../../redux/actions/questionsAction';
import './questionRecruit.scss';
import { BasicNotification } from '../../../components/Notification';

const Questions = (props) => {
  const { saveQuestion, getQuestions, questions } = props;
  const [dataUpdate, setDateUpdate] = useState(null);
  const [formValues, setFormValues] = useState({
    vi: '',
    en: ''
  });

  const show = (color, message) => {
    NotificationSystem.newInstance({}, (notification) => {
      notification.notice({
        content: <BasicNotification
          title="Question"
          color={color}
          message={message}
        />,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: 'right-up',
      });
    });
  };


  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    if (dataUpdate) {
      setFormValues(dataUpdate.name);
    }
  }, [dataUpdate]);

  const cleanDataForm = () => {
    setDateUpdate(null);
    setFormValues({ vi: '', en: ''});
  };

  const onSubmit = (formInput) => {
    const mergeDataUpdate = dataUpdate ? { ...dataUpdate, ...{ name: formInput} } : { name: formInput};
    saveQuestion(mergeDataUpdate);
    cleanDataForm();
    show('success', 'Save Success');
  };

  const handleCancelForm = () => {
    cleanDataForm();
  };

  const changeStatus = (data) => {
    saveQuestion({ ...data, status: data.status === 'Active' ? 'InActive' : 'Active' });
    show('success', 'Change Status Success');
  };

  return (
    <Container className="pr-5">
      <h3 className="mb-3">Manager Questions</h3>
      <div className="custom-table-boostrap">
        <div className="card-form-wrapper w-100 mb-0">
          <CardForm
            onSubmit={onSubmit}
            formValues={formValues}
            type={dataUpdate ? 'save' : 'add'}
            cancelForm={handleCancelForm}
          />
        </div>
        {
          questions && questions.data && (
            <Table
              data={questions.data}
              columns={[
                {
                  Header: 'Question Name(EN)',
                  accessor: 'name.en',
                  sortable: true,
                },
                {
                  Header: 'Question Name (VI)',
                  accessor: 'name.vi',
                  sortable: true,
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                  sortable: true,
                },
                {
                  Header: 'Status',
                  accessor: null,
                  sortable: true,
                  Cell: (row) => {
                    const { original } = row;
                    const { status } = original;
                    return (
                      <div className="table-actions">
                        <Button
                          className="m-0"
                          outline
                          color={status === 'Active' ? 'primary' : 'danger'}
                          size="sm"
                          onClick={() => changeStatus(original)}
                        >{status}
                        </Button>
                      </div>
                    );
                  },
                },
                {
                  Header: 'Actions',
                  accessor: null,
                  Cell: row => (
                    <div className="table-actions">
                      <Button
                        outline
                        className="action-edit m-0"
                        color="primary"
                        size="sm"
                        onClick={() => setDateUpdate(row.original)}
                      >
                        Edit
                      </Button>
                    </div>
                  ),
                },
              ]}
              defaultPageSize={10}
              className="custom-table"
              showPaginationBottom
              sortable={false}
            />
          )
        }
      </div>
    </Container>
  );
};

Questions.propTypes = {
  getQuestions: PropTypes.func.isRequired,
  saveQuestion: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  const { questions } = state;
  return {
    questions,
  };
};

const mapDispatchToProps = dispatch => ({
  getQuestions: (...args) => dispatch(getQuestionsAction(...args)),
  saveQuestion: (...args) => dispatch(saveQuestionsAction(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(Questions));
