export const TOOGLE_DEPARTMENT_MODAL_SHOW = 'TOOGLE_DEPARTMENT_MODAL_SHOW';
export const TOOGLE_FUNCTION_MODAL_SHOW = 'TOOGLE_FUNCTIONS_MODAL_SHOW';
export const TOOGLE_MODAL_NONE = 'TOOGLE_MODAL_NONE';

export const SelectedHeaderModalType = {
  None: 'None',
  DEPARTMENT: 'Department',
  FUNCTION: 'Function',
};

export function toogleModalDepartmentShow(data) {
  return {
    type: TOOGLE_DEPARTMENT_MODAL_SHOW,
    data,
  };
}

export function toogleModalFunctionShow(data) {
  return {
    type: TOOGLE_FUNCTION_MODAL_SHOW,
    data,
  };
}

export function toogleModalNone() {
  return {
    type: TOOGLE_MODAL_NONE,
  };
}
