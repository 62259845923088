/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/named */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Button, Modal, ModalBody, Alert, ButtonGroup, ModalHeader, ModalFooter,
} from 'reactstrap';
import { toogleModalSave } from '../../../redux/actions/modalAction';
import { saveTemplate } from '../../../redux/actions/templateAction';


class SaveFieldModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
    };
    this.saveTemplate = this.saveTemplate.bind(this);
    this.toogleModalSave = this.toogleModalSave.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) this.toogleModalSave();
    });
  }

  toogleModalSave = () => {
    const { dispatch, modal } = this.props;
    const data = {
      isShow: !modal.isShow,
    };
    dispatch(toogleModalSave(data));
  };

  saveTemplate = () => {
    const { dispatch, modal } = this.props;
    dispatch(saveTemplate(modal.dataSave));
    this.toogleModalSave();
  }

  render() {
    const { modal } = this.props;
    return (
      <div>
        <Modal isOpen={modal.isShow} toogle={this.toogleModalSave}>
          <ModalHeader><h2>Confirm Save!</h2></ModalHeader>
          <ModalBody>
            <Alert color="primary">
              <h4>Do you want to save it?</h4>
            </Alert>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup className="float-right">
              <Button outline color="primary" size="sm" onClick={() => this.toogleModalSave()}>Cancel</Button>
              <Button outline color="danger" size="sm" onClick={() => this.saveTemplate()}>OK</Button>
            </ButtonGroup>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

SaveFieldModal.propTypes = {
  modal: PropTypes.objectOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { modal } = state;
  return {
    modal,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withTranslation('common')(SaveFieldModal));
