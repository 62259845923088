/* eslint-disable object-curly-newline */
import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Col, Card, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import HotCareersList from './HotCareersList';
import { BasicNotification } from '../../../components/Notification/index';
import HotCareersForm from './HotCareersForm';
import {
  getHotCareers as getHotCareersAction,
  updateHotCareers as updateHotCareersAction,
  sortHotCareers as sortHotCareersAction,
} from '../../../redux/actions/hotCareersAction';
import {
  STATUS_CODE_401,
} from '../../../shared/utils/constants';

let notificationRU = null;
class HotCareers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isForm: false,
      isShowNotify: false,
    };
  }

  componentWillMount = async () => {
    const { cookies, getHotCareers } = this.props;
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    getHotCareers();
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  onUpdateSortNumber = (items) => {
    const { sortHotCareers } = this.props;
    sortHotCareers(items);
  }

  onUpdateHotCareers = (id, params) => {
    const { updateHotCareers } = this.props;
    updateHotCareers(id, params);
    this.setState({ isShowNotify: true });
  }

  onDelete = (id) => {
    const { updateHotCareers, getHotCareers } = this.props;
    updateHotCareers(id, { isHot: false });
    getHotCareers();
    this.setState({ isShowNotify: true });
  }

  canceForm = () => {
    this.setState({ isForm: false });
  }

  show = (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Hot Careers"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  handleClickAddHotCareers = () => {
    this.setState({ isForm: true });
  }

  render() {
    const { hotCareers, cookies } = this.props;
    const { hasToken, isForm, isShowNotify } = this.state;
    if (isShowNotify && hotCareers.message) {
      if (hotCareers.isError) {
        this.show('warning', hotCareers.message);
      } else {
        this.show('success', hotCareers.message);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || hotCareers.statusCode === STATUS_CODE_401) {
      if (hotCareers.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <div>
        {isForm ? (
          <HotCareersForm
            onCancelForm={this.canceForm}
            isAddItem={isForm}
          />
        ) : (
          <Container>
            <Row>
              <Col md={12}>
                <h3 className="page-title">Hot Careers</h3>
              </Col>
            </Row>
            <Card body className="mb-5">
              <HotCareersList
                hotCareersList={hotCareers.hotCareersList}
                updateSortNumber={items => this.onUpdateSortNumber(items)}
                hotCareersEditing={item => this.onEditHotCareers(item)}
                onAddHotCareers={this.handleClickAddHotCareers}
                onDeleteHotCareers={id => this.onDelete(id)}
              />
            </Card>
          </Container>
        )}
      </div>
    );
  }
}

HotCareers.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  hotCareers: PropTypes.arrayOf(Object).isRequired,
  getHotCareers: PropTypes.func.isRequired,
  updateHotCareers: PropTypes.func.isRequired,
  sortHotCareers: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { hotCareers } = state;
  return {
    hotCareers,
  };
};
const mapDispatchToProps = dispatch => ({
  getHotCareers: (...args) => dispatch(getHotCareersAction(...args)),
  updateHotCareers: (...args) => dispatch(updateHotCareersAction(...args)),
  sortHotCareers: items => dispatch(sortHotCareersAction(items)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(HotCareers));
