import * as yup from 'yup';
import FIELD_REQUIRED from '../../../../../shared/utils/messageError';

const schemaQaForm = yup.object().shape({
  enTitle: yup.string().required(FIELD_REQUIRED),
  viTitle: yup.string().required(FIELD_REQUIRED),
  categoryId: yup.string().required(FIELD_REQUIRED),
  enContent: yup.string().required(FIELD_REQUIRED),
  viContent: yup.string().required(FIELD_REQUIRED),
});
export default schemaQaForm;
