import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../components/Table/index';

class JobList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { data } = this.props;
    return (
      <Table
        data={data}
        columns={[
          {
            Header: 'Code',
            accessor: 'jobCode',
            sortable: true,
          },
          {
            Header: 'Job',
            accessor: 'jobName',
            sortable: true,
          },
          {
            Header: 'Recruiter',
            accessor: 'nameOfRecruiter',
            sortable: true,
          },
          {
            Header: 'Department (EN)',
            accessor: 'departmentName.en',
            sortable: true,
          },
          {
            Header: 'Department (VI)',
            accessor: 'departmentName.vi',
            sortable: true,
          },
          {
            Header: 'Position (EN)',
            accessor: 'functionName.en',
            sortable: true,
          },
          {
            Header: 'Position (VI)',
            accessor: 'functionName.vi',
            sortable: true,
          },
          {
            Header: 'Date',
            accessor: 'updatedDate',
            sortable: true,
          },
          {
            Header: 'Status',
            accessor: 'status',
            sortable: true,
          },
        ]}
        defaultPageSize={5}
        className="custom-table"
        showPaginationBottom
        sortable={false}
      />
    );
  }
}

JobList.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};
export default JobList;
