export const GET_HOT_CAREERS = 'GET_HOT_CAREERS';
export const GET_HOT_CAREERS_SUCCESS = 'GET_HOT_CAREERS_SUCCESS';
export const GET_HOT_CAREERS_FAIL = 'GET_HOT_CAREERS_FAIL';

export const UPDATE_HOT_CAREERS = 'UPDATE_HOT_CAREERS';
export const UPDATE_HOT_CAREERS_SUCCESS = 'UPDATE_HOT_CAREERS_SUCCESS';
export const UPDATE_HOT_CAREERS_FAIL = 'UPDATE_HOT_CAREERS_FAIL';

export const SORT_HOT_CAREERS = 'SORT_HOT_CAREERS';
export const SORT_HOT_CAREERS_SUCCESS = 'SORT_HOT_CAREERS_SUCCESS';
export const SORT_HOT_CAREERS_FAIL = 'SORT_HOT_CAREERS_FAIL';

export const GET_HOT_CAREERS_BY_ID = 'GET_HOT_CAREERS_BY_ID';
export const GET_HOT_CAREERS_BY_ID_SUCCESS = 'GET_HOT_CAREERS_BY_ID_SUCCESS';
export const GET_HOT_CAREERS_BY_ID_FAIL = 'GET_HOT_CAREERS_BY_ID_FAIL';

export function getHotCareers() {
  return {
    type: GET_HOT_CAREERS,
  };
}

export function getHotCareersSuccess(data) {
  return {
    type: GET_HOT_CAREERS_SUCCESS,
    data,
  };
}

export function getHotCareersFail(error) {
  return {
    type: GET_HOT_CAREERS_FAIL,
    error,
  };
}

export function getHotCareersById(id) {
  return {
    type: GET_HOT_CAREERS_BY_ID,
    id,
  };
}

export function getHotCareersByIdSuccess(data) {
  return {
    type: GET_HOT_CAREERS_BY_ID_SUCCESS,
    data,
  };
}

export function getHotCareersByIdFail(error) {
  return {
    type: GET_HOT_CAREERS_BY_ID_FAIL,
    error,
  };
}

export function updateHotCareers(id, params) {
  return {
    type: UPDATE_HOT_CAREERS,
    id,
    params,
  };
}

export function updateHotCareersSuccess(data) {
  return {
    type: UPDATE_HOT_CAREERS_SUCCESS,
    data,
  };
}

export function updateHotCareersFail(error) {
  return {
    type: UPDATE_HOT_CAREERS_FAIL,
    error,
  };
}

export function sortHotCareers(items) {
  return {
    type: SORT_HOT_CAREERS,
    items,
  };
}

export function sortHotCareersSuccess(data) {
  return {
    type: SORT_HOT_CAREERS_SUCCESS,
    data,
  };
}

export function sortHotCareersFail(error) {
  return {
    type: SORT_HOT_CAREERS_FAIL,
    error,
  };
}
