/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/named */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Button, Modal, ModalFooter,
} from 'reactstrap';
import { toogleModalAdd } from '../../../redux/actions/modalAction';
import { addNewField, saveTemplate } from '../../../redux/actions/templateAction';
import CommonAttribute from './CommonAttribute';
import DetailsAttribute from './DetailsAttribute';
import {
  setDataField,
  validData,
  checkSetDataChange,
  defineItem,
  checkDelete,
} from '../../utils/modal';


class AddFieldModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msgError: '',
      isError: false,
      showNextView: false,
      dataField: {
        type: 'Set',
        level: 1,
        detail: {},
      },
      listItemSet: [],
      labelItemEnglish: '',
      labelItemVn: '',
      tooltipItemEnglish: '',
      tooltipItemVn: '',
      listItemLanguage: [],
      languageEnglish: '',
      languageVn: '',
      listeningLte: 0,
      listeningGte: 0,
      speakingLte: 0,
      speakingGte: 0,
      readingLte: 0,
      readingGte: 0,
      writingLte: 0,
      writingGte: 0,
      listItemUnit: [],
      unit: '',
      indexItemSelect: null,
    };
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    this.toogleModalAdd = this.toogleModalAdd.bind(this);
    this.nextView = this.nextView.bind(this);
    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.addTableItem = this.addTableItem.bind(this);
    this.deleteTableItem = this.deleteTableItem.bind(this);
    this.updateTableItem = this.updateTableItem.bind(this);
    this.getDataItem = this.getDataItem.bind(this);
    this.addNewField = this.addNewField.bind(this);
    this.htmlCommonAttribute = this.htmlCommonAttribute.bind(this);
    this.htmlDetailAttribute = this.htmlDetailAttribute.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleESC);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleESC);
  }

  handleESC = (e) => {
    if (e.keyCode === 27) this.toogleModalAdd();
  }

  toogleModalAdd = () => {
    const { dispatch } = this.props;
    const data = {
      isShow: false,
    };
    dispatch(toogleModalAdd(data));
    this.setState({
      showNextView: false,
      isError: false,
      msgError: '',
      dataField: {
        type: 'Set',
        level: 1,
      },
      listItemSet: [],
      labelItemEnglish: '',
      labelItemVn: '',
      tooltipItemEnglish: '',
      tooltipItemVn: '',
      listItemLanguage: [],
      languageEnglish: '',
      languageVn: '',
      listeningLte: '',
      listeningGte: '',
      speakingLte: '',
      speakingGte: '',
      readingLte: '',
      readingGte: '',
      writingLte: '',
      writingGte: '',
      listItemUnit: [],
      unit: '',
      indexItemSelect: null,
    });
  };

  nextView = () => {
    const { dataField } = this.state;

    if (!Object.prototype.hasOwnProperty.call(dataField, 'type') || dataField.type === '') {
      this.setState({
        isError: true,
        msgError: 'Please choose type field',
      });
      return;
    }

    if (!Object.prototype.hasOwnProperty.call(dataField, 'level') || dataField.level === '') {
      this.setState({
        isError: true,
        msgError: 'Please choose level field',
      });
      return;
    }

    this.setState({
      showNextView: true,
      isError: false,
      msgError: '',
    });
  };

  backView = () => {
    this.setState({
      showNextView: false,
      msgError: '',
    });
  }

  onDismissAlert = () => {
    this.setState({
      isError: false,
    });
  }

  handleChangeEvent = (event) => {
    const dataChange = {};
    const { dataField } = this.state;
    const targetName = event.target.name;
    const targetValue = event.target.value;
    const targetType = event.target.type;
    const isSetDataChange = checkSetDataChange(targetName);

    if (!isSetDataChange) {
      this.setState({ [targetName]: targetValue });
      return;
    }
    dataChange[targetName] = targetValue;
    if (targetType === 'checkbox') {
      dataChange[targetName] = event.target.checked;
    }

    if (dataChange.fieldType) {
      this.setState({
        listItemSet: [],
        listItemLanguage: [],
        listItemUnit: [],
      });
    }

    const dataSet = setDataField(dataChange, dataField);
    this.setState({
      dataField: dataSet,
    });
  };

  addNewField = () => {
    const { dispatch, modal, template } = this.props;
    let isError = false;
    const {
      dataField,
      listItemSet,
      listItemLanguage,
      listItemUnit,
    } = this.state;
    const dataNewField = validData(dataField, listItemSet, listItemLanguage, listItemUnit);
    if (dataNewField.type === 'Skill' && dataNewField.detail.isShowRate) {
      isError = this.validateFieldSkill(dataNewField);
    }
    if (!isError) {
      dispatch(addNewField(dataNewField, modal));
      dispatch(saveTemplate(template.data));
      this.toogleModalAdd();
    }
  };

  validateFieldSkill = (dataNewField) => {
    let isError = false;
    const { columns } = dataNewField.detail;
    const { rows } = columns[1];
    for (let index = 0; index < columns.length; index += 1) {
      const item = columns[index];
      if (item.header.en === '') {
        isError = true;
        break;
      } else if (item.header.vi === '') {
        isError = true;
        break;
      }
    }

    for (let index = 0; index < rows.length; index += 1) {
      const item = rows[index];
      if (item.en === '') {
        isError = true;
        break;
      } else if (item.vi === '') {
        isError = true;
        break;
      }
    }

    if (isError) {
      this.setState({
        msgError: 'Complete this field',
      });
    } else {
      this.setState({
        msgError: '',
      });
    }
    return isError;
  };

  getDataItem = (index) => {
    const { dataField } = this.state;
    const fieldType = dataField.type;
    if (fieldType === 'Set' || fieldType === 'Skill') {
      const { listItemSet } = this.state;
      const item = listItemSet[index];
      this.setState({
        labelItemEnglish: item.value.en,
        labelItemVn: item.value.vi,
        tooltipItemEnglish: item.tooltip.en,
        tooltipItemVn: item.tooltip.vi,
        indexItemSelect: index,
      });
    } else if (fieldType === 'Language') {
      const { listItemLanguage } = this.state;
      const item = listItemLanguage[index];
      this.setState({
        languageEnglish: item.label.en,
        languageVn: item.label.vi,
        listeningLte: item.listening.lte ? item.listening.lte : 0,
        listeningGte: item.listening.gte ? item.listening.gte : 0,
        speakingLte: item.speaking.lte ? item.speaking.lte : 0,
        speakingGte: item.speaking.gte ? item.speaking.gte : 0,
        readingLte: item.reading.lte ? item.reading.lte : 0,
        readingGte: item.reading.gte ? item.reading.gte : 0,
        writingLte: item.writing.lte ? item.writing.lte : 0,
        writingGte: item.writing.gte ? item.writing.gte : 0,
        indexItemSelect: index,
      });
    } else if (fieldType === 'Currency') {
      const { listItemUnit } = this.state;
      const item = listItemUnit[index];
      this.setState({
        unit: item,
        indexItemSelect: index,
      });
    }
  }

  addTableItem = () => {
    const { dataField } = this.state;
    const fieldType = dataField.type;
    const { listItemSet, listItemLanguage, listItemUnit } = this.state;
    const item = defineItem(this.state);
    if (Object.getOwnPropertyNames(item).length !== 0) {
      if (fieldType === 'Set') {
        listItemSet.push(item);
        this.resetItemSet();
      } else if (fieldType === 'Language') {
        listItemLanguage.push(item);
        this.resetItemLanguage();
      } else if (fieldType === 'Currency') {
        listItemUnit.push(item.unit);
        this.resetItemUnit();
      }
    }
  }

  updateTableItem = () => {
    const { dataField, indexItemSelect } = this.state;
    const fieldType = dataField.type;
    const { listItemSet, listItemLanguage, listItemUnit } = this.state;
    const item = defineItem(this.state);
    if (Object.getOwnPropertyNames(item).length !== 0) {
      if (indexItemSelect !== null) {
        if (fieldType === 'Set') {
          listItemSet[indexItemSelect] = item;
          this.resetItemSet();
        } else if (fieldType === 'Language') {
          listItemLanguage[indexItemSelect] = item;
          this.resetItemLanguage();
        } else if (fieldType === 'Currency') {
          listItemUnit[indexItemSelect] = item.unit;
          this.resetItemUnit();
        }
        this.resetIndexItemSelect();
      }
    }
  }

  deleteTableItem = () => {
    const {
      dataField,
      indexItemSelect,
      listItemSet,
      listItemLanguage,
      listItemUnit,
    } = this.state;
    const fieldType = dataField.type;
    const canDelete = checkDelete(this.state);
    if (canDelete) {
      if (fieldType === 'Set' || fieldType === 'Skill') {
        listItemSet.splice(indexItemSelect, 1);
        this.resetItemSet();
      } else if (fieldType === 'Language') {
        listItemLanguage.splice(indexItemSelect, 1);
        this.resetItemLanguage();
      } else if (fieldType === 'Currency') {
        listItemUnit.splice(indexItemSelect, 1);
        this.resetItemUnit();
      }
      this.resetIndexItemSelect();
    }
  }

  resetItemSet = () => {
    this.setState({
      labelItemEnglish: '',
      labelItemVn: '',
      tooltipItemEnglish: '',
      tooltipItemVn: '',
    });
  }

  resetItemLanguage = () => {
    this.setState({
      languageEnglish: '',
      languageVn: '',
      listeningLte: '',
      listeningGte: '',
      speakingLte: '',
      speakingGte: '',
      readingLte: '',
      readingGte: '',
      writingLte: '',
      writingGte: '',
    });
  }

  resetItemUnit = () => {
    this.setState({
      unit: '',
    });
  }

  resetIndexItemSelect = () => {
    this.setState({
      indexItemSelect: null,
    });
  }

  htmlCommonAttribute = (modal) => {
    const { msgError, isError, dataField } = this.state;
    return (
      <div>
        <CommonAttribute isError={isError} msgError={msgError} dataField={dataField} modal={modal} htmlButton={this.htmlButton()} onDismissAlert={this.onDismissAlert} handleChangeEvent={this.handleChangeEvent} />
      </div>
    );
  }

  htmlDetailAttribute =(modal) => {
    const { msgError } = this.state;
    const htmlModal = (
      <div>
        <DetailsAttribute state={this.state} modal={modal} msgError={msgError} getDataItem={this.getDataItem} addTableItem={this.addTableItem} handleChangeEvent={this.handleChangeEvent} deleteTableItem={this.deleteTableItem} updateTableItem={this.updateTableItem} />
        <ModalFooter>
          <Button outline color="danger" size="sm" onClick={() => this.toogleModalAdd()}>Cancel</Button>
          <Button outline color="primary" size="sm" onClick={() => this.backView()}>Back</Button>
          <Button outline color="primary" size="sm" onClick={() => this.addNewField()}>Add</Button>
        </ModalFooter>
      </div>
    );

    return htmlModal;
  }

  htmlButton() {
    const { dataField } = this.state;
    let html;
    const typeField = dataField.type;
    const isGroup = typeField === 'Group';
    if (!isGroup) {
      html = (
        <div>
          <Button outline color="danger" size="sm" onClick={() => this.toogleModalAdd()}>Cancel</Button>
          <Button outline color="primary" size="sm" onClick={() => this.nextView()}>Next</Button>
        </div>
      );
    } else {
      html = (
        <div>
          <Button outline color="danger" size="sm" onClick={() => this.toogleModalAdd()}>Cancel</Button>
          <Button outline color="primary" size="sm" onClick={() => this.addNewField()}>Add</Button>
        </div>
      );
    }

    return html;
  }

  render() {
    const { modal } = this.props;
    const { showNextView } = this.state;
    return (
      <div>
        <Modal isOpen={modal.isShow} toogle={this.toogleModalAdd} size="lg">
          { !showNextView ? this.htmlCommonAttribute(modal) : this.htmlDetailAttribute(modal) }
        </Modal>
      </div>
    );
  }
}

AddFieldModal.propTypes = {
  modal: PropTypes.objectOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  template: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  const { modal, template } = state;
  return {
    modal,
    template,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withTranslation('common')(AddFieldModal));
