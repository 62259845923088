/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Spinner,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Section from '../../../../shared/components/common/SectionComponent';
import SectionGroup from '../../../../shared/components/common/SectionGroupComponent';
import AddFieldModal from '../../../../shared/components/modal/AddFieldModal';
import EditFieldModal from '../../../../shared/components/modal/EditFiledModal';
import DeleteFieldModal from '../../../../shared/components/modal/DeleteFieldModal';
import SaveFieldModal from '../../../../shared/components/modal/SaveFieldModal';
import { toogleModalSave } from '../../../../redux/actions/modalAction';
import { loadTemplate } from '../../../../redux/actions/templateAction';

class BoxedCollapseFullWidth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
    };
    this.renderHtmlModal = this.renderHtmlModal.bind(this);
    this.toogleModalSave = this.toogleModalSave.bind(this);
    this.cancelView = this.cancelView.bind(this);
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadTemplate());
  }


  toogleModalSave = (dataSave) => {
    const { dispatch } = this.props;
    const data = {
      isShow: true,
      isSave: true,
      dataSave,
    };
    dispatch(toogleModalSave(data));
  }

  cancelView = () => {
    const { dispatch } = this.props;
    dispatch(loadTemplate());
  }

  renderHtmlModal = () => {
    const { modal } = this.props;
    let html;
    if (modal.isEdit) {
      html = <EditFieldModal />;
    } else if (modal.isDelete || modal.isClone) {
      html = <DeleteFieldModal />;
    } else if (modal.isSave) {
      html = <SaveFieldModal />;
    } else {
      html = <AddFieldModal />;
    }
    return html;
  }

  render() {
    const { template } = this.props;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <Section title="GENERAL INFO" className="with-shadow" typeSection="informationSection" />
            <Section title="EXPECTATION" className="with-shadow" typeSection="offerabilitySection" />
            <SectionGroup title="YOUR ABILITIES" className="with-shadow" />
            <Section title="SITUATION QUESTIONS" className="with-shadow" typeSection="situationQuestionSection" />
            <ButtonToolbar className="form__button-toolbar">
              <Button outline color="danger" size="sm" onClick={() => this.cancelView()}>Cancel</Button>
              <Button color="primary" type="submit" size="sm" onClick={() => this.toogleModalSave(template.data)}>Save</Button>{template.isSaving && <Spinner style={{ marginTop: '10px' }} color="primary" />}
            </ButtonToolbar>
          </CardBody>
        </Card>
        { this.renderHtmlModal() }
      </Col>
    );
  }
}

BoxedCollapseFullWidth.propTypes = {
  modal: PropTypes.objectOf(PropTypes.object).isRequired,
  template: PropTypes.objectOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { modal, template } = state;
  return {
    modal, template,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withTranslation('common')(BoxedCollapseFullWidth));
