/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
import {
  GET_FAQ_CATEGORIES,
  GET_FAQ_CATEGORIES_SUCCESS,
  GET_FAQ_CATEGORIES_FAIL,
  CREATE_FAQ_CATEGORY,
  CREATE_FAQ_CATEGORY_SUCCESS,
  CREATE_FAQ_CATEGORY_FAIL,
  UPDATE_FAQ_CATEGORY,
  UPDATE_FAQ_CATEGORY_SUCCESS,
  UPDATE_FAQ_CATEGORY_FAIL,
  DELETE_FAQ_CATEGORY,
  DELETE_FAQ_CATEGORY_SUCCESS,
  DELETE_FAQ_CATEGORY_FAIL,
  GET_FAQ_CATEGORY_BY_ID,
  GET_FAQ_CATEGORY_BY_ID_SUCCESS,
  GET_FAQ_CATEGORY_BY_ID_FAIL,
  RESET_MESSAGE,
} from '../actions/faqCategoryAction';

import {
  MSG_UPDATE_CATEGORY_SUCCESS,
  MSG_UPDATE_CATEGORY_FAIL,
  MSG_CREATE_CATEGORY_SUCCESS,
  MSG_CREATE_CATEGORY_FAIL,
  MSG_DELETE_CATEGORY_SUCCESS,
  MSG_DELETE_CATEGORY_FAIL,
} from '../../shared/utils/constants';

const initialState = {
  categoryList: [],
  message: '',
  isUpdate: false,
  isError: false,
  data: null,
  statusCode: '',
  categoryEdit: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FAQ_CATEGORIES: {
      return { ...state, type: action.type };
    }
    case GET_FAQ_CATEGORIES_SUCCESS: {
      return { ...state, ...{ type: action.type, categoryList: action.data } };
    }
    case GET_FAQ_CATEGORIES_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case GET_FAQ_CATEGORY_BY_ID: {
      return { ...state, type: action.type };
    }
    case GET_FAQ_CATEGORY_BY_ID_SUCCESS: {
      return { ...state, ...{ type: action.type, categoryEdit: action.data } };
    }
    case GET_FAQ_CATEGORY_BY_ID_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case CREATE_FAQ_CATEGORY: {
      return { ...state, type: action.type };
    }
    case CREATE_FAQ_CATEGORY_SUCCESS: {
      return {
        ...state,
        type: action.type,
        isError: false,
        message: MSG_CREATE_CATEGORY_SUCCESS,
      };
    }
    case CREATE_FAQ_CATEGORY_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: MSG_CREATE_CATEGORY_FAIL,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case UPDATE_FAQ_CATEGORY: {
      return { ...state, type: action.type };
    }
    case UPDATE_FAQ_CATEGORY_SUCCESS: {
      return {
        ...state,
        type: action.type,
        isError: false,
        message: MSG_UPDATE_CATEGORY_SUCCESS,
        categoryEdit: action.data,
      };
    }
    case UPDATE_FAQ_CATEGORY_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: MSG_UPDATE_CATEGORY_FAIL,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case DELETE_FAQ_CATEGORY: {
      return { ...state, type: action.type };
    }
    case DELETE_FAQ_CATEGORY_SUCCESS: {
      return {
        ...state,
        type: action.type,
        isError: false,
        message: MSG_DELETE_CATEGORY_SUCCESS,
        categoryList: state.categoryList.filter(item => item.id !== action.data.id),
      };
    }
    case DELETE_FAQ_CATEGORY_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: MSG_DELETE_CATEGORY_FAIL,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case RESET_MESSAGE: {
      return {
        ...state,
        message: '',
      };
    }
    default:
      return state;
  }
}
