import * as yup from 'yup';
import FIELD_REQUIRED from '../../../../../shared/utils/messageError';

const schemaPostForm = yup.object().shape({
  englishTitle: yup.string().required(FIELD_REQUIRED),
  vietnameseTitle: yup.string().required(FIELD_REQUIRED),
  url: yup.string().required(FIELD_REQUIRED),
  categoryId: yup.string().required(FIELD_REQUIRED),
  featureImage: yup.string().required(FIELD_REQUIRED),
  tag: yup.array().required(FIELD_REQUIRED),
});
export default schemaPostForm;
