/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { Row, Col } from 'reactstrap';
import { Input } from '../../../../components';
import { schameFormExpertise } from '../validation';
import validateForm from '../../../../shared/utils/validation';

import './FormExpertise.scss';

const FormExpertise = ({
  isReadOnly,
  idFunction,
  data,
  setFormValuesProps,
  createExpertise,
  updateExpertise,
  setReadOnly,
}) => {
  const [formValues, setFormValues] = useState({});
  const [err, setErr] = useState({});

  useEffect(() => {
    setFormValues(data.id ? { ...data } : {});
    setErr({});
  }, [data]);

  const hanldeCancelForm = () => {
    setFormValuesProps({});
    setErr({});
    setReadOnly(false);
  };

  const getValue = (obj, path) => get(obj, path, '');

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newData = cloneDeep(formValues);
    const newFormValues = set(newData, name, value);
    setFormValues(state => ({ ...state, ...newFormValues }));
  };

  const handleSubmitForm = () => {
    setErr({});
    const temp = {
      'label.en': getValue(formValues, 'label.en'),
      'label.vi': getValue(formValues, 'label.vi'),
      'tooltip.en': getValue(formValues, 'tooltip.en'),
      'tooltip.vi': getValue(formValues, 'tooltip.vi'),
      'titleDescription.en': getValue(formValues, 'titleDescription.en'),
      'titleDescription.vi': getValue(formValues, 'titleDescription.vi'),
      'columns[0].header.en': getValue(formValues, 'columns[0].header.en'),
      'columns[0].header.vi': getValue(formValues, 'columns[0].header.vi'),
      'columns[0].headerTooltip.en': getValue(formValues, 'columns[0].headerTooltip.en'),
      'columns[0].headerTooltip.vi': getValue(formValues, 'columns[0].headerTooltip.vi'),
      'columns[0].rows[0].en': getValue(formValues, 'columns[0].rows[0].en'),
      'columns[0].rows[0].vi': getValue(formValues, 'columns[0].rows[0].vi'),
      'columns[0].rows[1].en': getValue(formValues, 'columns[0].rows[1].en'),
      'columns[0].rows[1].vi': getValue(formValues, 'columns[0].rows[1].vi'),
      'columns[0].rows[2].en': getValue(formValues, 'columns[0].rows[2].en'),
      'columns[0].rows[2].vi': getValue(formValues, 'columns[0].rows[2].vi'),
      'columns[0].rows[3].en': getValue(formValues, 'columns[0].rows[3].en'),
      'columns[0].rows[3].vi': getValue(formValues, 'columns[0].rows[3].vi'),
      'columns[0].rows[4].en': getValue(formValues, 'columns[0].rows[4].en'),
      'columns[0].rows[4].vi': getValue(formValues, 'columns[0].rows[4].vi'),
      'columns[1].header.en': getValue(formValues, 'columns[1].header.en'),
      'columns[1].header.vi': getValue(formValues, 'columns[1].header.vi'),
      'columns[1].headerTooltip.en': getValue(formValues, 'columns[0].headerTooltip.en'),
      'columns[1].headerTooltip.vi': getValue(formValues, 'columns[0].headerTooltip.vi'),
      'columns[1].rows[0].en': getValue(formValues, 'columns[1].rows[0].en'),
      'columns[1].rows[0].vi': getValue(formValues, 'columns[1].rows[0].vi'),
      'columns[1].rows[1].en': getValue(formValues, 'columns[1].rows[1].en'),
      'columns[1].rows[1].vi': getValue(formValues, 'columns[1].rows[1].vi'),
      'columns[1].rows[2].en': getValue(formValues, 'columns[1].rows[2].en'),
      'columns[1].rows[2].vi': getValue(formValues, 'columns[1].rows[2].vi'),
      'columns[1].rows[3].en': getValue(formValues, 'columns[1].rows[3].en'),
      'columns[1].rows[3].vi': getValue(formValues, 'columns[1].rows[3].vi'),
      'columns[1].rows[4].en': getValue(formValues, 'columns[1].rows[4].en'),
      'columns[1].rows[4].vi': getValue(formValues, 'columns[1].rows[4].vi'),
    };
    const { isError, error } = validateForm(schameFormExpertise, temp);
    if (isError) {
      setErr(error);
      return undefined;
    }

    if (!Object.prototype.hasOwnProperty.call(formValues.columns[0], 'rows')) {
      formValues.columns[0].rows = [{
        en: '',
        vi: '',
      }];
    }

    if (formValues.id) {
      return updateExpertise(formValues.id, {
        ...formValues,
      });
    }

    return createExpertise({
      ...formValues,
      functionId: idFunction,
    });
  };

  return (
    <div className="form-expertise">
      <span className="form-expertise__legend">{`${formValues.id ? 'Edit' : 'Add'}`} Expertise</span>
      <Row className="form-expertise__item">
        <Col md="2">
          <div className="form-expertise__label">Name</div>
        </Col>
        <Col md="5">
          <Input
            type="text"
            className="md-mb-0"
            name="label.en"
            placeholder="English"
            value={getValue(formValues, 'label.en')}
            readOnly={isReadOnly}
            onChange={handleChange}
            errorMesssage={getValue(err, 'label.en')}
          />
        </Col>
        <Col md="5">
          <Input
            type="text"
            className="md-mb-0"
            name="label.vi"
            placeholder="Vietnamese"
            value={getValue(formValues, 'label.vi')}
            readOnly={isReadOnly}
            onChange={handleChange}
            errorMesssage={getValue(err, 'label.vi')}
          />
        </Col>
      </Row>
      <Row className="form-expertise__item">
        <Col md="2">
          <div className="form-expertise__label">Tooltip</div>
        </Col>
        <Col md="5">
          <Input
            type="text"
            className="md-mb-0"
            name="tooltip.en"
            placeholder="English"
            value={getValue(formValues, 'tooltip.en')}
            readOnly={isReadOnly}
            onChange={handleChange}
            errorMesssage={getValue(err, 'tooltip.en')}
          />
        </Col>
        <Col md="5">
          <Input
            type="text"
            className="md-mb-0"
            name="tooltip.vi"
            placeholder="Vietnamese"
            value={getValue(formValues, 'tooltip.vi')}
            readOnly={isReadOnly}
            onChange={handleChange}
            errorMesssage={getValue(err, 'tooltip.vi')}
          />
        </Col>
      </Row>
      <Row className="form-expertise__item">
        <Col md="2">
          <div className="form-expertise__label">Title Descripttion</div>
        </Col>
        <Col md="5">
          <Input
            type="text"
            className="md-mb-0"
            name="titleDescription.en"
            placeholder="English"
            value={getValue(formValues, 'titleDescription.en')}
            readOnly={isReadOnly}
            onChange={handleChange}
            errorMesssage={getValue(err, 'titleDescription.en')}
          />
        </Col>
        <Col md="5">
          <Input
            type="text"
            className="md-mb-0"
            name="titleDescription.vi"
            placeholder="Vietnamese"
            value={getValue(formValues, 'titleDescription.vi')}
            readOnly={isReadOnly}
            onChange={handleChange}
            errorMesssage={getValue(err, 'titleDescription.vi')}
          />
        </Col>
      </Row>
      <Row className="form-expertise__item">
        <Col sm="12" md="6">
          <div className="column-form">
            <div className="form-expertise__title">Column1</div>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Header</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[0].header.en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[0].header.en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].header.en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  name="columns[0].header.vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[0].header.vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].header.vi')}
                />
              </Col>
            </Row>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Header Tooltip</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[0].headerTooltip.en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[0].headerTooltip.en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].headerTooltip.en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  name="columns[0].headerTooltip.vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[0].headerTooltip.vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].headerTooltip.vi')}
                />
              </Col>
            </Row>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Row 1</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[0].rows[0].en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[0].rows[0].en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].rows[0].en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[0].rows[0].vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[0].rows[0].vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].rows[0].vi')}
                />
              </Col>
            </Row>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Row 2</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[0].rows[1].en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[0].rows[1].en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].rows[1].en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[0].rows[1].vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[0].rows[1].vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].rows[1].vi')}
                />
              </Col>
            </Row>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Row 3</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[0].rows[2].en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[0].rows[2].en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].rows[2].en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[0].rows[2].vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[0].rows[2].vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].rows[2].vi')}
                />
              </Col>
            </Row>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Row 4</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[0].rows[3].en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[0].rows[3].en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].rows[3].en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[0].rows[3].vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[0].rows[3].vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].rows[3].vi')}
                />
              </Col>
            </Row>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Row 5</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[0].rows[4].en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[0].rows[4].en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].rows[4].en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[0].rows[4].vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[0].rows[4].vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[0].rows[4].vi')}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12" md="6">
          <div className="column-form">
            <div className="form-expertise__title">Column 2</div>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Header</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].header.en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[1].header.en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].header.en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].header.vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[1].header.vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].header.en')}
                />
              </Col>
            </Row>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Header Tooltip</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].headerTooltip.en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[1].headerTooltip.en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].headerTooltip.en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  name="columns[1].headerTooltip.vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[1].headerTooltip.vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].headerTooltip.vi')}
                />
              </Col>
            </Row>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Row 1</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].rows[0].en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[1].rows[0].en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].rows[0].en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].rows[0].vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[1].rows[0].vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].rows[0].vi')}
                />
              </Col>
            </Row>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Row 2</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].rows[1].en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[1].rows[1].en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].rows[1].en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].rows[1].vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[1].rows[1].vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].rows[1].vi')}
                />
              </Col>
            </Row>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Row 3</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].rows[2].en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[1].rows[2].en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].rows[2].en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].rows[2].vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[1].rows[2].vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].rows[2].vi')}
                />
              </Col>
            </Row>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Row 4</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].rows[3].en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[1].rows[3].en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].rows[3].en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].rows[3].vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[1].rows[3].vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].rows[3].vi')}
                />
              </Col>
            </Row>
            <Row className="form-expertise__item">
              <Col sm="12" lg="2">
                <div className="form-expertise__label">Row 5</div>
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].rows[4].en"
                  placeholder="English"
                  value={getValue(formValues, 'columns[1].rows[4].en')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].rows[4].en')}
                />
              </Col>
              <Col sm="12" lg="5">
                <Input
                  type="text"
                  name="columns[1].rows[4].vi"
                  placeholder="Vietnamese"
                  value={getValue(formValues, 'columns[1].rows[4].vi')}
                  readOnly={isReadOnly}
                  onChange={handleChange}
                  errorMesssage={getValue(err, 'columns[1].rows[4].vi')}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="form-expertise__actions form-expertise__item">
        <button
          type="button"
          className="card-form__btn card-form__btn--submit"
          onClick={handleSubmitForm}
          disabled={isReadOnly}
        >
          {formValues.id ? 'Save' : 'Add'}
        </button>
        <button type="button" className="card-form__btn card-form__btn--cancel" onClick={hanldeCancelForm}>
          Cancel
        </button>
      </div>
    </div>
  );
};

FormExpertise.propTypes = {
  idFunction: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,

  setFormValuesProps: PropTypes.func.isRequired,
  updateExpertise: PropTypes.func.isRequired,
  createExpertise: PropTypes.func.isRequired,
  setReadOnly: PropTypes.func.isRequired,
};

FormExpertise.defaultProps = {};

export default FormExpertise;
