import * as yup from 'yup';
import FIELD_REQUIRED from '../../../../shared/utils/messageError';

export const schemaRecruiterForm = yup.object().shape({
  fullName: yup.string().required(FIELD_REQUIRED),
  contact: yup.string().required(FIELD_REQUIRED),
  email: yup.string().email().required(FIELD_REQUIRED),
  rcEmail: yup.string().email().required(FIELD_REQUIRED),
  rcSendEmailUrl: yup.string().url().required(FIELD_REQUIRED),
  website: yup.string().required().url(),
  phone: yup.string().required(FIELD_REQUIRED).matches(/^$|^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, { message: 'phone must be a valid phone' }),
});

export const schemaUpdateRecruiterForm = yup.object().shape({
  rcEmail: yup.string().email().required(FIELD_REQUIRED),
  rcSendEmailUrl: yup.string().url().required(FIELD_REQUIRED),
});