import api from '../index';
import ApiConstants from '../ApiConstants';

export const getEmailTemplates = () => api(
  `${ApiConstants.EMAILTEMPLATE}`,
  null,
  'get',
  null,
);

export const getEmailTemplatesSystem = () => api(
  `${ApiConstants.EMAILTEMPLATESYSTEM}`,
  null,
  'get',
  null,
);

export const createEmailTemplate = emailTemplate => api(
  `${ApiConstants.EMAILTEMPLATE}`,
  emailTemplate,
  'post',
  null,
);

export const createEmailTemplateSystem = emailTemplate => api(
  `${ApiConstants.EMAILTEMPLATESYSTEM}`,
  emailTemplate,
  'post',
  null,
);

export const updateEmailTemplate = args => api(
  `${ApiConstants.EMAILTEMPLATE}/${args[0]}`,
  args[1],
  'PATCH',
  null,
);

export const updateEmailTemplateSystem = args => api(
  `${ApiConstants.EMAILTEMPLATESYSTEM}/${args[0]}`,
  args[1],
  'PATCH',
  null,
);

export const deleteEmailTemplateSystem = id => api(
  `${ApiConstants.EMAILTEMPLATESYSTEM}/${id}`,
  null,
  'DELETE',
);