/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, FormGroup, Label } from 'reactstrap';
import './styleField.scss';

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (e) => {
    const { onChange } = this.props;
    onChange([e.target.name], e.target.value);
  }

  render() {
    const { formField, isEdit, error } = this.props;
    const inputClass = isEdit ? '' : 'input-disable';
    const isDisable = isEdit ? '' : 'disabled';
    return (
      <>
        <FormGroup row>
          <Col sm={6}>
            <Label for="adminEmail">Admin Email</Label>
            <Input type="textarea" className={inputClass} id="adminEmail" name="adminEmail" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.adminEmail} />
            <div className={`${error.adminEmail ? 'show-err' : ''} error-message`}>{error.adminEmail}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="enCompanyName">Company Name (EN)</Label>
            <Input type="textarea" className={inputClass} id="enCompanyName" name="enCompanyName" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.enCompanyName} />
            <div className={`${error.enCompanyName ? 'show-err' : ''} error-message`}>{error.enCompanyName}</div>
          </Col>
          <Col sm={6}>
            <Label for="viCompanyName">Company Name (VI)</Label>
            <Input type="textarea" className={inputClass} id="viCompanyName" name="viCompanyName" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.viCompanyName} />
            <div className={`${error.viCompanyName ? 'show-err' : ''} error-message`}>{error.viCompanyName}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="companyEmail">Company Email</Label>
            <Input type="text" className={inputClass} id="companyEmail" name="companyEmail" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.companyEmail} />
            <div className={`${error.enLabel ? 'show-err' : ''} error-message`}>{error.enLabel}</div>
          </Col>
          <Col sm={6}>
            <Label for="companyPhone">Company Phone</Label>
            <Input type="text" className={inputClass} id="companyPhone" name="companyPhone" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.companyPhone} />
            <div className={`${error.viLabel ? 'show-err' : ''} error-message`}>{error.viLabel}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="enCompanyAddress1">Address Line 1 (EN)</Label>
            <Input type="textarea" className={inputClass} id="enCompanyAddress1" name="enCompanyAddress1" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.enCompanyAddress1} />
            <div className={`${error.enCompanyAddress1 ? 'show-err' : ''} error-message`}>{error.enCompanyAddress1}</div>
          </Col>
          <Col sm={6}>
            <Label for="viCompanyAddress1">Address Line 1 (VI)</Label>
            <Input type="textarea" className={inputClass} id="viCompanyAddress1" name="viCompanyAddress1" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.viCompanyAddress1} />
            <div className={`${error.viCompanyAddress1 ? 'show-err' : ''} error-message`}>{error.viCompanyAddress1}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="enCompanyAddress2">Address Line2 (EN)</Label>
            <Input type="textarea" className={inputClass} id="enCompanyAddress2" name="enCompanyAddress2" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.enCompanyAddress2} />
            <div className={`${error.enCompanyAddress2 ? 'show-err' : ''} error-message`}>{error.enCompanyAddress2}</div>
          </Col>
          <Col sm={6}>
            <Label for="viCompanyAddress2">Address Line 2 (VI)</Label>
            <Input type="textarea" className={inputClass} id="viCompanyAddress2" name="viCompanyAddress2" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.viCompanyAddress2} />
            <div className={`${error.viCompanyAddress2 ? 'show-err' : ''} error-message`}>{error.viCompanyAddress2}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="enContactDesc">Contact Description(EN)</Label>
            <Input type="textarea" className={inputClass} id="enContactDesc" name="enContactDesc" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.enContactDesc} />
            <div className={`${error.enContactDesc ? 'show-err' : ''} error-message`}>{error.enContactDesc}</div>
          </Col>
          <Col sm={6}>
            <Label for="viContactDesc">Contact Description(VI)</Label>
            <Input type="textarea" className={inputClass} id="viContactDesc" name="viContactDesc" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.viContactDesc} />
            <div className={`${error.viContactDesc ? 'show-err' : ''} error-message`}>{error.viContactDesc}</div>
          </Col>
        </FormGroup>
      </>
    );
  }
}

ContactUs.propTypes = {
  formField: PropTypes.instanceOf(Object).isRequired,
  isEdit: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ContactUs;
