/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, FormGroup, Label } from 'reactstrap';
import './styleField.scss';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (e) => {
    const { onChange } = this.props;
    onChange([e.target.name], e.target.value);
  }

  render() {
    const { formField, isEdit, error } = this.props;
    const inputClass = isEdit ? '' : 'input-disable';
    const isDisable = isEdit ? '' : 'disabled';
    return (
      <>
        <FormGroup row>
          <Col sm={6}>
            <Label for="enNewsDesc">News Description (EN)</Label>
            <Input type="textarea" className={inputClass} id="enNewsDesc" name="enNewsDesc" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.enNewsDesc} />
            <div className={`${error.enNewsDesc ? 'show-err' : ''} error-message`}>{error.enNewsDesc}</div>
          </Col>
          <Col sm={6}>
            <Label for="viNewsDesc">News Description (VN)</Label>
            <Input type="textarea" className={inputClass} id="viNewsDesc" name="viNewsDesc" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.viNewsDesc} />
            <div className={`${error.viNewsDesc ? 'show-err' : ''} error-message`}>{error.viNewsDesc}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="enUseful">Useful Resources Description (EN)</Label>
            <Input type="textarea" className={inputClass} id="enUseful" name="enUseful" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.enUseful} />
            <div className={`${error.enUseful ? 'show-err' : ''} error-message`}>{error.enUseful}</div>
          </Col>
          <Col sm={6}>
            <Label for="viUseful">Useful Resources Description (VI)</Label>
            <Input type="textarea" className={inputClass} id="viUseful" name="viUseful" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.viUseful} />
            <div className={`${error.viUseful ? 'show-err' : ''} error-message`}>{error.viUseful}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="enFAQ">FAQ Description (EN)</Label>
            <Input type="textarea" className={inputClass} id="enFAQ" name="enFAQ" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.enFAQ} />
            <div className={`${error.enFAQ ? 'show-err' : ''} error-message`}>{error.enFAQ}</div>
          </Col>
          <Col sm={6}>
            <Label for="viFAQ">FAQ Description (VI)</Label>
            <Input type="textarea" className={inputClass} id="viFAQ" name="viFAQ" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.viFAQ} />
            <div className={`${error.viFAQ ? 'show-err' : ''} error-message`}>{error.viFAQ}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="enHotCareer">Hot Career Description (EN)</Label>
            <Input type="textarea" className={inputClass} id="enHotCareer" name="enHotCareer" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.enHotCareer} />
            <div className={`${error.enHotCareer ? 'show-err' : ''} error-message`}>{error.enHotCareer}</div>
          </Col>
          <Col sm={6}>
            <Label for="viHotCareer">Hot Career Description (VI)</Label>
            <Input type="textarea" className={inputClass} id="viHotCareer" name="viHotCareer" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.viHotCareer} />
            <div className={`${error.viHotCareer ? 'show-err' : ''} error-message`}>{error.viHotCareer}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="enHotIndustry">Hot Industry Description (EN)</Label>
            <Input type="textarea" className={inputClass} id="enHotIndustry" name="enHotIndustry" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.enHotIndustry} />
            <div className={`${error.enHotIndustry ? 'show-err' : ''} error-message`}>{error.enHotIndustry}</div>
          </Col>
          <Col sm={6}>
            <Label for="viHotIndustry">Hot Industry Description (VI)</Label>
            <Input type="textarea" className={inputClass} id="viHotIndustry" name="viHotIndustry" onChange={e => this.handleChange(e)} disabled={isDisable} value={formField.viHotIndustry} />
            <div className={`${error.viHotIndustry ? 'show-err' : ''} error-message`}>{error.viHotIndustry}</div>
          </Col>
        </FormGroup>
      </>
    );
  }
}

Home.propTypes = {
  formField: PropTypes.instanceOf(Object).isRequired,
  isEdit: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Home;
