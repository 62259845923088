import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../components/Table/index';

class JobList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { data } = this.props;
    return (
      <Table
        data={data}
        columns={[
          {
            Header: 'Code',
            accessor: 'jobCode',
            sortable: true,
          },
          {
            Header: 'Job',
            accessor: 'name',
            sortable: true,
          },
          {
            Header: 'Department (EN)',
            accessor: 'department.en',
            sortable: true,
          },
          {
            Header: 'Department (VI)',
            accessor: 'department.vi',
            sortable: true,
          },
          {
            Header: 'Position (EN)',
            accessor: 'position.en',
            sortable: true,
          },
          {
            Header: 'Position (VI)',
            accessor: 'position.vi',
            sortable: true,
          },
          {
            Header: 'Date',
            accessor: 'expiredDate',
            sortable: true,
          },
          {
            Header: 'Status',
            accessor: 'status',
            sortable: true,
          },
        ]}
        defaultPageSize={5}
        className="custom-table"
        showPaginationBottom
        sortable={false}
      />
    );
  }
}
JobList.propTypes = {
  data: PropTypes.array.isRequired,
};
export default JobList;
