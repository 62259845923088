/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_RECRUITERS,
  GET_RECRUITERS_SUCCESS,
  GET_RECRUITERS_FAIL,
  UPDATE_RECRUITER,
  UPDATE_RECRUITER_SUCCESS,
  UPDATE_RECRUITER_FAIL,
  GET_JOBS_BY_RECRUITER_ID,
  GET_JOBS_BY_RECRUITER_ID_SUCCESS,
  GET_JOBS_BY_RECRUITER_ID_FAIL,
  CREATE_RECRUITER,
  CREATE_RECRUITER_SUCCESS,
  CREATE_RECRUITER_FAIL,
  DELETE_RECRUITER,
  DELETE_RECRUITER_SUCCESS,
  DELETE_RECRUITER_FAIL,
} from '../actions/recruiterAction';
import {
  updateRecruiterFromArrayById,
  deleteElementFromArrayByUnderscoreId,
} from '../../shared/utils/calculation';

const initialState = {
  recruiterList: [],
  message: '',
  isUpdate: false,
  isError: false,
  data: null,
  statusCode: '',
  recruiterEdit: null,
  jobs: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RECRUITERS: {
      return { ...state, type: action.type };
    }
    case GET_RECRUITERS_SUCCESS: {
      return { ...state, ...{ type: action.type, recruiterList: action.data } };
    }
    case GET_RECRUITERS_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case CREATE_RECRUITER: {
      return { ...state, type: action.type };
    }
    case CREATE_RECRUITER_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        ...{
          type: action.type,
          isError: false,
          message: 'Create Recruiter Successful!',
          recruiterList: [
            {...data, _id: data.id, jobs: 0, status: (data.isDeleted ? 'Inactive' : 'Active')}, 
            ...state.recruiterList],
        },
      };
    }
    case CREATE_RECRUITER_FAIL: {
      return {
        ...state,
        ...{
          type: action.type,
          isError: true,
          message: action.error && action.error.message ? action.error.message : 'Create Recruiter Fail!',
          statusCode: action.data ? action.data.statusCode.toString() : '',
        },
      };
    }
    case UPDATE_RECRUITER: {
      return { ...state, type: action.type };
    }
    case UPDATE_RECRUITER_SUCCESS: {
      return {
        ...state,
        ...{
          type: action.type,
          isError: false,
          message: 'Update Recruiter Successful!',
          recruiterList: updateRecruiterFromArrayById(state.recruiterList, action.data),
        },
      };
    }
    case UPDATE_RECRUITER_FAIL: {
      return {
        ...state,
        ...{
          type: action.type,
          isError: true,
          message: 'Update Recruiter Fail!',
          statusCode: action.data ? action.data.statusCode.toString() : '',
        },
      };
    }
    case DELETE_RECRUITER: {
      return { ...state, type: action.type, message: '' };
    }
    case DELETE_RECRUITER_SUCCESS: {
      return {
        ...state,
        message: 'Successful!',
        ...{
          type: action.type,
          isError: false,
          recruiterList: deleteElementFromArrayByUnderscoreId(state.recruiterList, action.data.id),
        },
      };
    }
    case DELETE_RECRUITER_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: 'Fail!',
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case GET_JOBS_BY_RECRUITER_ID: {
      return { ...state, type: action.type };
    }
    case GET_JOBS_BY_RECRUITER_ID_SUCCESS: {
      return { ...state, ...{ type: action.type, jobs: action.data } };
    }
    case GET_JOBS_BY_RECRUITER_ID_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    default:
      return state;
  }
}
