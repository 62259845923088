import * as yup from 'yup';
import FIELD_REQUIRED from '../../../../shared/utils/messageError';

const schemaHotIndustriesForm = yup.object().shape({
  icon: yup.string().required(FIELD_REQUIRED),
  idIndustry: yup.string().required(FIELD_REQUIRED),
  enLabel: yup.string().required(FIELD_REQUIRED),
  viLabel: yup.string().required(FIELD_REQUIRED),

});
export default schemaHotIndustriesForm;
