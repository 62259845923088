import { take, call, put } from 'redux-saga/effects';
import {
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATES_FAIL,
  UPDATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAIL,
  CREATE_EMAIL_TEMPLATE,
  CREATE_EMAIL_TEMPLATE_SUCCESS,
  CREATE_EMAIL_TEMPLATE_FAIL,
  GET_EMAIL_TEMPLATES_SYSTEM,
  GET_EMAIL_TEMPLATES_SUCCESS_SYSTEM,
  GET_EMAIL_TEMPLATES_FAIL_SYSTEM,
  UPDATE_EMAIL_TEMPLATE_SYSTEM,
  UPDATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM,
  UPDATE_EMAIL_TEMPLATE_FAIL_SYSTEM,
  CREATE_EMAIL_TEMPLATE_SYSTEM,
  CREATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM,
  CREATE_EMAIL_TEMPLATE_FAIL_SYSTEM,
  DELETE_EMAIL_TEMPLATE_SYSTEM,
  DELETE_EMAIL_TEMPLATE_SUCCESS_SYSTEM,
  DELETE_EMAIL_TEMPLATE_FAIL_SYSTEM,
} from '../actions/emailTemplateAction';
import {
  getEmailTemplates,
  createEmailTemplate,
  updateEmailTemplate,
  getEmailTemplatesSystem,
  createEmailTemplateSystem,
  updateEmailTemplateSystem,
  deleteEmailTemplateSystem,
} from '../../shared/api/methods/emailTemplate';

export function* getEmailTemplatesSaga() {
  while (true) {
    try {
      yield take(GET_EMAIL_TEMPLATES);
      const data = yield call(getEmailTemplates);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_EMAIL_TEMPLATES_FAIL, error });
      } else {
        yield put({ type: GET_EMAIL_TEMPLATES_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_EMAIL_TEMPLATES_FAIL, data: [] });
    }
  }
}

export function* getEmailTemplatesSystemSaga() {
  while (true) {
    try {
      yield take(GET_EMAIL_TEMPLATES_SYSTEM);
      const data = yield call(getEmailTemplatesSystem);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_EMAIL_TEMPLATES_FAIL_SYSTEM, error });
      } else {
        yield put({ type: GET_EMAIL_TEMPLATES_SUCCESS_SYSTEM, data });
      }
    } catch (error) {
      yield put({ type: GET_EMAIL_TEMPLATES_FAIL_SYSTEM, data: [] });
    }
  }
}

export function* updateEmailTemplateSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_EMAIL_TEMPLATE);
      const data = yield call(updateEmailTemplate, [id, params]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_EMAIL_TEMPLATE_FAIL, error });
      } else {
        yield put({ type: UPDATE_EMAIL_TEMPLATE_SUCCESS, data });
      }
    } catch (error) {
      const returnValue = { error };
      yield put({ type: UPDATE_EMAIL_TEMPLATE_FAIL, returnValue });
    }
  }
}

export function* updateEmailTemplateSystemSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_EMAIL_TEMPLATE_SYSTEM);
      const data = yield call(updateEmailTemplateSystem, [id, params]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_EMAIL_TEMPLATE_FAIL_SYSTEM, error });
      } else {
        yield put({ type: UPDATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM, data });
      }
    } catch (error) {
      const returnValue = { error };
      yield put({ type: UPDATE_EMAIL_TEMPLATE_FAIL_SYSTEM, returnValue });
    }
  }
}

export function* createEmailTemplateSaga() {
  while (true) {
    try {
      const { params } = yield take(CREATE_EMAIL_TEMPLATE);
      const data = yield call(createEmailTemplate, params);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: CREATE_EMAIL_TEMPLATE_FAIL, error });
      } else {
        yield put({ type: CREATE_EMAIL_TEMPLATE_SUCCESS, data });
      }
      // yield put({ type: CREATE_EMAIL_TEMPLATE_SUCCESS, data });
    } catch (error) {
      yield put({ type: CREATE_EMAIL_TEMPLATE_FAIL });
    }
  }
}

export function* createEmailTemplateSystemSaga() {
  while (true) {
    try {
      const { params } = yield take(CREATE_EMAIL_TEMPLATE_SYSTEM);
      console.log("create");
      console.log(params);
      const data = yield call(createEmailTemplateSystem, params);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: CREATE_EMAIL_TEMPLATE_FAIL_SYSTEM, error });
      } else {
        yield put({ type: CREATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM, data });
      }
    } catch (error) {
      yield put({ type: CREATE_EMAIL_TEMPLATE_FAIL_SYSTEM });
    }
  }
}

export function* deleteEmailTemplateSystemSaga() {
  while (true) {
    try {
      const { id } = yield take(DELETE_EMAIL_TEMPLATE_SYSTEM);
      const data = yield call(deleteEmailTemplateSystem, id);
      console.log(data);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        console.log('loi');
        yield put({ type: DELETE_EMAIL_TEMPLATE_FAIL_SYSTEM, error });
      } else {
        yield put({ type: DELETE_EMAIL_TEMPLATE_SUCCESS_SYSTEM, data });
      }
    } catch (error) {
      yield put({ type: DELETE_EMAIL_TEMPLATE_FAIL_SYSTEM, error });
    }
  }
}