/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { JEditor } from '../../../../components';
import './styleField.scss';

class FirstLook extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChangeDescEn = (value) => {
    const { onChange } = this.props;
    onChange('enFirstLookDesc', value);
  }

  handleChangeDescVi = (value) => {
    const { onChange } = this.props;
    onChange('viFirstLookDesc', value);
  }

  render() {
    const { formField, error } = this.props;
    return (
      <>
        <FormGroup row>
          <Col sm={6}>
            <Label for="enFirstLookDesc">First Look Description (EN)</Label>
            <JEditor
              content={formField.enFirstLookDesc}
              onChangeEditor={value => this.handleChangeDescEn(value)}
              name="enFirstLookDesc"
              id="enFirstLookDesc"
            />
            <div className={`${error.enFirstLookDesc ? 'show-err' : ''} error-message`}>{error.enFirstLookDesc}</div>
          </Col>
          <Col sm={6}>
            <Label for="viFirstLookDesc">First Look Description (VN)</Label>
            <JEditor
              content={formField.viFirstLookDesc}
              onChangeEditor={(e, value) => this.handleChangeDescVi(e, value)}
              name="viFirstLookDesc"
              id="viFirstLookDesc"
            />
            <div className={`${error.viFirstLookDesc ? 'show-err' : ''} error-message`}>{error.viFirstLookDesc}</div>
          </Col>
        </FormGroup>
      </>
    );
  }
}

FirstLook.propTypes = {
  formField: PropTypes.instanceOf(Object).isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FirstLook;
