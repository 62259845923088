/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_NEWS,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  CREATE_NEWS,
  CREATE_NEWS_SUCCESS,
  CREATE_NEWS_FAIL,
  UPDATE_NEWS,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAIL,
  DELETE_NEWS,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  GET_NEWS_BY_ID,
  GET_NEWS_BY_ID_SUCCESS,
  GET_NEWS_BY_ID_FAIL,
  GET_NEWS_CATEGORIES,
  GET_NEWS_CATEGORIES_SUCCESS,
  GET_NEWS_CATEGORIES_FAIL,
} from '../actions/newsAction';
import {
  updateNewsList,
  addElementToBeginningArrayForNews,
  deleteElementFromArrayByUnderscoreId,
} from '../../shared/utils/calculation';
import {
  MSG_UPDATE_NEWS_SUCCESS,
  MSG_UPDATE_NEWS_FAIL,
  MSG_CREATE_NEWS_SUCCESS,
  MSG_CREATE_NEWS_FAIL,
  MSG_DELETE_NEWS_SUCCESS,
  MSG_DELETE_NEWS_FAIL,
} from '../../shared/utils/constants';

const initialState = {
  newsList: [],
  message: '',
  isUpdate: false,
  isError: false,
  data: null,
  statusCode: '',
  newsEdit: null,
  categories: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NEWS: {
      return { ...state, type: action.type };
    }
    case GET_NEWS_SUCCESS: {
      return { ...state, ...{ type: action.type, newsList: action.data } };
    }
    case GET_NEWS_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case GET_NEWS_CATEGORIES: {
      return { ...state, type: action.type };
    }
    case GET_NEWS_CATEGORIES_SUCCESS: {
      return { ...state, ...{ type: action.type, categories: action.data } };
    }
    case GET_NEWS_CATEGORIES_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case GET_NEWS_BY_ID: {
      return { ...state, type: action.type };
    }
    case GET_NEWS_BY_ID_SUCCESS: {
      return { ...state, ...{ type: action.type, newsEdit: action.data } };
    }
    case GET_NEWS_BY_ID_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case CREATE_NEWS: {
      return { ...state, type: action.type, message: '' };
    }
    case CREATE_NEWS_SUCCESS: {
      return {
        ...state,
        ...{
          type: action.type,
          newsList: addElementToBeginningArrayForNews(state.newsList, action.data),
          isError: false,
          message: MSG_CREATE_NEWS_SUCCESS,
        },
      };
    }
    case CREATE_NEWS_FAIL: {
      return {
        ...state,
        ...{
          type: action.type,
          isError: true,
          message: MSG_CREATE_NEWS_FAIL,
          statusCode: action.error ? action.error.statusCode.toString() : '',
        },
      };
    }
    case UPDATE_NEWS: {
      return { ...state, type: action.type, message: '' };
    }
    case UPDATE_NEWS_SUCCESS: {
      return {
        ...state,
        message: MSG_UPDATE_NEWS_SUCCESS,
        ...{
          type: action.type,
          isError: false,
          newsEdit: action.data,
          newsList: updateNewsList(state.newsList, action.data),
        },
      };
    }
    case UPDATE_NEWS_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: MSG_UPDATE_NEWS_FAIL,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case DELETE_NEWS: {
      return { ...state, type: action.type, message: '' };
    }
    case DELETE_NEWS_SUCCESS: {
      return {
        ...state,
        message: MSG_DELETE_NEWS_SUCCESS,
        ...{
          type: action.type,
          isError: false,
          newsList: deleteElementFromArrayByUnderscoreId(state.newsList, action.data.id),
        },
      };
    }
    case DELETE_NEWS_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: MSG_DELETE_NEWS_FAIL,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    default:
      return state;
  }
}
