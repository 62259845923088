import React, { PureComponent } from 'react';
import {
  Col,
  CardBody,
  Input,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './styleEditForm.scss';

class EditForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      jobseekerEditing: null,
    };
  }

  componentWillMount = () => {
    const { data } = this.props;
    this.setState({ jobseekerEditing: data });
  }

  onChangeStatus = (e) => {
    const { value } = e.target;
    const { updateJobseeker } = this.props;
    const { jobseekerEditing } = this.state;
    if (value !== jobseekerEditing.status) {
      this.setState(prevState => ({
        jobseekerEditing: {
          ...prevState.jobseekerEditing,
          status: value,
        },
      }));
      updateJobseeker({ status: value });
    }
  }

  handleCancel = () => {
    const { cancelEdit } = this.props;
    cancelEdit();
  }

  render() {
    const { jobseekerEditing } = this.state;
    return (
      <Col md={12} lg={12} xl={12}>
        <CardBody>
          <Form className="form-jobseeker">
            <FormGroup row>
              <Label for="name" sm={2}>Name</Label>
              <Col sm={10}>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  value={jobseekerEditing.fullName}
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="email" sm={2}>Registered Email</Label>
              <Col sm={10}>
                <Input
                  type="text"
                  id="email"
                  name="email"
                  value={jobseekerEditing.email}
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="phone" sm={2}>Phone No.</Label>
              <Col sm={10}>
                <Input
                  type="text"
                  id="phone"
                  name="phone"
                  value={jobseekerEditing.phone}
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="status" sm={2}>Status</Label>
              <Col sm={3}>
                <Input
                  type="select"
                  name="status"
                  id="status"
                  value={jobseekerEditing.status}
                  onChange={this.onChangeStatus}
                >
                  <option value="Active">Active</option>
                  <option value="InActive">InActive</option>
                </Input>
              </Col>
              <Col sm={7} className="cb-verified">
                <Label check>
                  <Input
                    type="checkbox"
                    disabled
                    checked={jobseekerEditing.isVerify ? 'checked' : ''}
                  />
                  {' '}
                  Verified
                </Label>
              </Col>
            </FormGroup>
          </Form>
          <Link to="/#">View standard profile</Link>
        </CardBody>
        <button
          type="button"
          color="danger"
          className="card-form__btn card-form__btn--submit"
          onClick={() => this.handleCancel()}
        >
          Back to List
        </button>
      </Col>
    );
  }
}

EditForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  updateJobseeker: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};
export default EditForm;
