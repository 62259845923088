import React, { useState } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';
import Pagination from '../Pagination/index';
import './styleTable.scss';

const Table = ({ className, ...rest }) => {
  const [selectedRow, setSelectedRow] = useState('');
  return (
    <ReactTable
      className={`${className} -highlight`}
      defaultPageSize={10}
      PaginationComponent={Pagination}
      {...rest}
      getTrProps={(state, rowInfo) => {
        if (rowInfo) {
          return {
            onClick: () => {
              setSelectedRow(rowInfo.original.id || '');
            },
            className: selectedRow === rowInfo.original.id ? 'selected-row' : '',
          };
        }
        return {};
      }}
    />
  );
};

Table.propTypes = {
  className: PropTypes.string,
};

Table.defaultProps = {
  className: '',
};

// PaginationComponent={Pagination}
export default Table;
