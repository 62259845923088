import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../../components/Notification/index';
import JobseekerList from './JobseekerList';
import JobseekerDetailt from './JobseekerDetailt';
import {
  STATUS_CODE_401,
  DEFAULT_QUERY_LIMIT,
  DEFAULT_QUERY_SKIP,
  DEFAULT_QUERY_ASC,
} from '../../../shared/utils/constants';
import {
  updateJobseeker as updateJobseekerAction,
  getJobseekers as getJobseekersAction,
  getJobsByJobseekerId as getJobsByJobseekerIdAction,
} from '../../../redux/actions/jobseekerAction';

let notificationRU = null;

class Jobseekers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      jobseekerDetailView: null,
      isShowNotify: false,
    };
  }

  componentWillMount = async () => {
    const { cookies, getJobseekers } = this.props;
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    getJobseekers('', 'candidateId', DEFAULT_QUERY_ASC, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  onEditJobseeker= (item) => {
    const { getJobsByJobseekerId } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    getJobsByJobseekerId(item._id, 'jobCode', DEFAULT_QUERY_ASC, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
    this.setState({ jobseekerDetailView: item });
  }

  onUpdate = (id, status) => {
    const { updateJobseeker } = this.props;
    updateJobseeker(id, status);
    this.setState({ isShowNotify: true });
  }

  cancelEdit = () => {
    this.setState({ jobseekerDetailView: null });
  }

  show = (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Jobseeker"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  onSearchJobseeker = (query) => {
    const { getJobseekers } = this.props;
    getJobseekers(query, 'candidateId', DEFAULT_QUERY_ASC, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  render() {
    const { jobseeker, cookies } = this.props;
    const { hasToken, jobseekerDetailView, isShowNotify } = this.state;
    if (isShowNotify && jobseeker.message) {
      if (jobseeker.isError) {
        this.show('warning', jobseeker.message);
      } else {
        this.show('success', jobseeker.message);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || jobseeker.statusCode === STATUS_CODE_401) {
      if (jobseeker.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`${jobseekerDetailView ? 'Jobseeker Info' : 'Jobseekers Management'}`}</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          {jobseekerDetailView ? (
            <JobseekerDetailt
              data={jobseekerDetailView}
              updateJobseeker={(id, status) => this.onUpdate(id, status)}
              onClickCancel={this.cancelEdit}
              jobsList={jobseeker.jobs}
            />
          ) : (
            <JobseekerList
              jobseekerEditing={item => this.onEditJobseeker(item)}
              jobseekerListData={jobseeker.jobseekerList}
              onSearch={query => this.onSearchJobseeker(query)}
            />
          )
          }
        </Card>
      </Container>
    );
  }
}

Jobseekers.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  jobseeker: PropTypes.objectOf(PropTypes.object).isRequired,
  updateJobseeker: PropTypes.func.isRequired,
  getJobseekers: PropTypes.func.isRequired,
  getJobsByJobseekerId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { jobseeker } = state;
  return {
    jobseeker,
  };
};
const mapDispatchToProps = dispatch => ({
  updateJobseeker: (...args) => dispatch(updateJobseekerAction(...args)),
  getJobseekers: (...args) => dispatch(getJobseekersAction(...args)),
  getJobsByJobseekerId: (...args) => dispatch(getJobsByJobseekerIdAction(...args)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(Jobseekers));
