/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Col, CardBody, Container, Row, Card } from 'reactstrap';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import PropTypes from 'prop-types';
import { BasicNotification } from '../../../../../components/Notification/index';
import {
  getTagById as getTagByIdAction,
  updateTag as updateTagAction,
  createTag as createTagAction,
} from '../../../../../redux/actions/tagAction';
import {
  STATUS_CODE_401,
} from '../../../../../shared/utils/constants';
import './styleEditForm.scss';
import EditForm from './EditForm';

let notificationRU = null;

class TagForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isShowNotify: false,
      tagType: '',
    };
  }

  componentWillMount = () => {
    const { match, getTagById, cookies, tType } = this.props;
    if (tType) {
      this.setState({ tagType: tType });
    } else if (match) {
      const { path } = match;
      if (path.includes('recruiter')) {
        this.setState({ tagType: 'RECRUITER' });
      }
      if (path.includes('jobseeker')) {
        this.setState({ tagType: 'CANDIDATE' });
      }
    }
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    if (match) {
      getTagById(match.params.id);
    }
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  showNotification = (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Tags"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  onUpdateTag = (id, params) => {
    const { updateTag, createTag } = this.props;
    if (id) {
      updateTag(id, params);
    } else {
      createTag(params);
    }
    this.setState({ isShowNotify: true });
  }

  cancelForm = () => {
    const { match, onCancelForm, history } = this.props;
    const { tagType } = this.state;
    if (match) {
      if (tagType === 'RECRUITER') {
        history.push('/admin/news-recruiter/tags');
      }
      if (tagType === 'CANDIDATE') {
        history.push('/admin/news-jobseeker/tags');
      }
    } else {
      onCancelForm();
    }
  }

  render() {
    const { tag, cookies, match, isAddItem } = this.props;
    const { hasToken, isShowNotify, tagType } = this.state;
    let tagForm;
    if (tag.tagEdit != null && match) {
      if (tag.tagEdit.id === match.params.id) {
        tagForm = <EditForm tagType={tagType} data={tag.tagEdit} save={(id, params) => this.onUpdateTag(id, params)} onCancel={this.cancelForm} />;
      }
    }
    if (isAddItem) {
      tagForm = <EditForm tagType={tagType} save={(id, params) => this.onUpdateTag(id, params)} onCancel={this.cancelForm} />;
    }

    if (isShowNotify && tag.message) {
      if (tag.isError) {
        this.showNotification('warning', tag.message);
      } else {
        this.showNotification('success', tag.message);
        setTimeout(() => {
          this.cancelForm();
        }, 3000);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || tag.statusCode === STATUS_CODE_401) {
      if (tag.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`${isAddItem ? 'Create Tag' : 'Update Tag'}`}</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          <Col md={12} lg={12} xl={12}>
            <CardBody>
              { tagForm }
            </CardBody>
          </Col>
        </Card>
      </Container>
    );
  }
}

TagForm.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  getTagById: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  tag: PropTypes.instanceOf(Object).isRequired,
  updateTag: PropTypes.func.isRequired,
  createTag: PropTypes.func.isRequired,
  onCancelForm: PropTypes.func,
  isAddItem: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired,
  tType: PropTypes.string,
};
const mapStateToProps = (state) => {
  const { tag } = state;
  return {
    tag,
  };
};
const mapDispatchToProps = dispatch => ({
  getTagById: id => dispatch(getTagByIdAction(id)),
  updateTag: (id, params) => dispatch(updateTagAction(id, params)),
  createTag: params => dispatch(createTagAction(params)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(TagForm));
