export const LOGIN = 'LOGIN';
export const LOGGINING = 'LOGGINING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export function login(dataLogin) {
  return {
    type: LOGIN,
    dataLogin,
  };
}
