import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import RecruiterList from './RecruiterList';
import { BasicNotification } from '../../../components/Notification/index';
import RecruiterDetailt from './RecruiterDetailt';
import {
  getRecruiters as getRecruitersAction,
  createRecruiter as createRecruiterAction,
  updateRecruiter as updateRecruiterAction,
  deleteRecruiter as deleteRecruiterAction,
  getJobsByRecruiterId as getJobsByRecruiterIdAction,
} from '../../../redux/actions/recruiterAction';
import {
  STATUS_CODE_401,
  DEFAULT_QUERY_LIMIT,
  DEFAULT_QUERY_SKIP,
  DEFAULT_QUERY_ASC,
} from '../../../shared/utils/constants';

let notificationRU = null;

class Recruiters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      recruiterDetailView: null,
      isShowNotify: false,
      isCreateNew: false,
    };
  }

  componentWillMount = async () => {
    const { cookies, getRecruiters } = this.props;
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    getRecruiters('', 'recruiterId', DEFAULT_QUERY_ASC, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  onEditRecruiter= (item) => {
    const { getJobsByRecruiterId } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    getJobsByRecruiterId(item._id, '_id', DEFAULT_QUERY_ASC, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
    this.setState({ recruiterDetailView: item, isCreateNew: false });
  }

  onUpdate = (id, data) => {
    const { updateRecruiter } = this.props;
    updateRecruiter(id, data);
    this.setState({ isShowNotify: true });
  }

  onCreateNew = (data) => {
    const { createRecruiter } = this.props;
    createRecruiter(data);
    this.setState({ isShowNotify: true, recruiterDetailView: null });
  }

  onDelete = (id) => {
    const { deleteRecruiter } = this.props;
    deleteRecruiter(id);
    this.setState({ isShowNotify: true });
  }

  cancelEdit = () => {
    this.setState({ recruiterDetailView: null });
  }

  show = (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Recruiter"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  onSearchRecruiter = (query) => {
    const { getRecruiters } = this.props;
    getRecruiters(query, 'recruiterId', DEFAULT_QUERY_ASC, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  handleClickAddNewRecruiter = () => {
    this.setState({
      recruiterDetailView: {},
      isCreateNew: true,
    });
  }

  render() {
    const { recruiter, cookies } = this.props;
    const { hasToken, recruiterDetailView, isShowNotify, isCreateNew } = this.state;
    if (isShowNotify && recruiter.message) {
      if (recruiter.isError) {
        this.show('warning', recruiter.message);
      } else {
        this.show('success', recruiter.message);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || recruiter.statusCode === STATUS_CODE_401) {
      if (recruiter.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`${recruiterDetailView ? 'Recruiter Info' : 'Recruiters Management'}`}</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          {recruiterDetailView ? (
            <RecruiterDetailt
              data={recruiterDetailView}
              jobsList={recruiter.jobs}
              updateRecruiter={(id, data) => this.onUpdate(id, data)}
              createRecruiter={(data) => this.onCreateNew(data)}
              onClickCancel={this.cancelEdit}
              isCreateNew={isCreateNew}
            />
          ) : (
            <RecruiterList
              recruiterList={recruiter.recruiterList}
              onSearch={query => this.onSearchRecruiter(query)}
              onAddNewRecruiter={this.handleClickAddNewRecruiter}
              recruiterEditing={item => this.onEditRecruiter(item)}
              onDeleteRecruiter={id => this.onDelete(id)}
            />
          )}
        </Card>
      </Container>
    );
  }
}
Recruiters.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  recruiter: PropTypes.instanceOf(Object).isRequired,
  updateRecruiter: PropTypes.func.isRequired,
  getRecruiters: PropTypes.func.isRequired,
  getJobsByRecruiterId: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { recruiter } = state;
  return {
    recruiter,
  };
};
const mapDispatchToProps = dispatch => ({
  getRecruiters: (...args) => dispatch(getRecruitersAction(...args)),
  createRecruiter: (...args) => dispatch(createRecruiterAction(...args)),
  updateRecruiter: (...args) => dispatch(updateRecruiterAction(...args)),
  getJobsByRecruiterId: (...args) => dispatch(getJobsByRecruiterIdAction(...args)),
  deleteRecruiter: id => dispatch(deleteRecruiterAction(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(Recruiters));
