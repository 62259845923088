/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Collapse from '../Collapse';

import Section from './SectionComponent';

class SectionGroupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { title, className } = this.props;
    return (
      <Collapse title={title} className={className}>
        <Section title="HARD SKILLS" className="with-shadow" typeSection="hardSkillSection" typeGroupSection="jobRequirementSection" />
        <Section title="SOFT SKILLS" className="with-shadow" typeSection="softSkillSection" typeGroupSection="jobRequirementSection" />
      </Collapse>
    );
  }
}

SectionGroupComponent.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default connect(
  null,
  null,
)(withTranslation('common')(SectionGroupComponent));
