import React from 'react';
import PropTypes from 'prop-types';
import './styleInput.scss';

const Input = ({ errorMesssage, classRoot, ...props }) => (
  <div className={`input-container ${classRoot}`}>
    <input {...props} />
    <div className={`${errorMesssage ? 'show-err' : ''} error-message`}>{errorMesssage}</div>
  </div>
);

Input.propTypes = {
  classRoot: PropTypes.string,
  errorMesssage: PropTypes.string,
};

Input.defaultProps = {
  classRoot: '',
  errorMesssage: '',
};

export default Input;
