import api from '../index';
import ApiConstants from '../ApiConstants';

export const getTags = type => api(
  `${ApiConstants.NEWS_TAG}/by-types?tagType=${type}`,
  null,
  'get',
  null,
);
export const createTag = news => api(
  `${ApiConstants.NEWS_TAG}`,
  news,
  'POST',
  null,
);
export const updateTag = args => api(
  `${ApiConstants.NEWS_TAG}/${args[0]}`,
  args[1],
  'PATCH',
  null,
);

export const deleteTag = id => api(
  `${ApiConstants.NEWS_TAG}/${id}`,
  null,
  'DELETE',
  null,
);

export const getTagById = id => api(
  `${ApiConstants.NEWS_TAG}/${id}`,
  null,
  'GET',
  null,
);
