/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATES_FAIL,
  UPDATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAIL,
  CREATE_EMAIL_TEMPLATE,
  CREATE_EMAIL_TEMPLATE_SUCCESS,
  CREATE_EMAIL_TEMPLATE_FAIL,
  GET_EMAIL_TEMPLATES_SYSTEM,
  GET_EMAIL_TEMPLATES_SUCCESS_SYSTEM,
  GET_EMAIL_TEMPLATES_FAIL_SYSTEM,
  UPDATE_EMAIL_TEMPLATE_SYSTEM,
  UPDATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM,
  UPDATE_EMAIL_TEMPLATE_FAIL_SYSTEM,
  CREATE_EMAIL_TEMPLATE_SYSTEM,
  CREATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM,
  CREATE_EMAIL_TEMPLATE_FAIL_SYSTEM,
  DELETE_EMAIL_TEMPLATE_SYSTEM,
  DELETE_EMAIL_TEMPLATE_SUCCESS_SYSTEM,
  DELETE_EMAIL_TEMPLATE_FAIL_SYSTEM,
} from '../actions/emailTemplateAction';
import {
  addElementToBeginningArray,
  updateElementFromArrayById,
  deleteElementFromArrayById,
} from '../../shared/utils/calculation';

const initialState = {
  emailTemplates: [],
  emailTemplateSystem:[],
  emailTemplateEditor: null,
  error: null,
  statusCode: '',
  isFinishRequest: true,
  data: null,
  message: '',
  isSaving: false,
  isError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMAIL_TEMPLATES: {
      return { ...state, type: action.type };
    }
    case GET_EMAIL_TEMPLATES_SUCCESS: {
      return { ...state, ...{ type: action.type, emailTemplates: action.data } };
    }
    case GET_EMAIL_TEMPLATES_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case CREATE_EMAIL_TEMPLATE: {
      return { ...state, type: action.type };
    }
    case CREATE_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        type: action.type,
        isSaving: true,
        isError: false,
        message: 'Create Email Template successful!',
        emailTemplates: addElementToBeginningArray(state.emailTemplates, action.data),
      };
    }
    case CREATE_EMAIL_TEMPLATE_FAIL: {
      return {
        ...state,
        ...{ type: action.type },
        message: 'Create Email Template fail!',
        isSaving: true,
        isError: true,
      };
    }
    case UPDATE_EMAIL_TEMPLATE: {
      return { ...state, type: action.type, isSaving: false };
    }
    case UPDATE_EMAIL_TEMPLATE_SUCCESS: {
      const { isDeleted, id } = action.data;
      return {
        ...state,
        type: action.type,
        message: 'Update Email Template successful!',
        isSaving: true,
        isError: false,
        emailTemplates: isDeleted
          ? deleteElementFromArrayById(state.emailTemplates, id)
          : updateElementFromArrayById(state.emailTemplates, action.data),
      };
    }
    case UPDATE_EMAIL_TEMPLATE_FAIL: {
      return {
        ...state,
        ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' },
        message: 'Update Email Template fail!',
        isSaving: true,
        isError: true,
      };
    }

    case GET_EMAIL_TEMPLATES_SYSTEM: {
      return { ...state, type: action.type };
    }
    case GET_EMAIL_TEMPLATES_SUCCESS_SYSTEM: {
      return { ...state, ...{ type: action.type, emailTemplateSystem: action.data } };
    }
    case GET_EMAIL_TEMPLATES_FAIL_SYSTEM: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case CREATE_EMAIL_TEMPLATE_SYSTEM: {
      return { ...state, type: action.type };
    }
    case CREATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM: {
      return {
        ...state,
        type: action.type,
        isSaving: true,
        isError: false,
        message: 'Create Email Template successful!',
        emailTemplateSystem: addElementToBeginningArray(state.emailTemplateSystem, action.data),
      };
    }
    case CREATE_EMAIL_TEMPLATE_FAIL_SYSTEM: {
      return {
        ...state,
        ...{ type: action.type },
        message: 'Create Email Template fail!',
        isSaving: true,
        isError: true,
      };
    }
    case UPDATE_EMAIL_TEMPLATE_SYSTEM: {
      return { ...state, type: action.type, isSaving: false };
    }
    case UPDATE_EMAIL_TEMPLATE_SUCCESS_SYSTEM: {
      const { isDeleted, id } = action.data;
      return {
        ...state,
        type: action.type,
        message: 'Update Email Template successful!',
        isSaving: true,
        isError: false,
        emailTemplateSystem: isDeleted
          ? deleteElementFromArrayById(state.emailTemplateSystem, id)
          : updateElementFromArrayById(state.emailTemplateSystem, action.data),
      };
    }
    case UPDATE_EMAIL_TEMPLATE_FAIL_SYSTEM: {
      return {
        ...state,
        ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' },
        message: 'Update Email Template fail!',
        isSaving: true,
        isError: true,
      };
    }
    case DELETE_EMAIL_TEMPLATE_SYSTEM: {
      return { ...state, type: action.type };
    }
    case DELETE_EMAIL_TEMPLATE_SUCCESS_SYSTEM: {
      const {id} = action.data;
      return {
        ...state,
        type: action.type,
        isError: false,
        message: 'Email Template is deleted!',
        emailTemplateSystem: deleteElementFromArrayById(state.emailTemplateSystem, id),
      };
    }
    case DELETE_EMAIL_TEMPLATE_FAIL_SYSTEM: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: 'Email Template delete failed!',
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    default:
      return state;
  }
}
