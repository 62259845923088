/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  ModalBody, ModalFooter, Form, FormGroup, Label, Input, Row, Col, Alert,
} from 'reactstrap';

class CommonAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    // eslint-disable-next-line react/no-unused-state
    };
  }

  render() {
    const {
      msgError,
      isError,
      dataField,
      modal,
      htmlButton,
      onDismissAlert,
      handleChangeEvent,
    } = this.props;
    const fieldType = dataField.type ? dataField.type : '';
    const isShowGroup = modal.isShowGroup ? modal.isShowGroup : false;
    const isEdit = modal.isEdit ? modal.isEdit : false;
    const attributeUpload = (
      <div>
        <Row>
          <Col sm={2} />
          <Col sm={5}>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" name="allowUploadFile" checked={!!dataField.allowUploadFile} />
                Allow upload file
              </Label>
            </FormGroup>
          </Col>
          <Col sm={5} />
        </Row>
      </div>
    );

    return (
      <div>
        <ModalBody>
          <h2>{ isEdit ? 'Edit Field' : 'Add New Field' }</h2>
          <Alert color="warning" isOpen={isError} toggle={onDismissAlert}>
            {msgError}
          </Alert>
          <hr />
          <Form onChange={handleChangeEvent}>
            <Row>
              <Col xs="5" sm="5">
                <FormGroup tag="fieldset">
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="fieldType" value="Set" checked={!!dataField.type && dataField.type === 'Set'} />
                      Set
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="fieldType" value="Location" checked={!!dataField.type && dataField.type === 'Location'} />
                      Location
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="fieldType" value="Range" checked={!!dataField.type && dataField.type === 'Range'} />
                      Range
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="fieldType" value="Slider" checked={!!dataField.type && dataField.type === 'Slider'} />
                      Slider
                    </Label>
                  </FormGroup>
                  { isShowGroup && (
                    <FormGroup check>
                      <Label check>
                        <Input type="radio" name="fieldType" value="Group" checked={!!dataField.type && dataField.type === 'Group'} />
                        Group
                      </Label>
                    </FormGroup>
                  )}
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="fieldType" value="Number" checked={!!dataField.type && dataField.type === 'Number'} />
                      Number
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col xs="5" sm="5">
                <FormGroup tag="fieldset">
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="fieldType" value="InputText" checked={!!dataField.type && dataField.type === 'InputText'} />
                      InputText
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="fieldType" value="Language" checked={!!dataField.type && dataField.type === 'Language'} />
                      Language
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="fieldType" value="Currency" checked={!!dataField.type && dataField.type === 'Currency'} />
                      Currency
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="fieldType" value="Skill" checked={!!dataField.type && dataField.type === 'Skill'} />
                      Skill
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="fieldType" value="Year" checked={!!dataField.type && dataField.type === 'Year'} />
                      Year
                    </Label>
                  </FormGroup>
                  {/* <FormGroup check>
                    <Label check>
                      <Input type="radio" name="fieldType" value="Point" checked={!!dataField.type && dataField.type === 'Point'} />
                      Point
                    </Label>
                  </FormGroup> */}
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <FormGroup row>
              <Col for="exampleEmail" sm={2}><strong>ID</strong></Col>
              <Col sm={5}>
                <Input type="text" name="fieldId" id="exampleEmail" value={dataField.fieldId ? dataField.fieldId : ''} />
              </Col>
              <Col sm={5} />
            </FormGroup>
            <FormGroup row>
              <Col for="exampleEmail" sm={2}><strong>Label</strong></Col>
              <Col sm={5}>
                <Input type="text" name="labelEnglish" id="exampleEmail" placeholder="English" value={dataField.label ? dataField.label.en : ''} />
              </Col>
              <Col sm={5}>
                <Input type="text" name="labelVn" id="exampleEmail" placeholder="Vietnamese" value={dataField.label ? dataField.label.vi : ''} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col for="exampleEmail" sm={2}><strong>Tooltip</strong></Col>
              <Col sm={5}>
                <Input type="text" name="tooltipEnglish" id="exampleEmail" placeholder="English" value={dataField.tooltip ? dataField.tooltip.en : ''} />
              </Col>
              <Col sm={5}>
                <Input type="text" name="tooltipVn" id="exampleEmail" placeholder="Vietnamese" value={dataField.tooltip ? dataField.tooltip.vi : ''} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col for="exampleEmail" sm={2}><strong>Weight</strong></Col>
              <Col sm={5}>
                <Input type="number" name="weight" id="exampleEmail" value={dataField.weight ? dataField.weight : ''} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={2} />
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="showDescriptionForApplicant" checked={!!dataField.showDescriptionForApplicant} />
                    Show description field for appliciant
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={5} />
            </FormGroup>
            <FormGroup row>
              <Col sm={2} />
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="allowRecruiterRemove" checked={!!dataField.allowRecruiterRemove} />
                    Recuiter can remove this field
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={5} />
            </FormGroup>
            <FormGroup row>
              <Col sm={2} />
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="allowApplicantRemove" checked={!!dataField.allowApplicantRemove} />
                    Appliciant can remove this field
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={5} />
            </FormGroup>
            <FormGroup row>
              <Col sm={2} />
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="isHidden" checked={!!dataField.isHidden} />
                    Hidden this field
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={5} />
            </FormGroup>
            <FormGroup row>
              <Col sm={2} />
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="requiredFieldApplyJob" checked={!!(dataField.validateInfo && dataField.validateInfo.view && dataField.validateInfo.view.includes('apply_job'))} />
                    Required Field (Apply Job)
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={5} />
            </FormGroup>
            <FormGroup row>
              <Col sm={2} />
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="requiredFieldCreateJob" checked={!!(dataField.validateInfo && dataField.validateInfo.view && dataField.validateInfo.view.includes('create_job'))} />
                    Required Field (Create Job)
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={5} />
            </FormGroup>
            <FormGroup row>
              <Col sm={2} />
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="requiredFieldCreateFilter" checked={!!(dataField.validateInfo && dataField.validateInfo.view && dataField.validateInfo.view.includes('create_filter'))} />
                    Required Field (Create Filter)
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={5} />
            </FormGroup>
            <FormGroup row>
              <Col sm={2} />
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="isJobSeekerReadonly" checked={!!(dataField.isJobSeekerReadonly && dataField.isJobSeekerReadonly === true)} />
                    JobSeeker Readonly
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={5} />
            </FormGroup>
            {fieldType === 'Skill' && (
              <div>
                <FormGroup row>
                  <Col sm={2}/>
                  <Col sm={5}>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" name="isCompact"
                               checked={!!(dataField.isCompact && dataField.isCompact === true)}/>
                       Is Compact
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col sm={5}/>
                </FormGroup>
              </div>
            )
            }
            { fieldType === 'Group' && (
              <div>
                <FormGroup row>
                  <Col sm={2} />
                  <Col sm={5}>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" name="isClone" checked={!!dataField.isClone} />
                        Add dupplicate
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col sm={5} />
                </FormGroup>
                <FormGroup row>
                  <Col sm={2} />
                  <Col sm={5}>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" name="isNotShowButtonAdd" checked={!!(dataField.isShowButtonAdd === false)} />
                        {'Don\'t Show button Add'}
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col sm={5} />
                </FormGroup>
              </div>
            )}
            <FormGroup row>
              <Col for="exampleEmail" sm={2}><strong>Level Field</strong></Col>
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="levelField" value="1" checked={!!dataField.level && dataField.level === 1} />
                    Level 1
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={5} />
            </FormGroup>
            <FormGroup row>
              <Col sm={2} />
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="levelField" value="2" checked={!!dataField.level && dataField.level === 2} />
                    Level 2
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={5} />
            </FormGroup>
            <FormGroup row>
              <Col sm={2} />
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="levelField" value="3" checked={!!dataField.level && dataField.level === 3} />
                    Level 3
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={5} />
            </FormGroup>
            { fieldType !== 'Group' && attributeUpload }
          </Form>
        </ModalBody>
        <ModalFooter>
          { htmlButton }
        </ModalFooter>
      </div>
    );
  }
}

CommonAttribute.propTypes = {
  modal: PropTypes.objectOf(PropTypes.object).isRequired,
  msgError: PropTypes.string,
  dataField: PropTypes.objectOf(PropTypes.object).isRequired,
  isError: PropTypes.bool,
  htmlButton: PropTypes.func.isRequired,
  onDismissAlert: PropTypes.func.isRequired,
  handleChangeEvent: PropTypes.func.isRequired,
};

CommonAttribute.defaultProps = {
  msgError: '',
  isError: false,
};

export default connect(
  null,
  null,
)(withTranslation('common')(CommonAttribute));
