import React, { PureComponent } from 'react';
import {
  Col,
  CardBody,
  Input,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './styleEditForm.scss';
import validateForm from '../../../../shared/utils/validation';
import { schemaRecruiterForm, schemaUpdateRecruiterForm } from './validation';

class EditForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      jobseekerEditing: null,
      error: '',
    };
  }

  componentWillMount = () => {
    const {companies, ...recruiterData } = this.props.data;
    const jobseekerEditing = { 
      ...recruiterData, 
      website: companies && companies[0] ? companies[0].website : '',
      contact: companies && companies[0] ? companies[0].contact_name : '',
      phone: companies && companies[0] ? companies[0].phone : '',
    }
    this.setState({ jobseekerEditing });
  }

  onChangeStatus = (e) => {
    const { value } = e.target;
    const { data, updateRecruiter, isCreateNew } = this.props;
    if (isCreateNew) return;
    const isDeleted = value !== 'Active';

    if (isDeleted !== data.isDeleted) {
      this.setState(prevState => ({
        jobseekerEditing: {
          ...prevState.jobseekerEditing,
          status: value,
        },
      }));
      // updateRecruiter({ isDeleted });
    }
  }

  handleCreateNewRecruiter = () => {
    const { jobseekerEditing } = this.state;
    const { isError, error: err } = validateForm(schemaRecruiterForm, jobseekerEditing);
    if (isError) {
      this.setState({ error: err });
      return;
    }
    const { createNewRecruiter } = this.props;
    createNewRecruiter(jobseekerEditing);
  }

  handleUpdateRecruiter = () => {
    const { jobseekerEditing } = this.state;

    const isDeleted = jobseekerEditing.status !== 'Active';
    let updateData = { isDeleted };
    if (jobseekerEditing.phone) { // company
      const { isError, error: err } = validateForm( schemaUpdateRecruiterForm, jobseekerEditing);
      if (isError) {
        this.setState({ error: err });
        return;
      }
      updateData = { ...updateData, rcEmail: jobseekerEditing.rcEmail, rcSendEmailUrl: jobseekerEditing.rcSendEmailUrl };
    }
    
    const { updateRecruiter } = this.props;
    updateRecruiter(updateData);
  }

  handleCancel = () => {
    const { cancelEdit } = this.props;
    cancelEdit();
  }

  handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type !== 'checkbox' ? value : checked;
    let { jobseekerEditing } = this.state;
    jobseekerEditing = {...jobseekerEditing, [name]: fieldValue};
    this.setState({ jobseekerEditing });
  }

  render() {
    const { isCreateNew } = this.props;
    const { jobseekerEditing, error } = this.state;
    const { status } = jobseekerEditing;
    return (
      <Col md={12} lg={12} xl={12}>
        <CardBody>
          <Form className="form-recruiter">
            <FormGroup row>
              <Label for="name" sm={2}>Name of Company</Label>
              <Col sm={10}>
                <Input type="text" id="fullName" name="fullName"  onChange={e => this.handleChange(e)} value={jobseekerEditing.fullName || ''} disabled={!isCreateNew} />
                <div className={`${error.fullName ? 'show-err' : ''} error-message`}>{error.fullName}</div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="website" sm={2}>Website</Label>
              <Col sm={10}>
                <Input type="text" id="website" name="website"  onChange={e => this.handleChange(e)} value={jobseekerEditing.website || ''} disabled={!isCreateNew} />
                <div className={`${error.website ? 'show-err' : ''} error-message`}>{error.website}</div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="email" sm={2}>Registered Email</Label>
              <Col sm={10}>
                <Input type="email" id="email" name="email"  onChange={e => this.handleChange(e)} value={jobseekerEditing.email || ''} disabled={!isCreateNew} />
                <div className={`${error.email ? 'show-err' : ''} error-message`}>{error.email}</div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="contact" sm={2}>Name of contact person</Label>
              <Col sm={10}>
                <Input type="text" id="contact" name="contact"  onChange={e => this.handleChange(e)} value={jobseekerEditing.contact || ''} disabled={!isCreateNew} />
                <div className={`${error.contact ? 'show-err' : ''} error-message`}>{error.contact}</div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="phone" sm={2}>Phone No. of contact person</Label>
              <Col sm={10}>
                <Input type="text" id="phone" name="phone"  onChange={e => this.handleChange(e)} value={jobseekerEditing.phone || ''} disabled={!isCreateNew} />
                <div className={`${error.phone ? 'show-err' : ''} error-message`}>{error.phone}</div>
              </Col>
            </FormGroup>
            {(isCreateNew || jobseekerEditing.phone) && <FormGroup row>
              <Label for="rcEmail" sm={2}>RC Email</Label>
              <Col sm={10}>
                <Input type="text" id="rcEmail" name="rcEmail"  onChange={e => this.handleChange(e)} value={jobseekerEditing.rcEmail || ''}/>
                <div className={`${error.rcEmail ? 'show-err' : ''} error-message`}>{error.rcEmail}</div>
              </Col>
            </FormGroup>}
            {(isCreateNew || jobseekerEditing.phone) && <FormGroup row>
              <Label for="rcEmail" sm={2}>RC Send Email URL</Label>
              <Col sm={10}>
                <Input type="text" id="rcSendEmailUrl" name="rcSendEmailUrl"  onChange={e => this.handleChange(e)} value={jobseekerEditing.rcSendEmailUrl || ''}/>
                <div className={`${error.rcSendEmailUrl ? 'show-err' : ''} error-message`}>{error.rcSendEmailUrl}</div>
              </Col>
            </FormGroup>}
            <FormGroup row>
              <Label for="status" sm={2}>Status</Label>
              <Col sm={3}>
                <Input type="select" name="status" id="status" value={status} onChange={this.onChangeStatus}>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Input>
              </Col>
              <Col sm={7} className="cb-verified">
                <Label check>
                  <Input type="checkbox" disabled={!isCreateNew} name="isVerify" 
                    onChange={e => this.handleChange(e)} checked={jobseekerEditing.isVerify ? 'checked' : ''} />
                  {' '} Verified
                </Label>
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
        <button
          type="button"
          color="danger"
          className="card-form__btn card-form__btn--submit"
          onClick={() => isCreateNew ? this.handleCreateNewRecruiter() : this.handleUpdateRecruiter()}
        >Save</button>
        <button
          type="button"
          color="danger"
          className="card-form__btn card-form__btn--submit"
          onClick={() => this.handleCancel()}
        >
          Back To Recruiter List
        </button>
      </Col>
    );
  }
}

EditForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  updateRecruiter: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};
export default EditForm;
