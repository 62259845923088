import React, { PureComponent } from 'react';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';
import PropTypes from 'prop-types';
import ApiConstants from '../../shared/api/ApiConstants';
import { getCookie } from '../../shared/utils/calculation';
import { IMAGE_TYPE, MSG_FILE_UPLOAD_OVER_SIZE } from '../../shared/utils/constants';

const token = getCookie('token');
const bearer = `Bearer ${token}`;

const config = {
  disablePlugins: ['mobile'],
  uploader: {
    url: `${ApiConstants.BASE_URL}${ApiConstants.UPLOADFILE}`,
    headers: {
      Authorization: bearer,
    },
    queryBuild(data) {
      return data;
    },
    method: 'POST',
    process(resp) {
      let data;
      if (resp.url) {
        const fileName = resp.url.split('/').pop();
        const baseU = resp.url.split(fileName).shift();
        data = {
          files: [fileName],
          baseurl: baseU,
          code: 220,
          isImages: [true],
          messages: [],
        };
      }
      return data;
    },
    isSuccess(e) {
      return e;
    },
    getMessage(resp) {
      return resp.msg;
    },
    error(e) {
      if (e) {
        if (e.toString().includes('Length Required')) {
          // eslint-disable-next-line no-alert
          alert(MSG_FILE_UPLOAD_OVER_SIZE);
        }
        if (e.toString().includes('Unauthorized')) {
          window.location.replace('/');
        }
      }
    },
    defaultHandlerSuccess(data) {
      const fileType = data.files[0].split('.').pop().toLowerCase();
      if (IMAGE_TYPE.includes(fileType)) {
        this.selection.insertHTML(`<img src="${data.baseurl}${data.files[0]}" />`);
      } else {
        this.selection.insertHTML(`<a href="${data.baseurl}${data.files[0]}">${data.baseurl}${data.files[0]}</a>`);
      }
    },
  },
};

class JEditor extends PureComponent {
  static propTypes = {
    content: PropTypes.string.isRequired,
    onChangeEditor: PropTypes.func.isRequired,
  };

  updateContent = (value) => {
    const { onChangeEditor } = this.props;
    onChangeEditor(value);
  }

  render() {
    const { content } = this.props;
    return (
      <JoditEditor
        value={content}
        config={config}
        onChange={value => this.updateContent(value)}
      />
    );
  }
}
export default JEditor;
