/* eslint-disable object-curly-newline */
import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import PostList from './PostList';
import { BasicNotification } from '../../../../components/Notification/index';
import PostForm from './PostForm';
import {
  getNews as getNewsAction,
  updateNews as updateNewsAction,
  deleteNews as deleteNewsAction,
} from '../../../../redux/actions/newsAction';
import {
  getTags as getTagsAction,
} from '../../../../redux/actions/tagAction';
import {
  getCategories as getCategoriesAction,
} from '../../../../redux/actions/categoryAction';
import {
  STATUS_CODE_401,
  DEFAULT_QUERY_LIMIT,
  DEFAULT_QUERY_SKIP,
  DEFAULT_QUERY_ASC,
} from '../../../../shared/utils/constants';

let notificationRU = null;

class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isForm: false,
      isShowNotify: false,
      type: '',
    };
  }

  componentWillMount = async () => {
    const { cookies, getNews, match, getCategories, getTags } = this.props;
    const { path } = match;
    let newsType;
    if (path.includes('recruiter')) {
      newsType = 'RECRUITER';
      this.setState({ type: 'RECRUITER' });
    }
    if (path.includes('jobseeker')) {
      newsType = 'CANDIDATE';
      this.setState({ type: 'CANDIDATE' });
    }
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    getNews('', '', newsType, 'createdDate', DEFAULT_QUERY_ASC, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
    getTags(newsType);
    getCategories(newsType, 'sortNum', '1', DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  onUpdateIsHot = (id, params) => {
    const { updateNews } = this.props;
    updateNews(id, params);
    this.setState({ isShowNotify: true });
  }

  canceForm = () => {
    this.setState({ isForm: false });
  }

  show = (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Posts"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  handleClickAddnew = () => {
    this.setState({ isForm: true });
  }

  onSearchNews = (query, cat) => {
    const { getNews } = this.props;
    const { type } = this.state;
    getNews(query, cat, type, 'createdDate', DEFAULT_QUERY_ASC, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  onDelete = (id) => {
    const { deleteNews } = this.props;
    deleteNews(id);
    this.setState({ isShowNotify: true });
  }

  render() {
    const { news, cookies, tag, category } = this.props;
    const { hasToken, isForm, isShowNotify, type } = this.state;
    let titType = '';
    if (type === 'CANDIDATE') {
      titType = 'Jobseeker';
    }
    if (type === 'RECRUITER') {
      titType = 'Recruiter';
    }
    if (isShowNotify && news.message) {
      if (news.isError) {
        this.show('warning', news.message);
      } else {
        this.show('success', news.message);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || news.statusCode === STATUS_CODE_401) {
      if (news.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <div>
        {isForm ? (
          <PostForm
            onCancelForm={this.canceForm}
            pType={type}
            isAddItem={isForm}
          />
        ) : (
          <Container>
            <Row>
              <Col md={12}>
                <h3 className="page-title">{titType} Posts</h3>
              </Col>
            </Row>
            <Card body className="mb-5">
              <PostList
                postList={news.newsList}
                catList={category.categoryList}
                postType={type}
                tagList={tag.tagList}
                onSearch={(query, catId) => this.onSearchNews(query, catId)}
                newsEditing={item => this.onEditNews(item)}
                onAddNews={this.handleClickAddnew}
                onUpdateNews={(id, params) => this.onUpdateIsHot(id, params)}
                onDeleteNews={id => this.onDelete(id)}
              />
            </Card>
          </Container>
        )}
      </div>
    );
  }
}

Post.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  news: PropTypes.instanceOf(Object).isRequired,
  tag: PropTypes.instanceOf(Object).isRequired,
  category: PropTypes.instanceOf(Object).isRequired,
  updateNews: PropTypes.func.isRequired,
  deleteNews: PropTypes.func.isRequired,
  getNews: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getTags: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { news, tag, category } = state;
  return {
    news,
    tag,
    category,
  };
};
const mapDispatchToProps = dispatch => ({
  getNews: (...args) => dispatch(getNewsAction(...args)),
  updateNews: (...args) => dispatch(updateNewsAction(...args)),
  deleteNews: id => dispatch(deleteNewsAction(id)),
  getCategories: (...args) => dispatch(getCategoriesAction(...args)),
  getTags: type => dispatch(getTagsAction(type)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(Post));
