/* eslint-disable object-curly-newline */
import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import QaList from './QaList';
import { BasicNotification } from '../../../../components/Notification/index';
import {
  getFaq as getFaqAction,
  deleteFaq as deleteFaqAction,
  resetMessage as resetMessageAction,
} from '../../../../redux/actions/faqAction';
import {
  getCategories as getCategoriesAction,
} from '../../../../redux/actions/faqCategoryAction';
import {
  STATUS_CODE_401,
  DEFAULT_QUERY_LIMIT,
  DEFAULT_QUERY_SKIP,
  DEFAULT_QUERY_ASC,
} from '../../../../shared/utils/constants';
import Modals from '../../../../components/Modal';

let notificationRU = null;

class Qa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      type: '',
      isOpen: false,
      idDelete: '',
      faqCategory: {},
      faq: {},
    };
  }

  componentWillMount = async () => {
    const { cookies, getFaq, match, getCategories } = this.props;
    const { path } = match;
    let faqType;
    if (path.includes('recruiter')) {
      faqType = 'RECRUITER';
      this.setState({ type: 'RECRUITER' });
    }
    if (path.includes('jobseeker')) {
      faqType = 'CANDIDATE';
      this.setState({ type: 'CANDIDATE' });
    }
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    getFaq('', '', faqType, 'id', DEFAULT_QUERY_ASC, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
    getCategories(faqType, 'sortNum', '1', DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillReceiveProps(nextProps) {
    const { faq, faqCategory } = this.props;
    if (nextProps.faqCategory !== faqCategory) {
      this.setState({
        faqCategory: nextProps.faqCategory,
      });
    }
    if (nextProps.faq !== faq) {
      this.setState({
        faq: nextProps.faq,
      });
    }
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  show = (color, message) => {
    const { resetMessage } = this.props;
    notificationRU.notice({
      content: <BasicNotification
        title="Faq"
        color={color}
        message={message}
      />,
      duration: 5,
      closable: true,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: 'right-up',
    });
    resetMessage();
  }

  onSearchFaq = (query, cat) => {
    const { getFaq } = this.props;
    const { type } = this.state;
    getFaq(query, cat, type, 'id', DEFAULT_QUERY_ASC, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  handleDeleteCategory = (idDelete) => {
    this.setState({ isOpen: true });
    this.setState({
      idDelete,
    });
  }

  renderBodyModalConfirm = () => (
    <div className="Modal__body">
      <div className="alert alert-danger" role="alert">
        Do you want to delete it?
      </div>
    </div>
  );

  renderFooterModalConfirm = () => (
    <div className="Modal__footer">
      <button
        type="button"
        className="btn btn-sm btn-outline-primary"
        onClick={this.deleteFaq}
      >
        Delete
      </button>
      <button
        type="button"
        className="btn btn-sm btn-outline-danger"
        onClick={this.handleCancelModalConfirm}
      >
        Cancel
      </button>
    </div>
  );

  handleCancelModalConfirm = () => {
    this.setState({
      isOpen: false,
      idDelete: '',
    });
  }

  deleteFaq = () => {
    this.setState({
      isOpen: false,
    });
    const { type, idDelete } = this.state;
    const { deleteFaq, getFaq } = this.props;
    deleteFaq(idDelete);
    getFaq('', '', type, 'id', DEFAULT_QUERY_ASC, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  render() {
    const { cookies } = this.props;
    const { hasToken, type, isOpen, faq, faqCategory } = this.state;
    let titType = '';
    if (type === 'CANDIDATE') {
      titType = 'Jobseeker';
    }
    if (type === 'RECRUITER') {
      titType = 'Recruiter';
    }
    if (faq.message && faq.message !== '') {
      if (faq.isError) {
        this.show('warning', faq.message);
      } else {
        this.show('success', faq.message);
      }
    }
    if (!hasToken || faq.statusCode === STATUS_CODE_401) {
      if (faq.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <div>
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="page-title">{titType} Question And Answer</h3>
            </Col>
          </Row>
          <Card body className="mb-5">
            <QaList
              qaList={faq.faqList}
              catList={faqCategory.categoryList ? faqCategory.categoryList : []}
              qaType={type}
              onSearch={(query, catId) => this.onSearchFaq(query, catId)}
              onDeleteFaq={id => this.handleDeleteCategory(id)}
            />
          </Card>
          <Modals
            isOpen={isOpen}
            size="xs"
            title="Confirm Delete"
            footerModal={this.renderFooterModalConfirm}
            onCancel={this.handleCancelModalConfirm}
          >
            {this.renderBodyModalConfirm}
          </Modals>
        </Container>
      </div>
    );
  }
}

Qa.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  faq: PropTypes.instanceOf(Object).isRequired,
  faqCategory: PropTypes.instanceOf(Object).isRequired,
  deleteFaq: PropTypes.func.isRequired,
  getFaq: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  resetMessage: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { faq, faqCategory } = state;
  return {
    faq,
    faqCategory,
  };
};
const mapDispatchToProps = dispatch => ({
  getFaq: (...args) => dispatch(getFaqAction(...args)),
  deleteFaq: (...args) => dispatch(deleteFaqAction(...args)),
  getCategories: (...args) => dispatch(getCategoriesAction(...args)),
  resetMessage: (...args) => dispatch(resetMessageAction(...args)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(Qa));
