/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Col, CardBody, Container, Row, Card } from 'reactstrap';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import PropTypes from 'prop-types';
import { BasicNotification } from '../../../../components/Notification/index';
import {
  getHotCareersById as getHotCareersByIdAction,
  updateHotCareers as updateHotCareersAction,
  getHotCareers as getHotCareersAction,
} from '../../../../redux/actions/hotCareersAction';
import {
  getDepartments as getDepartmentsAction,
} from '../../../../redux/actions/departmentAction';

import {
  STATUS_CODE_401,
} from '../../../../shared/utils/constants';
import './styleEditForm.scss';
import EditForm from './EditForm';

let notificationRU = null;

class CatForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isShowNotify: false,
    };
  }

  componentWillMount = () => {
    const { match, getHotCareersById, getDepartments, cookies } = this.props;
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    getDepartments();
    if (match) {
      getHotCareersById(match.params.id);
    }
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  showNotification = (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Hot Careers"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  onUpdateHotCareers = (id, params) => {
    const { updateHotCareers, getHotCareers } = this.props;
    updateHotCareers(id, params);
    this.setState({ isShowNotify: true });
    getHotCareers();
  }

  cancelForm = () => {
    const { match, onCancelForm, history } = this.props;
    if (match) {
      history.push('/admin/hot-careers');
    } else {
      onCancelForm();
    }
  }

  render() {
    const { hotCareers, department, cookies, match, isAddItem } = this.props;
    const { hasToken, isShowNotify } = this.state;
    let hotCareersForm;
    if (hotCareers.hotCareersEdit != null && match) {
      if (hotCareers.hotCareersEdit.id === match.params.id) {
        hotCareersForm = <EditForm departments={department.departments} hotCareersList={hotCareers.hotCareersList} data={hotCareers.hotCareersEdit} save={(id, params) => this.onUpdateHotCareers(id, params)} onCancel={this.cancelForm} />;
      }
    }
    if (isAddItem) {
      hotCareersForm = <EditForm departments={department.departments} hotCareersList={hotCareers.hotCareersList} save={(id, params) => this.onUpdateHotCareers(id, params)} onCancel={this.cancelForm} />;
    }
    if (isShowNotify && hotCareers.message) {
      if (hotCareers.isError) {
        this.showNotification('warning', hotCareers.message);
      } else {
        this.showNotification('success', hotCareers.message);
        setTimeout(() => {
          this.cancelForm();
        }, 3000);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || hotCareers.statusCode === STATUS_CODE_401) {
      if (hotCareers.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`${isAddItem ? 'Add' : 'Update'}`} Hot Careers</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          <Col md={12} lg={12} xl={12}>
            <CardBody>
              { hotCareersForm }
            </CardBody>
          </Col>
        </Card>
      </Container>
    );
  }
}

CatForm.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  getHotCareersById: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  hotCareers: PropTypes.instanceOf(Object).isRequired,
  department: PropTypes.instanceOf(Object).isRequired,
  updateHotCareers: PropTypes.func.isRequired,
  onCancelForm: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  isAddItem: PropTypes.bool,
  getDepartments: PropTypes.func.isRequired,
  getHotCareers: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { hotCareers, department } = state;
  return {
    hotCareers,
    department,
  };
};
const mapDispatchToProps = dispatch => ({
  getHotCareersById: id => dispatch(getHotCareersByIdAction(id)),
  updateHotCareers: (id, params) => dispatch(updateHotCareersAction(id, params)),
  getHotCareers: () => dispatch(getHotCareersAction()),
  getDepartments: () => dispatch(getDepartmentsAction()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(CatForm));
