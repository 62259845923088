/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Label, Button,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Line, Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import '../../../../node_modules/react-datepicker/dist/react-datepicker.css';
import './react-datepicker.scss';
import moment from 'moment';
import { loadDataChart as loadDataChartAction } from '../../../redux/actions/dashboardAction';


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      startDate: new Date(2020, 0),
      endDate: new Date(),
      msgError: '',
      dataRecruiters: {},
      dataCandidates: {},
      dataJobs: {},
    };
  }

  componentWillMount = async () => {
    const { cookies } = this.props;
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    this.handleLoadDataChart();
  };

  componentWillReceiveProps(nextProps) {
    const { dashboard } = this.props;
    if (nextProps.dashboard !== dashboard) {
      const dataRecruiters = {};
      const dataCandidates = {};
      const dataJobs = {};
      if (Object.prototype.hasOwnProperty.call(nextProps.dashboard, 'accumulateData') && Object.prototype.hasOwnProperty.call(nextProps.dashboard, 'newData')) {
        const { accumulateData, newData } = nextProps.dashboard;
        // Set dataRecruiters
        dataRecruiters.cumulative = [accumulateData.recruiterChart[0].amounts, accumulateData.recruiterChart[1].amounts];
        dataRecruiters.new = [newData.recruiterChart[0].amounts, newData.recruiterChart[1].amounts];
        const dataMonth = accumulateData.recruiterChart[1].months;
        const dataMonthConvert = dataMonth.map(item => moment(item).format('MM/YYYY'));
        dataRecruiters.months = dataMonthConvert;
        // Set dataCandidates
        dataCandidates.cumulative = [accumulateData.candidateChart[0].amounts, accumulateData.candidateChart[1].amounts];
        dataCandidates.new = [newData.candidateChart[0].amounts, newData.candidateChart[1].amounts];
        dataCandidates.months = dataMonthConvert;
        // Set dataJobs
        dataJobs.cumulative = [accumulateData.jobChart[0].amounts, accumulateData.jobChart[1].amounts];
        dataJobs.new = [newData.jobChart[0].amounts, newData.jobChart[1].amounts];
        dataJobs.months = dataMonthConvert;
      }

      this.setState({
        dataRecruiters,
        dataCandidates,
        dataJobs,
      });
    }
  }

  handleLoadDataChart = () => {
    const { startDate, endDate } = this.state;
    const { loadDataChart } = this.props;
    loadDataChart(moment(startDate).format('MM-YYYY'), moment(endDate).format('MM-YYYY'));
  };

  defineData = (labels, dataThis, dataCompared, isBar) => {
    const dataBar = {
      labels,
      datasets: [
        {
          label: 'Cumulative (this period)',
          backgroundColor: '#FF6384',
          borderWidth: 1,
          data: dataThis,
        },
        {
          label: 'Cumulative (compared period)',
          backgroundColor: '#FFCE56',
          borderColor: '#FFCE56',
          borderWidth: 1,
          data: dataCompared,
        },
      ],
    };

    const dataLine = {
      labels,
      datasets: [
        {
          label: 'New (this period)',
          fill: false,
          lineTension: 0.1,
          backgroundColor: '#FF6384',
          borderColor: '#FF6384',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBorderColor: 'rgba(75,192,192,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataThis,
        },
        {
          label: 'New (compared period)',
          fill: false,
          lineTension: 0.1,
          backgroundColor: '#FFCE56',
          borderColor: '#FFCE56',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBorderColor: 'rgba(75,192,192,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataCompared,
        },
      ],
    };

    if (isBar) return dataBar;
    return dataLine;
  };

  defineOptions = (text) => {
    const options = {
      title: {
        display: true,
        text,
      },
      legend: {
        display: true,
        position: 'bottom',
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
        }],
      },
    };

    return options;
  };

  handleFilter = () => {
    const { startDate, endDate } = this.state;
    if (moment(startDate) <= moment(endDate)) {
      this.handleLoadDataChart();
    } else {
      this.setState({
        msgError: 'From must be <= To!',
      });
    }
  }

  render() {
    const {
      hasToken, startDate,
      endDate, msgError,
      dataRecruiters, dataCandidates, dataJobs,
    } = this.state;
    const { statusCode, cookies } = this.props;
    // Define data Recruiters
    const dataCumulativeRecruitersThis = dataRecruiters.cumulative && dataRecruiters.cumulative[1] ? dataRecruiters.cumulative[1] : [];
    const dataCumulativeRecruitersCompared = dataRecruiters.cumulative && dataRecruiters.cumulative[0] ? dataRecruiters.cumulative[0] : [];
    const dataNewRecruitersThis = dataRecruiters.new && dataRecruiters.new[1] ? dataRecruiters.new[1] : [];
    const dataNewRecruitersCompared = dataRecruiters.new && dataRecruiters.new[0] ? dataRecruiters.new[0] : [];
    const dataCumulativeRecruiters = this.defineData(dataRecruiters.months, dataCumulativeRecruitersThis, dataCumulativeRecruitersCompared, true);
    const dataNewRecruiters = this.defineData(dataRecruiters.months, dataNewRecruitersThis, dataNewRecruitersCompared, false);
    const noCumulativeRecruiters = dataCumulativeRecruitersThis[dataCumulativeRecruitersThis.length - 1];
    const optionsCumulativeRecruiters = this.defineOptions(`No. of Cumulative Recruiters: ${noCumulativeRecruiters}`);
    const noNewRecruiters = dataNewRecruitersThis.reduce((a, b) => a + b, 0);
    const optionsNewRecruiters = this.defineOptions(`No. of New Recruiters: ${noNewRecruiters}`);

    // Define data Candidates
    const dataCumulativeCandidatesThis = dataCandidates.cumulative && dataCandidates.cumulative[1] ? dataCandidates.cumulative[1] : [];
    const dataCumulativeCandidatesCompared = dataCandidates.cumulative && dataCandidates.cumulative[0] ? dataCandidates.cumulative[0] : [];
    const dataNewCandidatesThis = dataCandidates.new && dataCandidates.new[1] ? dataCandidates.new[1] : [];
    const dataNewCandidatesCompared = dataCandidates.new && dataCandidates.new[0] ? dataCandidates.new[0] : [];
    const dataCumulativeCandidates = this.defineData(dataCandidates.months, dataCumulativeCandidatesThis, dataCumulativeCandidatesCompared, true);
    const dataNewCandidates = this.defineData(dataCandidates.months, dataNewCandidatesThis, dataNewCandidatesCompared, false);
    const noCumulativeCandidates = dataCumulativeCandidatesThis[dataCumulativeCandidatesThis.length - 1];
    const optionsCumulativeCandidates = this.defineOptions(`No. of Cumulative Candidates: ${noCumulativeCandidates}`);
    const noNewCandidates = dataNewCandidatesThis.reduce((a, b) => a + b, 0);
    const optionsNewCandidates = this.defineOptions(`No. of New Candidates: ${noNewCandidates}`);

    // Define data Jobs
    const dataCumulativeJobsThis = dataJobs.cumulative && dataJobs.cumulative[1] ? dataJobs.cumulative[1] : [];
    const dataCumulativeJobsCompared = dataJobs.cumulative && dataJobs.cumulative[0] ? dataJobs.cumulative[0] : [];
    const dataNewJobsThis = dataJobs.new && dataJobs.new[1] ? dataJobs.new[1] : [];
    const dataNewJobsCompared = dataJobs.new && dataJobs.new[0] ? dataJobs.new[0] : [];
    const dataCumulativeJobs = this.defineData(dataJobs.months, dataCumulativeJobsThis, dataCumulativeJobsCompared, true);
    const dataNewJobs = this.defineData(dataJobs.months, dataNewJobsThis, dataNewJobsCompared, false);
    const noCumulativeJobs = dataCumulativeJobsThis[dataCumulativeJobsThis.length - 1];
    const optionsCumulativeJobs = this.defineOptions(`No. of Cumulative Jobs: ${noCumulativeJobs}`);
    const noNewJobs = dataNewJobsThis.reduce((a, b) => a + b, 0);
    const optionsNewJobs = this.defineOptions(`No. of New Jobs: ${noNewJobs}`);

    if (!hasToken || statusCode === '401') {
      if (statusCode === '401') cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }

    return (
      <Container>
        {msgError !== '' && (<p style={{ color: 'red', marginBottom: 20 }}>{msgError}</p>)}
        <div className="d-flex justify-content-start">
          <div>
            <Label>From: </Label>
            <DatePicker
              selected={startDate}
              onChange={date => this.setState({
                startDate: date,
                msgError: '',
              })}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </div>
          <div className="ml-4">
            <Label>To: </Label>
            <DatePicker
              selected={endDate}
              onChange={date => this.setState({
                endDate: date,
                msgError: '',
              })}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </div>
          <Button style={{ marginLeft: 20, marginTop: -2 }} outline color="primary" size="sm" onClick={() => this.handleFilter()}>Filter</Button>
        </div>
        <Row>
          <Col md={6}>
            <Bar
              data={dataCumulativeRecruiters}
              options={optionsCumulativeRecruiters}
            />
          </Col>
          <Col md={6}>
            <Line
              data={dataNewRecruiters}
              options={optionsNewRecruiters}
            />
          </Col>
        </Row>
        <hr style={{
          backgroundColor: 'rgba(75,192,192,1)',
          height: 0.2,
          marginTop: 50,
          marginBottom: 50,
        }}
        />
        <Row>
          <Col md={6}>
            <Bar
              data={dataCumulativeCandidates}
              options={optionsCumulativeCandidates}
            />
          </Col>
          <Col md={6}>
            <Line
              data={dataNewCandidates}
              options={optionsNewCandidates}
            />
          </Col>
        </Row>
        <hr style={{
          backgroundColor: 'rgba(75,192,192,1)',
          height: 0.2,
          marginTop: 50,
          marginBottom: 50,
        }}
        />
        <Row>
          <Col md={6}>
            <Bar
              data={dataCumulativeJobs}
              options={optionsCumulativeJobs}
            />
          </Col>
          <Col md={6}>
            <Line
              data={dataNewJobs}
              options={optionsNewJobs}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

Dashboard.defaultProps = {
  dashboard: {},
  statusCode: '',
};

Dashboard.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  dashboard: PropTypes.instanceOf(Object),
  loadDataChart: PropTypes.func.isRequired,
  statusCode: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { dashboard } = state;
  return {
    dashboard: dashboard.data,
    statusCode: dashboard.statusCode,
  };
};

const mapDispatchToProps = dispatch => ({
  loadDataChart: (from, to) => dispatch(loadDataChartAction(from, to)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(Dashboard));
