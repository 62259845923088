import api from '../index';
import ApiConstants from '../ApiConstants';

export const getRecruiters = args => api(
  `${ApiConstants.RECRUITER}/?q=${args[0]}&order=${args[1]}&asc=${args[2]}&limit=${args[3]}&skip=${args[4]}`,
  null,
  'get',
  null,
);

export const createRecruiter = (args) => api(
  `/admin/recruiters`,
  args,
  'POST',
  null,
);

export const updateRecruiter = args => api(
  `/admin/${args[0]}/recruiters`,
  args[1],
  'PATCH',
  null,
);

export const deleteRecruiter = id => api(
  `/admin/${id}/recruiters`,
  null,
  'DELETE',
  null,
);

export const getJobsByRecruiterId = args => api(
  `/admin/${args[0]}/jobs/?order=${args[1]}&asc=${args[2]}&limit=${args[3]}&skip=${args[4]}`,
  null,
  'get',
  null,
);
