/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import {
  Form,
  Container,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  Spinner,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { login } from '../../../redux/actions/authenticationAction';

const queryString = require('query-string');

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      validate: {
        emailState: '',
      },
      hasToken: false,
    };
    this.submitForm = this.submitForm.bind(this);
  }

  componentWillMount = async () => {
    const { location, cookies } = this.props;
    const url = location.search;
    const params = queryString.parse(url);
    let hasToken = false;
    if (params.token && params.token !== '') {
      await cookies.set('token', params.token, { path: '/' });
      hasToken = true;
    } else {
      const token = cookies.get('token') || '';
      if (token !== '') {
        hasToken = true;
      }
    }

    this.setState({
      hasToken,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    const { email, validate } = this.state;
    const { emailState } = validate;
    const { dispatch } = this.props;
    const dataLogin = {
      email,
    };
    if (emailState === 'has-success') {
      dispatch(login(dataLogin));
    }
  };

  validateEmail = (e) => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state;
    if (emailRex.test(e.target.value)) {
      validate.emailState = 'has-success';
    } else {
      validate.emailState = 'has-danger';
    }
    this.setState({ validate });
  };

  handleChange = async (event) => {
    const { target } = event;
    const { value } = target;
    await this.setState({
      email: value,
    });
  };

  render() {
    const { email, validate, hasToken } = this.state;
    const { emailState } = validate;
    const { authentication } = this.props;
    const { message, isLoging } = authentication;

    if (hasToken) {
      return (
        <Redirect
          to={{
            pathname: '/admin/dashboard',
          }}
        />
      );
    }

    return (
      <Container className="LoginContainer">
        <h2>Sign In</h2>
        <br />
        <h4>To Sign In, Please enter email address to get access link.</h4>
        <br />
        <Form onSubmit={e => this.submitForm(e)}>
          <InputGroup>
            <Input
              type="email"
              placeholder="abc@email.com"
              value={email}
              valid={emailState === 'has-success'}
              invalid={emailState === 'has-danger'}
              onChange={(e) => {
                this.validateEmail(e);
                this.handleChange(e);
              }}
              required
            />
            <InputGroupAddon addonType="append">
              <Button outline color="warning" className="btn-login">
                GET LINK
              </Button>
              {isLoging && <Spinner style={{ marginTop: '2px' }} color="warning" />}
            </InputGroupAddon>
          </InputGroup>
          <h5 className="text-primary">{message}</h5>
        </Form>
      </Container>
    );
  }
}

Login.propTypes = {
  authentication: PropTypes.objectOf(PropTypes.object).isRequired,
  location: PropTypes.objectOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

const mapStateToProps = (state) => {
  const { authentication } = state;
  return {
    authentication,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withCookies(Login), withTranslation('common')(Login));
