import { call, put, take } from 'redux-saga/effects';
import {
  GET_QUESTIONS,
  GET_QUESTIONS_FAILED,
  GET_QUESTIONS_SUCCESS,
  SAVE_QUESTIONS,
  SAVE_QUESTIONS_FAILED,
  SAVE_QUESTIONS_SUCCESS,
} from '../actions/questionsAction';
import { createQuestion, getQuestions } from '../../shared/api/methods/questions';

export function* getQuestionsSaga() {
  while (true) {
    try {
      yield take(GET_QUESTIONS);
      const data = yield call(getQuestions);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_QUESTIONS_SUCCESS, error });
      } else {
        yield put({ type: GET_QUESTIONS_FAILED, data });
      }
    } catch (error) {
      yield put({ type: GET_QUESTIONS_FAILED, data: [] });
    }
  }
}

export function* saveQuestionsSaga() {
  while (true) {
    try {
      const { data: params } = yield take(SAVE_QUESTIONS);
      const data = yield call(createQuestion, params);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: SAVE_QUESTIONS_FAILED, error });
      } else {
        const { id } = params;
        const dataUpdate = id ? { ...data, id } : data;
        yield put({ type: SAVE_QUESTIONS_SUCCESS, data: dataUpdate });
      }
    } catch (error) {
      yield put({ type: SAVE_QUESTIONS_FAILED, error });
    }
  }
}
