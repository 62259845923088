/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_JOBSEEKERS,
  GET_JOBSEEKERS_SUCCESS,
  GET_JOBSEEKERS_FAIL,
  UPDATE_JOBSEEKER,
  UPDATE_JOBSEEKER_SUCCESS,
  UPDATE_JOBSEEKER_FAIL,
  GET_JOBS_BY_JOBSEEKER_ID,
  GET_JOBS_BY_JOBSEEKER_ID_SUCCESS,
  GET_JOBS_BY_JOBSEEKER_ID_FAIL,
} from '../actions/jobseekerAction';
import {
  updateJobseekerFromArrayById,
} from '../../shared/utils/calculation';

const initialState = {
  jobseekerList: [],
  message: '',
  isUpdate: false,
  isError: false,
  data: null,
  statusCode: '',
  jobseekerEdit: null,
  jobs: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_JOBSEEKERS: {
      return { ...state, type: action.type };
    }
    case GET_JOBSEEKERS_SUCCESS: {
      return { ...state, ...{ type: action.type, jobseekerList: action.data } };
    }
    case GET_JOBSEEKERS_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case UPDATE_JOBSEEKER: {
      return { ...state, type: action.type };
    }
    case UPDATE_JOBSEEKER_SUCCESS: {
      return {
        ...state,
        ...{
          type: action.type,
          isError: false,
          message: 'Update Jobseeker Successful!',
          jobseekerList: updateJobseekerFromArrayById(state.jobseekerList, action.data),
        },
      };
    }
    case UPDATE_JOBSEEKER_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: 'Update Jobseeker Fail!',
        statusCode: action.data ? action.data.statusCode.toString() : '',
      };
    }
    case GET_JOBS_BY_JOBSEEKER_ID: {
      return { ...state, type: action.type };
    }
    case GET_JOBS_BY_JOBSEEKER_ID_SUCCESS: {
      return { ...state, ...{ type: action.type, jobs: action.data } };
    }
    case GET_JOBS_BY_JOBSEEKER_ID_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    default:
      return state;
  }
}
