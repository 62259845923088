import { take, call, put } from 'redux-saga/effects';
import {
  GET_EXPERTISES_BY_FUNCTION_ID,
  GET_EXPERTISES_BY_FUNCTION_ID_SUCCESS,
  GET_EXPERTISES_BY_FUNCTION_ID_FAIL,
  CREATE_EXPERTISE,
  CREATE_EXPERTISE_SUCCESS,
  CREATE_EXPERTISE_FAIL,
  UPDATE_EXPERTISE,
  UPDATE_EXPERTISE_SUCCESS,
  UPDATE_EXPERTISE_FAIL,
  DELETE_EXPERTISE_BY_ID,
  DELETE_EXPERTISE_BY_ID_SUCCESS,
  DELETE_EXPERTISE_BY_ID_FAIL,
} from '../actions/expertiseAction';
import {
  updateExpertise,
  createExpertise,
  deleteExpertiseById,
  getExpertisesByFunctionId,
} from '../../shared/api/methods/expertise';

export function* getExpertisesByFunctionIdSaga() {
  while (true) {
    try {
      const { functionId } = yield take(GET_EXPERTISES_BY_FUNCTION_ID);
      const data = yield call(getExpertisesByFunctionId, functionId);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_EXPERTISES_BY_FUNCTION_ID_FAIL, error });
      } else {
        yield put({ type: GET_EXPERTISES_BY_FUNCTION_ID_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_EXPERTISES_BY_FUNCTION_ID_FAIL, error });
    }
  }
}

export function* createExpertiseSaga() {
  while (true) {
    try {
      const { params } = yield take(CREATE_EXPERTISE);
      const data = yield call(createExpertise, params);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: CREATE_EXPERTISE_FAIL, error });
      } else {
        yield put({ type: CREATE_EXPERTISE_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: CREATE_EXPERTISE_FAIL, error });
    }
  }
}

export function* updateExpertiseSaga() {
  while (true) {
    try {
      const { idExpertise, params } = yield take(UPDATE_EXPERTISE);
      const data = yield call(updateExpertise, [idExpertise, params]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_EXPERTISE_FAIL, error });
      } else {
        yield put({ type: UPDATE_EXPERTISE_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: UPDATE_EXPERTISE_FAIL, error });
    }
  }
}

export function* deleteExpertiseByIdSaga() {
  while (true) {
    try {
      const { expertiseId } = yield take(DELETE_EXPERTISE_BY_ID);
      const data = yield call(deleteExpertiseById, expertiseId);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: DELETE_EXPERTISE_BY_ID_FAIL, error });
      } else {
        yield put({ type: DELETE_EXPERTISE_BY_ID_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: DELETE_EXPERTISE_BY_ID_FAIL, error });
    }
  }
}
