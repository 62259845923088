export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';

export const GET_CATEGORY_BY_ID = 'GET_CATEGORY_BY_ID';
export const GET_CATEGORY_BY_ID_SUCCESS = 'GET_CATEGORY_BY_ID_SUCCESS';
export const GET_CATEGORY_BY_ID_FAIL = 'GET_CATEGORY_BY_ID_FAIL';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

export const SORT_CATEGORY = 'SORT_CATEGORY';
export const SORT_CATEGORY_SUCCESS = 'SORT_CATEGORY_SUCCESS';
export const SORT_CATEGORY_FAIL = 'SORT_CATEGORY_FAIL';

export function getCategories() {
  return {
    type: GET_CATEGORIES,
  };
}

export function getCategoriesSuccess(data) {
  return {
    type: GET_CATEGORIES_SUCCESS,
    data,
  };
}

export function getCategoriesFail(error) {
  return {
    type: GET_CATEGORIES_FAIL,
    error,
  };
}

export function createCategory(params) {
  return {
    type: CREATE_CATEGORY,
    params,
  };
}

export function createCategorySuccess(data) {
  return {
    type: CREATE_CATEGORY_SUCCESS,
    data,
  };
}

export function createCategoryFail(error) {
  return {
    type: CREATE_CATEGORY_FAIL,
    error,
  };
}

export function updateCategory(id, params) {
  return {
    type: UPDATE_CATEGORY,
    id,
    params,
  };
}

export function updateCategorySuccess(data) {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    data,
  };
}

export function updateCategoryFail(error) {
  return {
    type: UPDATE_CATEGORY_FAIL,
    error,
  };
}

export function deleteCategory(id) {
  return {
    type: DELETE_CATEGORY,
    id,
  };
}

export function deleteCategorySuccess(data) {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    data,
  };
}

export function deleteCategoryFail(error) {
  return {
    type: DELETE_CATEGORY_FAIL,
    error,
  };
}

export function getCategoryById(id) {
  return {
    type: GET_CATEGORY_BY_ID,
    id,
  };
}

export function getCategoryByIdSuccess(data) {
  return {
    type: GET_CATEGORY_BY_ID_SUCCESS,
    data,
  };
}

export function getCategoryByIdFail(error) {
  return {
    type: GET_CATEGORY_BY_ID_FAIL,
    error,
  };
}

export function sortCategory(items) {
  return {
    type: SORT_CATEGORY,
    items,
  };
}

export function sortCategorySuccess(data) {
  return {
    type: SORT_CATEGORY_SUCCESS,
    data,
  };
}

export function sortCategoryFail(error) {
  return {
    type: SORT_CATEGORY_FAIL,
    error,
  };
}
