import api from '../index';
import ApiConstants from '../ApiConstants';

export const getJobseekers = args => api(
  `${ApiConstants.JOBSEEKER}/?q=${args[0]}&order=${args[1]}&asc=${args[2]}&limit=${args[3]}&skip=${args[4]}`,
  null,
  'get',
  null,
);
export const updateJobseeker = args => api(
  `/admin/${args[0]}/candidate`,
  args[1],
  'PATCH',
  null,
);

export const getJobsByJobseekerId = args => api(
  `/admin/${args[0]}/applied-job/?order=${args[1]}&asc=${args[2]}&limit=${args[3]}&skip=${args[4]}`,
  null,
  'get',
  null,
);
