/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, FormGroup, Label, Button } from 'reactstrap';
import './styleField.scss';
import Table from '../../../../components/Table/index';
import Modals from '../../../../components/Modal';

class Sologan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title1: {
        en: '',
        vi: '',
      },
      title2: {
        en: '',
        vi: '',
      },
      indexTitle1: null,
      indexTitle2: null,
      isOpen: false,
      isTitle1: null,
    };
  }

  handleChange = (e) => {
    const { onUpdateSologan, formField } = this.props;
    const { sologanDesc } = formField;
    if (e.target.name === 'question1En') {
      sologanDesc[0].question.en = e.target.value;
    } else if (e.target.name === 'question2En') {
      sologanDesc[1].question.en = e.target.value;
    } else if (e.target.name === 'question1Vi') {
      sologanDesc[0].question.vi = e.target.value;
    } else if (e.target.name === 'question2Vi') {
      sologanDesc[1].question.vi = e.target.value;
    }
    onUpdateSologan(sologanDesc);
  }

  handleChangeTitle = (e) => {
    const { title1, title2 } = this.state;
    if (e.target.name === 'title1En') {
      title1.en = e.target.value;
    } else if (e.target.name === 'title2En') {
      title2.en = e.target.value;
    } else if (e.target.name === 'title1Vi') {
      title1.vi = e.target.value;
    } else if (e.target.name === 'title2Vi') {
      title2.vi = e.target.value;
    }

    this.setState({
      title1,
      title2,
    });
  }

  handleAddTitle = (isTitle1) => {
    const { title1, title2 } = this.state;
    const { onUpdateSologan, formField } = this.props;
    const { sologanDesc } = formField;
    if (isTitle1) {
      if (title1.en !== '' || title1.vi !== '') {
        sologanDesc[0].title.push({
          en: title1.en,
          vi: title1.vi,
        });
        this.setState({
          title1: {
            en: '',
            vi: '',
          },
        });
      }
    } else if (title2.en !== '' || title2.vi !== '') {
      sologanDesc[1].title.push({
        en: title2.en,
        vi: title2.vi,
      });
      this.setState({
        title2: {
          en: '',
          vi: '',
        },
      });
    }
    onUpdateSologan(sologanDesc);
  };

  handleUpdateTitle = (isTitle1) => {
    const { indexTitle1, indexTitle2, title1, title2 } = this.state;
    const { onUpdateSologan, formField } = this.props;
    const { sologanDesc } = formField;
    if (isTitle1) {
      if (indexTitle1 !== null && (title1.en !== '' || title1.vi !== '')) {
        sologanDesc[0].title[indexTitle1].en = title1.en;
        sologanDesc[0].title[indexTitle1].vi = title1.vi;
        this.setState({
          title1: {
            en: '',
            vi: '',
          },
          indexTitle1: null,
        });
      }
    } else if (indexTitle2 !== null && (title2.en !== '' || title2.vi !== '')) {
      sologanDesc[1].title[indexTitle2].en = title2.en;
      sologanDesc[1].title[indexTitle2].vi = title2.vi;
      this.setState({
        title2: {
          en: '',
          vi: '',
        },
        indexTitle2: null,
      });
    }
    onUpdateSologan(sologanDesc);
  };

  handleClickEditRow = (original, isTitle1) => {
    const { en, vi } = original;
    const { title1, title2 } = this.state;
    if (isTitle1) {
      title1.en = en;
      title1.vi = vi;
      this.setState({
        title1,
      });
    } else {
      title2.en = en;
      title2.vi = vi;
      this.setState({
        title2,
      });
    }
  };

  handleClickDeleteRow = (isTitle1) => {
    this.setState({
      isTitle1,
      isOpen: true,
    });
  };

  renderBodyModalConfirm = () => (
    <div className="Modal__body">
      <div className="alert alert-danger" role="alert">
        Do you want to delete it?.
      </div>
    </div>
  );

  renderFooterModalConfirm = () => (
    <div className="Modal__footer">
      <button
        type="button"
        className="btn btn-sm btn-outline-primary"
        onClick={this.deleteTitle}
      >
        Delete
      </button>
      <button
        type="button"
        className="btn btn-sm btn-outline-danger"
        onClick={this.handleCancelModalConfirm}
      >
        Cancel
      </button>
    </div>
  );

  handleCancelModalConfirm = () => {
    const { isTitle1 } = this.state;
    if (isTitle1) {
      this.setState({
        indexTitle1: null,
      });
    } else {
      this.setState({
        indexTitle2: null,
      });
    }
    this.setState({
      isOpen: false,
      isTitle1: null,
    });
  };

  deleteTitle = () => {
    const { indexTitle1, indexTitle2, isTitle1 } = this.state;
    const { onUpdateSologan, formField } = this.props;
    const { sologanDesc } = formField;
    if (isTitle1) {
      sologanDesc[0].title.splice(indexTitle1, 1);
      this.setState({
        indexTitle1: null,
      });
    } else {
      sologanDesc[1].title.splice(indexTitle2, 1);
      this.setState({
        indexTitle2: null,
      });
    }
    this.setState({
      isOpen: false,
    });
    onUpdateSologan(sologanDesc);
  };

  renderTableTitle = (dataTable, isTitle1, isEdit) => (
    <Table
      data={dataTable}
      columns={[
        {
          Header: 'English',
          accessor: 'en',
        },
        {
          Header: 'Vietnamese',
          accessor: 'vi',
        },
        {
          Header: 'Actions',
          accessor: null,
          Cell: props => (
            <div className="title-table-actions">
              <Button
                type="button"
                color="primary"
                size="sm"
                disabled={!isEdit}
                onClick={() => {
                  this.handleClickEditRow(props.original, isTitle1);
                }}
              >
                Edit
              </Button>
              <Button
                type="button"
                color="danger"
                size="sm"
                disabled={!isEdit}
                onClick={() => this.handleClickDeleteRow(isTitle1)}
              >
                Delete
              </Button>
            </div>
          ),
        },
      ]}
      defaultPageSize={15}
      className="custom-table"
      showPaginationBottom
      sortable={false}
      getTdProps={(state, rowInfo, column, instance) => ({
        onClick: () => {
          if (column.Header === 'Actions') {
            if (isTitle1) {
              this.setState({
                indexTitle1: rowInfo.index,
              });
            } else {
              this.setState({
                indexTitle2: rowInfo.index,
              });
            }
          }
        },
      })}
    />
  )

  render() {
    const { formField, isEdit } = this.props;
    const { sologanDesc } = formField;
    const { title1, title2, isOpen } = this.state;
    const inputClass = isEdit ? '' : 'input-disable';
    const isDisable = isEdit ? '' : 'disabled';
    const question1En = sologanDesc && sologanDesc.length > 0 && sologanDesc[0].question && sologanDesc[0].question.en ? sologanDesc[0].question.en : '';
    const question2En = sologanDesc && sologanDesc.length > 1 && sologanDesc[1].question && sologanDesc[1].question.en ? sologanDesc[1].question.en : '';
    const question1Vi = sologanDesc && sologanDesc.length > 0 && sologanDesc[0].question && sologanDesc[0].question.vi ? sologanDesc[0].question.vi : '';
    const question2Vi = sologanDesc && sologanDesc.length > 1 && sologanDesc[1].question && sologanDesc[1].question.vi ? sologanDesc[1].question.vi : '';
    const dataTableTitle1 = sologanDesc && sologanDesc.length > 0 && sologanDesc[0].title ? sologanDesc[0].title : [];
    const dataTableTitle2 = sologanDesc && sologanDesc.length > 1 && sologanDesc[1].title ? sologanDesc[1].title : [];
    return (
      <>
        <FormGroup row>
          <Col sm={6}>
            <Label for="question1En">Question1 (EN)</Label>
            <Input type="textarea" className={inputClass} id="question1En" name="question1En" onChange={e => this.handleChange(e)} disabled={isDisable} value={question1En} />
          </Col>
          <Col sm={6}>
            <Label for="question2En">Question2 (EN)</Label>
            <Input type="textarea" className={inputClass} id="question2En" name="question2En" onChange={e => this.handleChange(e)} disabled={isDisable} value={question2En} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="question1Vi">Question1 (VI)</Label>
            <Input type="textarea" className={inputClass} id="question1Vi" name="question1Vi" onChange={e => this.handleChange(e)} disabled={isDisable} value={question1Vi} />
          </Col>
          <Col sm={6}>
            <Label for="question2Vi">Question2 (VI)</Label>
            <Input type="textarea" className={inputClass} id="question2Vi" name="question2Vi" onChange={e => this.handleChange(e)} disabled={isDisable} value={question2Vi} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="title1En">Title1 (EN)</Label>
            <Input type="textarea" className={inputClass} id="title1En" name="title1En" onChange={e => this.handleChangeTitle(e)} disabled={isDisable} value={title1.en} />
          </Col>
          <Col sm={6}>
            <Label for="title2En">Title2 (EN)</Label>
            <Input type="textarea" className={inputClass} id="title2En" name="title2En" onChange={e => this.handleChangeTitle(e)} disabled={isDisable} value={title2.en} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="title1Vi">Title1 (VI)</Label>
            <Input type="textarea" className={inputClass} id="title1Vi" name="title1Vi" onChange={e => this.handleChangeTitle(e)} disabled={isDisable} value={title1.vi} />
            <Button
              type="button"
              color="primary"
              size="sm"
              className="float-right"
              disabled={!isEdit}
              onClick={() => {
                this.handleAddTitle(true);
              }}
            >Add Title1
            </Button>
            <Button
              type="button"
              color="primary"
              size="sm"
              className="float-right"
              disabled={!isEdit}
              onClick={() => {
                this.handleUpdateTitle(true);
              }}
            >Update Title1
            </Button>
          </Col>
          <Col sm={6}>
            <Label for="title2Vi">Title2 (VI)</Label>
            <Input type="textarea" className={inputClass} id="title2Vi" name="title2Vi" onChange={e => this.handleChangeTitle(e)} disabled={isDisable} value={title2.vi} />
            <Button
              type="button"
              color="primary"
              size="sm"
              className="float-right"
              disabled={!isEdit}
              onClick={() => {
                this.handleAddTitle(false);
              }}
            >Add Title2
            </Button>
            <Button
              type="button"
              color="primary"
              size="sm"
              className="float-right"
              disabled={!isEdit}
              onClick={() => {
                this.handleUpdateTitle(false);
              }}
            >Update Title1
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            {this.renderTableTitle(dataTableTitle1, true, isEdit)}
          </Col>
          <Col sm={6}>
            {this.renderTableTitle(dataTableTitle2, false, isEdit)}
          </Col>
        </FormGroup>
        <Modals
          isOpen={isOpen}
          size="xs"
          title="Confirm Delete"
          footerModal={this.renderFooterModalConfirm}
          onCancel={this.handleCancelModalConfirm}
        >
          {this.renderBodyModalConfirm}
        </Modals>
      </>
    );
  }
}

Sologan.propTypes = {
  formField: PropTypes.instanceOf(Object).isRequired,
  isEdit: PropTypes.bool.isRequired,
  onUpdateSologan: PropTypes.func.isRequired,
};

export default Sologan;
