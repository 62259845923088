import api from '../index';
import ApiConstants, { METHOD } from '../ApiConstants';

export const getDescription = () => api(
  `${ApiConstants.DESCRIPTION}`,
  null,
  METHOD.GET,
);

export const saveDescription = (description) => {
  const type = description.id ? METHOD.PATCH : METHOD.POST;
  const path = description.id ? `${ApiConstants.DESCRIPTION}/${description.id}` : `${ApiConstants.DESCRIPTION}`;
  return api(
    path,
    description,
    type,
  );
};

export const deleteDescriptionById = (idRow) => {
  const path = `${ApiConstants.DESCRIPTION}/${idRow}`;
  return api(
    path,
    null,
    METHOD.DELETE,
  );
};

export const updateOrderDescription = (data) => {
  const path = `${ApiConstants.DESCRIPTION}/order`;
  return api(
    path,
    data,
    METHOD.PATCH,
  );
};
