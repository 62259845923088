export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAIL = 'GET_TAGS_FAIL';

export const CREATE_TAG = 'CREATE_TAG';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAIL = 'CREATE_TAG_FAIL';

export const UPDATE_TAG = 'UPDATE_TAG';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAIL = 'UPDATE_TAG_FAIL';

export const DELETE_TAG = 'DELETE_TAG';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAIL = 'DELETE_TAG_FAIL';

export const GET_TAG_BY_ID = 'GET_TAG_BY_ID';
export const GET_TAG_BY_ID_SUCCESS = 'GET_TAG_BY_ID_SUCCESS';
export const GET_TAG_BY_ID_FAIL = 'GET_TAG_BY_ID_FAIL';

export function getTags(tagTyes) {
  return {
    type: GET_TAGS,
    tagTyes,
  };
}

export function getTagsSuccess(data) {
  return {
    type: GET_TAGS_SUCCESS,
    data,
  };
}

export function getTagsFail(error) {
  return {
    type: GET_TAGS_FAIL,
    error,
  };
}

export function createTag(params) {
  return {
    type: CREATE_TAG,
    params,
  };
}

export function createTagSuccess(data) {
  return {
    type: CREATE_TAG_SUCCESS,
    data,
  };
}

export function createTagFail(error) {
  return {
    type: CREATE_TAG_FAIL,
    error,
  };
}

export function updateTag(id, params) {
  return {
    type: UPDATE_TAG,
    id,
    params,
  };
}

export function updateTagSuccess(data) {
  return {
    type: UPDATE_TAG_SUCCESS,
    data,
  };
}

export function updateTagFail(error) {
  return {
    type: UPDATE_TAG_FAIL,
    error,
  };
}

export function deleteTag(id) {
  return {
    type: DELETE_TAG,
    id,
  };
}

export function deleteTagSuccess(data) {
  return {
    type: DELETE_TAG_SUCCESS,
    data,
  };
}

export function deleteTagFail(error) {
  return {
    type: DELETE_TAG_FAIL,
    error,
  };
}

export function getTagById(id) {
  return {
    type: GET_TAG_BY_ID,
    id,
  };
}

export function getTagByIdSuccess(data) {
  return {
    type: GET_TAG_BY_ID_SUCCESS,
    data,
  };
}

export function getTagByIdFail(error) {
  return {
    type: GET_TAG_BY_ID_FAIL,
    error,
  };
}
