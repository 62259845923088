export const TOOGLE_MODAL_ADD = 'TOOGLE_MODAL_ADD';
export const TOOGLE_MODAL_EDIT = 'TOOGLE_MODAL_EDIT';
export const TOOGLE_MODAL_DELETE = 'TOOGLE_MODAL_DELETE';
export const TOOGLE_MODAL_SAVE = 'TOOGLE_MODAL_SAVE';

export function toogleModalAdd(data) {
  return {
    type: TOOGLE_MODAL_ADD,
    data,
  };
}

export function toogleModalEdit(data) {
  return {
    type: TOOGLE_MODAL_EDIT,
    data,
  };
}

export function toogleModalDelete(data) {
  return {
    type: TOOGLE_MODAL_DELETE,
    data,
  };
}

export function toogleModalSave(data) {
  return {
    type: TOOGLE_MODAL_SAVE,
    data,
  };
}
