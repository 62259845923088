import {
  TOOGLE_MODAL_ADD,
  TOOGLE_MODAL_EDIT,
  TOOGLE_MODAL_DELETE,
  TOOGLE_MODAL_SAVE,
} from '../actions/modalAction';

const initialState = {
  isShow: false,
  isFieldGroup: false,
  isFiledGroupChild: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOOGLE_MODAL_ADD: {
      if (action.data.isShow === false) {
        return Object.assign({}, initialState);
      }
      return Object.assign({}, state, action.data);
    }
    case TOOGLE_MODAL_EDIT: {
      if (action.data.isShow === false) {
        return Object.assign({}, initialState);
      }
      return Object.assign({}, state, action.data);
    }
    case TOOGLE_MODAL_DELETE: {
      if (action.data.isShow === false) {
        return Object.assign({}, initialState);
      }
      return Object.assign({}, state, action.data);
    }
    case TOOGLE_MODAL_SAVE: {
      if (action.data.isShow === false) {
        return Object.assign({}, initialState);
      }
      return Object.assign({}, state, action.data);
    }
    default:
      return state;
  }
}
