/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Row, Col, Label, Button, FormGroup, Tooltip, ButtonGroup,
} from 'reactstrap';
import AddIcon from 'mdi-react/AddIcon';
import DeleteIcon from 'mdi-react/TrashIcon';
import EditIcon from 'mdi-react/EditIcon';
import CopyIcon from 'mdi-react/ContentCopyIcon';
import InformationIcon from 'mdi-react/InformationIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toogleModalAdd, toogleModalEdit, toogleModalDelete } from '../../../redux/actions/modalAction';
import GroupChild from './GroupChildComponent';
import Field from './FieldComponent';

class GroupComponent extends React.Component {
  constructor(props) {
    super(props);
    const generateID = Math.random().toString(36).substr(2, 9);
    this.state = {
      tooltipOpen: false,
      tooltipTarget: `tooltip-${generateID}`,
    };
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toogleModalAdd = this.toogleModalAdd.bind(this);
    this.toogleModalEditDeleteClone = this.toogleModalEditDeleteClone.bind(this);
    this.htmlFields = this.htmlFields.bind(this);
  }

  toogleModalAdd = () => {
    const { dispatch, typeSection, indexInTypeSection } = this.props;
    const data = {
      isShow: true,
      typeSection,
      indexInTypeSection,
      isShowGroup: true,
    };

    dispatch(toogleModalAdd(data));
  }

  toogleModalEditDeleteClone = (data, actionEdit, actionDelete) => {
    const {
      dispatch,
      typeSection,
      indexInTypeSection,
    } = this.props;

    const dataModal = {
      isShow: true,
      typeSection,
      indexInTypeSection,
    };

    if (actionEdit) {
      const isShowGroup = typeof index === 'undefined';
      dataModal.isEdit = true;
      dataModal.dataEdit = data;
      dataModal.isShowGroup = isShowGroup;
      dispatch(toogleModalEdit(dataModal));
    } else {
      if (actionDelete) {
        dataModal.isDelete = true;
      } else {
        dataModal.isClone = true;
      }
      dispatch(toogleModalDelete(dataModal));
    }
  }

  toggleTooltip = () => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  htmlFields = (fields, typeSection) => {
    const html = [];
    const { indexInTypeSection } = this.props;
    const isShowGroup = true;
    if (fields.length > 0) {
      for (let i = 0; i < fields.length; i += 1) {
        const item = fields[i];
        if (item.type === 'Group') {
          html.push(<GroupChild data={item} typeSection={typeSection} indexInTypeSection={indexInTypeSection} indexInGroup={i} />);
        } else {
          html.push(<Field data={item} typeSection={typeSection} isShowGroup={isShowGroup} indexInTypeSection={indexInTypeSection} indexInGroup={i} />);
        }
      }
    }
    return html;
  }

  render() {
    const {
      template, indexInTypeSection, typeSection, typeGroupSection,
    } = this.props;
    const data = typeGroupSection ? template.data[typeGroupSection][typeSection].fields[indexInTypeSection] : template.data[typeSection].fields[indexInTypeSection];
    const {
      type, label, tooltip, fieldName,
    } = data;
    const { fields } = data.detail;
    const { tooltipOpen, tooltipTarget } = this.state;
    const labelField = label && label.en ? label.en : '';
    const tooltipField = tooltip && tooltip.en ? tooltip.en : '';
    return (
      <Container>
        <Row>
          <Col md={3} sm={12} className="border">
            <FormGroup>
              {labelField !== '' && (
                <Label sm={12}><strong>{labelField}</strong>
                  { tooltipField !== '' && (
                    <span>
                      <span id={tooltipTarget}><InformationIcon /></span>
                      <Tooltip isOpen={tooltipOpen} target={tooltipTarget} toggle={this.toggleTooltip}>
                        {tooltipField}
                      </Tooltip>
                    </span>
                  )}
                </Label>
              )}
            </FormGroup>
            <Button outline color="primary" size="sm" onClick={() => this.toogleModalAdd()}>
              <p><AddIcon />Add Field</p>
            </Button>
          </Col>
          <Col className="border">
            <FormGroup row>
              <Col md={9}>
                <Label><h4><strong>{type}</strong></h4></Label><br />
                <Label><strong>{fieldName}</strong></Label>
              </Col>
              <Col>
                <ButtonGroup className="float-right">
                  <Button outline color="danger" size="sm" onClick={() => this.toogleModalEditDeleteClone(data, false, true)}>
                    <p><DeleteIcon /></p>
                  </Button>
                  <Button outline color="primary" size="sm" onClick={() => this.toogleModalEditDeleteClone(data, true, false)}>
                    <p><EditIcon /></p>
                  </Button>
                  <Button outline color="primary" size="sm" onClick={() => this.toogleModalEditDeleteClone(data, false, false)}>
                    <p><CopyIcon /></p>
                  </Button>
                </ButtonGroup>
              </Col>
            </FormGroup>
            {this.htmlFields(fields, typeSection)}
          </Col>
        </Row>
      </Container>
    );
  }
}

GroupComponent.propTypes = {
  // data: PropTypes.arrayOf.isRequired,
  dispatch: PropTypes.func.isRequired,
  typeSection: PropTypes.string.isRequired,
  indexInTypeSection: PropTypes.number.isRequired,
  template: PropTypes.objectOf(PropTypes.object).isRequired,
  typeGroupSection: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { template } = state;
  return {
    template,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withTranslation('common')(GroupComponent));
