/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class TableItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  htmlTableItem = () => {
    const { state, getDataItem } = this.props;
    const { dataField } = state;
    const fieldType = dataField.type;
    const htmlRows = [];
    if (fieldType === 'Set' || fieldType === 'Skill') {
      const { listItemSet } = state;
      if (listItemSet.length > 0) {
        for (let i = 0; i < listItemSet.length; i += 1) {
          const item = listItemSet[i];
          const htmlRow = (<tr onClick={() => getDataItem(i)} key={i}><td>{item.value.en}</td><td>{item.value.vi}</td><td>{item.tooltip.en}</td><td>{item.tooltip.vi}</td></tr>);
          htmlRows.push(htmlRow);
        }
      }
    } else if (fieldType === 'Language') {
      const { listItemLanguage } = state;
      if (listItemLanguage.length > 0) {
        for (let i = 0; i < listItemLanguage.length; i += 1) {
          const item = listItemLanguage[i];
          const listeningLte = item.listening ? item.listening.lte : '';
          const listeningGte = item.listening ? item.listening.gte : '';
          const speakingLte = item.speaking ? item.speaking.lte : '';
          const speakingGte = item.speaking ? item.speaking.gte : '';
          const readingLte = item.reading ? item.reading.lte : '';
          const readingGte = item.reading ? item.reading.gte : '';
          const writingLte = item.writing ? item.writing.lte : '';
          const writingGte = item.writing ? item.writing.gte : '';
          const htmlRow = (<tr onClick={() => getDataItem(i)} key={i}><td>{item.label.en}</td><td>{item.label.vi}</td><td>{listeningGte} - {listeningLte}</td><td>{speakingGte} - {speakingLte}</td><td>{readingGte} - {readingLte}</td><td>{writingGte} - {writingLte}</td></tr>);
          htmlRows.push(htmlRow);
        }
      }
    } else if (fieldType === 'Currency') {
      const { listItemUnit } = state;
      if (listItemUnit.length > 0) {
        for (let i = 0; i < listItemUnit.length; i += 1) {
          const item = listItemUnit[i];
          const htmlRow = (<tr onClick={() => getDataItem(i)} key={i}><td>{item}</td></tr>);
          htmlRows.push(htmlRow);
        }
      }
    }

    return htmlRows;
  }

  render() {
    return (
      this.htmlTableItem()
    );
  }
}

TableItemModal.propTypes = {
  state: PropTypes.objectOf(PropTypes.object).isRequired,
  getDataItem: PropTypes.func.isRequired,
};

export default connect(
  null,
  null,
)(withTranslation('common')(TableItemModal));
