import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import TagList from './TagList';
import { BasicNotification } from '../../../../components/Notification/index';
import TagForm from './TagForm';
import {
  getTags as getTagsAction,
  updateTag as updateTagAction,
  deleteTag as deleteTagAction,
} from '../../../../redux/actions/tagAction';
import {
  STATUS_CODE_401,
  DEFAULT_QUERY_LIMIT,
  DEFAULT_QUERY_SKIP,
} from '../../../../shared/utils/constants';

let notificationRU = null;

class Tag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isForm: false,
      isShowNotify: false,
      type: '',
    };
  }

  componentWillMount = async () => {
    const { cookies, getTags, match } = this.props;
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    const { path } = match;
    let tagType;
    if (path.includes('recruiter')) {
      tagType = 'RECRUITER';
      this.setState({ type: 'RECRUITER' });
    }
    if (path.includes('jobseeker')) {
      tagType = 'CANDIDATE';
      this.setState({ type: 'CANDIDATE' });
    }
    getTags(tagType);
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  onUpdateIsHot = (id, params) => {
    const { updateTag } = this.props;
    updateTag(id, params);
    this.setState({ isShowNotify: true });
  }

  canceForm = () => {
    this.setState({ isForm: false });
  }

  show = (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Posts"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  handleClickAddTag = () => {
    this.setState({ isForm: true });
  }

  onSearchTag = (query) => {
    const { getTags } = this.props;
    getTags(query, DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  onDelete = (id) => {
    const { deleteTag } = this.props;
    deleteTag(id);
    this.setState({ isShowNotify: true });
  }

  render() {
    const { tag, cookies } = this.props;
    const {
      hasToken, isForm, isShowNotify, type,
    } = this.state;
    let data = [];
    let titType = '';
    if (type === 'CANDIDATE') {
      titType = 'Jobseeker';
    }
    if (type === 'RECRUITER') {
      titType = 'Recruiter';
    }
    if (tag.tagList) {
      data = tag.tagList.map(item => ({
        id: item.id,
        english: item.name.en || '',
        vietnamese: item.name.vi || '',
        description: item.description,
        slug: item.slug,
        views: item.views,
        isHot: item.isHot,
        isDeleted: item.isDeleted,
      }));
    }
    if (isShowNotify && tag.message) {
      if (tag.isError) {
        this.show('warning', tag.message);
      } else {
        this.show('success', tag.message);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || tag.statusCode === STATUS_CODE_401) {
      if (tag.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <div>
        {isForm ? (
          <TagForm
            onCancelForm={this.canceForm}
            isAddItem={isForm}
            tType={type}
          />
        ) : (
          <Container>
            <Row>
              <Col md={12}>
                <h3 className="page-title">{titType} Tags</h3>
              </Col>
            </Row>
            <Card body className="mb-5">
              <TagList
                tags={data}
                catList={tag.categories}
                tType={type}
                onSearch={query => this.onSearchTag(query)}
                tagEditing={item => this.onEditTag(item)}
                onAddTag={this.handleClickAddTag}
                onUpdateTag={(id, params) => this.onUpdateIsHot(id, params)}
                onDeleteTag={id => this.onDelete(id)}
              />
            </Card>
          </Container>
        )}
      </div>
    );
  }
}

Tag.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  tag: PropTypes.instanceOf(Object).isRequired,
  updateTag: PropTypes.func.isRequired,
  getTags: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { tag } = state;
  return {
    tag,
  };
};
const mapDispatchToProps = dispatch => ({
  getTags: tagType => dispatch(getTagsAction(tagType)),
  updateTag: (...args) => dispatch(updateTagAction(...args)),
  deleteTag: id => dispatch(deleteTagAction(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(Tag));
