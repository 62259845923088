/* eslint-disable object-curly-newline */
import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import CatList from './CatList';
import { BasicNotification } from '../../../../components/Notification/index';
import {
  getCategories as getCategoriesAction,
  updateCategory as updateCategoryAction,
  deleteCategory as deleteCategoryAction,
  resetMessage as resetMessageAction,
} from '../../../../redux/actions/faqCategoryAction';
import {
  STATUS_CODE_401,
  DEFAULT_QUERY_LIMIT,
  DEFAULT_QUERY_SKIP,
} from '../../../../shared/utils/constants';
import Modals from '../../../../components/Modal';

let notificationRU = null;

class FaqsCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      type: '',
      isOpen: false,
      idDelete: '',
      faqCategory: {},
    };
  }

  componentWillMount = () => {
    const { cookies, getCategories, match } = this.props;
    const { path } = match;
    let cateType;
    if (path.includes('recruiter')) {
      cateType = 'RECRUITER';
      this.setState({ type: 'RECRUITER' });
    }
    if (path.includes('jobseeker')) {
      cateType = 'CANDIDATE';
      this.setState({ type: 'CANDIDATE' });
    }
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }

    getCategories(cateType, 'sortNum', '1', DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillReceiveProps(nextProps) {
    const { faqCategory } = this.props;
    if (nextProps.faqCategory !== faqCategory) {
      this.setState({
        faqCategory: nextProps.faqCategory,
      });
    }
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  handleDeleteCategory = (idDelete) => {
    this.setState({ isOpen: true });
    this.setState({
      idDelete,
    });
  };

  renderBodyModalConfirm = () => (
    <div className="Modal__body">
      <div className="alert alert-danger" role="alert">
        Do you want to delete it?.
      </div>
    </div>
  );

  renderFooterModalConfirm = () => (
    <div className="Modal__footer">
      <button
        type="button"
        className="btn btn-sm btn-outline-primary"
        onClick={this.deleteSelected}
      >
        Delete
      </button>
      <button
        type="button"
        className="btn btn-sm btn-outline-danger"
        onClick={this.handleCancelModalConfirm}
      >
        Cancel
      </button>
    </div>
  );

  handleCancelModalConfirm = () => {
    this.setState({
      isOpen: false,
      idDelete: '',
    });
  }

  deleteSelected = () => {
    this.setState({
      isOpen: false,
    });
    const { type, idDelete } = this.state;
    const { deleteCategory, getCategories } = this.props;
    deleteCategory(idDelete);
    getCategories(type, 'sortNum', '1', DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
  }

  show = (color, message) => {
    const { resetMessage } = this.props;
    notificationRU.notice({
      content: <BasicNotification
        title="Faq"
        color={color}
        message={message}
      />,
      duration: 5,
      closable: true,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: 'right-up',
    });
    resetMessage();
  }


  render() {
    const { cookies } = this.props;
    const { hasToken, type, isOpen, faqCategory } = this.state;
    let titType = '';
    if (type === 'CANDIDATE') {
      titType = 'Jobseeker';
    }
    if (type === 'RECRUITER') {
      titType = 'Recruiter';
    }
    if (faqCategory.message && faqCategory.message !== '') {
      if (faqCategory.isError) {
        this.show('warning', faqCategory.message);
      } else {
        this.show('success', faqCategory.message);
      }
    }
    if (!hasToken || faqCategory.statusCode === STATUS_CODE_401) {
      if (faqCategory.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }

    return (
      <div>
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="page-title">{titType} Faq Category</h3>
            </Col>
          </Row>
          <Card body className="mb-5">
            <CatList
              catList={faqCategory.categoryList ? faqCategory.categoryList : []}
              cateType={type}
              onDeleteCategory={id => this.handleDeleteCategory(id)}
            />
          </Card>
          <Modals
            isOpen={isOpen}
            size="xs"
            title="Confirm Delete"
            footerModal={this.renderFooterModalConfirm}
            onCancel={this.handleCancelModalConfirm}
          >
            {this.renderBodyModalConfirm}
          </Modals>
        </Container>
      </div>
    );
  }
}

FaqsCategory.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  faqCategory: PropTypes.instanceOf(Object).isRequired,
  deleteCategory: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  resetMessage: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { faqCategory } = state;
  return {
    faqCategory,
  };
};
const mapDispatchToProps = dispatch => ({
  getCategories: (...args) => dispatch(getCategoriesAction(...args)),
  updateCategory: (...args) => dispatch(updateCategoryAction(...args)),
  deleteCategory: id => dispatch(deleteCategoryAction(id)),
  resetMessage: () => dispatch(resetMessageAction()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(FaqsCategory));
