/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/named */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Button, Modal, ModalBody, Alert, ButtonGroup, ModalHeader, ModalFooter,
} from 'reactstrap';
import { toogleModalDelete } from '../../../../../redux/actions/modalAction';
import { deleteField } from '../../../../../redux/actions/descriptionAction';


class DeleteFieldModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
    };
  }

  componentDidMount() {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) this.toogleModalDelete();
    });
  }

  toogleModalDelete = () => {
    const { dispatch, modal } = this.props;
    const data = {
      isShow: !modal.isShow,
    };
    dispatch(toogleModalDelete(data));
  };

  deleteField = () => {
    const { dispatch, modal } = this.props;
    dispatch(deleteField(modal.idRow));
    this.toogleModalDelete();
  }

  render() {
    const { modal } = this.props;
    return (
      <div>
        <Modal isOpen={modal.isShow} toogle={this.toogleModalAdd}>
          <ModalHeader><h2>Confirm Delete!</h2></ModalHeader>
          <ModalBody>
            <Alert color="danger">
              <h4>Do you want to delete it?</h4>
            </Alert>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup className="float-right">
              <Button outline color="primary" size="sm" onClick={() => this.toogleModalDelete()}>Cancel</Button>
              <Button outline color="danger" size="sm" onClick={() => this.deleteField()}>OK</Button>
            </ButtonGroup>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

DeleteFieldModal.propTypes = {
  modal: PropTypes.objectOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { modal } = state;
  return {
    modal,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withTranslation('common')(DeleteFieldModal));
