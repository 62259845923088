/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Card, Row, Col } from 'reactstrap';
import Table from '../../../../components/Table/index';
import CardForm from '../CardForm/index';
import { addElementToBeginningArray, deleteElementFromArrayById } from '../../../../utils/calculation';

const initFormInputs = { engInput: '', vietInput: '' };

const CardFunction = ({
  data,
  setIdFunction,
  getExpertiseByFunctionId,
  createFunction,
  updateFunction,
  idDeparment,
}) => {
  const [dataTable, setDataTable] = useState(data);
  const [formValues, setFormValues] = useState(initFormInputs);
  const [id, setId] = useState('');
  const [isReadOnly, setReadOnly] = useState(false);
  const NAME_ACTION = 'Actions';

  useEffect(() => {
    const newData = data.map(item => ({
      id: item.id,
      english: item.name.en || '',
      vietnamese: item.name.vi || '',
    }));
    setDataTable(newData);
  }, [data]);

  const onSubmit = (formInputs) => {
    const { engInput: english, vietInput: vietnamese } = formInputs;

    if (id) {
      updateFunction(id, {
        name: {
          en: english,
          vi: vietnamese,
        },
        isDeleted: false,
        departmentId: idDeparment,
        id, // unnecessary param
      });
      return setId('');
    }

    return createFunction({
      name: {
        en: english,
        vi: vietnamese,
      },
      departmentId: idDeparment,
    });
  };

  const handleClickDeleteRow = (arg) => {
    const { id: idRow, english, vietnamese } = arg;
    updateFunction(idRow, {
      name: {
        en: english,
        vi: vietnamese,
      },
      isDeleted: true,
      id,
    });
  };

  const handleClickEditRow = (idRow) => {
    const filteredData = dataTable.find(item => item.id === idRow);
    setId(idRow);
    setFormValues({ engInput: filteredData.english, vietInput: filteredData.vietnamese });
  };

  const handleCancelForm = () => {
    setId('');
    setFormValues(initFormInputs);
  };

  const handleShowExpertise = (idFunction) => {
    getExpertiseByFunctionId(idFunction);
    setIdFunction(idFunction);
  };

  return (
    <Card body className="mb-5">
      <div className="card-title">Function</div>
      <CardForm
        onSubmit={onSubmit}
        isReadOnly={isReadOnly}
        setReadOnly={setReadOnly}
        formValues={formValues}
        type={id ? 'save' : 'add'}
        cancelForm={handleCancelForm}
      />
      <Table
        data={dataTable}
        columns={[
          {
            Header: 'English',
            accessor: 'english',
          },
          {
            Header: 'Vietnamese',
            accessor: 'vietnamese',
          },
          {
            Header: 'Actions',
            accessor: null,
            Cell: (props) => {
              const {
                original: { id: idRow },
                original,
              } = props;
              return (
                <div className="table-actions">
                  <span
                    role="presentation"
                    className="action-edit"
                    onClick={() => {
                      handleClickEditRow(idRow);
                      setReadOnly(false);
                    }}
                  >
                    Edit
                  </span>
                  <span
                    role="presentation"
                    className="action-delete"
                    onClick={() => handleClickDeleteRow(props.original)}
                  >
                    Delete
                  </span>
                  <span role="presentation" className="action-show" onClick={() => handleShowExpertise(idRow)}>
                    Show Expertise
                  </span>
                </div>
              );
            },
          },
        ]}
        className="custom-table"
        showPaginationBottom
        sortable={false}
        defaultPageSize={5}
        getTdProps={(state, rowInfo, column, instance) => ({
          onClick: () => {
            if (rowInfo && column.Header !== NAME_ACTION) {
              const { id: idData } = rowInfo.original;
              handleClickEditRow(idData);
              setReadOnly(true);
              handleShowExpertise(idData);
            }
          },
        })}
      />
    </Card>
  );
};

CardFunction.propTypes = {};

CardFunction.defaultProps = {};

export default CardFunction;
