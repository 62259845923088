import * as yup from 'yup';
import FIELD_REQUIRED from '../../../../shared/utils/messageError';

const schemaHotCareersForm = yup.object().shape({
  icon: yup.string().required(FIELD_REQUIRED),
  departmentsId: yup.string().required(FIELD_REQUIRED),
  enLabel: yup.string().required(FIELD_REQUIRED),
  viLabel: yup.string().required(FIELD_REQUIRED),
});
export default schemaHotCareersForm;
