/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Col, CardBody, Container, Row, Card } from 'reactstrap';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import PropTypes from 'prop-types';
import { BasicNotification } from '../../../../../components/Notification/index';
import {
  uploadFile as uploadFileAction,
} from '../../../../../redux/actions/uploadFileAction';
import {
  getNewsById as getNewsByIdAction,
  updateNews as updateNewsAction,
  createNews as createNewsAction,
} from '../../../../../redux/actions/newsAction';
import {
  getCategories as getCategoriesAction,
} from '../../../../../redux/actions/categoryAction';
import {
  getTags as getTagsAction,
} from '../../../../../redux/actions/tagAction';
import {
  STATUS_CODE_401,
  DEFAULT_QUERY_LIMIT,
  DEFAULT_QUERY_SKIP,
} from '../../../../../shared/utils/constants';
import './styleEditForm.scss';
import EditForm from './EditForm';

let notificationRU = null;

class PostForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isShowNotify: false,
      postType: '',
    };
  }

  componentWillMount = async () => {
    const { match, getNewsById, getCategories, getTags, cookies, pType } = this.props;
    const token = cookies.get('token') || '';
    let newsType;
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    if (pType) {
      newsType = pType;
      this.setState({ postType: pType });
    } else if (match) {
      const { path } = match;
      if (path.includes('recruiter')) {
        newsType = 'RECRUITER';
        this.setState({ postType: 'RECRUITER' });
      }
      if (path.includes('jobseeker')) {
        newsType = 'CANDIDATE';
        this.setState({ postType: 'CANDIDATE' });
      }
    }
    if (match) {
      getNewsById(match.params.id);
    }
    getCategories(newsType, 'sortNum', '1', DEFAULT_QUERY_LIMIT, DEFAULT_QUERY_SKIP);
    getTags(newsType);
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  showNotification = async (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Posts"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  onUpdateNews = (id, params) => {
    const { updateNews, createNews } = this.props;
    if (id) {
      updateNews(id, params);
    } else {
      createNews(params);
    }
    this.setState({ isShowNotify: true });
  }

  cancelForm = () => {
    const { match, onCancelForm, history } = this.props;
    const { postType } = this.state;
    if (match) {
      if (postType === 'RECRUITER') {
        history.push('/admin/news-recruiter/posts');
      }
      if (postType === 'CANDIDATE') {
        history.push('/admin/news-jobseeker/posts');
      }
    } else {
      onCancelForm();
    }
  }

  render() {
    const { news, cookies, match, category, isAddItem, tag } = this.props;
    const { hasToken, isShowNotify, postType } = this.state;
    let newsForm;
    if (news.newsEdit != null && match && tag.tagList) {
      if (news.newsEdit.id === match.params.id && tag.tagList.length > 0) {
        newsForm = <EditForm data={news.newsEdit} postType={postType} tagList={tag.tagList} categories={category.categoryList} save={(id, params) => this.onUpdateNews(id, params)} onCancel={this.cancelForm} />;
      }
    }
    if (isAddItem && tag.tagList) {
      newsForm = <EditForm tagList={tag.tagList} postType={postType} categories={category.categoryList} save={(id, params) => this.onUpdateNews(id, params)} onCancel={this.cancelForm} />;
    }
    if (isShowNotify && news.message) {
      if (news.isError) {
        this.showNotification('warning', news.message);
      } else {
        this.showNotification('success', news.message);
        setTimeout(() => {
          this.cancelForm();
        }, 3000);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || news.statusCode === STATUS_CODE_401) {
      if (news.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`${isAddItem ? 'Create Post' : 'Update Post'}`}</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          <Col md={12} lg={12} xl={12}>
            <CardBody>
              {newsForm}
            </CardBody>
          </Col>
        </Card>
      </Container>
    );
  }
}

PostForm.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  getNewsById: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object),
  news: PropTypes.instanceOf(Object).isRequired,
  tag: PropTypes.instanceOf(Object).isRequired,
  getCategories: PropTypes.func.isRequired,
  getTags: PropTypes.func.isRequired,
  updateNews: PropTypes.func.isRequired,
  createNews: PropTypes.func.isRequired,
  onCancelForm: PropTypes.func,
  history: PropTypes.instanceOf(Object),
  category: PropTypes.instanceOf(Object).isRequired,
  pType: PropTypes.string,
  isAddItem: PropTypes.bool,
};
const mapStateToProps = (state) => {
  const { news, category, tag } = state;
  return {
    news,
    category,
    tag,
  };
};
const mapDispatchToProps = dispatch => ({
  uploadFile: file => dispatch(uploadFileAction(file)),
  getNewsById: id => dispatch(getNewsByIdAction(id)),
  getCategories: (...args) => dispatch(getCategoriesAction(...args)),
  updateNews: (id, params) => dispatch(updateNewsAction(id, params)),
  createNews: params => dispatch(createNewsAction(params)),
  getTags: type => dispatch(getTagsAction(type)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(PostForm));
