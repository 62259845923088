import React, { Component } from 'react';
import {
  EditorState,
  ContentState,
  convertToRaw,
} from 'draft-js';
import { Modal } from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import ApiConstants from '../../shared/api/ApiConstants';
import { getCookie } from '../../shared/utils/calculation';
import './styleEditor.scss';

function uploadImageCallBack(file) {
  const token = getCookie('token');
  const bearer = `Bearer ${token}`;
  return new Promise(
    (resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${ApiConstants.BASE_URL}${ApiConstants.UPLOADFILE}`);
      xhr.setRequestHeader('Authorization', bearer);
      const data = new FormData();
      data.append('image', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        resolve({ data: { link: response.url } });
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    },
  );
}

const ToolbarOptions = {
  image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true }, previewImage: true },
};

export default class TextEditorTwo extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    defaulValue: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      editorState: EditorState.createEmpty(),
      isViewHTML: false,
      contentEditor: '',
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  componentWillMount() {
    const { defaulValue } = this.props;
    if (defaulValue) {
      const contentBlock = htmlToDraft(defaulValue);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState,
      });
    }
  }

  onEditorStateChange(editorState) {
    const { onChange } = this.props;
    this.setState({
      editorState,
    });
    if (onChange) { onChange(draftToHtml(convertToRaw(editorState.getCurrentContent()))); }
  }

  toggleEditorCode = () => {
    const { editorState } = this.state;
    this.setState({
      isViewHTML: true,
      contentEditor: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  handleCancelModal = () => {
    this.setState({
      isViewHTML: false,
    });
  }

  onEditEditorHTML = (e) => {
    this.setState({
      contentEditor: e.target.value,
    });
  }

  handleSaveHTML = () => {
    const { contentEditor } = this.state;
    const { onChange } = this.props;
    const contentBlock = htmlToDraft(contentEditor);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    this.setState({
      editorState,
      isViewHTML: false,
    });
    if (onChange) { onChange(draftToHtml(convertToRaw(editorState.getCurrentContent()))); }
  }

  render() {
    const { editorState, isViewHTML, contentEditor } = this.state;
    const ShowEditorCode = () => (
      <div className="rdw-html-wrapper">
        <div className="rdw-option-wrapper">
          <button className="btn-html-edit" type="button" onClick={() => this.toggleEditorCode()}>HTML</button>
        </div>
      </div>
    );
    return (
      <div className="text-editor">
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={ToolbarOptions}
          toolbarCustomButtons={[<ShowEditorCode />]}
        />
        <Modal isOpen={isViewHTML} className="editor-edit-html">
          <div className="modal-header">
            <h5 className="modal-title">HTML Edit</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.handleCancelModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <textarea
              rows={20}
              value={contentEditor}
              onChange={this.onEditEditorHTML}
            />
          </div>
          <div className="modal-footer">
            <div className="Modal__footer">
              <button
                type="button"
                color="primary"
                className="card-form__btn card-form__btn--submit"
                onClick={this.handleSaveHTML}
              >
                Update
              </button>
              <button
                type="button"
                color="danger"
                className="card-form__btn card-form__btn--cancel"
                onClick={this.handleCancelModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
