/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { Col, CardBody, Container, Row, Card } from 'reactstrap';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  getCategoryById as getCategoryByIdAction,
  updateCategory as updateCategoryAction,
  createCategory as createCategoryAction,
} from '../../../../../redux/actions/faqCategoryAction';
import {
  STATUS_CODE_401,
} from '../../../../../shared/utils/constants';
import './styleEditForm.scss';
import EditForm from './EditForm';

class CatForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      catType: '',
      isAddItem: false,
    };
  }

  componentWillMount = () => {
    const { match, getCategoryById, cookies, pType } = this.props;
    if (pType) {
      this.setState({ catType: pType });
    } else if (match) {
      const { path } = match;
      if (path.includes('recruiter')) {
        this.setState({ catType: 'RECRUITER' });
      }
      if (path.includes('jobseeker')) {
        this.setState({ catType: 'CANDIDATE' });
      }
      if (path.includes('add')) {
        this.setState({ isAddItem: true });
      }
    }
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    if (match.params.id) {
      getCategoryById(match.params.id);
    }
  }

  onUpdateCategory = (id, params) => {
    const { updateCategory, createCategory } = this.props;
    if (id) {
      updateCategory(id, params);
    } else {
      createCategory(params);
    }
    this.cancelForm();
  }

  cancelForm = () => {
    const { catType } = this.state;
    if (catType === 'RECRUITER') {
      window.location.href = '/admin/faq-recruiter/category';
    }
    if (catType === 'CANDIDATE') {
      window.location.href = '/admin/faq-jobseeker/category';
    }
  }

  render() {
    const { faqCategory, cookies, match } = this.props;
    const { hasToken, catType, isAddItem } = this.state;
    let categoryForm;
    if (faqCategory.categoryEdit != null && match) {
      if (faqCategory.categoryEdit.id === match.params.id) {
        categoryForm = <EditForm catType={catType} data={faqCategory.categoryEdit} save={(id, params) => this.onUpdateCategory(id, params)} onCancel={this.cancelForm} />;
      }
    }
    if (isAddItem) {
      categoryForm = <EditForm catType={catType} save={(id, params) => this.onUpdateCategory(id, params)} onCancel={this.cancelForm} />;
    }

    if (!hasToken || faqCategory.statusCode === STATUS_CODE_401) {
      if (faqCategory.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`${isAddItem ? 'Create' : 'Update'} ${catType === 'RECRUITER' ? 'Recruiter' : 'Jobseeker'}`} Faq Category</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          <Col md={12} lg={12} xl={12}>
            <CardBody>
              { categoryForm }
            </CardBody>
          </Col>
        </Card>
      </Container>
    );
  }
}

CatForm.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  getCategoryById: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  faqCategory: PropTypes.instanceOf(Object).isRequired,
  updateCategory: PropTypes.func.isRequired,
  createCategory: PropTypes.func.isRequired,
  pType: PropTypes.string,
};
const mapStateToProps = (state) => {
  const { faqCategory } = state;
  return {
    faqCategory,
  };
};
const mapDispatchToProps = dispatch => ({
  getCategoryById: id => dispatch(getCategoryByIdAction(id)),
  updateCategory: (id, params) => dispatch(updateCategoryAction(id, params)),
  createCategory: params => dispatch(createCategoryAction(params)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(CatForm));
