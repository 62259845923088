/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import StarIcon from 'mdi-react/StarIcon';
import StarOutline from 'mdi-react/StarOutlineIcon';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Card, Row, Modal, ModalBody, ButtonGroup, ModalHeader, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import Table from '../../../../../components/Table/index';
import './styleCatList.scss';
import {
  MSG_CONFIRM_DELETE,
} from '../../../../../shared/utils/constants';

const SortableItem = SortableElement(({ item }) => (
  <tr>
    <td>{item.name.en}</td>
    <td>{item.name.vi}</td>
  </tr>
));

const SortableList = SortableContainer(({ items }) => (
  <table className="order-table">
    <thead>
      <tr>
        <th scope="col">Name (EN)</th>
        <th scope="col">Name (VI)</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item, i) => (
        <SortableItem key={item.id} index={i} item={item} />
      ))}
    </tbody>
  </table>
));

class CatList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      catItems: [],
      isOpenOrder: false,
      isPopupDelete: false,
      deleteItemId: null,
    };
  }

  componentWillMount = () => {
    const { catsList } = this.props;
    this.setState({ catItems: catsList });
  }

  componentDidMount() {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) this.handleCancelModal();
    });
  }

  handleClickEditRow = (idRow) => {
    const { catsList, categoryEditing } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    const categoryDetailView = catsList.find(item => item._id === idRow);
    if (categoryDetailView) {
      categoryEditing(categoryDetailView);
    }
  }

  handleClickDeleteRow = (idRow) => {
    const { onDeleteCategory } = this.props;
    onDeleteCategory(idRow);
    this.handleCancelModal();
  };

  handleClickChangeStatus = (id, isDeleted) => {
    const { onUpdateCategoryNews } = this.props;
    onUpdateCategoryNews(id, {
      isDeleted: !isDeleted,
    });
  };

  handleSetUsefulResource = (id, value) => {
    const { onUpdateCategoryNews } = this.props;
    onUpdateCategoryNews(id, {
      isUsefulResource: !value,
    });
  };

  onClickAddCategory = () => {
    const { onAddCategory } = this.props;
    onAddCategory();
  }

  handleCancelModal = () => {
    this.setState({
      isOpenOrder: false,
      isPopupDelete: false,
      deleteItemId: null,
    });
  };

  handleShowModal = () => {
    const { catsList } = this.props;
    this.setState({
      isOpenOrder: true,
      catItems: catsList,
    });
  };

  handleShowModalDelete = (id) => {
    this.setState({
      isPopupDelete: true,
      deleteItemId: id,
    });
  };

  onReorderCategory = () => {
    const { updateSortNumber } = this.props;
    const catItems = this.state;
    updateSortNumber(catItems);
    this.handleCancelModal();
  }

  renderPopupOrder = () => {
    const { catItems, isOpenOrder } = this.state;
    return (
      <Modal isOpen={isOpenOrder} toogle={this.toogleModalSave} className="order-popup">
        <ModalHeader>Reorder Category</ModalHeader>
        <ModalBody>
          <SortableList items={catItems} onSortEnd={this.onSortEnd} />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup className="float-right">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.onReorderCategory()}
            >
              Save
            </button>
            <button
              type="button"
              className="card-form__btn card-form__btn--cancel"
              color="danger"
              onClick={() => this.handleCancelModal()}
            >
              Cancel
            </button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }

  renderPopupConfirmDelete = () => {
    const { deleteItemId, isPopupDelete } = this.state;
    return (
      <Modal isOpen={isPopupDelete} toogle={this.toogleModalSave} className="order-popup">
        <ModalHeader>Delete Category</ModalHeader>
        <ModalBody>
          {MSG_CONFIRM_DELETE}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup className="float-right">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.handleClickDeleteRow(deleteItemId)}
            >
              Yes
            </button>
            <button
              type="button"
              className="card-form__btn card-form__btn--cancel"
              color="danger"
              onClick={() => this.handleCancelModal()}
            >
              Cancel
            </button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ catItems }) => ({
      catItems: arrayMove(catItems, oldIndex, newIndex),
    }));
  };

  renderCategoryList = () => {
    const { catsList, postType } = this.props;
    let listUrl;
    if (postType === 'CANDIDATE') {
      listUrl = '/admin/news-jobseeker/category';
    }
    if (postType === 'RECRUITER') {
      listUrl = '/admin/news-recruiter/category';
    }
    return (
      <Table
        data={catsList}
        columns={[
          {
            Header: 'Order',
            accessor: 'sortNum',
            sortable: true,
          },
          {
            Header: 'Published',
            accessor: null,
            Cell: (row) => {
              const { id, isDeleted } = row.original;
              if (!isDeleted) {
                return <div><StarIcon onClick={() => this.handleClickChangeStatus(id, isDeleted)} className="ishot-yes" /></div>;
              }
              return <StarOutline onClick={() => this.handleClickChangeStatus(id, isDeleted)} className="ishot-no" />;
            },
          },
          {
            Header: 'Name (EN)',
            accessor: 'name.en',
            sortable: true,
          },
          {
            Header: 'Name (VI)',
            accessor: 'name.vi',
            sortable: true,
          },
          {
            Header: 'Usefull Resource',
            accessor: null,
            Cell: (row) => {
              const { id, isUsefulResource } = row.original;
              if (isUsefulResource) {
                return <div><StarIcon onClick={() => this.handleSetUsefulResource(id, isUsefulResource)} className="ishot-yes" /></div>;
              }
              return <StarOutline onClick={() => this.handleSetUsefulResource(id, isUsefulResource)} className="ishot-no" />;
            },
          },
          {
            Header: 'Actions',
            accessor: null,
            Cell: (row) => {
              const { id } = row.original;
              return (
                <div className="table-actions">
                  <Link to={`${listUrl}/${id}`}>
                    Edit
                  </Link>
                  <span
                    role="presentation"
                    className="action-delete"
                    onClick={() => this.handleShowModalDelete(id)}
                  >
                    Delete
                  </span>
                </div>

              );
            },
          },
        ]}
        defaultSorted={[
          {
            id: 'sortNum',
            desc: false,
          },
        ]}
        defaultPageSize={10}
        className="custom-table"
        showPaginationBottom
        sortable={false}
      />
    );
  }

  render() {
    return (
      <Card>
        <Row>
          <div className="card-form-wrapper">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.onClickAddCategory()}
            >
              Add
            </button>
            <button
              type="button"
              className="card-form__btn card-form__btn--save"
              color="primary"
              onClick={() => this.handleShowModal()}
            >
              Reorder
            </button>
          </div>
        </Row>
        {this.renderCategoryList()}
        {this.renderPopupOrder()}
        {this.renderPopupConfirmDelete()}
      </Card>
    );
  }
}

CatList.propTypes = {
  onAddCategory: PropTypes.func.isRequired,
  categoryEditing: PropTypes.func.isRequired,
  onUpdateCategoryNews: PropTypes.func.isRequired,
  catsList: PropTypes.instanceOf(Object).isRequired,
  postType: PropTypes.string.isRequired,
  updateSortNumber: PropTypes.func.isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
};

export default CatList;
