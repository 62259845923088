/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  TOGGLE_ADD_FUNCTION,
  TOGGLE_UPDATE_FUNCTION,
  TOGGLE_DELETE_FUNCTION,
  HIDE_STATUS_REQUEST,
  ModalShowType,
  GET_FUNCTIONS,
  GET_FUNCTION_LOADING,
  GET_FUNCTION_SUCCESS,
  GET_FUNCTION_FAILED,
  CREATE_FUNCTION,
  CREATE_FUNCTION_SUCCESS,
  CREATE_FUNCTION_FAILED,
  CANCEL_FUNCTION_ACTION,
  UPDATE_FUNCTION,
  UPDATE_FUNCTION_LOADING,
  UPDATE_FUNCTION_SUCCESS,
  UPDATE_FUNCTION_FAILED,
  DELETE_FUNCTION,
  DELETE_FUNCTION_FAILED,
  DELETE_FUNCTION_SUCCESS,
  DELETE_FUNCTION_LOADING,
  GET_FUNCTIONS_BY_ID_DEPARTMENT,
  GET_FUNCTIONS_BY_ID_DEPARTMENT_SUCCESS,
  GET_FUNCTIONS_BY_ID_DEPARTMENT_FAIL,
} from '../actions/functionAction';
import {
  addElementToBeginningArray,
  updateElementFromArrayById,
  deleteElementFromArrayById,
} from '../../shared/utils/calculation';

const initialState = {
  department: null,
  func: null,
  functions: [],
  loading: false,
  data: null,
  isError: false,
  showType: ModalShowType.NONE,
  statusCode: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FUNCTIONS: {
      return { ...state, isError: false, loading: true };
    }
    case GET_FUNCTION_LOADING: {
      return { ...state, isError: false, loading: true };
    }
    case GET_FUNCTION_SUCCESS: {
      return {
        ...state,
        isError: false,
        loading: false,
        functions: action.data,
      };
    }
    case GET_FUNCTION_FAILED: {
      return {
        ...state,
        isError: true,
        loading: false,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case CREATE_FUNCTION: {
      return { ...state, type: action.type };
    }
    case CREATE_FUNCTION_SUCCESS: {
      return {
        ...state,
        type: action.type,
        functions: addElementToBeginningArray(state.functions, action.data),
      };
    }
    case CREATE_FUNCTION_FAILED: {
      return {
        ...state,
        isError: true,
        loading: false,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case UPDATE_FUNCTION: {
      return { ...state, type: action.type };
    }
    case UPDATE_FUNCTION_LOADING: {
      return { ...state, isError: false, loading: true };
    }
    case UPDATE_FUNCTION_SUCCESS: {
      const { isDeleted, id } = action.data;
      return {
        ...state,
        type: action.type,
        functions: isDeleted
          ? deleteElementFromArrayById(state.functions, id)
          : updateElementFromArrayById(state.functions, action.data),
      };
    }
    case UPDATE_FUNCTION_FAILED: {
      return {
        ...state,
        isError: true,
        loading: false,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case DELETE_FUNCTION: {
      return { ...state, isError: false, loading: true };
    }
    case DELETE_FUNCTION_LOADING: {
      return { ...state, isError: false, loading: true };
    }
    case DELETE_FUNCTION_SUCCESS: {
      return {
        ...state,
        isError: false,
        loading: false,
        data: action.data,
        showType: ModalShowType.NONE,
      };
    }
    case DELETE_FUNCTION_FAILED: {
      return {
        ...state,
        isError: true,
        loading: false,
        statusCode: action.error ? action.error.statusCode.toString() : '',
        showType: ModalShowType.NONE,
      };
    }
    case CANCEL_FUNCTION_ACTION: {
      return {
        ...state,
        showType: ModalShowType.NONE,
      };
    }
    case TOGGLE_ADD_FUNCTION: {
      return {
        ...state,
        showType: ModalShowType.ADD_MODAL,
        department: action.department,
        func: null,
      };
    }
    case TOGGLE_UPDATE_FUNCTION: {
      return {
        ...state,
        showType: ModalShowType.EDIT_MODAL,
        department: action.department,
        func: action.func,
      };
    }
    case TOGGLE_DELETE_FUNCTION: {
      return {
        ...state,
        showType: ModalShowType.DELETE_CONFIRM,
        department: action.department,
        func: action.func,
      };
    }
    case HIDE_STATUS_REQUEST: {
      return {
        ...state,
        data: null,
        isError: false,
      };
    }
    case GET_FUNCTIONS_BY_ID_DEPARTMENT: {
      return { ...state, type: action.type };
    }
    case GET_FUNCTIONS_BY_ID_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        type: action.type,
        functions: action.data,
      };
    }
    case GET_FUNCTIONS_BY_ID_DEPARTMENT_FAIL: {
      return { ...state, type: action.type };
    }
    default:
      return state;
  }
}
