/* eslint-disable object-curly-newline */
import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Col, Card, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../../components/Notification/index';
import {
  getGlobalSetting as getGlobalSettingAction,
  updateGlobalSetting as updateGlobalSettingAction,
} from '../../../redux/actions/globalSettingAction';
import {
  STATUS_CODE_401,
} from '../../../shared/utils/constants';
import EditForm from './EditForm';

let notificationRU = null;
class GlobalSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isShowNotify: false,
    };
  }

  componentWillMount = async () => {
    const { cookies, getGlobalSetting } = this.props;
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    getGlobalSetting();
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  onUpdateGlobalSetting = (params) => {
    const { updateGlobalSetting, globalSetting } = this.props;
    const { id } = globalSetting.globalSettingData;
    updateGlobalSetting(id, params);
    this.setState({ isShowNotify: true });
  }

  show = (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Global Setting"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  render() {
    const { globalSetting, cookies } = this.props;
    const { hasToken, isShowNotify } = this.state;
    if (isShowNotify && globalSetting.message) {
      if (globalSetting.isError) {
        this.show('warning', globalSetting.message);
      } else {
        this.show('success', globalSetting.message);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || globalSetting.statusCode === STATUS_CODE_401) {
      if (globalSetting.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Global Setting</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          {globalSetting.globalSettingData ? (
            <EditForm
              data={globalSetting.globalSettingData.data}
              onUpdate={param => this.onUpdateGlobalSetting(param)}
            />
          ) : ''}
        </Card>
      </Container>
    );
  }
}

GlobalSetting.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  globalSetting: PropTypes.arrayOf(Object).isRequired,
  getGlobalSetting: PropTypes.func.isRequired,
  updateGlobalSetting: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { globalSetting } = state;
  return {
    globalSetting,
  };
};
const mapDispatchToProps = dispatch => ({
  getGlobalSetting: (...args) => dispatch(getGlobalSettingAction(...args)),
  updateGlobalSetting: (...args) => dispatch(updateGlobalSettingAction(...args)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(GlobalSetting));
