import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import ContactList from './ContactList';
import ContactDetailt from './ContactDetailt';
import {
  STATUS_CODE_401,
} from '../../../shared/utils/constants';
import {
  getContact as getContactAction,
} from '../../../redux/actions/contactAction';


class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      contactDetailView: null,
    };
  }

  componentWillMount = async () => {
    const { cookies, getContact } = this.props;
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    getContact();
  }

  cancelEdit = () => {
    this.setState({ contactDetailView: null });
  }

  render() {
    const { contact, cookies } = this.props;
    const { hasToken, contactDetailView } = this.state;
    if (!hasToken || contact.statusCode === STATUS_CODE_401) {
      if (contact.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{`${contactDetailView ? 'Contact Detail' : 'Contact Management'}`}</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          {contactDetailView ? (
            <ContactDetailt
              data={contactDetailView}
              onClickCancel={this.cancelEdit}
            />
          ) : (
            <ContactList
              contactEditing={item => this.onEditContact(item)}
              contactListData={contact.contactList}
            />
          )
          }
        </Card>
      </Container>
    );
  }
}

Contacts.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  contact: PropTypes.objectOf(PropTypes.object).isRequired,
  getContact: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { contact } = state;
  return {
    contact,
  };
};
const mapDispatchToProps = dispatch => ({
  getContact: (...args) => dispatch(getContactAction(...args)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(Contacts));
