/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  GET_CATEGORY_BY_ID,
  GET_CATEGORY_BY_ID_SUCCESS,
  GET_CATEGORY_BY_ID_FAIL,
  SORT_CATEGORY,
  SORT_CATEGORY_SUCCESS,
  SORT_CATEGORY_FAIL,
} from '../actions/categoryAction';
import {
  MSG_UPDATE_CATEGORY_SUCCESS,
  MSG_UPDATE_CATEGORY_FAIL,
  MSG_CREATE_CATEGORY_SUCCESS,
  MSG_CREATE_CATEGORY_FAIL,
  MSG_DELETE_CATEGORY_SUCCESS,
  MSG_DELETE_CATEGORY_FAIL,
} from '../../shared/utils/constants';

const initialState = {
  categoryList: [],
  message: '',
  isUpdate: false,
  isError: false,
  data: null,
  statusCode: '',
  categoryEdit: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES: {
      return { ...state, type: action.type };
    }
    case GET_CATEGORIES_SUCCESS: {
      return { ...state, ...{ type: action.type, categoryList: action.data } };
    }
    case GET_CATEGORIES_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case GET_CATEGORY_BY_ID: {
      return { ...state, type: action.type };
    }
    case GET_CATEGORY_BY_ID_SUCCESS: {
      return { ...state, ...{ type: action.type, categoryEdit: action.data } };
    }
    case GET_CATEGORY_BY_ID_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case CREATE_CATEGORY: {
      return { ...state, type: action.type, message: '' };
    }
    case CREATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        type: action.type,
        isError: false,
        message: MSG_CREATE_CATEGORY_SUCCESS,
      };
    }
    case CREATE_CATEGORY_FAIL: {
      return {
        ...state,
        ...{ type: action.type,
          isError: true,
          message: MSG_CREATE_CATEGORY_FAIL,
          statusCode: action.error ? action.error.statusCode.toString() : '',
        },
      };
    }
    case UPDATE_CATEGORY: {
      return { ...state, type: action.type, message: '' };
    }
    case UPDATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        message: MSG_UPDATE_CATEGORY_SUCCESS,
        ...{
          type: action.type,
          isError: false,
        },
      };
    }
    case UPDATE_CATEGORY_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: MSG_UPDATE_CATEGORY_FAIL,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    case DELETE_CATEGORY: {
      return { ...state, type: action.type, message: '' };
    }
    case DELETE_CATEGORY_SUCCESS: {
      return {
        ...state,
        message: MSG_DELETE_CATEGORY_SUCCESS,
        ...{
          type: action.type,
          isError: false,
        },
      };
    }
    case DELETE_CATEGORY_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        message: MSG_DELETE_CATEGORY_FAIL,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }

    case SORT_CATEGORY: {
      return { ...state, type: action.type };
    }
    case SORT_CATEGORY_SUCCESS: {
      return {
        ...state,
        ...{
          type: action.type,
          isError: false,
          categoryList: action.data,
        },
      };
    }
    case SORT_CATEGORY_FAIL: {
      return {
        ...state,
        type: action.type,
        isError: true,
        statusCode: action.error ? action.error.statusCode.toString() : '',
      };
    }
    default:
      return state;
  }
}
