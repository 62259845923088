import api from '../index';
import ApiConstants from '../ApiConstants';

export const getDepartments = () => api(
  `${ApiConstants.DEPARTMENT}`,
  null,
  'get',
  null,
);

export const createDepartment = department => api(
  `${ApiConstants.DEPARTMENT}`,
  department,
  'POST',
  null,
);

export const updateDepartment = args => api(
  `${ApiConstants.DEPARTMENT}/${args[0]}`,
  args[1],
  'PATCH',
  null,
);

export const getFunctionsByDepartmentId = departmentId => api(
  `${ApiConstants.FUNCTION}/${departmentId}`,
  null,
  'get',
  null,
);

export const createFunction = fun => api(
  `${ApiConstants.FUNCTION}`,
  fun,
  'post',
  null,
);
