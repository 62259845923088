/* eslint-disable object-curly-newline */
import React from 'react';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import CatList from './CatList';
import { BasicNotification } from '../../../../components/Notification/index';
import CatForm from './CatForm';
import {
  getCategories as getCategoriesAction,
  updateCategory as updateCategoryAction,
  deleteCategory as deleteCategoryAction,
  sortCategory as sortCategoryAction,
} from '../../../../redux/actions/categoryAction';
import {
  STATUS_CODE_401,
} from '../../../../shared/utils/constants';

let notificationRU = null;

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isForm: false,
      isShowNotify: false,
      type: '',
    };
  }

  componentWillMount = async () => {
    const { cookies, getCategories, match } = this.props;
    const { path } = match;
    if (path.includes('recruiter')) {
      this.setState({ type: 'RECRUITER' });
    }
    if (path.includes('jobseeker')) {
      this.setState({ type: 'CANDIDATE' });
    }
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
    getCategories();
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  onUpdateSortNumber = (items) => {
    const { sortCategory } = this.props;
    sortCategory(items);
  }

  onUpdateCategory = (id, params) => {
    const { updateCategory } = this.props;
    updateCategory(id, params);
    this.setState({ isShowNotify: true });
  }

  onDelete = (id) => {
    const { deleteCategory } = this.props;
    deleteCategory(id);
    this.setState({ isShowNotify: true });
  }

  canceForm = () => {
    this.setState({ isForm: false });
  }

  show = (color, message) => notificationRU.notice({
    content: <BasicNotification
      title="Posts"
      color={color}
      message={message}
    />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  })

  handleClickAddCategory = () => {
    this.setState({ isForm: true });
  }

  render() {
    const { category, cookies } = this.props;
    const { hasToken, isForm, isShowNotify, type } = this.state;
    const catList = category.categoryList.filter(item => item.catType === type);
    let titType = '';
    if (type === 'CANDIDATE') {
      titType = 'Jobseeker';
    }
    if (type === 'RECRUITER') {
      titType = 'Recruiter';
    }
    if (isShowNotify && category.message) {
      if (category.isError) {
        this.show('warning', category.message);
      } else {
        this.show('success', category.message);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || category.statusCode === STATUS_CODE_401) {
      if (category.statusCode === STATUS_CODE_401) cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <div>
        {isForm ? (
          <CatForm
            onCancelForm={this.canceForm}
            pType={type}
            isAddItem={isForm}
            catList={catList}
          />
        ) : (
          <Container>
            <Row>
              <Col md={12}>
                <h3 className="page-title">{titType} Category</h3>
              </Col>
            </Row>
            <Card body className="mb-5">
              <CatList
                catsList={catList}
                postType={type}
                updateSortNumber={items => this.onUpdateSortNumber(items)}
                categoryEditing={item => this.onEditCategory(item)}
                onAddCategory={this.handleClickAddCategory}
                onUpdateCategoryNews={(id, params) => this.onUpdateCategory(id, params)}
                onDeleteCategory={id => this.onDelete(id)}
              />
            </Card>
          </Container>
        )}
      </div>
    );
  }
}

Category.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  category: PropTypes.instanceOf(Object).isRequired,
  updateCategory: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  sortCategory: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { category } = state;
  return {
    category,
  };
};
const mapDispatchToProps = dispatch => ({
  getCategories: (...args) => dispatch(getCategoriesAction(...args)),
  updateCategory: (...args) => dispatch(updateCategoryAction(...args)),
  deleteCategory: id => dispatch(deleteCategoryAction(id)),
  sortCategory: items => dispatch(sortCategoryAction(items)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(Category));
