/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/named */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Button, Modal, ModalBody, Alert, ButtonGroup, ModalHeader, ModalFooter,
} from 'reactstrap';
import { toogleModalDelete } from '../../../redux/actions/modalAction';
import { deleteField, cloneField, saveTemplate } from '../../../redux/actions/templateAction';


class DeleteFieldModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
    };
  }

  componentDidMount() {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) this.toogleModalDelete();
    });
  }

  toogleModalDelete = () => {
    const { dispatch, modal } = this.props;
    const data = {
      isShow: !modal.isShow,
    };
    dispatch(toogleModalDelete(data));
  };

  handleDeleteClone = () => {
    const { modal } = this.props;
    const { isDelete } = modal;
    if (isDelete) {
      this.deleteField();
    } else {
      this.cloneField();
    }
  };

  cloneField = () => {
    const { dispatch, modal, template } = this.props;
    dispatch(cloneField(modal));
    dispatch(saveTemplate(template.data));
    this.toogleModalDelete();
  };

  deleteField = () => {
    const { dispatch, modal, template } = this.props;
    dispatch(deleteField(modal));
    dispatch(saveTemplate(template.data));
    this.toogleModalDelete();
  }

  render() {
    const { modal } = this.props;
    return (
      <div>
        <Modal isOpen={modal.isShow} toogle={this.toogleModalDelete}>
          <ModalHeader><h2>{modal.isDelete ? 'Confirm Delete!' : 'Confirm Clone!'}</h2></ModalHeader>
          <ModalBody>
            {modal.isDelete ? (
              <Alert color="danger">
                <h4>Do you want to delete it?</h4>
              </Alert>
            )
              : (
                <Alert color="primary">
                  <h4>Do you want to clone it?</h4>
                </Alert>
              )}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup className="float-right">
              <Button outline color="primary" size="sm" onClick={() => this.toogleModalDelete()}>Cancel</Button>
              <Button outline color="danger" size="sm" onClick={() => this.handleDeleteClone()}>OK</Button>
            </ButtonGroup>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

DeleteFieldModal.propTypes = {
  modal: PropTypes.objectOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  template: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  const { modal, template } = state;
  return {
    modal,
    template,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withTranslation('common')(DeleteFieldModal));
