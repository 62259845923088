import api from '../index';
import ApiConstants from '../ApiConstants';

export const getTemplate = () => api(
  `${ApiConstants.TEMPLATE}`,
  null,
  'get',
);

export const saveTemplate = (template) => {
  const type = template.id !== '' ? 'put' : 'post';
  return api(
    `${ApiConstants.TEMPLATE}`,
    template,
    type,
  );
};
