/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  ModalBody, ModalFooter, Form, FormGroup, Label, Input, Row, Col, Alert,
} from 'reactstrap';

class CommonAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    // eslint-disable-next-line react/no-unused-state
    };
  }

  render() {
    const {
      msgError,
      isError,
      dataField,
      modal,
      htmlButton,
      onDismissAlert,
      handleChangeEvent,
    } = this.props;
    const isEdit = modal.isEdit ? modal.isEdit : false;

    return (
      <div>
        <ModalBody>
          <h2>{ isEdit ? 'Edit Field' : 'Add New Field' }</h2>
          <Alert color="warning" isOpen={isError} toggle={onDismissAlert}>
            {msgError}
          </Alert>
          <hr />
          <Form onChange={handleChangeEvent}>
            <Row>
              <Col xs="5" sm="5">
                <FormGroup tag="fieldset">
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="type" value="InputText" checked={!!dataField.type && dataField.type === 'InputText'} />
                      InputText
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col xs="5" sm="5">
                <FormGroup tag="fieldset">
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="type" value="TextArea" checked={!!dataField.type && dataField.type === 'TextArea'} />
                      Text Area
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <FormGroup row>
              <Col for="label" sm={2}><strong>Label</strong></Col>
              <Col sm={5}>
                <Input type="text" name="labelEnglish" id="labelEnglish" placeholder="English" value={dataField.labelEnglish ? dataField.labelEnglish : ''} />
              </Col>
              <Col sm={5}>
                <Input type="text" name="labelVn" id="labelVn" placeholder="Vietnamese" value={dataField.labelVn ? dataField.labelVn : ''} />
              </Col>
            </FormGroup>
            {
              dataField.type && dataField.type === 'TextArea' && (
              <FormGroup row>
                <Col for="row" sm={2}><strong>Row</strong></Col>
                <Col sm={5}>
                  <Input type="number" name="row" id="row" placeholder="row" value={dataField.row ? dataField.row : ''} />
                </Col>
              </FormGroup>
              )
            }
            <FormGroup row>
              <Col sm={2} />
              <Col sm={5}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" name="isHidden" checked={!dataField.isHidden} />
                    Showed
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={5} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { htmlButton }
        </ModalFooter>
      </div>
    );
  }
}

CommonAttribute.propTypes = {
  modal: PropTypes.objectOf(PropTypes.object).isRequired,
  msgError: PropTypes.string,
  dataField: PropTypes.objectOf(PropTypes.object).isRequired,
  isError: PropTypes.bool,
  htmlButton: PropTypes.func.isRequired,
  onDismissAlert: PropTypes.func.isRequired,
  handleChangeEvent: PropTypes.func.isRequired,
};

CommonAttribute.defaultProps = {
  msgError: '',
  isError: false,
};

// const mapStateToProps = (state) => {
//   const { modal } = state;
//   return {
//     modal,
//   };
// };

export default connect(
  null,
  null,
)(withTranslation('common')(CommonAttribute));
