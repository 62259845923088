import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../components';
import validateForm from '../../../../shared/utils/validation';
import { schemaCardForm } from '../validation';
import './styleCardForm.scss';

const initFormInputs = { engInput: '', vietInput: '' };

const CardForm = ({
  onSubmit, cancelForm, setReadOnly, formValues, type, isReadOnly,
}) => {
  const [formInputs, setFormInputs] = useState(formValues);
  const [typeForm, setTypeForm] = useState(type);
  const [error, setError] = useState({});

  useEffect(() => {
    setFormInputs(formValues);
    setError({});
  }, formValues);

  useEffect(() => {
    setTypeForm(type);
  }, type);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormInputs({ ...formInputs, [name]: value });
  };

  const handleClickSubmit = () => {
    const { isError, error: err } = validateForm(schemaCardForm, formInputs);

    if (isError) {
      setError(err);
      return;
    }

    onSubmit(formInputs);
    setFormInputs(initFormInputs);
  };

  const handleClickCancel = () => {
    setTypeForm('add');
    cancelForm();
    setReadOnly(false);
    setError({});
  };

  return (
    <div className="card-form-wrapper">
      <div className="card-form__input">
        <Input
          readOnly={isReadOnly}
          placeholder="English"
          name="engInput"
          value={formInputs.engInput}
          onChange={handleChangeInput}
          errorMesssage={error.engInput || ''}
        />
        <Input
          readOnly={isReadOnly}
          placeholder="Vietnamese"
          name="vietInput"
          value={formInputs.vietInput}
          onChange={handleChangeInput}
          errorMesssage={error.vietInput || ''}
        />
      </div>
      <div>
        <button
          type="button"
          disabled={isReadOnly}
          className="card-form__btn card-form__btn--submit"
          onClick={handleClickSubmit}
        >
          {`${typeForm === 'add' ? 'Add' : 'Save'}`}
        </button>
        <button type="button" className="card-form__btn card-form__btn--cancel" onClick={handleClickCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

CardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  cancelForm: PropTypes.func,
  setReadOnly: PropTypes.func,
  type: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  formValues: PropTypes.objectOf(PropTypes.any),
};

CardForm.defaultProps = {
  formValues: {
    engInput: '',
    vietInput: '',
  },
  isReadOnly: false,
  cancelForm: () => {},
  setReadOnly: () => {},
};

export default CardForm;
