/* eslint-disable object-curly-newline */
import { take, call, put } from 'redux-saga/effects';
import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  GET_CATEGORY_BY_ID,
  GET_CATEGORY_BY_ID_SUCCESS,
  GET_CATEGORY_BY_ID_FAIL,
  SORT_CATEGORY,
  SORT_CATEGORY_SUCCESS,
  SORT_CATEGORY_FAIL,
} from '../actions/categoryAction';
import {
  getCategories,
  createCategory,
  updateCategory,
  getCategoryById,
  emptyCategory,
} from '../../shared/api/methods/category';

export function* getCategoriesSaga() {
  while (true) {
    try {
      yield take(GET_CATEGORIES);
      const data = yield call(getCategories);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_CATEGORIES_FAIL, error });
      } else {
        yield put({ type: GET_CATEGORIES_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_CATEGORIES_FAIL, error });
    }
  }
}

export function* createCategorySaga() {
  while (true) {
    try {
      const { params } = yield take(CREATE_CATEGORY);
      const data = yield call(createCategory, params);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: CREATE_CATEGORY_FAIL, error });
      } else {
        yield put({ type: CREATE_CATEGORY_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: CREATE_CATEGORY_FAIL, error });
    }
  }
}

export function* deleteCategorySaga() {
  while (true) {
    try {
      const { id } = yield take(DELETE_CATEGORY);
      const data = yield call(emptyCategory, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: DELETE_CATEGORY_FAIL, error });
      } else {
        yield put({ type: DELETE_CATEGORY_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: DELETE_CATEGORY_FAIL, error });
    }
  }
}

export function* updateCategorySaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_CATEGORY);
      const data = yield call(updateCategory, [id, params]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_CATEGORY_FAIL, error });
      } else {
        yield put({ type: UPDATE_CATEGORY_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: UPDATE_CATEGORY_FAIL, error });
    }
  }
}

export function* getCategoryByIdSaga() {
  while (true) {
    try {
      const { id } = yield take(GET_CATEGORY_BY_ID);
      const data = yield call(getCategoryById, id);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_CATEGORY_BY_ID_FAIL, error });
      } else {
        yield put({ type: GET_CATEGORY_BY_ID_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_CATEGORY_BY_ID_FAIL, error });
    }
  }
}

export function* sortCategorySaga() {
  while (true) {
    try {
      const { items } = yield take(SORT_CATEGORY);
      const data = yield Promise.all(items.catItems.map(async (item, i) => {
        const result = updateCategory([item.id, { sortNum: i }]);
        return result;
      }));
      if (data === null || Object.prototype.hasOwnProperty.call(data[0], 'error')) {
        const { error } = data[0];
        yield put({ type: SORT_CATEGORY_FAIL, error });
      } else {
        yield put({ type: SORT_CATEGORY_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: SORT_CATEGORY_FAIL, error });
    }
  }
}
