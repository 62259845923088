import { take, call, put } from 'redux-saga/effects';
import {
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAIL,
  GET_FUNCTIONS,
  UPDATE_DEPARTMENT,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAIL,
  CREATE_DEPARTMENT,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAIL,
} from '../actions/departmentAction';
import {
  getDepartments,
  getFunctionsByDepartmentId,
  updateDepartment,
  createDepartment,
} from '../../shared/api/methods/department';

export function* getDepartmentsSaga() {
  while (true) {
    try {
      yield take(GET_DEPARTMENTS);
      const data = yield call(getDepartments);
      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_DEPARTMENTS_FAIL, error });
      } else {
        yield put({ type: GET_DEPARTMENTS_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_DEPARTMENTS_FAIL, error });
      yield put({ type: GET_DEPARTMENTS, data: [] });
    }
  }
}

export function* getFunctionByDepartmentIdSaga() {
  while (true) {
    try {
      const { departmentId } = yield take(GET_FUNCTIONS);
      const data = yield call(getFunctionsByDepartmentId, departmentId);
      yield put({ type: GET_FUNCTIONS, data });
    } catch (error) {
      yield put({ type: GET_FUNCTIONS, data: [] });
    }
  }
}

export function* updateDepartmentSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_DEPARTMENT);
      const data = yield call(updateDepartment, [id, params]);
      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_DEPARTMENT_FAIL, error });
      } else {
        yield put({ type: UPDATE_DEPARTMENT_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: UPDATE_DEPARTMENT_FAIL, error });
    }
  }
}

export function* createDepartmentSaga() {
  while (true) {
    try {
      const { params } = yield take(CREATE_DEPARTMENT);
      let data = yield call(createDepartment, params);
      if (typeof data === 'string') {
        data = { data, error: null };
      }
      if (Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: CREATE_DEPARTMENT_FAIL, error });
      } else {
        yield put({ type: CREATE_DEPARTMENT_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: CREATE_DEPARTMENT_FAIL, error });
    }
  }
}
