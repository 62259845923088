import React from 'react';
import PropTypes from 'prop-types';
import '../Input/styleInput.scss';

const TextArea = ({ errorMesssage, styles, classRoot, ...props }) => (
  <div className={`input-container ${classRoot}`}>
    <textarea style={styles} className={classRoot} {...props} />
    <div className={`${errorMesssage ? 'show-err' : ''} error-message`}>{errorMesssage}</div>
  </div>
);

TextArea.propTypes = {
  classRoot: PropTypes.string,
  errorMesssage: PropTypes.string,
};

TextArea.defaultProps = {
  classRoot: '',
  errorMesssage: '',
};

export default TextArea;
