/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import Table from '../../../../../components/Table/index';
import './styleCatList.scss';

class CatList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleClickAddCategory = () => {
    const { history } = this.props;
    const listUrl = this.defineBaseUrl();
    history.push(`${listUrl}/add`);
  }

  handleClickDeleteRow = (idRow) => {
    const { onDeleteCategory } = this.props;
    onDeleteCategory(idRow);
  };

  defineBaseUrl = () => {
    const { cateType } = this.props;
    let listUrl = '';
    if (cateType === 'CANDIDATE') {
      listUrl = '/admin/faq-jobseeker/category';
    }
    if (cateType === 'RECRUITER') {
      listUrl = '/admin/faq-recruiter/category';
    }

    return listUrl;
  };

  render() {
    const { catList } = this.props;
    const listUrl = this.defineBaseUrl();

    return (
      <Card>
        <Row>
          <div className="card-form-wrapper">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.handleClickAddCategory()}
            >
              Add
            </button>
          </div>
        </Row>
        <Table
          data={catList}
          columns={[
            {
              Header: 'Name (EN)',
              accessor: 'content.en',
              sortable: true,
            },
            {
              Header: 'Name (VI)',
              accessor: 'content.vi',
              sortable: true,
            },
            {
              Header: 'Order number',
              accessor: 'sortNum',
              sortable: true,
            },
            {
              Header: 'Actions',
              accessor: null,
              Cell: (row) => {
                const { id } = row.original;
                return (
                  <div className="table-actions">
                    <Link to={`${listUrl}/edit/${id}`}>
                      Edit
                    </Link>
                    <span
                      role="presentation"
                      className="action-delete"
                      onClick={() => this.handleClickDeleteRow(id)}
                    >
                      Delete
                    </span>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          className="custom-table"
          showPaginationBottom
          sortable={false}
        />
      </Card>
    );
  }
}

CatList.propTypes = {
  // categoryEditing: PropTypes.func.isRequired,
  catList: PropTypes.instanceOf(Object).isRequired,
  cateType: PropTypes.string.isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(CatList);
