import api from '../index';
import ApiConstants from '../ApiConstants';

// eslint-disable-next-line arrow-body-style
export const getExpertisesByFunctionId = (departmentId) => {
  return api(`${ApiConstants.EXPERTISE}/${departmentId}/expertise`, null, 'get', null);
};

export const createExpertise = params => api(`${ApiConstants.EXPERTISE}`, params, 'POST', null);

export const updateExpertise = args => api(`${ApiConstants.EXPERTISE}/${args[0]}`, args[1], 'PATCH', null);

export const deleteExpertiseById = expertiseId => api(`${ApiConstants.EXPERTISE}/${expertiseId}`, null, 'DELETE', null);
