/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Card, Row, Modal, ModalBody, ButtonGroup, ModalHeader, ModalFooter } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import Table from '../../../../components/Table/index';
import './styleHotIndustriesList.scss';
import {
  MSG_CONFIRM_DELETE,
} from '../../../../shared/utils/constants';

library.add(
  fab,
  fas,
);

const SortableItem = SortableElement(({ item }) => (
  <tr>
    <td>{item.name.en}</td>
    <td>{item.name.vi}</td>
  </tr>
));

const SortableList = SortableContainer(({ items }) => (
  <table className="order-table">
    <thead>
      <tr>
        <th scope="col">Name (EN)</th>
        <th scope="col">Name (VI)</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item, i) => (
        <SortableItem key={item.id} index={i} item={item} />
      ))}
    </tbody>
  </table>
));

class HotIndustriesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotIndustriesItems: [],
      isOpenOrder: false,
      isPopupDelete: false,
      deleteItemId: null,
    };
  }

  componentWillMount = () => {
    const { hotIndustriesList } = this.props;
    this.setState({ hotIndustriesItems: hotIndustriesList });
  }

  componentDidMount() {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) this.handleCancelModal();
    });
  }

  handleClickEditRow = (idRow) => {
    const { hotIndustriesList, hotIndustriesEditing } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    const hotIndustriesDetailView = hotIndustriesList.find(item => item._id === idRow);
    if (hotIndustriesDetailView) {
      hotIndustriesEditing(hotIndustriesDetailView);
    }
  }

  handleClickDeleteRow = (idRow) => {
    const { onDeleteHotIndustries } = this.props;
    onDeleteHotIndustries(idRow);
    this.handleCancelModal();
  };


  onClickAddHotIndustries = () => {
    const { onAddHotIndustries } = this.props;
    onAddHotIndustries();
  }

  handleCancelModal = () => {
    this.setState({
      isOpenOrder: false,
      isPopupDelete: false,
      deleteItemId: null,
    });
  };

  handleShowModal = () => {
    const { hotIndustriesList } = this.props;
    this.setState({
      isOpenOrder: true,
      hotIndustriesItems: hotIndustriesList,
    });
  };

  handleShowModalDelete = (id) => {
    this.setState({
      isPopupDelete: true,
      deleteItemId: id,
    });
  };

  onReorderHotIndustries = () => {
    const { updateSortNumber } = this.props;
    const { hotIndustriesItems } = this.state;
    updateSortNumber(hotIndustriesItems);
    this.handleCancelModal();
  }

  renderPopupOrder = () => {
    const { hotIndustriesItems, isOpenOrder } = this.state;
    return (
      <Modal isOpen={isOpenOrder} toogle={this.toogleModalSave} className="order-popup">
        <ModalHeader>Reorder Hot Industries</ModalHeader>
        <ModalBody>
          <SortableList items={hotIndustriesItems} onSortEnd={this.onSortEnd} />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup className="float-right">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.onReorderHotIndustries()}
            >
              Save
            </button>
            <button
              type="button"
              className="card-form__btn card-form__btn--cancel"
              color="danger"
              onClick={() => this.handleCancelModal()}
            >
              Cancel
            </button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }

  renderPopupConfirmDelete = () => {
    const { deleteItemId, isPopupDelete } = this.state;
    return (
      <Modal isOpen={isPopupDelete} toogle={this.toogleModalSave} className="order-popup">
        <ModalHeader>Delete Hot Industries</ModalHeader>
        <ModalBody>
          {MSG_CONFIRM_DELETE}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup className="float-right">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.handleClickDeleteRow(deleteItemId)}
            >
              Yes
            </button>
            <button
              type="button"
              className="card-form__btn card-form__btn--cancel"
              color="danger"
              onClick={() => this.handleCancelModal()}
            >
              Cancel
            </button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ hotIndustriesItems }) => ({
      hotIndustriesItems: arrayMove(hotIndustriesItems, oldIndex, newIndex),
    }));
  };

  renderHotIndustriesList = () => {
    const { hotIndustriesList } = this.props;
    const listUrl = '/admin/hot-industries';
    return (
      <Table
        data={hotIndustriesList}
        columns={[
          {
            Header: 'Order',
            accessor: 'sortNum',
            sortable: true,
          },
          {
            Header: 'Name',
            accessor: 'name.en',
            sortable: true,
          },
          {
            Header: 'Label',
            accessor: 'label.vi',
            sortable: true,
          },
          {
            Header: 'Icon',
            accessor: null,
            Cell: (row) => {
              const { icon } = row.original;
              return (
                <FontAwesomeIcon icon={['fas', icon]} size="2x" style={{ color: '#026bbd' }} />
              );
            },
          },
          {
            Header: 'Actions',
            accessor: null,
            Cell: (row) => {
              const { id } = row.original;
              return (
                <div className="table-actions">
                  <Link to={`${listUrl}/${id}`}>
                    Edit
                  </Link>
                  <span
                    role="presentation"
                    className="action-delete"
                    onClick={() => this.handleShowModalDelete(id)}
                  >
                    Delete
                  </span>
                </div>

              );
            },
          },
        ]}
        defaultSorted={[
          {
            id: 'sortNum',
            desc: false,
          },
        ]}
        defaultPageSize={10}
        className="custom-table"
        showPaginationBottom
        sortable={false}
      />
    );
  }

  render() {
    return (
      <Card>
        <Row>
          <div className="card-form-wrapper">
            <button
              type="button"
              className="card-form__btn card-form__btn--submit"
              color="primary"
              onClick={() => this.onClickAddHotIndustries()}
            >
              Add
            </button>
            <button
              type="button"
              className="card-form__btn card-form__btn--save"
              color="primary"
              onClick={() => this.handleShowModal()}
            >
              Reorder
            </button>
          </div>
        </Row>
        {this.renderHotIndustriesList()}
        {this.renderPopupOrder()}
        {this.renderPopupConfirmDelete()}
      </Card>
    );
  }
}

HotIndustriesList.propTypes = {
  onAddHotIndustries: PropTypes.func.isRequired,
  hotIndustriesEditing: PropTypes.func.isRequired,
  hotIndustriesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateSortNumber: PropTypes.func.isRequired,
  onDeleteHotIndustries: PropTypes.func.isRequired,
};

export default HotIndustriesList;
