/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Form, FormGroup, Label } from 'reactstrap';
import { changeTextToSlug } from '../../../../../shared/utils/calculation';
import validateForm from '../../../../../shared/utils/validation';
import schemaTagForm from './validation';
import './styleEditForm.scss';

class EditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemEditing: null,
      enName: '',
      viName: '',
      description: '',
      slug: '',
      error: '',
    };
  }

  componentWillMount = async () => {
    const { data } = this.props;
    if (data != null) {
      this.setState({
        itemEditing: data,
        enName: data.name.en,
        viName: data.name.vi,
        description: data.description,
        slug: data.slug,
      });
    }
  }

  handleClickSave = () => {
    const { save, tagType } = this.props;
    const { enName, viName, description, slug, itemEditing } = this.state;
    const formData = { enName, viName, slug };
    const { isError, error: err } = validateForm(schemaTagForm, formData);
    if (isError) {
      this.setState({ error: err });
      return;
    }
    let id;
    const item = {
      name: {
        en: enName,
        vi: viName,
      },
      description,
      slug,
      tagType,
    };
    if (itemEditing != null) {
      // eslint-disable-next-line prefer-destructuring
      id = itemEditing.id;
    }
    save(id, item);
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  }

  handleChange = (e) => {
    if (e.target.name === 'enName') {
      this.setState({ slug: changeTextToSlug(e.target.value) });
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  renderButton() {
    return (
      <div className="card-form-wrapper">
        <button
          type="button"
          color="primary"
          className="card-form__btn card-form__btn--submit"
          onClick={() => this.handleClickSave()}
        >
          Save & Close
        </button>
        <button
          type="button"
          color="danger"
          className="card-form__btn card-form__btn--cancel"
          onClick={() => this.handleCancel()}
        >
          Close
        </button>
      </div>
    );
  }

  render() {
    const { enName, viName, description, slug, error } = this.state;
    return (
      <Form className="form-tag">
        <FormGroup row>
          <Col sm={6}>
            <Label for="enName">Name (EN)</Label>
            <Input type="text" id="enName" name="enName" onChange={e => this.handleChange(e)} value={enName} />
            <div className={`${error.enName ? 'show-err' : ''} error-message`}>{error.enName}</div>
          </Col>
          <Col sm={6}>
            <Label for="viName">Name (VI)</Label>
            <Input type="text" id="viName" name="viName" onChange={e => this.handleChange(e)} value={viName} />
            <div className={`${error.viName ? 'show-err' : ''} error-message`}>{error.viName}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="slug">Slug</Label>
            <Input type="text" id="slug" name="slug" onChange={e => this.handleChange(e)} value={slug} />
            <div className={`${error.slug ? 'show-err' : ''} error-message`}>{error.slug}</div>
          </Col>
          <Col sm={6}>
            <Label for="description">Description</Label>
            <Input type="text" id="description" name="description" onChange={e => this.handleChange(e)} value={description} />
          </Col>
        </FormGroup>
        {this.renderButton()}
      </Form>
    );
  }
}

EditForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  save: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tagType: PropTypes.string.isRequired,
};

export default EditForm;
