/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Form, FormGroup, Label } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validateForm from '../../../../shared/utils/validation';
import schemaHotIndustriesForm from './validation';
import './styleEditForm.scss';

library.add(
  fab,
  fas,
);

class EditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemEditing: null,
      enName: '',
      viName: '',
      enLabel: '',
      viLabel: '',
      icon: '',
      error: '',
      idIndustry: '',
    };
  }

  componentWillMount = () => {
    const { data } = this.props;
    if (data != null) {
      this.setState({
        itemEditing: data,
        enName: data.name.en,
        viName: data.name.vi,
        enLabel: data.label.en,
        viLabel: data.label.vi,
        icon: data.icon,
        idIndustry: data.idIndustry,
      });
    }
  }

  handleClickSave = () => {
    const { save, hotIndustriesList } = this.props;
    const { itemEditing, enName, viName, enLabel, viLabel, icon, idIndustry } = this.state;
    const formData = { icon, enLabel, viLabel, idIndustry };
    const { isError, error: err } = validateForm(schemaHotIndustriesForm, formData);
    if (isError) {
      this.setState({ error: err });
      return;
    }
    let id;
    const item = {
      icon,
      name: {
        en: enName,
        vi: viName,
      },
      label: {
        en: enLabel,
        vi: viLabel,
      },
    };
    if (itemEditing != null) {
      id = itemEditing.id;
    } else {
      item.sortNum = hotIndustriesList.length;
      item.idIndustry = idIndustry;
    }
    save(id, item);
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeIdIndustry = (e) => {
    const { industries } = this.props;
    const func = industries.find(item => item.id === e.target.value);
    if (func) {
      this.setState({
        idIndustry: e.target.value,
        enName: func.value.en,
        viName: func.value.vi,
      });
    }
  }

  renderButton() {
    return (
      <div className="card-form-wrapper">
        <button
          type="button"
          color="primary"
          className="card-form__btn card-form__btn--submit"
          onClick={() => this.handleClickSave()}
        >
          Save & Close
        </button>
        <button
          type="button"
          color="danger"
          className="card-form__btn card-form__btn--cancel"
          onClick={() => this.handleCancel()}
        >
          Close
        </button>
      </div>
    );
  }

  render() {
    const { enName, viName, enLabel, viLabel, error, icon, idIndustry } = this.state;
    const { industries, data } = this.props;
    const industriesOptions = industries.map(item => <option value={item.id}>{item.value.en}</option>);
    return (
      <Form className="form-cat">
        { !data ? (
          <FormGroup row>
            <Col sm={6}>
              <Label for="idIndustry">Industry</Label>
              <Input type="select" name="idIndustry" id="idIndustry" onChange={e => this.handleChangeIdIndustry(e)} value={idIndustry}>
                <option>Choose One</option>
                {industriesOptions}
              </Input>
              <div className={`${error.idIndustry ? 'show-err' : ''} error-message`}>{error.idIndustry}</div>
            </Col>
          </FormGroup>
        ) : ''
        }
        <FormGroup row>
          <Col sm={6}>
            <Label for="enName">Name (EN)</Label>
            <Input type="text" id="enName" name="enName" disabled value={enName} />
          </Col>
          <Col sm={6}>
            <Label for="viName">Name (VI)</Label>
            <Input type="text" id="viName" name="viName" disabled value={viName} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="enLabel">Label (EN)</Label>
            <Input type="text" id="enLabel" name="enLabel" onChange={e => this.handleChange(e)} value={enLabel} />
            <div className={`${error.enLabel ? 'show-err' : ''} error-message`}>{error.enLabel}</div>
          </Col>
          <Col sm={6}>
            <Label for="viLabel">Label (VI)</Label>
            <Input type="text" id="viLabel" name="viLabel" onChange={e => this.handleChange(e)} value={viLabel} />
            <div className={`${error.enLabel ? 'show-err' : ''} error-message`}>{error.enLabel}</div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label for="icon">Icon (<a href="https://fontawesome.com/icons?d=gallery&s=solid&m=free" target="_blank">Icon List</a>)</Label>
            <Input type="text" id="icon" name="icon" onChange={e => this.handleChange(e)} value={icon} />
            <div className={`${error.icon ? 'show-err' : ''} error-message`}>{error.icon}</div>
            <FontAwesomeIcon mb={20} icon={['fas', icon]} size="3x" style={{ color: '#026bbd', marginTop: '15px' }} />
          </Col>
        </FormGroup>
        {this.renderButton()}
      </Form>
    );
  }
}

EditForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  save: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  hotIndustriesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  industries: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EditForm;
