import api from '../index';
import ApiConstants from '../ApiConstants';

const authentication = email => api(
  `${ApiConstants.LOGIN}`,
  email,
  'post',
  null,
);

export { authentication as default };
